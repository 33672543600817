export const kycRoutes = {
  base: {
    path: '/identity-verification',
    title: 'Identity Verification',
  },
  complete: {
    path: '/identity-verification/complete',
    title: 'Verification Complete',
  },
  reminder: {
    path: '/identity-verification/reminder',
    title: 'Please Verify Your Identity',
  },
};
