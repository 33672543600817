import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { LowDialog, PrimaryButton } from '@noah-labs/fe-shared-ui-components';
import { useToggleDialog } from '@noah-labs/fe-shared-ui-shared';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';

export const ReceiveAddressInfoDialog = forwardRef<TpDialogToggle>((_, ref): JSX.Element => {
  const { open, toggle } = useToggleDialog({ ref });

  return (
    <LowDialog open={open} onClose={toggle}>
      <DialogTitle>Receiving cryptocurrency? Make sure it’s the right address</DialogTitle>
      <DialogContent>
        <DialogContentText paragraph>
          Imagine each of your crypto addresses as a unique mailbox, each designed for a specific
          currency. Sending the wrong currency to a mailbox can mean it’s lost for good.
        </DialogContentText>
        <DialogContentText>
          Always double-check that the coin matches the address!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton color="primary" onClick={toggle}>
          Got It
        </PrimaryButton>
      </DialogActions>
    </LowDialog>
  );
});
ReceiveAddressInfoDialog.displayName = ReceiveAddressInfoDialog.name;
