import type { TpUserWithdrawalAllowance } from '@noah-labs/fe-shared-data-access-user';
import { useUserLimitsQuery } from '@noah-labs/fe-shared-data-access-user';
import {
  selectFromFilterWithFallback,
  useAccountsQueryPoll,
} from '@noah-labs/fe-shared-data-access-wallet';
import { getAccountUi } from '@noah-labs/fe-shared-ui-shared';
import type { TpCryptoCurrency, TpCryptoNetwork } from '@noah-labs/shared-currencies';
import type { AccountType } from '@noah-labs/shared-schema-gql';
import { processAllowances } from '../allowances';

type PpUseUserWithdrawalAllowance = {
  accountType: AccountType | undefined;
  cryptoCurrency: TpCryptoCurrency | undefined;
  cryptoNetwork?: TpCryptoNetwork;
};
export function useUserWithdrawalAllowance({
  accountType,
  cryptoCurrency,
  cryptoNetwork,
}: PpUseUserWithdrawalAllowance): TpUserWithdrawalAllowance | undefined {
  const { data: account } = useAccountsQueryPoll(undefined, {
    select: (data) => {
      if (!accountType || !cryptoCurrency) {
        return undefined;
      }
      return getAccountUi(
        selectFromFilterWithFallback({
          AccountType: accountType,
          CurrencyCode: cryptoCurrency.code,
          data,
        }),
      );
    },
  });
  const { data: withdrawalAllowance } = useUserLimitsQuery(
    { currencyCodes: cryptoCurrency ? [cryptoCurrency.code] : undefined },
    {
      enabled: !!account,
      select: (data): TpUserWithdrawalAllowance =>
        processAllowances({
          availableBalanceCrypto: account?.Balance?.Available,
          cryptoNetwork,
          limitsData: data,
        }),
    },
  );

  return withdrawalAllowance;
}
