import type { StreetAddress } from '@noah-labs/shared-schema-gql';

export function formatStreetAddress(
  address: Omit<StreetAddress, 'CountryCode'> | null | undefined,
): string {
  if (!address) {
    return '';
  }

  const { City, PostCode, State, Street, Street2 } = address;

  return [Street, Street2, State, City, PostCode].filter(Boolean).join(', ');
}
