import { useUserAccountsReceive } from '@noah-labs/fe-shared-feature-user';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import { AccountCardList, SelectAccountScene } from '@noah-labs/fe-shared-ui-wallet';
import { getReceiveCryptoRoute } from '@noah-labs/fe-shared-util-routes';
import { isFalseyOrEmptyArray } from '@noah-labs/shared-util-vanilla';
import { AccountCardData } from '../../components/AccountCardData';

export function DepositAccountController(): React.ReactElement {
  const { data: accountsData, isFetched } = useUserAccountsReceive();
  const AccountsSlot = (
    <AccountCardList isFetched={isFetched}>
      {!isFalseyOrEmptyArray(accountsData) &&
        accountsData.map((account) => {
          const receivePath = getReceiveCryptoRoute(
            account.cryptoCurrency.requireNetworkSelection.receive,
          );
          return (
            <AccountCardData
              key={account.ID}
              balance={account.Balance?.Available}
              cryptoCurrency={account.cryptoCurrency}
              href={generatePath(receivePath, {
                accountType: account.AccountType,
                currencyCode: account.CurrencyCode,
              })}
              id={account.ID}
            />
          );
        })}
    </AccountCardList>
  );

  return (
    <SelectAccountScene
      AccountsSlot={AccountsSlot}
      subTitle="Select which of your NOAH accounts you are depositing to."
      title="Select account"
    />
  );
}
