import { useCallback } from 'react';
import {
  useOry,
  useOrySettingsFlowError,
  useOryUpdatePasswordFlowCallback,
} from '@noah-labs/fe-shared-data-access-auth';
import { ForgottenPasswordNewPasswordScene } from '@noah-labs/fe-shared-ui-auth';
import type { PpForgottenPasswordNewPasswordScene } from '@noah-labs/fe-shared-ui-auth';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import { authRoutes } from '@noah-labs/fe-shared-util-routes';
import { useUpdateNewPasswordFlow } from '../hooks/useUpdateNewPasswordFlow';

type PpForgottenPasswordNewPassword = {
  helpButton?: React.ReactNode;
};

export function ForgottenPasswordNewPassword({
  helpButton,
}: PpForgottenPasswordNewPassword): React.ReactElement {
  const ory = useOry();
  const { push } = useRouter();

  const updatePasswordFlowCallback = useOryUpdatePasswordFlowCallback();
  const flowError = useOrySettingsFlowError();

  const returnToFromParams = new URLSearchParams(window.location.search).get('returnTo');
  const returnTo = returnToFromParams
    ? new URL(returnToFromParams, window.location.origin).toString()
    : ory.returnTo;

  const { error: newPasswordFlowError, isLoading: newPasswordFlowLoading } =
    useUpdateNewPasswordFlow(returnTo);

  const onUpdate = useCallback<PpForgottenPasswordNewPasswordScene['onUpdate']>(
    async (values, setError) => {
      const settingsFlow = await updatePasswordFlowCallback(values, setError);
      if (settingsFlow?.id === undefined) {
        return;
      }
      const successPath = settingsFlow.return_to
        ? {
            pathname: authRoutes.newPasswordComplete.path,
            search: new URLSearchParams({ returnTo: settingsFlow.return_to }).toString(),
          }
        : authRoutes.newPasswordComplete.path;

      push(successPath);
    },
    [push, updatePasswordFlowCallback],
  );

  return (
    <ForgottenPasswordNewPasswordScene
      error={flowError || newPasswordFlowError}
      helpButton={helpButton}
      loading={newPasswordFlowLoading}
      onUpdate={onUpdate}
    />
  );
}
