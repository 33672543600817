import type {
  AppConfigManualRamp,
  FiatCurrencyCode,
  TransactionRecord,
} from '@noah-labs/shared-schema-gql';
import { safeBN } from '@noah-labs/shared-util-vanilla';
import BigNumber from 'bignumber.js';
import { getManualRampCurrencyConfig } from './getManualRampCurrencyConfig';
import type { TpAmount, TpSimpleAmount } from './types';

type TpCalculateFee = {
  amount: TpAmount;
  fee: TpAmount;
  feeMin?: TpAmount;
  promotion?: TpAmount; // added promotion as an optional argument
};

export function calculateFee({ amount, fee, feeMin, promotion }: TpCalculateFee): BigNumber {
  // calculate fee as (amount * fee) / 100
  let feeAmount = safeBN(amount).multipliedBy(safeBN(fee)).dividedBy(100);

  // if feeMin exists, ensure feeAmount is at least feeMin
  if (feeMin) {
    feeAmount = BigNumber.max(feeAmount, feeMin);
  }

  if (promotion) {
    feeAmount = feeAmount.minus(safeBN(promotion));
  }

  return BigNumber.max(feeAmount, 0);
}

export function getSourceRecordFee(
  sourceRecords: TransactionRecord[] | undefined | null,
): string | undefined {
  return sourceRecords?.find((item) => item.Amount)?.Amount;
}

type TpGetRampFeeFiatAmount = {
  feeMinimumFiatAmount: string;
  feePercentage: string;
  fiatAmount: string | undefined;
  fiatCurrencyCode: FiatCurrencyCode | undefined;
  isManualRamp: boolean;
  manualRampConfig: AppConfigManualRamp;
  sourceRecords: TransactionRecord[] | undefined | null;
};
export function getRampFeeFiatAmount({
  feeMinimumFiatAmount,
  feePercentage,
  fiatAmount,
  fiatCurrencyCode,
  isManualRamp,
  manualRampConfig,
  sourceRecords,
}: TpGetRampFeeFiatAmount): string | undefined {
  const feeRecord = getSourceRecordFee(sourceRecords);
  if (feeRecord) {
    return feeRecord;
  }

  if (isManualRamp && fiatCurrencyCode) {
    const manualRampCurrencyConfig = getManualRampCurrencyConfig({
      config: manualRampConfig,
      fiatCurrencyCode,
    });
    return calculateFee({
      amount: fiatAmount,
      fee: manualRampCurrencyConfig?.FeePercentage,
      feeMin: manualRampCurrencyConfig?.FeeMinimumFiatAmount,
    }).toString();
  }

  return calculateFee({
    amount: fiatAmount,
    fee: feePercentage,
    feeMin: feeMinimumFiatAmount,
  }).toString();
}

export type TpSlippage = {
  slippage: string;
  type: 'positive' | 'negative';
};
/**
 * Adds slippage to an amount and returns the new amount
 */
export function withSlippage(amount: null | undefined, slippage: TpSlippage): undefined;
export function withSlippage(amount: string | number, slippage: TpSlippage): string;
export function withSlippage(amount: TpAmount, slippage: TpSlippage): TpSimpleAmount;
export function withSlippage(amount: TpAmount, slippage: TpSlippage): TpSimpleAmount {
  if (!amount) {
    return undefined;
  }
  const percent = safeBN(slippage.slippage).dividedBy(100);
  const one = safeBN(1);
  const factor = slippage.type === 'positive' ? one.plus(percent) : one.minus(percent);
  return safeBN(amount).times(factor).toString();
}
