import { useLogOryFlowError } from '@noah-labs/fe-shared-data-access-auth';
import { ErrorPage, useSearchParams } from '@noah-labs/fe-shared-ui-components';

export function AuthError(): React.ReactElement {
  const params = useSearchParams();
  const id = params?.get('id');
  useLogOryFlowError(id);

  return <ErrorPage message="Something went wrong" />;
}
