/// <reference types="@noah-labs/ts-config/@types/images" />

import type { TpFeFeatureFlag } from '@noah-labs/fe-shared-data-access-user';
import type {
  TpCryptoCurrency,
  TpCryptoNetwork,
  TpFiatCurrency,
} from '@noah-labs/shared-currencies';
import type {
  FiatCurrencyCode,
  FiatPaymentCardScheme,
  StreetAddress,
} from '@noah-labs/shared-schema-gql';
import type * as CSS from 'csstype';

type CSSObject = CSS.Properties;

/**
 * Type Naming:
 *
 * - General Types: prefix with Tp, e.g. TpConfig
 * - Props: prefix with Pp, e.g. PpErrorPage
 * - State: prefix with St e.g. StAlerts
 * - Context: prefix with Cx e.g. CxAlertPush
 * - Router Params: prefix with Rp e.g. RpCurrency
 *
 */

/**
 * Props With Children
 */
export type PpWC = {
  children: React.ReactNode;
};
/**
 * Props With Optional Children
 */
export type PpWOC = {
  children?: React.ReactNode;
};

/**
 * Currency Types
 */
export type TpExtraFiatCurrencyUI = {
  svg: TpReactSvg | null;
};
export type TpExtraCryptoCurrencyUI = {
  styles: {
    avatar: CSSObject;
  };
  svg: TpReactSvg;
};
export type TpExtraCryptoNetworkUI = {
  getPublicTxUrl: (publicId: string | undefined | null) => string | undefined;
  styles: {
    border: CSSObject;
    card: CSSObject;
  };
  svg: TpReactSvg;
};

export type TpCryptoCurrencyUI = TpCryptoCurrency &
  TpExtraCryptoCurrencyUI & {
    availableNetworks: (ff: TpFeFeatureFlag) => TpCryptoNetworkUI[];
  };
export type TpFiatCurrencyUI = TpFiatCurrency & TpExtraFiatCurrencyUI;
export type TpAllCurrenciesUI = TpCryptoCurrencyUI | TpFiatCurrencyUI;
export type TpCryptoNetworkUI = TpCryptoNetwork & TpExtraCryptoNetworkUI;

export enum TpPaymentMethod {
  ApplePay = 'Apple Pay',
  BankTransfer = 'Bank transfer',
  Card = 'Credit/Debit card',
  GooglePay = 'Google Pay',
}

export type TpPaymentBank = {
  accountCurrency: FiatCurrencyCode;
  accountHolderAddress: StreetAddress | undefined | null;
  accountNumber: string;
  bankCode: string | null | undefined;
  estimateHours?: string | undefined | null;
  id?: string;
  payoutMethod?: TpPaymentMethod;
  payoutSupported?: boolean;
};

export type TpPaymentCard = {
  billingAddress: StreetAddress | undefined | null;
  estimateHours?: string | undefined | null;
  /** Checkout token if card is new, DynamoID if card is saved */
  id: string;
  issuer: string | undefined | null;
  last4: string;
  payoutMethod?: TpPaymentMethod;
  payoutSupported?: boolean;
  scheme: FiatPaymentCardScheme | undefined;
};

export type TpTransactionType = 'Buy' | 'Deposit' | 'Sell' | 'Withdraw';

export type TpUseError = {
  ApiErrorScene: React.ReactElement | null;
};

/**
 * Use undefined for a value that is not yet known
 * TextOrSkeleton will render loading state if value is null or undefined
 */
export type TpSkeletonText = string | number | null | undefined;
