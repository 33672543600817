import React from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import type { TpAddressData } from '@noah-labs/fe-shared-ui-address';
import type { TpCurrencyAmountSlots } from '@noah-labs/fe-shared-ui-components';
import {
  ButtonGroup,
  CryptoAmount,
  FooterContentText,
  List,
  ListItem,
  ListItemCard,
  ListItemContent,
  ListItemEndContent,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  LowDialog,
  PrimaryButton,
  TruncatedText,
} from '@noah-labs/fe-shared-ui-components';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-shared';
import { NetworkFeeItem } from '@noah-labs/fe-shared-ui-wallet';
import type { TpAmount } from '@noah-labs/shared-currencies';
import { CurrencyCode } from '@noah-labs/shared-schema-gql';

export type PpConfirm = {
  FeeAmountSlots: TpCurrencyAmountSlots;
  TotalAmountSlots: TpCurrencyAmountSlots;
  addressData: TpAddressData | null;
  cancelPayment: () => void;
  email: string | undefined;
  isCtaDisabled: boolean;
  isLightningPayment: boolean;
  loadedPrice?: TpAmount | undefined;
  onConfirm: () => void;
  open: boolean;
  submitLoading: boolean;
};

export function Confirm({
  addressData,
  cancelPayment,
  email,
  FeeAmountSlots,
  isCtaDisabled,
  isLightningPayment,
  loadedPrice,
  onConfirm,
  open,
  submitLoading,
  TotalAmountSlots,
}: PpConfirm): React.ReactElement {
  return (
    <LowDialog
      fullWidth
      data-qa="bitrefill-confirm-dialog"
      data-qa-price-loaded={loadedPrice ? 'true' : 'false'}
      fullScreen={window.innerHeight < 600} // content gets squished on small screens
      open={open}
    >
      <DialogTitle>Confirm Payment</DialogTitle>
      <DialogContent>
        <DialogContentText paragraph>Your gift card purchase details</DialogContentText>
        <ListItemCard sx={{ px: 2 }}>
          <List disablePadding>
            <ListItem divider>
              <ListItemContent sx={{ px: 0 }}>
                <ListItemStartContent>
                  <ListItemSecondaryText>Email</ListItemSecondaryText>
                </ListItemStartContent>
                <ListItemEndContent>
                  <ListItemPrimaryText>
                    <TruncatedText prefix={14} text={email ?? ''} />
                  </ListItemPrimaryText>
                </ListItemEndContent>
              </ListItemContent>
            </ListItem>

            <ListItem divider>
              <ListItemContent sx={{ px: 0 }}>
                <ListItemStartContent>
                  <ListItemSecondaryText>Invoice</ListItemSecondaryText>
                </ListItemStartContent>
                <ListItemEndContent>
                  <ListItemPrimaryText>{addressData?.description}</ListItemPrimaryText>
                </ListItemEndContent>
              </ListItemContent>
            </ListItem>

            <ListItem divider={!isLightningPayment}>
              <ListItemContent sx={{ px: 0 }}>
                <ListItemStartContent>
                  <ListItemSecondaryText>Amount</ListItemSecondaryText>
                </ListItemStartContent>
                <ListItemEndContent>
                  <ListItemPrimaryText>
                    <CryptoAmount
                      amount={addressData?.amount}
                      currency={cryptoCurrencyFromCode(CurrencyCode.BTC)}
                    />
                  </ListItemPrimaryText>
                </ListItemEndContent>
              </ListItemContent>
            </ListItem>

            {!isLightningPayment && (
              <React.Fragment>
                <ListItem divider>
                  <ListItemContent sx={{ px: 0 }}>
                    <NetworkFeeItem
                      cryptoCurrency={cryptoCurrencyFromCode(CurrencyCode.BTC)}
                      PrimaryAmountSlot={FeeAmountSlots.PrimaryAmountSlot}
                    />
                  </ListItemContent>
                </ListItem>

                <ListItem>
                  <ListItemContent sx={{ px: 0 }}>
                    <ListItemStartContent>
                      <ListItemPrimaryText>Total</ListItemPrimaryText>
                    </ListItemStartContent>
                    <ListItemEndContent>
                      <ListItemPrimaryText>
                        {TotalAmountSlots.PrimaryAmountSlot}
                      </ListItemPrimaryText>
                    </ListItemEndContent>
                  </ListItemContent>
                </ListItem>
              </React.Fragment>
            )}
          </List>
        </ListItemCard>
      </DialogContent>
      <DialogActions>
        <Stack spacing={3}>
          <FooterContentText>
            *Due to price volatility, there may be a small difference between the displayed amount
            and the amount paid.
          </FooterContentText>
          <ButtonGroup>
            <PrimaryButton
              color="primaryBrand"
              data-qa="confirm-btn"
              disabled={isCtaDisabled}
              loading={submitLoading}
              onClick={(): void => onConfirm()}
            >
              Confirm purchase
            </PrimaryButton>
            <PrimaryButton
              disabled={submitLoading}
              variant="text"
              onClick={(): void => cancelPayment()}
            >
              Cancel
            </PrimaryButton>
          </ButtonGroup>
        </Stack>
      </DialogActions>
    </LowDialog>
  );
}
