import React from 'react';
import type { FeatureFlag } from '@noah-labs/shared-schema-gql';
import { useLocation } from 'react-router-dom';
import { DisabledFeature } from './DisabledFeature';

export function DisabledFeatureData(): React.ReactElement {
  const location = useLocation<{ featureFlag?: Omit<FeatureFlag, 'Feature'> }>();

  let reason;
  if (Array.isArray(location.state.featureFlag?.Reason)) {
    reason = location.state.featureFlag.Reason;
  }

  return <DisabledFeature reason={reason} />;
}
