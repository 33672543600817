import type { ReactElement } from 'react';
import { SvgEmptyBox } from '@noah-labs/fe-shared-ui-assets/images/EmptyBox';
import {
  EmptyStateContent,
  EmptyStateRoot,
  EmptyStateSubtitle,
  EmptyStateTitle,
} from '@noah-labs/fe-shared-ui-components';

export function EmptyMessage(): ReactElement {
  return (
    <EmptyStateRoot>
      <SvgEmptyBox height={88} />
      <EmptyStateContent>
        <EmptyStateTitle>No notifications yet</EmptyStateTitle>
        <EmptyStateSubtitle>We will let you know when we get news for you</EmptyStateSubtitle>
      </EmptyStateContent>
    </EmptyStateRoot>
  );
}
