import type {
  TpCryptoCurrency,
  TpCryptoNetwork,
  TpFiatCurrency,
  TpSimpleAmount,
} from '@noah-labs/shared-currencies';
import { duration } from '@noah-labs/shared-util-vanilla';
import { getConfig } from '../config';
import { useCalculateFiatFromCrypto } from './useCalculate';
import { useWithdrawFeeQuery } from './wallet.generated';

type PpUseWithdrawFeeWithTransaction = {
  address: string;
  cryptoAmount: string;
  cryptoCurrency: TpCryptoCurrency;
  cryptoNetwork: TpCryptoNetwork;
  enabled: boolean;
  fiatCurrency: TpFiatCurrency | undefined;
};

export type TpUseWithdrawFeeWithTransaction = {
  feeCryptoAmount: TpSimpleAmount;
  feeError: unknown;
  feeFetchedAt: number;
  feeFiatAmount: TpSimpleAmount;
  feeQuote: string | null | undefined;
  isFeeFetched: boolean;
};

const quoteExpirySecThreshold = 15;

export function useWithdrawFeeWithTransaction({
  address,
  cryptoAmount,
  cryptoCurrency,
  cryptoNetwork,
  enabled,
  fiatCurrency,
}: PpUseWithdrawFeeWithTransaction): TpUseWithdrawFeeWithTransaction {
  const { networkFee } = getConfig();
  const withdrawFeeInterval = networkFee.quoteExpirySec - quoteExpirySecThreshold;

  const {
    data: feeData,
    dataUpdatedAt: withdrawFeeFetchedAt,
    error: withdrawFeeError,
    isFetched: isWithdrawFeeFetched,
  } = useWithdrawFeeQuery(
    {
      Input: {
        Currency: cryptoCurrency.code,
        Network: cryptoNetwork.id,
        Transaction: {
          Amount: cryptoAmount,
          DestinationAddress: {
            Address: address,
          },
        },
      },
    },
    {
      enabled,
      refetchInterval: duration.seconds(withdrawFeeInterval),
    },
  );

  const { data: priceData } = useCalculateFiatFromCrypto({
    cryptoAmount: feeData?.withdrawFee.NetworkFee,
    cryptoCurrency,
    fiatCurrency,
    priceProvider: 'market',
  });

  return {
    feeCryptoAmount: feeData?.withdrawFee.NetworkFee,
    feeError: withdrawFeeError,
    feeFetchedAt: withdrawFeeFetchedAt,
    feeFiatAmount: priceData?.fiatAmount,
    feeQuote: feeData?.withdrawFee.FeeQuote,
    isFeeFetched: isWithdrawFeeFetched && !!feeData,
  };
}
