import { Fragment } from 'react';
import { useOry } from '@noah-labs/fe-shared-data-access-auth';
import {
  AppFooter,
  AppHeader,
  AppMain,
  PrimaryButton,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';

export type PpForgottenPasswordSuccessScene = {
  helpButton?: React.ReactNode;
};

export function ForgottenPasswordSuccessScene({
  helpButton,
}: PpForgottenPasswordSuccessScene): React.ReactElement {
  const { returnTo } = useOry();
  const returnToFromParams = new URLSearchParams(window.location.search).get('returnTo');
  const successReturnTo = returnToFromParams || returnTo;

  return (
    <Fragment>
      <AppMain>
        <AppHeader endIconsSlot={helpButton} />
        <SceneMain dataQa="forgotten-code">
          <SceneTitleLarge>Success, your password has been changed</SceneTitleLarge>
          <SceneParagraph>Your new password is ready for you to use!</SceneParagraph>
        </SceneMain>
      </AppMain>
      <AppFooter wide>
        <PrimaryButton
          color="primaryBrand"
          data-qa="continue-button"
          onClick={(): void => window.location.assign(successReturnTo)}
        >
          Continue to NOAH
        </PrimaryButton>
      </AppFooter>
    </Fragment>
  );
}
