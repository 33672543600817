import type { TpUseQueryResultReplacedData } from '@noah-labs/fe-shared-data-access-shared';
import { getEnvCurrencyCode, withSlippage } from '@noah-labs/shared-currencies';
import type { TpCryptoCurrency, TpFiatCurrency, TpSlippage } from '@noah-labs/shared-currencies';
import { logger } from '@noah-labs/shared-logger/browser';
import type { CurrencyCode, FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import type { LiquidityPriceQuery, MarketPriceQuery } from './wallet.generated';
import { useLiquidityPriceQuery, useMarketPriceQuery } from './wallet.generated';

export type TpMarketOrLiquidityPriceQuery = MarketPriceQuery | LiquidityPriceQuery;

export type TpPriceProvider = 'market' | 'buy' | 'sell';

export type PpUseCryptoPrice = {
  cryptoCurrency: TpCryptoCurrency | undefined;
  fiatCurrency: TpFiatCurrency | undefined;
  options?: UseQueryOptions<TpMarketOrLiquidityPriceQuery>;
  priceProvider: TpPriceProvider;
  slippage?: TpSlippage;
};

type TpCalculateCryptoResponse = {
  fetchedAt: string;
  price: string;
};

export type PpBuildCryptoPriceResponse = {
  liquidityResponse: UseQueryResult<LiquidityPriceQuery>;
  marketResponse: UseQueryResult<MarketPriceQuery>;
  priceProvider: TpPriceProvider;
};

export function buildCryptoPriceResponse({
  liquidityResponse,
  marketResponse,
  priceProvider,
}: PpBuildCryptoPriceResponse): TpUseQueryResultReplacedData<
  TpMarketOrLiquidityPriceQuery,
  TpCalculateCryptoResponse
> {
  let response;
  let data: TpCalculateCryptoResponse | undefined;
  switch (priceProvider) {
    case 'market':
      if (marketResponse.data) {
        data = {
          fetchedAt: marketResponse.data.marketPrice.FetchedAt,
          price: marketResponse.data.marketPrice.Price,
        };
      }
      response = marketResponse;
      break;

    case 'buy':
      if (liquidityResponse.data) {
        data = {
          fetchedAt: liquidityResponse.data.liquidityPrice.FetchedAt,
          price: liquidityResponse.data.liquidityPrice.BuyPrice,
        };
      }
      response = liquidityResponse;
      break;

    case 'sell':
      if (liquidityResponse.data) {
        data = {
          fetchedAt: liquidityResponse.data.liquidityPrice.FetchedAt,
          price: liquidityResponse.data.liquidityPrice.SellPrice,
        };
      }
      response = liquidityResponse;
      break;

    default:
      logger.error('unknown price provider');
  }

  return { ...response, data } as TpUseQueryResultReplacedData<
    TpMarketOrLiquidityPriceQuery,
    TpCalculateCryptoResponse
  >;
}

export function useCryptoPrice({
  cryptoCurrency,
  fiatCurrency,
  options,
  priceProvider: pp,
  slippage,
}: PpUseCryptoPrice): TpUseQueryResultReplacedData<
  TpMarketOrLiquidityPriceQuery,
  TpCalculateCryptoResponse
> {
  // TODO (cs): we are currently using market prices from Coingecko for buys, maybe we will provide our own buy / sell price later
  const priceProvider = pp === 'buy' ? 'market' : (pp as TpPriceProvider);
  const marketResponse = useMarketPriceQuery(
    {
      Input: {
        // we have to force 'prod' crypto codes here because the market prices table does not support test codes
        // and it is a direct DDB vtl handler so no opportunity to convert
        CurrencyCode: cryptoCurrency?.code
          ? getEnvCurrencyCode(cryptoCurrency.code, true)
          : (cryptoCurrency?.code as CurrencyCode),
        FiatCurrency: fiatCurrency?.code as FiatCurrencyCode,
      },
    },
    {
      ...(options as UseQueryOptions<MarketPriceQuery>),
      enabled: Boolean(cryptoCurrency && fiatCurrency && priceProvider === 'market'),
    },
  );

  const liquidityResponse = useLiquidityPriceQuery(
    {
      Input: {
        // we do not have to convert test/main crypto codes here because core does the conversion in the handler
        CurrencyCode: cryptoCurrency?.code as CurrencyCode,
        FiatCurrency: fiatCurrency?.code as FiatCurrencyCode,
      },
    },
    {
      ...(options as UseQueryOptions<LiquidityPriceQuery>),
      enabled: Boolean(
        cryptoCurrency && fiatCurrency && (priceProvider === 'buy' || priceProvider === 'sell'),
      ),
    },
  );

  const response = buildCryptoPriceResponse({ liquidityResponse, marketResponse, priceProvider });

  if (response.data) {
    response.data.price = slippage
      ? withSlippage(response.data.price, slippage)
      : response.data.price;
  }

  return response;
}
