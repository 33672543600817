import type { TpCryptoNetwork } from '@noah-labs/shared-currencies';
import { getCryptoAmountForCurrency } from '@noah-labs/shared-currencies';
import { logger } from '@noah-labs/shared-logger/browser';
import type { CurrencyCode, OrderLimitPolicy } from '@noah-labs/shared-schema-gql';
import { isUndefinedOrNull, safeBN } from '@noah-labs/shared-util-vanilla';

type TpCanSkipSignature = {
  amount: string;
  cryptoNetwork: TpCryptoNetwork | null | undefined;
  currency: CurrencyCode;
  policies: OrderLimitPolicy[];
};

export function canSkipSignature({
  amount,
  cryptoNetwork,
  currency,
  policies,
}: TpCanSkipSignature): boolean {
  /**
   * Checks all relevant policies if the requested amount is within the the Signing limits
   */
  return policies
    .filter((policy) => isUndefinedOrNull(policy.Network) || policy.Network === cryptoNetwork?.id)
    .every((policy) => {
      const vl = policy.VolumeLimit;
      if (vl) {
        const signingAmount = getCryptoAmountForCurrency(vl.Signing?.CryptoAmounts, currency);
        const usageAmount = getCryptoAmountForCurrency(vl.Usage?.CryptoAmounts, currency);
        const updatedUsage = safeBN(usageAmount).plus(safeBN(amount));

        // safety check in case we forget to request crypto amounts in the limits query
        if (vl.Signing && !signingAmount) {
          logger.error(`Missing signing crypto amounts for ${currency}`);
          return false;
        }

        return !(signingAmount && updatedUsage.gt(safeBN(signingAmount)));
      }
      return true;
    });
}
