import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { generatePath, LoadingPage } from '@noah-labs/fe-shared-ui-components';
import { compareStrings } from '@noah-labs/shared-util-vanilla';
import { Redirect, useParams } from 'react-router-dom';
import { useQuestsAndBadgesQuery } from '../data/rewards.generated';
import { isQuestFeatureEnabled } from '../data/utils';
import { useQuestStatusById } from '../hooks/useQuestStatusById';
import { getBadgeButtonCopy, getBadgeButtonLink } from '../mappings/badgeButton';
import { routes } from '../routes';
import { BadgeScene } from '../scenes/Badge';
import { TpRewardsTabs } from '../types';

export function BadgeData(): React.ReactElement {
  const params = useParams<{ id: string | undefined }>();
  const { data: userData } = useUserInitUi();

  const { data: badge, isLoading } = useQuestsAndBadgesQuery(undefined, {
    enabled: !!params.id,
    select: (data) =>
      data.questsAndBadges.UserBadges.find((b) => compareStrings(b.BadgeTypeID, params.id)),
  });

  const { data: questStatus } = useQuestStatusById(badge?.QuestID);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!badge) {
    return (
      <Redirect
        to={generatePath(routes.tabs.path, {
          tab: TpRewardsTabs.Badges,
        })}
      />
    );
  }

  const questFeatureEnabled = questStatus
    ? isQuestFeatureEnabled(questStatus.Quest, userData?.userProfile.features)
    : undefined;

  return (
    <BadgeScene
      badge={badge}
      buttonCopy={getBadgeButtonCopy(badge.QuestID, questFeatureEnabled)}
      buttonLink={getBadgeButtonLink(badge.QuestID, questFeatureEnabled)}
      questFeatureEnabled={questFeatureEnabled}
      username={userData?.userProfile.UsernameDisplay}
    />
  );
}
