import { useCallback } from 'react';
import { Stack, Typography } from '@mui/material';
import type { TpSelectOption } from '@noah-labs/fe-shared-ui-components';
import { SearchSelect } from '@noah-labs/fe-shared-ui-components';
import type { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import type { PpPreferredCurrencySelect } from './types';

export function PreferredFiatCurrencyForm({
  id,
  items,
  onChange,
  title,
}: PpPreferredCurrencySelect): React.ReactElement {
  const handleChange = useCallback(
    (value: TpSelectOption | null) => {
      if (!value) {
        return;
      }
      onChange(value.value as FiatCurrencyCode);
    },
    [onChange],
  );

  return (
    <Stack spacing={3}>
      {title && (
        <Typography color="text.light" textAlign="center" variant="paragraphBodyS">
          {title}
        </Typography>
      )}
      <form>
        <SearchSelect id={id} loading={false} options={items} onChange={handleChange} />
      </form>
    </Stack>
  );
}
