import { useCallback } from 'react';
import type { LoginFlow } from '@ory/client';
import { useOry } from '../data';
import {
  getIsWebAuthnSupported,
  getOryCsrfToken,
  getOryWebAuthnCredentials,
  getOryWebAuthnOptions,
  isRedirectBrowserError,
} from '../utils';
import { isOryPrepareWebAuthnDeviceFlowState } from './utils';

/**
 * @returns function to create Ory LoginFlow in "Prepare WebAuthn Device" state
 */
function useOryCreateWebAuthnFlow(): (email: string) => Promise<LoginFlow> {
  const { ory, returnTo } = useOry();

  return useCallback(
    async (email: string) => {
      const { data: flow } = await ory.createBrowserLoginFlow({
        returnTo,
      });

      try {
        await ory.updateLoginFlow({
          flow: flow.id,
          updateLoginFlowBody: {
            csrf_token: getOryCsrfToken(flow.ui.nodes),
            identifier: email,
            method: 'webauthn',
          },
        });
        throw Error('Expected first webauthn login update to fail');
      } catch (error) {
        if (!isRedirectBrowserError(error)) {
          throw error;
        }

        const { data: webAuthFlow } = await ory.getLoginFlow({ id: flow.id });
        if (!isOryPrepareWebAuthnDeviceFlowState(webAuthFlow)) {
          throw error;
        }

        return webAuthFlow;
      }
    },
    [ory, returnTo],
  );
}

type TpOryWebAuthnSignInCallback = (values: { email: string }) => Promise<void>;

export function useOryWebAuthnSignInCallback(): TpOryWebAuthnSignInCallback {
  const { ory } = useOry();
  const createWebAuthFlow = useOryCreateWebAuthnFlow();

  return useCallback(
    async (values): Promise<void> => {
      if (!getIsWebAuthnSupported()) {
        throw Error('WebAuthn is not supported');
      }

      const webAuthFlow = await createWebAuthFlow(values.email);
      const options = getOryWebAuthnOptions(webAuthFlow.ui.nodes, 'request');
      if (options === undefined) {
        throw Error('Wrong credential options format');
      }

      const webAuthnCredentials = await getOryWebAuthnCredentials(options);
      const stringifiedWebAuthnCredentials = JSON.stringify(webAuthnCredentials);

      await ory.updateLoginFlow({
        flow: webAuthFlow.id,
        updateLoginFlowBody: {
          csrf_token: getOryCsrfToken(webAuthFlow.ui.nodes),
          identifier: values.email,
          method: 'webauthn',
          webauthn_login: stringifiedWebAuthnCredentials,
        },
      });
    },
    [ory, createWebAuthFlow],
  );
}
