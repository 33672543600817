import type {
  TpAllowance,
  TpUserWithdrawalAllowance,
  UserLimitsQuery,
} from '@noah-labs/fe-shared-data-access-user';
import {
  getLnSendLimitAndUsage,
  getWithdrawalDayLimitAndUsage,
  getWithdrawalSingleLimitAndUsage,
} from '@noah-labs/fe-shared-data-access-wallet';
import type { TpCryptoNetwork } from '@noah-labs/shared-currencies';
import { PolicyPeriod } from '@noah-labs/shared-schema-gql';
import { safeBN } from '@noah-labs/shared-util-vanilla';
import BigNumber from 'bignumber.js';

function getAllowance(limit?: BigNumber, usage?: BigNumber): BigNumber | undefined {
  if (!usage) {
    return limit;
  }
  return limit?.minus(usage);
}

type PpProcessAllowances = {
  availableBalanceCrypto: string | undefined;
  cryptoNetwork: TpCryptoNetwork | undefined;
  limitsData: UserLimitsQuery;
};
export function processAllowances({
  availableBalanceCrypto,
  cryptoNetwork,
  limitsData,
}: PpProcessAllowances): TpUserWithdrawalAllowance {
  const lnDayLimit = getLnSendLimitAndUsage(limitsData.userLimit.Limits, PolicyPeriod.DAY);
  const lnSingleLimit = getLnSendLimitAndUsage(limitsData.userLimit.Limits, PolicyPeriod.SINGLE);

  const accountDayOverAllLimit = getWithdrawalDayLimitAndUsage(limitsData.userLimit.Limits);
  const accountDayNetworkLimit = getWithdrawalDayLimitAndUsage(
    limitsData.userLimit.Limits,
    cryptoNetwork,
  );
  const accountSingleLimit = getWithdrawalSingleLimitAndUsage(
    limitsData.userLimit.Limits,
    cryptoNetwork,
  );

  const lnDayLimitCrypto = lnDayLimit.limit?.CryptoAmounts
    ? safeBN(lnDayLimit.limit.CryptoAmounts[0].Amount)
    : undefined;
  const lnDayUsageCrypto = lnDayLimit.usage?.CryptoAmounts
    ? safeBN(lnDayLimit.usage.CryptoAmounts[0].Amount)
    : undefined;
  const lnSingleLimitCrypto = lnSingleLimit.limit?.CryptoAmounts
    ? safeBN(lnSingleLimit.limit.CryptoAmounts[0].Amount)
    : undefined;

  const accountDayLimitCrypto = accountDayOverAllLimit.limit?.CryptoAmounts
    ? safeBN(accountDayOverAllLimit.limit.CryptoAmounts[0].Amount)
    : undefined;
  const accountDayUsageCrypto = accountDayOverAllLimit.usage?.CryptoAmounts
    ? safeBN(accountDayOverAllLimit.usage.CryptoAmounts[0].Amount)
    : undefined;

  const accountDayCountLimitCrypto = accountDayNetworkLimit.count?.Limit
    ? safeBN(accountDayNetworkLimit.count.Limit)
    : undefined;
  const accountDayCountUsageCrypto = accountDayNetworkLimit.count?.Usage
    ? safeBN(accountDayNetworkLimit.count.Usage)
    : undefined;

  const accountSingleLimitCrypto = accountSingleLimit.limit?.CryptoAmounts
    ? safeBN(accountSingleLimit.limit.CryptoAmounts[0].Amount)
    : undefined;
  const withdrawMinimumSingleCrypto = accountSingleLimit.minimum?.CryptoAmounts
    ? safeBN(accountSingleLimit.minimum.CryptoAmounts[0].Amount)
    : undefined;

  const lnDayAllowanceCrypto = getAllowance(lnDayLimitCrypto, lnDayUsageCrypto);
  const accountDayAllowanceCrypto = getAllowance(accountDayLimitCrypto, accountDayUsageCrypto);
  const withdrawalRemainingTxs = getAllowance(
    accountDayCountLimitCrypto,
    accountDayCountUsageCrypto,
  );

  const balanceUserCrypto = availableBalanceCrypto ? safeBN(availableBalanceCrypto) : undefined;

  const lnAllowanceCryptoAmount = BigNumber.min(
    ...([lnDayAllowanceCrypto, lnSingleLimitCrypto, balanceUserCrypto].filter((val) =>
      Boolean(val),
    ) as BigNumber[]),
  );

  let lnAllowanceCrypto: TpAllowance | undefined;
  switch (lnAllowanceCryptoAmount.valueOf()) {
    case lnDayAllowanceCrypto?.valueOf():
      lnAllowanceCrypto = {
        amount: lnDayAllowanceCrypto as BigNumber,
        reason: 'PolicyLimit',
      };
      break;
    case lnSingleLimitCrypto?.valueOf():
      lnAllowanceCrypto = {
        amount: lnSingleLimitCrypto as BigNumber,
        reason: 'PolicyLimit',
      };
      break;
    case balanceUserCrypto?.valueOf():
      lnAllowanceCrypto = {
        amount: balanceUserCrypto as BigNumber,
        reason: 'Balance',
      };
      break;
    default:
  }

  const accountAllowanceCryptoAmount = BigNumber.min(
    ...([accountDayAllowanceCrypto, accountSingleLimitCrypto, balanceUserCrypto].filter((val) =>
      Boolean(val),
    ) as BigNumber[]),
  );

  let accountAllowanceCrypto: TpAllowance | undefined;
  switch (accountAllowanceCryptoAmount.valueOf()) {
    case accountDayAllowanceCrypto?.valueOf():
      accountAllowanceCrypto = {
        amount: accountDayAllowanceCrypto as BigNumber,
        reason: 'PolicyLimit',
      };
      break;
    case accountSingleLimitCrypto?.valueOf():
      accountAllowanceCrypto = {
        amount: accountSingleLimitCrypto as BigNumber,
        reason: 'PolicyLimit',
      };
      break;
    case balanceUserCrypto?.valueOf():
      accountAllowanceCrypto = {
        amount: balanceUserCrypto as BigNumber,
        reason: 'Balance',
      };
      break;
    default:
  }

  return {
    accountAllowanceCrypto,
    accountDayCountLimitCrypto,
    balanceUserCrypto,
    lnAllowanceCrypto,
    withdrawalRemainingTxs,
    withdrawMinimumSingleCrypto,
  };
}
