import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';
import { TextOrSkeleton } from '@noah-labs/fe-shared-ui-components';

export function TransactionDate({ children, ...rest }: TypographyProps): React.ReactElement {
  return (
    <Typography
      component="dt"
      sx={{ color: 'text.secondary', mb: 1 }}
      variant="paragraphBodyS"
      {...rest}
    >
      <TextOrSkeleton sx={{ px: '6em' }}>{children}</TextOrSkeleton>
    </Typography>
  );
}
