import type { TpUserWithdrawalAllowance } from '@noah-labs/fe-shared-data-access-user';
import { CryptoAmount } from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoUnitAmount } from '@noah-labs/fe-shared-ui-shared';
import type { TpAmount, TpFiatCurrency } from '@noah-labs/shared-currencies';
import { isNonZero, isUndefinedOrNull, safeBN } from '@noah-labs/shared-util-vanilla';
import { z } from 'zod';
import { maxSafetyAmount } from './amounts';
import { getCryptoWithdrawalLimitsSchema } from './getCryptoWithdrawalLimitsSchema';

type TpGetOnchainWithdrawSchema = {
  allowance: TpUserWithdrawalAllowance | undefined;
  feeCryptoAmount: TpAmount;
  fiatCurrency: TpFiatCurrency | undefined;
  minAmounts: TpCryptoUnitAmount | undefined;
};

export function getOnchainWithdrawSchema({
  allowance,
  feeCryptoAmount,
  fiatCurrency,
  minAmounts,
}: TpGetOnchainWithdrawSchema): z.ZodSchema {
  return z.object({
    cryptoAmount: z
      .string()
      .optional()
      .superRefine((value, ctx) => {
        // We need to set this first so we can override the other error messages
        if (allowance?.withdrawalRemainingTxs?.eq(0)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Sorry, you have reached your daily send limits.',
          });
        }

        const schema = getCryptoWithdrawalLimitsSchema(allowance?.accountAllowanceCrypto);

        const result = schema.safeParse(value);
        if (!result.success) {
          ctx.addIssue(result.error.issues[0]);
        }

        if (
          isUndefinedOrNull(feeCryptoAmount) ||
          isUndefinedOrNull(allowance?.balanceUserCrypto) ||
          isUndefinedOrNull(value)
        ) {
          ctx.addIssue({ code: z.ZodIssueCode.custom, message: ' ' });
          return;
        }

        const cryptoAmountWithFee = safeBN(value).plus(feeCryptoAmount);
        if (cryptoAmountWithFee.gt(allowance.balanceUserCrypto)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              'Your balance is too low to cover the sending amount and fees. Please adjust the amount or add funds to continue.',
          });
        }

        if (!isUndefinedOrNull(minAmounts)) {
          if (!isNonZero(value)) {
            ctx.addIssue({ code: z.ZodIssueCode.custom, message: ' ' });
          }
          if (safeBN(value).lt(minAmounts.amountBtc)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: (
                <span>
                  Minimum amount is{' '}
                  <CryptoAmount
                    amount={minAmounts.amountBtc}
                    currency={minAmounts.cryptoCurrency}
                    currencyUnit={minAmounts.unit}
                  />
                </span> // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ) as any, // https://github.com/colinhacks/zod/issues/3212
            });
          }
        }
      }),
    description: z.string().optional(),
    fetchedAt: z.string().optional(),
    fiatAmount: maxSafetyAmount(fiatCurrency),
    price: z.string().optional(),
    primaryAmount: z.string().optional(),
    secondaryAmount: z.string().optional(),
  });
}
