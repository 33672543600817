import { TpAnalyticsEvent, useAnalytics } from '@noah-labs/fe-shared-feature-analytics';
import { SecretPhraseScene } from '@noah-labs/fe-shared-ui-signing';
import type { SafeWrapper } from '@noah-labs/shared-cryptography';

export type PpSecretPhrase = {
  phrase: SafeWrapper<string> | undefined;
  successTo: string;
};

export function SecretPhrase({ phrase, successTo }: PpSecretPhrase): React.ReactElement {
  const { track } = useAnalytics();

  return (
    <SecretPhraseScene
      phrase={phrase}
      successTo={successTo}
      onCopyTrack={(): void => track(TpAnalyticsEvent.SecretPhraseCopyClicked)}
      onSaveTrack={(): void => track(TpAnalyticsEvent.RecoveryPhraseDownloadClicked)}
    />
  );
}
