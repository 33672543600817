import { useUserAccountFiatBalance, useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { AvatarIconFromSvg, getCurrencyAmountSlots } from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import { AccountCard } from '@noah-labs/fe-shared-ui-wallet';

type PpAccountCardData = {
  balance: string | undefined;
  cryptoCurrency: TpCryptoCurrencyUI;
  href?: string;
  id: string;
  onSelectAccount?: () => void;
};

export function AccountCardData({
  balance,
  cryptoCurrency,
  href,
  id,
  onSelectAccount,
}: PpAccountCardData): React.ReactElement {
  const { data: userData } = useUserInitUi();
  const fiatAmount = useUserAccountFiatBalance({ balance, cryptoCurrency });

  const Amounts = getCurrencyAmountSlots({
    cryptoAmount: balance,
    cryptoCurrency,
    cryptoUnit: userData?.userProfile.DisplayUnit,
    fiatAmount,
    fiatCurrency: userData?.userProfile.fiatCurrency,
    primaryCurrency: userData?.userProfile.PrimaryCurrency,
    roundDown: true,
  });

  return (
    <AccountCard
      AvatarSlot={<AvatarIconFromSvg svg={cryptoCurrency.svg} />}
      cryptoCurrency={cryptoCurrency}
      defaultAccount={id.includes('Default')}
      href={href}
      PrimaryTextSlot={cryptoCurrency.label}
      onSelectAccount={onSelectAccount}
      {...Amounts}
    />
  );
}
