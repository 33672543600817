import { useCallback } from 'react';
import { RadioGroup, Stack, Typography } from '@mui/material';
import {
  AvatarIconFromSvg,
  List,
  ListItem,
  ListItemCard,
  RadioButton,
} from '@noah-labs/fe-shared-ui-components';
import type { PpPreferredCurrencyRadio, TpPreferredCurrencyFormOptions } from './types';

export function PreferredCryptoCurrencyForm<T extends TpPreferredCurrencyFormOptions>({
  hideValues,
  items,
  name,
  onChange,
  title,
  value = '',
}: PpPreferredCurrencyRadio<T>): React.ReactElement {
  const handleChange = useCallback(
    (_, v: string) => {
      onChange(v as T);
    },
    [onChange],
  );
  return (
    <Stack spacing={2}>
      {title && (
        <Typography color="text.light" textAlign="center" variant="paragraphBodyS">
          {title}
        </Typography>
      )}
      <form>
        <RadioGroup name={name} value={value} onChange={handleChange}>
          <List spacing={1}>
            {items.map((item) => (
              <ListItem key={item.value}>
                <ListItemCard>
                  <RadioButton
                    Avatar={item.svg && <AvatarIconFromSvg svg={item.svg} />}
                    hideValue={hideValues}
                    label={item.label}
                    labelPlacement="start"
                    value={item.value}
                  />
                </ListItemCard>
              </ListItem>
            ))}
          </List>
        </RadioGroup>
      </form>
    </Stack>
  );
}
