import { useCallback } from 'react';
import { useFeature } from '@noah-labs/fe-shared-feature-user';
import {
  AddressScanScene,
  handleParseScannedAddress,
  InvalidAddressAlert,
} from '@noah-labs/fe-shared-ui-address';
import { generatePath, parseMarketingUrl, usePushAlert } from '@noah-labs/fe-shared-ui-components';
import { useRouter, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature } from '@noah-labs/shared-schema-gql';
import { getConfig } from '../config';
import { useAddress } from '../hooks/useAddress';

type PpAddressScanController = {
  appHeaderHeight: number;
};

export function AddressScanController({
  appHeaderHeight,
}: PpAddressScanController): React.ReactElement {
  const { isProd } = getConfig();

  const { params } = useWalletParams();
  const { push } = useRouter();
  const pushAlert = usePushAlert();
  const { feature: withdrawFF } = useFeature(Feature.Withdraw);

  const { setScannedAddress, setScannerUnavailable } = useAddress();

  const handleAddress = useCallback(
    (qrAddress: string) => {
      if (!withdrawFF?.Networks) {
        return;
      }

      const marketingUrl = parseMarketingUrl(qrAddress);
      if (marketingUrl) {
        push(marketingUrl);
        return;
      }

      const addressData = handleParseScannedAddress({
        address: qrAddress,
        availableNetworks: withdrawFF.Networks,
        isProd,
      });

      if (!addressData) {
        pushAlert(InvalidAddressAlert);
        return;
      }

      setScannedAddress(addressData.address);
      push(generatePath(walletRoutes().send.address.base.path, params));
    },
    [push, params, withdrawFF?.Networks, pushAlert, setScannedAddress, isProd],
  );

  return (
    <AddressScanScene
      appHeaderHeight={appHeaderHeight}
      onSuccess={handleAddress}
      onUnavailable={(): void => setScannerUnavailable(true)}
    />
  );
}
