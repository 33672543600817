import { useEffect } from 'react';
import { logger } from '@noah-labs/shared-logger/browser';
import { useOry } from '../data';

export function useLogOryFlowError(id: string | undefined | null): void {
  const { ory } = useOry();

  useEffect(() => {
    if (!id) {
      return;
    }

    (async (): Promise<void> => {
      const { data: errorFlow } = await ory.getFlowError({
        id,
      });
      logger.error(errorFlow);
    })().catch((error) => {
      logger.error(error);
    });
  }, [ory, id]);
}
