import type { SVGProps } from 'react';

export function SvgBitcoin(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7 14.419c-1.336 5.357-6.762 8.617-12.12 7.282C4.225 20.365.964 14.939 2.3 9.582 3.637 4.224 9.063.964 14.419 2.299c5.357 1.336 8.617 6.763 7.282 12.12Z"
        fill="#F7931A"
      />
      <path
        d="M16.426 10.568c.199-1.331-.814-2.047-2.2-2.524l.45-1.803-1.098-.273-.437 1.755a45 45 0 0 0-.88-.207l.442-1.766-1.097-.274-.45 1.802a36 36 0 0 1-.7-.165v-.005L8.945 6.73l-.292 1.172s.814.187.797.198c.444.111.524.405.51.638l-.511 2.054q.046.011.114.037l-.116-.03-.718 2.878c-.054.135-.192.337-.502.26.01.016-.798-.199-.798-.199l-.545 1.256 1.428.356c.266.067.526.137.783.202l-.454 1.824 1.096.273.45-1.804q.448.121.874.227l-.448 1.796 1.097.273.454-1.82c1.871.354 3.278.211 3.87-1.481.478-1.363-.023-2.149-1.008-2.661.717-.166 1.257-.637 1.401-1.611Zm-2.507 3.515c-.34 1.363-2.634.626-3.377.441l.602-2.415c.744.186 3.13.553 2.775 1.974m.34-3.535c-.31 1.24-2.22.61-2.84.455l.547-2.19c.62.154 2.614.442 2.292 1.735Z"
        fill="#fff"
      />
    </svg>
  );
}
