import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import { ProfileDetailsScene } from '../scenes/ProfileDetails';

export function ProfileDetails(): React.ReactElement {
  const { data: userData } = useUserInitUi();

  return (
    <ProfileDetailsScene
      email={userData?.userProfile.Email}
      pageTitle={userRoutes.profile.title}
      personalName={userData?.userProfile.PersonalName}
    />
  );
}
