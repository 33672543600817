import {
  AppMain,
  SceneHeader,
  SceneMain,
  SceneParagraph,
  SceneTitleMedium,
} from '@noah-labs/fe-shared-ui-components';

export type PpSelectAccountScene = {
  AccountsSlot: React.ReactElement;
  subTitle: string;
  title: string;
};

export function SelectAccountScene({
  AccountsSlot,
  subTitle,
  title,
}: PpSelectAccountScene): React.ReactElement {
  return (
    <AppMain>
      <SceneHeader dense>
        <SceneTitleMedium>{title}</SceneTitleMedium>
        <SceneParagraph>{subTitle}</SceneParagraph>
      </SceneHeader>
      <SceneMain dense dataQa="select-account">
        {AccountsSlot}
      </SceneMain>
    </AppMain>
  );
}
