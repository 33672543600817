import { selectAccount, useAccountsQuery } from '@noah-labs/fe-shared-data-access-wallet';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { AccountType, CurrencyCode } from '@noah-labs/shared-schema-gql';
import { useParams } from 'react-router-dom';
import { getReferralUrl } from '../../../utils/utils';
import { BadgesTabPanel } from '../components/tabs/badges/BadgesTabPanel';
import { QuestsTabPanel } from '../components/tabs/quest/QuestsTabPanel';
import { useQuestsAndBadgesQuery } from '../data/rewards.generated';
import { selectQuestsAndBadges } from '../data/utils';
import { routes } from '../routes';
import { RewardsScene } from '../scenes/Rewards';
import { TpRewardsTabs } from '../types';

export function Rewards(): React.ReactElement {
  const params = useParams<{ tab: TpRewardsTabs }>();
  const { data: userData } = useUserInitUi();
  const { data: questsAndBadgesData } = useQuestsAndBadgesQuery(undefined, {
    select: (data) => selectQuestsAndBadges(data, userData?.userProfile.features),
  });
  const { data: account } = useAccountsQuery(undefined, {
    select: (data) =>
      selectAccount({
        AccountType: AccountType.Current,
        CurrencyCode: CurrencyCode.NOAH,
        data,
      }),
  });
  const userPoints = account?.Balance?.Lifetime;

  const tabs = [
    {
      header: 'Earn Points',
      panel: (
        <QuestsTabPanel
          activeQuests={questsAndBadgesData?.activeQuests}
          completedBadges={questsAndBadgesData?.completedBadges}
          referralUrl={getReferralUrl(userData?.userProfile.UsernameDisplay)}
        />
      ),
      value: TpRewardsTabs.Quests,
    },
    {
      header: 'Rewards & Perks',
      panel: <BadgesTabPanel badges={questsAndBadgesData?.badges} />,
      value: TpRewardsTabs.Badges,
    },
  ];
  return (
    <RewardsScene
      activeTab={params.tab}
      pageTitle={routes.tabs.title}
      tabs={tabs}
      userPoints={userPoints}
    />
  );
}
