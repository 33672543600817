import { Fragment } from 'react';
import { AlertLink } from '@noah-labs/fe-shared-ui-components';
import type { TpPushAlert } from '@noah-labs/fe-shared-ui-components';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import { BuyBitcoinLink } from '../../../components/buttons/BuyBitcoinLink';

export const LightningLimitReached: TpPushAlert = {
  autoHideDuration: 4000,
  key: 'bitrefillLightningLimitReached',
  message: (
    <Fragment>
      You have reached your Lightning limit on this transaction. Will attempt on-chain payment
      instead.{' '}
      <AlertLink href={userRoutes.settings.accountLimits.path} target="_blank">
        Check your limits
      </AlertLink>
    </Fragment>
  ),
  severity: 'warning',
};

export const InsufficientBalance: TpPushAlert = {
  autoHideDuration: 6000,
  dismissable: true,
  key: 'InsufficientBalanceError',
  message: (
    <Fragment>
      Sorry, you don’t have enough funds for this. <BuyBitcoinLink text="Buy more Bitcoin" />
    </Fragment>
  ),
  severity: 'error',
};

export const SomethingWentWrong: TpPushAlert = {
  dismissable: true,
  key: 'bitrefillPaymentError',
  message: 'Sorry, something went wrong and we couldn’t make the payment. Please try again.',
  severity: 'error',
};

export const ParsingLnPaymentError: TpPushAlert = {
  autoHideDuration: 4000,
  key: 'bitrefillParseLnAddressError',
  message: 'Error parsing LN payment request',
  severity: 'error',
};

export const UnsupportedCurrency: TpPushAlert = {
  autoHideDuration: 4000,
  key: 'bitrefillUnsupportedCurrency',
  message:
    'We currently only support Bitcoin payments. We will soon roll out other currencies. Please check back later',
  severity: 'error',
};
