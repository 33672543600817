import type { TpUserLimitSignable } from '@noah-labs/fe-shared-data-access-signing';
import { FiatCurrencyCode, PolicyPeriod, UserLimitType } from '@noah-labs/shared-schema-gql';
import type { TpTransactionPinThresholdForm } from '../controllers/TransactionPinThreshold';

type TpGetLimitsSignaturePayload = {
  dailyLimitSignaturePayload: TpUserLimitSignable;
  singleTransactionLimitSignaturePayload: TpUserLimitSignable;
};

export function getLimitsSignaturePayload(
  data: TpTransactionPinThresholdForm,
): TpGetLimitsSignaturePayload {
  const customLimitsWereProvided =
    data.transactionPinThreshold === 'You decide when we ask for your PIN';

  const dailyLimitFiatAmount = (customLimitsWereProvided && data.dailyLimit) || '0';
  const singleTransactionLimitFiatAmount =
    (customLimitsWereProvided && data.singleTransactionLimit) || '0';

  const singleTransactionLimitSignaturePayload: TpUserLimitSignable = {
    FiatAmount: singleTransactionLimitFiatAmount,
    FiatCurrencyCode: FiatCurrencyCode.EUR,
    inputType: 'userLimit',
    LimitType: UserLimitType.Signing,
    Period: PolicyPeriod.SINGLE,
  };

  const dailyLimitSignaturePayload: TpUserLimitSignable = {
    FiatAmount: dailyLimitFiatAmount,
    FiatCurrencyCode: FiatCurrencyCode.EUR,
    inputType: 'userLimit',
    LimitType: UserLimitType.Signing,
    Period: PolicyPeriod.DAY,
  };

  return {
    dailyLimitSignaturePayload,
    singleTransactionLimitSignaturePayload,
  };
}
