import { useLayoutEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { AppContainer, AppHeaderTitle } from '@noah-labs/fe-shared-ui-components';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { AppHeaderData } from '../../../../components/layout/AppHeaderData';

type PpAddressScanWrapper = PpWC & {
  setAppHeaderHeight: (height: number) => void;
};
export function AddressScanWrapper({
  children,
  setAppHeaderHeight,
}: PpAddressScanWrapper): React.ReactElement {
  const appHeaderRef = useRef<HTMLDivElement | null>(null);

  const theme = useTheme();
  const backgroundColor = theme.palette.common.black;
  const fontColor = theme.palette.common.white;

  useLayoutEffect(() => {
    const height = appHeaderRef.current?.getBoundingClientRect().height || 0;
    setAppHeaderHeight(height);
  }, [setAppHeaderHeight]);

  return (
    <AppContainer backgroundColor={backgroundColor} fontColor={fontColor} headTitle="Scan Address">
      <div ref={appHeaderRef}>
        <AppHeaderData
          backButton
          exitButton
          helpButton
          backgroundColor={backgroundColor}
          fontColor={fontColor}
        >
          <AppHeaderTitle>Scan address</AppHeaderTitle>
        </AppHeaderData>
      </div>
      {children}
    </AppContainer>
  );
}
