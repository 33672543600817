import type { SVGProps } from 'react';

export function SvgDownload(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.635 9.059h-1.87V3.176A1.18 1.18 0 0 0 14.588 2H9.882a1.18 1.18 0 0 0-1.176 1.176V9.06h-1.87c-1.048 0-1.577 1.27-.836 2.012l5.4 5.4c.459.458 1.2.458 1.659 0l5.4-5.4c.74-.742.223-2.012-.824-2.012ZM4 20.823C4 21.471 4.53 22 5.176 22h14.118a1.18 1.18 0 0 0 1.177-1.177 1.18 1.18 0 0 0-1.177-1.176H5.176A1.18 1.18 0 0 0 4 20.823"
        fill="currentColor"
      />
    </svg>
  );
}
