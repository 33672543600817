import { useLocation } from 'react-router-dom';

export type TpBackHijackState = {
  from: string;
};

export type TpBackAction = {
  backTo?: string;
  goBack: boolean;
};

/**
 * If the location state contains a from prop, then we should go back to that route
 * Otherwise, we should go back to the default route
 */
export function useBackHijack(defaultRoute?: string): TpBackAction {
  const location = useLocation<TpBackHijackState | undefined>();
  if (location.state?.from) {
    return {
      goBack: true,
    };
  }
  return {
    backTo: defaultRoute,
    goBack: false,
  };
}
