import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { styled, useTheme } from '@mui/material/styles';
import { PlusCircleThickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/PlusCircleThickIcon';
import {
  Avatar,
  AvatarBadge,
  AvatarIcon,
  QuestBadge,
  styledMixins,
} from '@noah-labs/fe-shared-ui-components';

const StyledAvatarIconContent = styled('div')(({ theme }) => ({
  ...styledMixins.rowCentered,
  backgroundColor: theme.palette.common.black,
  borderRadius: '100%',
  height: theme.spacing(3.33),
  width: theme.spacing(3.33),
}));

const StyledAvatarIcon = styled(AvatarIcon)(() => ({
  alignItems: 'center',
  display: 'flex',
}));

type PpQuestCompletedNotificationIcon = {
  badgeId: string;
};

export function QuestCompletedNotificationIcon({
  badgeId,
}: PpQuestCompletedNotificationIcon): ReactElement {
  const theme = useTheme();

  return (
    <Avatar>
      <StyledAvatarIcon>
        <StyledAvatarIconContent>
          <QuestBadge
            badgeId={badgeId}
            imageCss={css`
              width: ${theme.spacing(3)};
              height: ${theme.spacing(3)};
            `}
          />
        </StyledAvatarIconContent>
      </StyledAvatarIcon>
      <AvatarBadge>
        <AvatarIcon color={theme.palette.system.green.main} size={2}>
          <PlusCircleThickIcon />
        </AvatarIcon>
      </AvatarBadge>
    </Avatar>
  );
}
