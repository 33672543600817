import { useEffect } from 'react';
import { useAddress } from '@noah-labs/fe-shared-feature-address';
import { KycAccessControlData } from '@noah-labs/fe-shared-feature-kyc';
import { useSardineFlow } from '@noah-labs/fe-shared-feature-sardine';
import type { SmLightningSend } from '@noah-labs/fe-shared-feature-wallet';
import {
  LightningSendCompleteController,
  LightningSendConfirmController,
  LightningSendEnterAmountController,
  useSendAmounts,
} from '@noah-labs/fe-shared-feature-wallet';
import type { TpLightningAddressData } from '@noah-labs/fe-shared-ui-address';
import { generatePath, Switch404, useStateMachine } from '@noah-labs/fe-shared-ui-components';
import { useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { ExpiredInvoiceScene } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { CurrencyUnit, Feature, SardineFlows } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AppHeaderData } from '../../../../components/layout/AppHeaderData';
import { AccessControlData } from '../../../auth/AccessControlData';
import { WalletControllerWrapper } from '../../components/layout/WalletControllerWrapper';
import { ExternalRequest } from './ExternalRequest';

const emptyState: SmLightningSend = {
  cryptoAmount: '',
  cryptoUnit: CurrencyUnit.Default,
  fetchedAt: '',
  fiatAmount: '',
  lightningAddressProxy: undefined,
  paymentRequest: undefined,
  paymentRequestData: undefined,
  price: '',
};
export function LightningSendRouter(): React.ReactElement {
  useSardineFlow({ flow: SardineFlows.CryptoWithdraw });
  const { data, setData } = useAddress();

  /**
   * Remove payment request from the address context on unmount
   */
  useEffect(() => () => setData(undefined), [setData]);

  let initialState: SmLightningSend | undefined;
  if (data) {
    initialState = {
      ...emptyState,
      cryptoAmount: data.amount || '',
      lightningAddressProxy: data.lightningAddressProxy,
      // can use 'as' here because we know it will only be routed here if parsedAddressData is TpLightningAddressData
      paymentRequestData: data as TpLightningAddressData,
    };

    // If an invoice is entered directly, set it as the paymentRequest
    if (data.addressType === 'lnbc') {
      initialState.paymentRequest = data.address;
    }
  }

  const sm = useStateMachine<SmLightningSend>({
    emptyState,
    initialState,
    name: 'LightningSendRouter',
  });

  /**
   * Get params and fetch the fiatAmount for the cryptoAmount in the paymentRequestData,
   * so that we can display it on the confirm & complete screeens.
   * Always fetch a new fiatAmount in case the stored value is out of date
   */
  const { cryptoCurrency, params } = useWalletParams();

  const { cryptoUnit, fetchedAt, fiatAmount, price } = useSendAmounts({
    cryptoAmount: sm.state.cryptoAmount,
    cryptoCurrency,
    fiatAmount: sm.state.fiatAmount,
  });

  const { updateState } = sm;
  useEffect(() => {
    updateState({ cryptoUnit, fetchedAt, fiatAmount, price });
  }, [cryptoUnit, fetchedAt, fiatAmount, price, updateState]);

  const { address, lightning } = walletRoutes().send;

  /**
   * Setup invalid screen redirects, we always need a cryptoAmount and a paymentRequest for lightning send
   */

  // Go back to the scan screen in case we don't have the paymentRequestData address
  const enterAmountInvalidRedirect =
    !sm.state.paymentRequestData?.address && generatePath(address.base.path, params);

  // Go back to enterAmount screen in case we don't have an amount on the confirm screen
  const confirmAndCompleteInvalidRedirect =
    !sm.state.cryptoAmount && generatePath(lightning.enterAmount.path, params);

  return (
    <Switch404>
      <Route exact path={lightning.enterAmount.path}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          needsFeature={[Feature.LnPayment]}
          redirectInvalid={enterAmountInvalidRedirect}
        >
          <KycAccessControlData bypassIfUserHasBalance origin="withdraw">
            <WalletControllerWrapper headTitle={lightning.enterAmount.title}>
              <LightningSendEnterAmountController {...sm} />
            </WalletControllerWrapper>
          </KycAccessControlData>
        </AccessControlData>
      </Route>

      <Route exact path={lightning.confirm.path}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          needsFeature={[Feature.LnPayment]}
          redirectInvalid={confirmAndCompleteInvalidRedirect}
        >
          <KycAccessControlData bypassIfUserHasBalance origin="withdraw">
            <WalletControllerWrapper
              headerTitle={lightning.confirm.title}
              headTitle={lightning.confirm.title}
            >
              <LightningSendConfirmController {...sm} />
            </WalletControllerWrapper>
          </KycAccessControlData>
        </AccessControlData>
      </Route>

      <Route exact path={lightning.complete.path}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          needsFeature={[Feature.LnPayment]}
          redirectInvalid={confirmAndCompleteInvalidRedirect}
        >
          <WalletControllerWrapper backButton={false} headTitle={lightning.complete.title}>
            <LightningSendCompleteController {...sm} />
          </WalletControllerWrapper>
        </AccessControlData>
      </Route>

      <Route exact path={lightning.invoiceExpired.path}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          needsFeature={[Feature.LnPayment]}
          redirectInvalid={enterAmountInvalidRedirect}
        >
          <ExpiredInvoiceScene AppHeaderSlot={<AppHeaderData backButton exitButton helpButton />} />
        </AccessControlData>
      </Route>

      <Route exact path={lightning.request.path}>
        <AccessControlData needsAuthStatus={['authenticated']} needsFeature={[Feature.LnPayment]}>
          <ExternalRequest {...sm} />
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
