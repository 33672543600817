import { CloseIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CloseIcon';
import type { PpHeaderIconButton } from '@noah-labs/fe-shared-ui-components';
import { HeaderIconButton, useNavigation } from '@noah-labs/fe-shared-ui-components';

type PpExitIconButtonData = Pick<PpHeaderIconButton, 'edge'>;

export function ExitIconButtonData({ edge }: PpExitIconButtonData): React.ReactElement {
  const { exitTo } = useNavigation();

  return (
    <HeaderIconButton aria-label="exit" data-qa="exit-button" edge={edge} href={exitTo}>
      <CloseIcon />
    </HeaderIconButton>
  );
}
