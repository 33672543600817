import React from 'react';
import { ListItemAvatar, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { TpAnalyticsEvent, useAnalytics } from '@noah-labs/fe-shared-feature-analytics';
import {
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemEndContent,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  ProgressBar,
  QuestBadge,
  styledMixins,
} from '@noah-labs/fe-shared-ui-components';
import { isQuestPerpetual } from '../../data/utils';
import type { TpQuestListItem } from '../../types';
import { FlashTimer } from '../flashTimer/FlashTimer';

export type PpQuestListItem = {
  quest: TpQuestListItem | null;
};

const ListItemAvatarStyled = styled(ListItemAvatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  borderRadius: theme.borderRadius.sm,
  height: '60px',
  margin: theme.spacing(0, 2, 0, 0),
  width: '60px',
  ...styledMixins.colCentered,
}));

export function QuestListItem({ quest }: PpQuestListItem): React.ReactElement | null {
  const theme = useTheme();
  const { track } = useAnalytics();

  if (!quest) {
    return null;
  }

  const isActiveFlash = quest.ends && !quest.completed;

  const questIsPerpetual = isQuestPerpetual(quest.questId);

  return (
    <ListItem>
      <ListItemButton
        href={quest.href}
        sx={
          isActiveFlash
            ? {
                border: `1px solid ${theme.palette.brand.light}`,
                borderRadius: theme.borderRadius.md,
              }
            : undefined
        }
        onClick={(): void => {
          track(TpAnalyticsEvent.QuestClicked, {
            id: quest.questId,
          });
        }}
      >
        <ListItemCard sx={{ flexDirection: 'column' }}>
          <ListItemContent sx={{ paddingBottom: 0 }}>
            {quest.badgeId && (
              <ListItemAvatarStyled>
                <QuestBadge badgeId={quest.badgeId} />
              </ListItemAvatarStyled>
            )}
            <ListItemStartContent>
              <ListItemPrimaryText>{quest.title}</ListItemPrimaryText>
              <ListItemSecondaryText color="text.light">
                {quest.completed ? 'Completed' : quest.activeTaskDescription}
              </ListItemSecondaryText>
            </ListItemStartContent>
            <ListItemEndContent>
              <Typography
                alignSelf="center"
                color="text.header"
                minWidth={80}
                textAlign="end"
                variant="paragraphBodyMBold"
              >
                {quest.np} pts
              </Typography>
            </ListItemEndContent>
          </ListItemContent>
          {!questIsPerpetual && (
            <Stack
              alignItems="center"
              direction="row"
              spacing={2}
              sx={{ padding: theme.spacing(1, 2, 2) }}
              width="100%"
            >
              <ProgressBar progress={quest.progress / quest.requiredProgress} />
              <Typography color="text.light" variant="paragraphBodyS">
                {quest.progress}/{quest.requiredProgress}
              </Typography>
            </Stack>
          )}
          {isActiveFlash && (
            <FlashTimer ends={quest.ends} sx={{ marginTop: questIsPerpetual ? 2 : 0 }} />
          )}
        </ListItemCard>
      </ListItemButton>
    </ListItem>
  );
}
