import { useCallback } from 'react';
import { ButtonBase } from '@mui/material';
import {
  useOptimisticUserInitOptions,
  useUpdateUserPrimaryCurrencySettingMutation,
} from '@noah-labs/fe-shared-data-access-user';
import {
  getDefaultAccount,
  selectFromFilterWithFallback,
  useAccountsQueryPoll,
  useCalculateFiatFromCrypto,
} from '@noah-labs/fe-shared-data-access-wallet';
import {
  Balance,
  CurrencySwitchButton,
  getCurrencyAmountSlots,
} from '@noah-labs/fe-shared-ui-components';
import { getAccountUi, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { CurrencyDisplayType } from '@noah-labs/shared-schema-gql';
import { useUserInitUi } from '../hooks/useUserInitUi';

/**
 * Displays a known crypto currency amount and calculates an approximate fiat amount
 */
export function CurrentBalanceData(): React.ReactElement {
  const da = getDefaultAccount();
  const { AccountType, cryptoCurrency } = useWalletParams({
    accountType: da.AccountType,
    currencyCode: da.CurrencyCode,
  });
  const { data: userData } = useUserInitUi();

  const { data: account } = useAccountsQueryPoll(undefined, {
    select: (data) =>
      getAccountUi(
        selectFromFilterWithFallback({
          AccountType,
          CurrencyCode: cryptoCurrency.code,
          data,
        }),
      ),
  });
  const cryptoBalance = account?.Balance?.Available;

  const { data: priceData } = useCalculateFiatFromCrypto({
    cryptoAmount: cryptoBalance,
    cryptoCurrency,
    fiatCurrency: userData?.userProfile.fiatCurrency,
    priceProvider: 'market',
  });
  const fiatAmount = priceData?.fiatAmount;

  const updateOptions = useOptimisticUserInitOptions();
  const { mutate } = useUpdateUserPrimaryCurrencySettingMutation(updateOptions);

  const handleClick = useCallback(() => {
    mutate({
      Input: {
        PrimaryCurrency:
          userData?.userProfile.PrimaryCurrency === CurrencyDisplayType.Fiat
            ? CurrencyDisplayType.Crypto
            : CurrencyDisplayType.Fiat,
      },
    });
  }, [mutate, userData?.userProfile.PrimaryCurrency]);

  const Amounts = getCurrencyAmountSlots({
    cryptoAmount: cryptoBalance,
    cryptoCurrency,
    cryptoUnit: userData?.userProfile.DisplayUnit,
    fiatAmount,
    fiatCurrency: userData?.userProfile.fiatCurrency,
    primaryCurrency: userData?.userProfile.PrimaryCurrency,
    roundDown: true,
  });

  return (
    <ButtonBase sx={{ width: '100%' }} onClick={handleClick}>
      <Balance {...Amounts} IconSlot={<CurrencySwitchButton />} />
    </ButtonBase>
  );
}
