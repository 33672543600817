import type { SVGProps } from 'react';

export function SvgWarning(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 25"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.107a10 10 0 1 0 0 20 10 10 0 0 0 0-20m0 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2m1-4a1 1 0 0 1-2 0v-5a1 1 0 0 1 2 0z"
        fill="currentColor"
      />
    </svg>
  );
}
