import type { ForwardedRef, MutableRefObject } from 'react';
import React, { forwardRef, useCallback, useState } from 'react';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import type { Section, TpIMessage } from '@noah-labs/fe-shared-ui-notifications';
import { NotificationPopover, NotificationsCenter } from '@noah-labs/fe-shared-ui-notifications';
import { ScreensEnum, useNotifications } from '@novu/notification-center';

export type SetScreenFn = {
  (screen: ScreensEnum): void;
};

type NotificationsCenterControllerProps = {
  isNotificationCenterOpen: boolean;
  setIsNotificationCenterOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NotificationsCenterController = forwardRef(
  (
    { isNotificationCenterOpen, setIsNotificationCenterOpen }: NotificationsCenterControllerProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const { markAllNotificationsAsSeen } = useNotifications();

    const [section, setSection] = useState<Section>('notification-list');

    const handleOpenSettingsClick = useCallback(({ setScreen }: { setScreen: SetScreenFn }) => {
      setScreen(ScreensEnum.SETTINGS);
      setSection('user-preferences');
    }, []);

    const handleReturnClick = useCallback(
      ({ setScreen }: { setScreen: SetScreenFn }) => {
        if (section === 'user-preferences') {
          setScreen(ScreensEnum.NOTIFICATIONS);
          setSection('notification-list');

          return;
        }

        markAllNotificationsAsSeen();
        setSection('notification-list');
        setIsNotificationCenterOpen(false);
      },
      [markAllNotificationsAsSeen, section, setIsNotificationCenterOpen],
    );

    const handleNotificationClick = useCallback(
      (message: TpIMessage) => {
        markAllNotificationsAsSeen();

        if (!message.cta.data.url) {
          return;
        }

        window.location.assign(generatePath(message.cta.data.url));
      },
      [markAllNotificationsAsSeen],
    );

    function handleClose(): void {
      markAllNotificationsAsSeen();
      setIsNotificationCenterOpen(false);
    }

    return (
      <NotificationPopover
        anchorEl={(ref as MutableRefObject<HTMLButtonElement>).current}
        open={isNotificationCenterOpen}
        onClose={(): void => handleClose()}
      >
        <NotificationsCenter
          handleNotificationClick={handleNotificationClick}
          handleOpenSettingsClick={handleOpenSettingsClick}
          handleReturnClick={handleReturnClick}
          section={section}
        />
      </NotificationPopover>
    );
  },
);

NotificationsCenterController.displayName = 'NotificationCenterController';
