import { Divider, Stack } from '@mui/material';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemSecondaryText,
  ListSection,
} from '@noah-labs/fe-shared-ui-components';
import { getPayoutFeeAndEta, TpPaymentMethod } from '@noah-labs/fe-shared-ui-shared';
import type { TpPaymentBank, TpPaymentCard } from '@noah-labs/fe-shared-ui-shared';
import { PaymentMethod } from '@noah-labs/fe-shared-ui-wallet';
import { ManagePaymentMethodsLink } from '../buttons/ManagePaymentMethodsLink';

export type PpSavedPayoutMethodsList = {
  onPayoutMethodClick: (payoutMethod: TpPaymentCard | TpPaymentBank) => void;
  savedPayoutMethods: Array<TpPaymentCard | TpPaymentBank>;
};

export function SavedPayoutMethodsList({
  onPayoutMethodClick,
  savedPayoutMethods,
}: PpSavedPayoutMethodsList): React.ReactElement {
  return (
    <Stack spacing={2}>
      <ListSection
        title="Saved payout methods"
        titleColor="text.dark"
        TitleEndSlot={<ManagePaymentMethodsLink />}
      >
        <List disablePadding spacing={1}>
          {savedPayoutMethods.map((method) => (
            <ListItem key={method.id}>
              <ListItemButton
                disabled={!method.payoutSupported}
                onClick={(): void => {
                  onPayoutMethodClick(method);
                }}
              >
                <ListItemCard sx={{ p: 2 }}>
                  <Stack divider={<Divider />} spacing={1} width="100%">
                    {method.payoutMethod &&
                      (method.payoutMethod === TpPaymentMethod.Card ? (
                        <PaymentMethod
                          disablePadding
                          method={method.payoutMethod}
                          paymentCard={method as TpPaymentCard}
                        />
                      ) : (
                        <PaymentMethod
                          disablePadding
                          method={method.payoutMethod}
                          paymentBank={method as TpPaymentBank}
                        />
                      ))}
                    {method.estimateHours && (
                      <ListItemSecondaryText>{getPayoutFeeAndEta(method)}</ListItemSecondaryText>
                    )}
                    {!method.payoutSupported && (
                      <ListItemSecondaryText>Not supported for sell</ListItemSecondaryText>
                    )}
                  </Stack>
                </ListItemCard>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </ListSection>
    </Stack>
  );
}
