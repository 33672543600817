import type { SVGProps } from 'react';

export function SvgStopwatch(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      data-name="Layer 1"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={12.03} cy={13.15} fill="none" r={9.49} stroke="currentColor" strokeWidth={1.69} />
      <path
        d="M11.83 3.09V1.24"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={0.83}
      />
      <path
        d="M12.82 1.01h-1.57"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.24}
      />
      <path
        d="M12.98 13.91V9.7m-1.47 4.21H8.35"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.69}
      />
    </svg>
  );
}
