import KycId from '@noah-labs/fe-shared-ui-assets/svg/images/kyc-id.svg?url';
import {
  AppContainer,
  AppFooter,
  AppMain,
  ButtonGroup,
  InlineLinkOrButton,
  PrimaryButton,
  SceneImage,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';
import { webLinks } from '@noah-labs/shared-constants';
import { Helmet } from 'react-helmet';

export type PpKycReminderScene = {
  handleNotNowClick: () => void;
  handleYesClick: () => void;
  image?: string;
  paragraph?: string;
  showFaqLink?: boolean;
  title?: string;
};

export function KycReminderScene({
  handleNotNowClick,
  handleYesClick,
  image = KycId,
  paragraph = 'Please complete KYC verification to unlock all the features of the NOAH app.',
  showFaqLink,
  title = 'Please complete identity verification to continue',
}: PpKycReminderScene): React.ReactElement {
  return (
    <AppContainer>
      <Helmet>
        <title>Please complete identity verification to continue</title>
      </Helmet>
      <AppMain
        sx={{
          textAlign: 'center',
        }}
      >
        <SceneMain dataQa="kyc-reminder">
          <SceneImage alt="Please complete identity verification to continue" src={image} />
          <SceneTitleLarge>{title}</SceneTitleLarge>
          <SceneParagraph>{paragraph}</SceneParagraph>
          {showFaqLink && (
            <SceneParagraph>
              Read our{' '}
              <InlineLinkOrButton href={webLinks.faq.base} target="_blank">
                FAQ
              </InlineLinkOrButton>{' '}
              to find out more
            </SceneParagraph>
          )}
        </SceneMain>
      </AppMain>
      <AppFooter>
        <ButtonGroup>
          <PrimaryButton color="primaryBrand" data-qa="yes-btn" onClick={handleYesClick}>
            Yes
          </PrimaryButton>
          <PrimaryButton data-qa="not-now-btn" variant="text" onClick={handleNotNowClick}>
            Not now
          </PrimaryButton>
        </ButtonGroup>
      </AppFooter>
    </AppContainer>
  );
}
