import React from 'react';
import { css } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  AppContainer,
  AppFooter,
  AppMain,
  ButtonGroup,
  FooterContentText,
  GetInTouchLink,
  PrimaryButton,
  SceneMain,
  StatusElevatedCard,
  useNavigation,
} from '@noah-labs/fe-shared-ui-components';
import { TpCheckoutPaymentStatus } from '@noah-labs/fe-shared-util-shared';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { getCheckoutAvatarStatus } from '../controllers/buy/getAvatarStatus';

export type PpCompleteBuyScene = {
  BalanceSlot: React.ReactElement;
  enableAnimation?: boolean;
  paymentStatus: TpCheckoutPaymentStatus;
  transactionUrl?: string;
};

export function CompleteBuyScene({
  BalanceSlot,
  enableAnimation = webConfigBrowser.settings.enableLoaderAnimations,
  paymentStatus,
  transactionUrl,
}: PpCompleteBuyScene): React.ReactElement {
  const theme = useTheme();
  const styles = {
    balance: css`
      margin-top: ${theme.spacing(3)};
    `,
    content: css`
      margin-top: ${theme.spacing(1)};
    `,
  };

  const { exitTo } = useNavigation();

  const isSuccessful = paymentStatus === TpCheckoutPaymentStatus.success;
  const isFailed = paymentStatus === TpCheckoutPaymentStatus.failure;

  return (
    <AppContainer>
      <Helmet>
        <title>Transaction Complete</title>
      </Helmet>
      <AppMain>
        <AppHeaderData exitButton helpButton />
        <SceneMain dataQa="complete">
          <StatusElevatedCard
            enableAnimation={enableAnimation}
            status={getCheckoutAvatarStatus(paymentStatus)}
          >
            <Stack css={styles.content} spacing={1} textAlign="center">
              {isSuccessful && (
                <Typography color="primary.dark" variant="paragraphBodyM">
                  Successfully purchased
                </Typography>
              )}
              {isFailed && (
                <React.Fragment>
                  <Typography color="primary.dark" variant="paragraphBodyM">
                    Purchase failed
                  </Typography>
                  <Typography color="grayscale.700" variant="paragraphBodyS">
                    {transactionUrl ? (
                      `Payment was declined by your card issuer, please contact your bank and try
                        again.`
                    ) : (
                      <React.Fragment>
                        Sorry, something went wrong when processing your purchase, please try again
                        later. If the problem persists, please <GetInTouchLink />.
                      </React.Fragment>
                    )}
                  </Typography>
                </React.Fragment>
              )}
            </Stack>
            <div css={styles.balance}>{BalanceSlot}</div>
          </StatusElevatedCard>
        </SceneMain>
      </AppMain>
      <AppFooter>
        <Stack spacing={2} textAlign="center">
          {isSuccessful && (
            <FooterContentText>
              Arriving within minutes in your account, check status in the transaction details.
            </FooterContentText>
          )}
          <ButtonGroup>
            <PrimaryButton data-qa="done-button" href={exitTo}>
              Done
            </PrimaryButton>
            {isSuccessful && (
              <PrimaryButton
                data-qa="view-transaction-button"
                disabled={!transactionUrl}
                href={transactionUrl}
                variant="text"
              >
                View transaction
              </PrimaryButton>
            )}
          </ButtonGroup>
        </Stack>
      </AppFooter>
    </AppContainer>
  );
}
