import { useTransactionQuery } from '@noah-labs/fe-shared-data-access-wallet';
import type { TpTransactionUi } from '@noah-labs/fe-shared-ui-shared';
import { getTransactionUi } from '@noah-labs/fe-shared-ui-shared';
import { TransactionStatus } from '@noah-labs/shared-schema-gql';
import { duration } from '@noah-labs/shared-util-vanilla';

export type TpTransactionDetails = {
  isFetching: boolean;
  transaction: TpTransactionUi | null;
};

export function useTransactionDetails(id: string | undefined | null): TpTransactionDetails {
  const { data, isFetching } = useTransactionQuery(
    { Input: { TransactionID: id || '' } },
    {
      enabled: Boolean(id),
      /**
       * refetch every second if the transaction is pending
       */
      refetchInterval: (d): number | false => {
        if (!d?.transaction || d.transaction.Status === TransactionStatus.Pending) {
          return duration.seconds(1);
        }
        return false;
      },
    },
  );

  if (!data?.transaction) {
    return { isFetching, transaction: null };
  }

  const transactionUi = getTransactionUi({ tx: data.transaction });

  const isPending = transactionUi.status === TransactionStatus.Pending;

  return {
    isFetching,
    transaction: { ...transactionUi, feeAmount: isPending ? undefined : transactionUi.feeAmount },
  };
}
