import { useEffect } from 'react';
import { useAuth } from '@noah-labs/fe-shared-feature-auth';
import type { SardineFlows } from '@noah-labs/shared-schema-gql';
import { useSardine } from './useSardine';

export type PpUseSardineFlow = {
  flow: SardineFlows;
  sessionKey?: string;
};

export function useSardineFlow({ flow, sessionKey }: PpUseSardineFlow): void {
  const auth = useAuth();
  const { updateSardineConfig } = useSardine();

  useEffect(() => {
    if (!auth.data?.sessionId || !auth.data.userId) {
      return;
    }
    updateSardineConfig({
      flow,
      sessionKey: sessionKey || auth.data.sessionId,
      userIdHash: auth.data.userId,
    });
  }, [auth.data?.sessionId, auth.data?.userId, flow, sessionKey, updateSardineConfig]);
}
