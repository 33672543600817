import {
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemEndContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
} from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-shared';

export type PpAccountCard = {
  AvatarSlot: React.ReactNode;
  PrimaryAmountSlot: React.ReactNode;
  PrimaryTextSlot: React.ReactNode;
  SecondaryAmountSlot: React.ReactNode;
  cryptoCurrency: TpCryptoCurrencyUI;
  defaultAccount?: boolean;
  href?: string;
  onSelectAccount?: () => void;
};

export function AccountCard({
  AvatarSlot,
  cryptoCurrency,
  defaultAccount = false,
  href,
  onSelectAccount,
  PrimaryAmountSlot,
  PrimaryTextSlot,
  SecondaryAmountSlot,
}: PpAccountCard): React.ReactElement {
  return (
    <ListItem>
      <ListItemButton href={href} onClick={onSelectAccount}>
        <ListItemCard
          data-qa={defaultAccount ? 'default-account' : `account-${cryptoCurrency.code}`}
        >
          <ListItemContent>
            <ListItemIcon>{AvatarSlot}</ListItemIcon>
            <ListItemStartContent>
              <ListItemPrimaryText>{PrimaryTextSlot}</ListItemPrimaryText>
            </ListItemStartContent>
            <ListItemEndContent>
              <ListItemPrimaryText>{PrimaryAmountSlot}</ListItemPrimaryText>
              <ListItemSecondaryText>{SecondaryAmountSlot}</ListItemSecondaryText>
            </ListItemEndContent>
          </ListItemContent>
        </ListItemCard>
      </ListItemButton>
    </ListItem>
  );
}
