import { getFeError } from '@noah-labs/fe-shared-ui-shared';

export function getRevokingError(error: unknown): string {
  switch (true) {
    case error instanceof Error:
      return error.message;
    case typeof error === 'string':
      return error;
    default:
      return getFeError().message;
  }
}
