import { useEffect } from 'react';
import { ErrorPage, LoadingPage } from '@noah-labs/fe-shared-ui-components';
import { useMarketingCodeMutation } from '../data/marketing.generated';
import { FailedScene } from '../scenes/Failed';
import { SuccessScene } from '../scenes/Success';

type PpMarketing = {
  code?: string;
};

export function Marketing({ code }: PpMarketing): React.ReactElement {
  const { data, error, isLoading, mutate } = useMarketingCodeMutation();

  useEffect(() => {
    if (!code) {
      return;
    }
    mutate({ Input: { Code: code } });
  }, [code, mutate]);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage message="An unknown error occurred" />;
  }

  const message = data?.marketingCode.Description;

  if (!message) {
    return <ErrorPage message="An unknown error occurred" />;
  }

  switch (message) {
    case 'TABConf has ended':
      return <FailedScene message={message} />;
    case 'Nothing here':
      return <FailedScene helpText="Try scanning the QR code again." message="Not a valid code" />;
    default:
      return <SuccessScene title={message} />;
  }
}
