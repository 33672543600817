import { calculateFee, truncateAmount } from '@noah-labs/shared-currencies';
import type { TpAmount, TpCryptoCurrency, TpSimpleAmount } from '@noah-labs/shared-currencies';
import { isUndefinedOrNull, safeBN } from '@noah-labs/shared-util-vanilla';
import BigNumber from 'bignumber.js';
import { getConfig } from '../config';

type PpGetNetCryptoAmount = {
  cryptoCurrency: TpCryptoCurrency;
  fiatAmount: TpAmount;
  price: TpAmount;
};

export function getNetCryptoAmount({
  cryptoCurrency,
  fiatAmount,
  price,
}: PpGetNetCryptoAmount): TpSimpleAmount {
  const { cko } = getConfig();
  if (isUndefinedOrNull(price) || isUndefinedOrNull(fiatAmount)) {
    return undefined;
  }

  const fiatFee = calculateFee({
    amount: fiatAmount,
    fee: cko.feePercentage,
    feeMin: cko.feeMinimumFiatAmount,
  });

  const netFiatAmount = safeBN(fiatAmount).minus(fiatFee);
  const newCryptoAmount = netFiatAmount.dividedBy(price);
  const zeroOrAmount = BigNumber.max(0, newCryptoAmount);

  return truncateAmount({
    amount: zeroOrAmount.toString(),
    decimalPlaces: cryptoCurrency.decimals,
  });
}
