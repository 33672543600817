import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { LowDialog, PrimaryButton } from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { getConfig, useToggleDialog } from '@noah-labs/fe-shared-ui-shared';

type PpCheckoutFeeDialog = {
  action: 'buying' | 'selling';
  content?: string;
};

export const CheckoutFeeDialog = forwardRef<TpDialogToggle, PpCheckoutFeeDialog>(
  ({ action, content }, ref): JSX.Element => {
    const { cko } = getConfig();
    const { open, toggle } = useToggleDialog({ ref });

    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle>Fees</DialogTitle>
        <DialogContent>
          <DialogContentText paragraph>
            Transparency is essential to us, and we want to provide clear information about the fees
            associated with {action} Bitcoin through our crypto wallet app.
          </DialogContentText>

          <DialogContentText paragraph={!!content}>
            There is a {cko.feePercentage}% fee on all buy and sell orders with a minimum fee of $
            {cko.feeMinimumFiatAmount}. Your bank or card provider may charge additional fees. NOAH
            has no control over the fees set by your bank or card provider.
          </DialogContentText>

          {content && <DialogContentText>{content}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <PrimaryButton color="primaryBrand" onClick={toggle}>
            Got it
          </PrimaryButton>
        </DialogActions>
      </LowDialog>
    );
  },
);

CheckoutFeeDialog.displayName = CheckoutFeeDialog.name;
