import { useUserLimitsQuery } from '@noah-labs/fe-shared-data-access-user';
import type { TpLimitAndUsage } from '@noah-labs/fe-shared-data-access-wallet';
import { getLnSendLimitAndUsage } from '@noah-labs/fe-shared-data-access-wallet';
import type { TpCryptoCurrency } from '@noah-labs/shared-currencies';
import { PolicyPeriod } from '@noah-labs/shared-schema-gql';

type TpWithdrawLimits = {
  lnSendDayLimit: TpLimitAndUsage;
  lnSendSingleLimit: TpLimitAndUsage;
};

type PpUseUserWithdrawLimits = {
  cryptoCurrency: TpCryptoCurrency;
};
export function useUserWithdrawLimits({
  cryptoCurrency,
}: PpUseUserWithdrawLimits): TpWithdrawLimits | undefined {
  const { data: limitData } = useUserLimitsQuery(
    {
      currencyCodes: [cryptoCurrency.code],
    },
    {
      select: (data): TpWithdrawLimits => ({
        lnSendDayLimit: getLnSendLimitAndUsage(data.userLimit.Limits, PolicyPeriod.DAY),
        lnSendSingleLimit: getLnSendLimitAndUsage(data.userLimit.Limits, PolicyPeriod.SINGLE),
      }),
    },
  );

  return limitData;
}
