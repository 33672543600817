import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link, Stack } from '@mui/material';
import {
  AppFooter,
  AppHeader,
  AppMain,
  PrimaryButton,
  SceneHeader,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';
import { authRoutes } from '@noah-labs/fe-shared-util-routes';
import { FormProvider, useForm } from 'react-hook-form';
import type { ErrorOption, Resolver, UseFormSetError } from 'react-hook-form';
import { z } from 'zod';
import { PasswordFormItem } from '../components/PasswordFormItem';
import { useAuthError } from '../hooks/useAuthError';

export type TpForgottenPasswordForm = {
  password: string;
  root?: { serverError: void };
};

export type TpForgottenPassword = {
  root?: { serverError: void };
};

const forgottenPasswordFormId = 'forgotten-password-form';

const defaultValues: TpForgottenPasswordForm = { password: '' };

const schema = z.object({
  password: z
    .string()
    .min(1, 'Password is a required field.')
    .min(8, 'Password must be at least 8 characters.'),
});
export type PpForgottenPasswordNewPasswordScene = {
  error: ErrorOption | undefined;
  helpButton?: React.ReactNode;
  loading: boolean;
  onUpdate: (
    values: TpForgottenPasswordForm,
    setError: UseFormSetError<TpForgottenPasswordForm>,
  ) => Promise<void>;
};

export function ForgottenPasswordNewPasswordScene({
  error,
  helpButton,
  loading,
  onUpdate,
}: PpForgottenPasswordNewPasswordScene): React.ReactElement {
  const methods = useForm<TpForgottenPasswordForm>({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(schema) as Resolver<TpForgottenPasswordForm>,
  });

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = methods;

  useAuthError({ error: errors.root?.serverError });

  useEffect(() => {
    if (!error) {
      return;
    }
    setError('root.serverError', error);
  }, [setError, error]);

  return (
    <FormProvider {...methods}>
      <AppMain>
        <AppHeader endIconsSlot={helpButton} />
        <SceneHeader>
          <SceneTitleLarge>Choose a new password</SceneTitleLarge>
          <SceneParagraph>
            Create a new password that is at least 8 characters long. A strong password has a
            combination of letters, digits and punctuation marks.
          </SceneParagraph>
        </SceneHeader>
        <SceneMain dataQa="forgotten-code">
          <Stack justifyContent="center" spacing={4}>
            <form
              id={forgottenPasswordFormId}
              onSubmit={handleSubmit((values) => onUpdate(values, setError))}
            >
              <PasswordFormItem variant="label" />
            </form>
            <SceneParagraph>
              Having trouble? <Link href={authRoutes.signOut.path}>Start again</Link>
            </SceneParagraph>
          </Stack>
        </SceneMain>
      </AppMain>
      <AppFooter wide>
        <PrimaryButton
          color="primaryBrand"
          disabled={isSubmitting || loading}
          form={forgottenPasswordFormId}
          loading={isSubmitting}
          type="submit"
        >
          Continue
        </PrimaryButton>
      </AppFooter>
    </FormProvider>
  );
}
