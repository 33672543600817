import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import dayjs from 'dayjs';
import { getConfig } from '../config';

const { kycExpirationAlertDays } = getConfig();

type TpUseIsKycExpiring = {
  kycExpired: boolean;
  kycIsExpiring: boolean;
};

export function useKycExpiryStatus(): TpUseIsKycExpiring {
  const { data: userData } = useUserInitUi();

  const daysToKycExpiry = dayjs(userData?.userProfile.KycVerification.Expiry).diff(dayjs(), 'days');
  const kycExpired = daysToKycExpiry <= 0;
  const kycIsExpiring = !kycExpired && daysToKycExpiry < kycExpirationAlertDays;

  return {
    kycExpired,
    kycIsExpiring,
  };
}
