import { useEffect } from 'react';
import {
  findDepositAddress,
  useAccountCreateMutation,
} from '@noah-labs/fe-shared-data-access-wallet';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import { useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { ReceiveScene, useWalletError } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { isLightningNetwork } from '@noah-labs/shared-currencies';

export function ReceiveController(): React.ReactElement {
  const { AccountType, cryptoCurrency, cryptoNetwork, params } = useWalletParams();
  const { data: userData } = useUserInitUi();
  const {
    data: accountCreateData,
    error: accountCreateErr,
    isIdle: accountCreateIdle,
    isLoading: accountCreateLoading,
    mutate: accountCreate,
  } = useAccountCreateMutation();
  const address = findDepositAddress(accountCreateData?.accountCreate);
  const lnAddress = userData?.userProfile.lnAddress;

  useEffect(() => {
    // If accountCreate is running or there is already an address, don't do anything
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (!accountCreateIdle || address || isLightningNetwork(cryptoNetwork!)) {
      return;
    }
    // Otherwise, create a new account
    accountCreate({
      Input: {
        AccountType,
        CurrencyCode: cryptoCurrency.code,
        DepositAddressRequest: {
          Create: true,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          Network: cryptoNetwork!.id,
        },
      },
    });
  }, [AccountType, cryptoCurrency.code, accountCreate, accountCreateIdle, address, cryptoNetwork]);

  const { ApiErrorScene } = useWalletError(accountCreateErr);

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <ReceiveScene
      address={address}
      cryptoCurrency={cryptoCurrency}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      cryptoNetwork={cryptoNetwork!}
      isAddressLoading={accountCreateLoading}
      lightningInvoiceHref={generatePath(walletRoutes().receive.lightning.enterAmount.path, params)}
      lnAddress={lnAddress}
    />
  );
}
