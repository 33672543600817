import { Stack } from '@mui/material';
import { AppMain, SceneHeader, SceneMain } from '@noah-labs/fe-shared-ui-components';

export type PpAccountsScene = {
  AccountsSlot: React.ReactElement;
  ActionsMenuSlot: React.ReactElement;
  TotalBalanceSlot: React.ReactElement;
};

export function AccountsScene({
  AccountsSlot,
  ActionsMenuSlot,
  TotalBalanceSlot,
}: PpAccountsScene): React.ReactElement {
  return (
    <AppMain sx={{ display: 'flex', flexDirection: 'column' }}>
      <SceneHeader>
        <Stack spacing={5}>
          {TotalBalanceSlot}
          {ActionsMenuSlot}
        </Stack>
      </SceneHeader>
      <SceneMain dataQa="wallet">{AccountsSlot}</SceneMain>
    </AppMain>
  );
}
