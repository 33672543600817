import { useEffect } from 'react';
import { useIntercomIdentifyHashQuery } from '@noah-labs/fe-shared-data-access-analytics';
import { disableRefetchRetry } from '@noah-labs/fe-shared-data-access-shared';
import { logger } from '@noah-labs/shared-logger/browser';
import { getConfig } from '../config';
import { useAnalyticsContext } from './useAnalyticsContext';

type PpUseIdentify = {
  email?: string;
  userId?: string;
};
export function useIdentify({ email, userId }: PpUseIdentify): void {
  const ajs = useAnalyticsContext();
  const config = getConfig();

  /**
   * Setup identify tracking callback
   */
  const { data: userHash } = useIntercomIdentifyHashQuery(undefined, disableRefetchRetry);

  useEffect(() => {
    /**
     * Don't call if disabled
     */
    if (!config.enabled || !config.identify) {
      logger.debug('identify not called: analytics disabled');
      return;
    }

    /**
     * Calls to identify can be throttled by destinations so only do it once all critical data is available
     * We need the user to:
     * - be authenticated
     * - have a userid
     * - have a verified email
     * - have the userHash for intercom - which can only be retrieved once the above conditions are satisfied
     */
    if (!userHash?.intercomIdentifyHash.UserHash) {
      logger.debug('identify not called: userHash is not not available');
      return;
    }

    /**
     * User profile updates are sent from our BE (more reliably) so no need to send extra info here
     */
    logger.debug('identify user');
    void ajs.identify(
      userId,
      {
        email,
        email_verified: true,
        user_id: userId,
      },
      {
        integrations: {
          Intercom: {
            user_hash: userHash.intercomIdentifyHash.UserHash,
          },
        },
      },
    );
  }, [
    ajs,
    config.enabled,
    config.identify,
    email,
    userHash?.intercomIdentifyHash.UserHash,
    userId,
  ]);
}
