import React from 'react';
import { useTheme } from '@mui/material/styles';
import { AppContainer, useDesktop } from '@noah-labs/fe-shared-ui-components';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { AppHeaderData } from '../../../../components/layout/AppHeaderData';
import { NavBarData } from '../../../../components/navigation/NavBarData';
import { SidebarNavData } from '../../../../components/navigation/SidebarNavData';
import { AccessControlData } from '../../../auth/AccessControlData';

export function AccountsControllerWrapper({ children }: PpWC): React.ReactElement {
  const isDesktop = useDesktop();
  const theme = useTheme();

  return (
    <AccessControlData needsAuthStatus={['authenticated']}>
      <AppContainer BottomNavbarSlot={<NavBarData />} SideNavbarSlot={<SidebarNavData />}>
        <AppHeaderData
          avatarButton
          disableBalancing
          helpButton
          notificationCenterButton
          backgroundColor={theme.palette.grayscale.offWhite}
          fullWidth={isDesktop}
        />
        {children}
      </AppContainer>
    </AccessControlData>
  );
}
