import type { SVGProps } from 'react';

export function SvgTwitter(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.647 10.469 20.932 2h-1.726L12.88 9.353 7.827 2H2l7.64 11.12L2 22h1.726l6.68-7.765L15.743 22h5.828l-7.923-11.531Zm-2.365 2.748-.774-1.107-6.16-8.81H7l4.971 7.11.774 1.107 6.462 9.242h-2.652z"
        fill="currentColor"
      />
    </svg>
  );
}
