import { Typography } from '@mui/material';
import { AppMain, SceneHeaderCard, SceneMain } from '@noah-labs/fe-shared-ui-components';

export type PpAccountScene = {
  ActionsMenuSlot: React.ReactElement;
  BalanceSlot: React.ReactElement;
  TransactionsSlot: React.ReactElement;
};

export function AccountScene({
  ActionsMenuSlot,
  BalanceSlot,
  TransactionsSlot,
}: PpAccountScene): React.ReactElement {
  return (
    <AppMain>
      <SceneHeaderCard>
        {BalanceSlot}
        {ActionsMenuSlot}
      </SceneHeaderCard>
      <SceneMain dataQa="account">
        <Typography gutterBottom component="h1" variant="subHeadingS">
          Activity history
        </Typography>
        {TransactionsSlot}
      </SceneMain>
    </AppMain>
  );
}
