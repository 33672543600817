import { useCallback, useMemo } from 'react';
import {
  assignLocationAndWait,
  useOryRecoveryFlowError,
  useOryRecoverySendCodeFlowCallback,
  useOryRecoveryVerifyCodeFlowCallback,
} from '@noah-labs/fe-shared-data-access-auth';
import {
  ForgottenPasswordEnterCodeScene,
  ForgottenPasswordEnterEmailScene,
} from '@noah-labs/fe-shared-ui-auth';
import { createPathnameWithParams, useRouter } from '@noah-labs/fe-shared-ui-shared';
import type { ErrorOption } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { orySessionKey } from '../hooks/useAuth';

type PpForgottenPassword = {
  helpButton?: React.ReactNode;
  returnTo: string;
};

export function ForgottenPassword({
  helpButton,
  returnTo,
}: PpForgottenPassword): React.ReactElement {
  const queryClient = useQueryClient();
  const { replace, search } = useRouter();

  const onAfterRecovery = useCallback(
    async (flowId: string) => {
      await queryClient.invalidateQueries(orySessionKey);
      const params = new URLSearchParams({ flow: flowId }).toString();
      const path = createPathnameWithParams(returnTo, params);
      await assignLocationAndWait(path);
    },
    [queryClient, returnTo],
  );

  const onVerify = useOryRecoveryVerifyCodeFlowCallback(onAfterRecovery);
  const recoverySendCodeFlowCallback = useOryRecoverySendCodeFlowCallback(returnTo);

  const params = useMemo(() => new URLSearchParams(search), [search]);
  const flowId = params.get('flow');
  const email = params.get('email');

  const flowError = useOryRecoveryFlowError();

  const onRecover = useCallback(
    async (
      values: { email: string },
      setError: (name: `root.${string}`, error: ErrorOption) => void,
    ) => {
      const recoveryFlowId = await recoverySendCodeFlowCallback(values, setError);
      if (recoveryFlowId === undefined) {
        return;
      }

      replace({
        search: new URLSearchParams({ email: values.email, flow: recoveryFlowId }).toString(),
      });
    },
    [recoverySendCodeFlowCallback, replace],
  );

  if (flowId && email) {
    return (
      <ForgottenPasswordEnterCodeScene
        email={email}
        error={flowError}
        helpButton={helpButton}
        onResend={onRecover}
        onVerify={onVerify}
      />
    );
  }

  return (
    <ForgottenPasswordEnterEmailScene
      email={email}
      error={flowError}
      helpButton={helpButton}
      onRecover={onRecover}
    />
  );
}
