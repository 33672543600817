import { useCallback } from 'react';
import type { SettingsFlow } from '@ory/client';
import { useOry } from '../data';
import {
  getIsWebAuthnSupported,
  getOryCsrfToken,
  getOryWebAuthnOptions,
  getOryWebAuthnRegister,
  isOryUpdateFlowError,
} from '../utils';

export function useOryAddToken(): (
  flow: SettingsFlow,
  tokenDisplayName: string,
) => Promise<SettingsFlow> {
  const { ory } = useOry();

  return useCallback(
    async (flow, tokenDisplayName) => {
      if (!getIsWebAuthnSupported()) {
        throw Error('WebAuthn is not supported');
      }
      const csrfToken = getOryCsrfToken(flow.ui.nodes);

      const options = getOryWebAuthnOptions(flow.ui.nodes, 'creation');
      if (options === undefined) {
        throw Error('Wrong credential options format');
      }

      const webAuthnRegister = await getOryWebAuthnRegister(options);
      if (webAuthnRegister === undefined) {
        throw Error('No register provided');
      }
      const stringifiedWebAuthnRegister = JSON.stringify(webAuthnRegister);

      try {
        const { data: resultFlow } = await ory.updateSettingsFlow({
          flow: flow.id,
          updateSettingsFlowBody: {
            csrf_token: csrfToken,
            method: 'webauthn',

            webauthn_register: stringifiedWebAuthnRegister,
            webauthn_register_displayname: tokenDisplayName,
          },
        });
        return resultFlow;
      } catch (error) {
        // retry if failed with update flow
        if (isOryUpdateFlowError(error)) {
          const newFlowId = error.response.data.use_flow_id;
          const { data: newFlow } = await ory.getSettingsFlow({ id: newFlowId });

          const newCsrfToken = getOryCsrfToken(newFlow.ui.nodes);

          const { data } = await ory.updateSettingsFlow({
            flow: newFlow.id,
            updateSettingsFlowBody: {
              csrf_token: newCsrfToken,
              method: 'webauthn',

              webauthn_register: stringifiedWebAuthnRegister,
              webauthn_register_displayname: tokenDisplayName,
            },
          });
          return data;
        }
        throw error;
      }
    },
    [ory],
  );
}
