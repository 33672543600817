import { useCallback } from 'react';
import { logger } from '@noah-labs/shared-logger/browser';
import { getConfig } from '../config';
import type { TpAnalyticsEvent, TpEventProperties } from '../utils/events';
import { useAnalyticsContext } from './useAnalyticsContext';

type TpUseAnalytics = {
  track: <T extends TpAnalyticsEvent>(eventName: T, properties?: TpEventProperties[T]) => void;
};

export function useAnalytics(): TpUseAnalytics {
  const ajs = useAnalyticsContext();
  const config = getConfig();

  const track = useCallback(
    <T extends TpAnalyticsEvent>(eventName: T, properties?: TpEventProperties[T]): void => {
      if (!config.enabled || !config.track) {
        logger.debug('track not called: analytics disabled');
        return;
      }
      // we have to 'override' the location data because if the track also caused a page navigation, the Segment SDK reports the new (wrong) location data for the click.
      void ajs.track(eventName, properties ?? {}, {
        context: {
          page: {
            path: window.location.pathname,
            search: window.location.search,
            url: window.location.href,
          },
        },
      });
    },
    [ajs, config.enabled, config.track],
  );

  return {
    track,
  };
}
