import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ButtonGroup, LowDialog, PrimaryButton } from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { useToggleDialog } from '@noah-labs/fe-shared-ui-shared';

type PpSkipPinDialog = {
  exitTo?: string;
  initialOpen?: boolean;
};

export const SkipPinDialog = forwardRef<TpDialogToggle, PpSkipPinDialog>(
  ({ exitTo, initialOpen }, ref): JSX.Element => {
    const { open, toggle } = useToggleDialog({ initialOpen, ref });

    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Using a security PIN will become mandatory for sending or withdrawing funds. We will
            keep reminding you, so we recommend creating one now.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <PrimaryButton color="primary" data-qa="verify-account-btn" href={exitTo}>
              I’m sure
            </PrimaryButton>
            <PrimaryButton variant="text" onClick={toggle}>
              Back
            </PrimaryButton>
          </ButtonGroup>
        </DialogActions>
      </LowDialog>
    );
  },
);

SkipPinDialog.displayName = SkipPinDialog.name;
