import { Fragment } from 'react';
import { FormItem, InputField } from '@noah-labs/fe-shared-ui-components';

export const SortCodeFormFields = (
  <Fragment>
    <FormItem>
      <InputField
        fullWidth
        required
        dataQa="account-number"
        label="Account number"
        name="accountNumber"
      />
    </FormItem>
    <FormItem>
      <InputField fullWidth required dataQa="bankCode" label="Sort code" name="bankCode" />
    </FormItem>
  </Fragment>
);

export const UsBankFormFields = (
  <Fragment>
    <FormItem>
      <InputField
        fullWidth
        required
        dataQa="account-number"
        label="Account number"
        name="accountNumber"
      />
    </FormItem>
    <FormItem>
      <InputField
        fullWidth
        required
        dataQa="bankCode"
        label="Wire routing number"
        name="bankCode"
      />
    </FormItem>
  </Fragment>
);

export const DefaultBankFormFields = (
  <Fragment>
    <FormItem>
      <InputField fullWidth required dataQa="iban" label="IBAN" name="accountNumber" />
    </FormItem>
    <FormItem>
      <InputField fullWidth required dataQa="swift-code" label="BIC / SWIFT" name="bankCode" />
    </FormItem>
  </Fragment>
);
