import type { ReactElement } from 'react';
import {
  ListItemContent,
  ListItemEndContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  MuiSvgIcon,
} from '@noah-labs/fe-shared-ui-components';
import { getPaymentMethod } from '@noah-labs/fe-shared-ui-shared';
import type { TpPaymentBank, TpPaymentCard, TpPaymentMethod } from '@noah-labs/fe-shared-ui-shared';

type PpPaymentMethod = {
  ToggleSlot?: false | ReactElement;
  disablePadding?: boolean;
  hideAddress?: boolean;
  method: TpPaymentMethod | undefined;
  paymentBank?: TpPaymentBank;
  paymentCard?: TpPaymentCard;
};

export const paymentMethodIconStyles = {
  color: 'text.primary',
  // TODO (cs): use 40px for better visuals until we get updated icons
  width: '40px',
};

export function PaymentMethod({
  disablePadding = false,
  hideAddress,
  method,
  paymentBank,
  paymentCard,
  ToggleSlot,
}: PpPaymentMethod): ReactElement {
  const { address, dataQa, primary, primaryTypographyProps, secondary, svg } = getPaymentMethod(
    method,
    paymentCard,
    paymentBank,
    hideAddress,
  );

  return (
    <ListItemContent data-qa={dataQa} padding={disablePadding ? 0 : undefined}>
      {svg && (
        <ListItemIcon>
          <MuiSvgIcon svg={svg} sx={paymentMethodIconStyles} />
        </ListItemIcon>
      )}
      <ListItemStartContent overflow="hidden">
        <ListItemPrimaryText noWrap color={primaryTypographyProps?.color}>
          {primary}
        </ListItemPrimaryText>
        <ListItemSecondaryText>{secondary}</ListItemSecondaryText>
        {address && (
          <ListItemSecondaryText noWrap mt={0.25}>
            {address}
          </ListItemSecondaryText>
        )}
      </ListItemStartContent>
      {ToggleSlot && <ListItemEndContent>{ToggleSlot}</ListItemEndContent>}
    </ListItemContent>
  );
}
