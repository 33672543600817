import type { PpQRScanner } from '@noah-labs/fe-shared-ui-components';
import { AppMain, QRScanner, SceneMain } from '@noah-labs/fe-shared-ui-components';

export function AddressScanScene(props: PpQRScanner): React.ReactElement {
  return (
    <AppMain paddingBottom={0}>
      <SceneMain dataQa="scan" style={{ padding: 0 }}>
        <QRScanner {...props} />
      </SceneMain>
    </AppMain>
  );
}
