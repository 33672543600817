import { Fragment } from 'react';
import { css } from '@emotion/react';
import { Divider } from '@mui/material';
import type {
  FrTransactionItemFragment,
  TransactionsQuery,
} from '@noah-labs/fe-shared-data-access-wallet';
import {
  coreThemeConstants,
  CryptoAmount,
  EmptyStateContent,
  EmptyStateRoot,
  EmptyStateSubtitle,
  EmptyStateTitle,
  FiatAmount,
  List,
  ListItemCard,
  NoahHologram,
  SkeletonCards,
} from '@noah-labs/fe-shared-ui-components';
import { getTransactionUi, useThrottling } from '@noah-labs/fe-shared-ui-shared';
import type { CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { CurrencyDisplayType } from '@noah-labs/shared-schema-gql';
import { formatDate, isArrayOfLength } from '@noah-labs/shared-util-vanilla';
import groupByToMap from 'array.prototype.groupbytomap';
import InfiniteScroll from 'react-infinite-scroller';
import type { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import { TransactionDate } from '../atoms/TransactionDate';
import { TransactionListItem } from './TransactionListItem';

export type PpTransactionList = {
  cryptoUnit: CurrencyUnit | undefined;
  hasNextPage?: boolean;
  isFetched?: boolean;
  isFetchingNextPage?: boolean;
  onNextPage?: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<TransactionsQuery, unknown>>;
  primaryCurrency: CurrencyDisplayType | null | undefined;
  transactions: Array<FrTransactionItemFragment | null | undefined> | undefined;
  userID: string | undefined;
};

const transactionsDefaultProp: FrTransactionItemFragment[] = []; // declared separately to prevent no-object-type-as-default warning

export function TransactionList({
  cryptoUnit,
  hasNextPage = false,
  isFetched = false,
  isFetchingNextPage = false,
  onNextPage,
  primaryCurrency,
  transactions = transactionsDefaultProp,
}: PpTransactionList): React.ReactElement {
  function loadMore(): void {
    if (isFetchingNextPage || !onNextPage) {
      return;
    }
    void onNextPage();
  }

  const throttledFetchNextPage = useThrottling(loadMore, 1000);

  const styles = {
    emptyTitle: css`
      /* TODO: Font variant missing */
      font-weight: 900;
      font-family: ${coreThemeConstants.fontFamily.expanded};
      font-size: 1.125rem;
      text-transform: uppercase;
    `,
    image: css`
      max-width: 200px;
    `,
  };

  if (!isFetched) {
    return (
      <Fragment>
        <TransactionDate />
        <List disablePadding spacing={1}>
          <SkeletonCards />
        </List>
      </Fragment>
    );
  }

  const hasNoTransactions = isArrayOfLength(transactions, 0);

  if (hasNoTransactions) {
    return (
      <EmptyStateRoot>
        <img alt="noah-hologram" css={styles.image} src={NoahHologram} />
        <EmptyStateContent>
          <EmptyStateTitle color="primary.main" css={styles.emptyTitle} data-qa="no-transactions">
            No transactions
          </EmptyStateTitle>
          <EmptyStateSubtitle color="text.secondary">
            Nothing here yet! Add some funds to get started.
          </EmptyStateSubtitle>
        </EmptyStateContent>
      </EmptyStateRoot>
    );
  }

  const groupedTransactionMap = groupByToMap<
    string | null,
    FrTransactionItemFragment | null | undefined
  >(transactions, (tx) => (tx ? formatDate(tx.Created) : null));
  const groupedTransactions = Array.from(groupedTransactionMap);

  return (
    <InfiniteScroll
      hasMore={hasNextPage}
      loader={<SkeletonCards />}
      loadMore={throttledFetchNextPage}
      pageStart={0}
      threshold={450}
    >
      {groupedTransactions.map(
        ([date, txns]) =>
          date && (
            <dl key={date}>
              <TransactionDate>{date}</TransactionDate>
              <ListItemCard component="dd" sx={{ mb: 3 }}>
                <List
                  useFlexGap
                  divider={<Divider aria-hidden component="li" variant="middle" />}
                  spacing={0.25}
                  sx={{ py: 0.25 }}
                >
                  {txns.map((tx) => {
                    if (!tx) {
                      return null;
                    }
                    const transactionUi = getTransactionUi({ tx });

                    let PrimaryAmountSlot = (
                      <FiatAmount
                        roundDown
                        amount={transactionUi.fiatAmount}
                        currency={transactionUi.fiatCurrency}
                        signDisplay="always"
                        visible={Boolean(transactionUi.fiatAmount)}
                      />
                    );
                    let SecondaryAmountSlot = (
                      <CryptoAmount
                        roundDown
                        amount={transactionUi.cryptoAmount}
                        currency={transactionUi.cryptoCurrency}
                        currencyUnit={cryptoUnit}
                        signDisplay="always"
                      />
                    );

                    if (primaryCurrency === CurrencyDisplayType.Crypto) {
                      PrimaryAmountSlot = (
                        <CryptoAmount
                          roundDown
                          amount={transactionUi.cryptoAmount}
                          currency={transactionUi.cryptoCurrency}
                          currencyUnit={cryptoUnit}
                          signDisplay="always"
                        />
                      );

                      SecondaryAmountSlot = (
                        <FiatAmount
                          roundDown
                          amount={transactionUi.fiatAmount}
                          currency={transactionUi.fiatCurrency}
                          signDisplay="always"
                          visible={Boolean(transactionUi.fiatAmount)}
                        />
                      );
                    }
                    return (
                      <TransactionListItem
                        key={transactionUi.id}
                        data-qa="tx-list-item"
                        PrimaryAmountSlot={PrimaryAmountSlot}
                        SecondaryAmountSlot={SecondaryAmountSlot}
                        {...transactionUi}
                      />
                    );
                  })}
                </List>
              </ListItemCard>
            </dl>
          ),
      )}
    </InfiniteScroll>
  );
}
