import React, { useMemo, useRef } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Divider, Typography } from '@mui/material';
import type { TpSelectOption } from '@noah-labs/fe-shared-ui-components';
import {
  AppContainer,
  AppFooter,
  AppHeaderTitle,
  AppMain,
  FormGrid,
  FormItem,
  InlineLinkOrButton,
  InputField,
  PrimaryButton,
  SceneMain,
} from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle, TpPaymentCard } from '@noah-labs/fe-shared-ui-shared';
import { billingAddressSchema } from '@noah-labs/fe-shared-util-validation-schemas';
import { Helmet } from 'react-helmet';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import { BillingAddressesForm } from '../../wallet/components/forms/BillingAddresses/BillingAddressesForm';
import type { TpBillingAddressForm } from '../../wallet/components/forms/BillingAddresses/schema';
import { emptyBillingAddress } from '../../wallet/components/forms/BillingAddresses/schema';
import { ConfirmRemoveDialog } from '../components/dialogs/ConfirmRemoveDialog';

export type TpEditCardForm = {
  billingAddress: TpBillingAddressForm;
};

const defaults: TpEditCardForm = {
  billingAddress: emptyBillingAddress,
};

const editBillingAddressId = 'editBillingAddress';
const schema = z.object({
  billingAddress: billingAddressSchema,
});
export type PpEditPaymentMethodScene = {
  cardDetails: TpPaymentCard | undefined;
  countries: TpSelectOption[] | undefined;
  defaultAddress: TpBillingAddressForm | undefined | null;
  fullName: string | undefined;
  onRemoveCard: () => void;
  onSubmit: SubmitHandler<TpEditCardForm>;
  pageTitle: string;
};
export function EditPaymentMethodScene({
  cardDetails,
  countries,
  defaultAddress,
  fullName = '',
  onRemoveCard,
  onSubmit,
  pageTitle,
}: PpEditPaymentMethodScene): React.ReactElement {
  const billingAddress = defaultAddress || defaults.billingAddress;
  const defaultValues = useMemo(() => ({ ...defaults, billingAddress }), [billingAddress]);
  const methods = useForm<TpEditCardForm>({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(schema),
    values: defaultValues,
  });

  const showConfirmRemoveDialog = useRef<TpDialogToggle>(null);

  return (
    <AppContainer>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AppMain>
        <AppHeaderData backButton exitButton helpButton>
          <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
        </AppHeaderData>
        <SceneMain dense dataQa="add-payment-card" maxWidth="xs">
          <FormProvider {...methods}>
            <form id={editBillingAddressId} onSubmit={methods.handleSubmit(onSubmit)}>
              <FormGrid>
                <FormItem>
                  <InputField
                    fullWidth
                    required
                    dataQa="name"
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Cardholder Name"
                    name="cardholderName"
                    value={fullName}
                  />
                </FormItem>
                <FormItem>
                  <InputField
                    fullWidth
                    required
                    dataQa="cardNumber"
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Card Number"
                    name="cardNumber"
                    value={`**** **** **** ${cardDetails?.last4 ?? '****'}`}
                  />
                </FormItem>
                <FormItem xs={6}>
                  <InputField
                    fullWidth
                    required
                    dataQa="expiryDate"
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Expiry Date"
                    name="expiryDate"
                    value="****"
                  />
                </FormItem>
                <FormItem xs={6}>
                  <InputField
                    fullWidth
                    required
                    dataQa="cvv"
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Cvv"
                    name="cvv"
                    value="***"
                  />
                </FormItem>
                <FormItem>
                  <InlineLinkOrButton
                    variant="paragraphBodySBold"
                    onClick={(): void => showConfirmRemoveDialog.current?.toggle()}
                  >
                    Remove payment method
                  </InlineLinkOrButton>
                </FormItem>
                <FormItem>
                  <Divider />
                </FormItem>
                <FormItem>
                  <Typography variant="paragraphBodyLBold">Billing address</Typography>
                </FormItem>
                <FormItem>
                  <BillingAddressesForm countries={countries} defaultAddress={defaultAddress} />
                </FormItem>
              </FormGrid>
            </form>
          </FormProvider>
          <ConfirmRemoveDialog
            ref={showConfirmRemoveDialog}
            cardDetails={cardDetails}
            onRemoveCard={onRemoveCard}
          />
        </SceneMain>
      </AppMain>
      <AppFooter>
        <PrimaryButton
          color="primaryBrand"
          disabled={!methods.formState.isDirty}
          form={editBillingAddressId}
          loading={methods.formState.isSubmitting}
          type="submit"
        >
          Save
        </PrimaryButton>
      </AppFooter>
    </AppContainer>
  );
}
