import dayjs from 'dayjs';
import { getConfig } from '../config';

const quoteExpirySecThreshold = 5;

export function getFeeQuoteExpiresIn(feeFetchedAt: number): dayjs.Dayjs {
  const { networkFee } = getConfig();
  const quoteExpiresInSeconds = networkFee.quoteExpirySec - quoteExpirySecThreshold;

  return dayjs(feeFetchedAt).add(dayjs.duration({ seconds: quoteExpiresInSeconds }));
}
