const noahWebsite = 'https://noah.com';
const noahApp = 'https://app.noah.com';
const noahSandbox = 'https://sandbox.noah.com';

export const webLinks = {
  app: {
    path: noahApp,
  },
  blog: {
    bitcoinBlockhain: 'https://noah.com/blog/what-is-a-blockchain/',
    compareOnChainOffChain: 'https://noah.com/blog/on-chain-vs-off-chain-transactions/',
    enclave: 'https://noah.com/blog/noah-key-enclave-a-fortress-for-your-bitcoin-wallet-keys/',
    lightningAddress: 'https://noah.com/blog/what-is-a-lightning-address/',
    lightningNetwork: 'https://noah.com/blog/what-is-the-lightning-network/',
    supportedNetworks: 'https://noah.com/blog/networks-noah-supports-bitcoin-ethereum-lightning',
    usdcStablecoin:
      'https://noah.com/blog/usdc-stablecoin-a-financial-innovation-with-promising-stability/',
    usdtStablecoin:
      'https://noah.com/blog/usdt-an-examination-of-tethers-role-controversies-and-future',
  },
  docs: {
    path: 'https://docs.noah.com',
    signing: 'https://docs.noah.com/business-api/authentication/#signing',
  },
  faq: {
    availableRegions: 'https://faq.noah.com/en/articles/6618974-is-noah-available-in-my-region',
    base: 'https://faq.noah.com',
    contactUs: 'https://faq.noah.com/en/articles/7034610-contact-us',
    lightningAddress: 'https://faq.noah.com/en/articles/6618937-what-is-my-noah-lightning-address',
    offRampFiatCurrencySupport:
      'https://faq.noah.com/en/articles/8317634-what-fiat-currencies-are-supported-for-off-ramping-on-noah',
    onChainAddress:
      'https://faq.noah.com/en/articles/6619047-what-s-the-difference-between-lightning-network-and-on-chain-btc-transactions',
    supportedAddresses:
      'https://faq.noah.com/en/articles/6618938-what-addresses-are-supported-in-noah',
    transactionTimes:
      'https://faq.noah.com/en/articles/6619060-what-sort-of-fees-will-be-involved-for-depositing-withdrawal-and-transfers',
    usdcEthereumFee:
      'https://faq.noah.com/en/articles/8450721-usdc-transactions-on-ethereum-navigating-the-fee-landscape',
    usdStablecoinsSupportedAddresses:
      'https://faq.noah.com/en/articles/8718278-supported-usdt-and-usdc-addresses-on-noah',
    usdtEthereumFee:
      'https://faq.noah.com/en/articles/8450718-navigating-usdt-tether-transaction-fees-on-ethereum-a-comprehensive-guide',
  },
  git: 'https://github.com/noah-labs/noah-main',
  noah: {
    path: noahWebsite,
    title: 'Noah Website Homepage',
  },
  privacyPolicy: {
    path: `${noahWebsite}/privacy`,
    title: 'Noah Privacy Policy',
  },
  sandbox: {
    path: noahSandbox,
  },
  social: {
    discord: 'https://discord.gg/5dPaFv3TQU',
    telegram: 'https://t.me/NoahHQ',
    twitter: 'https://twitter.com/Noah_HQ',
  },
  termsAndConditions: {
    path: `${noahWebsite}/terms`,
    title: 'Noah Terms and Conditions',
  },
} as const;
