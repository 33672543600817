import { Fragment } from 'react';
import { DialogContentText } from '@mui/material';
import { BulletedList, LearnMoreButton } from '@noah-labs/fe-shared-ui-components';
import { webLinks } from '@noah-labs/shared-constants';

export function ReceiveETHDialogContent(): React.ReactElement {
  return (
    <Fragment>
      <DialogContentText paragraph>
        Ether (ETH) is the native cryptocurrency of the Ethereum blockchain, serving as both a
        medium of exchange and a computational fuel for the network.
      </DialogContentText>
      <DialogContentText>
        Key Points:
        <BulletedList>
          <li>
            Global Access: Decentralized and available worldwide, offering global reach for
            financial activities.
          </li>
          <li>
            Gas Fees: Transactions require `gas`, a fee paid in ETH, which varies based on the
            computational effort involved.
          </li>
          <li>
            Transactional Fuel: ETH is used to pay for transactions and computational tasks on the
            Ethereum platform.
          </li>
          <li>
            Staking: Ether holders can stake ETH to help secure the Ethereum network and earn
            rewards.
          </li>
        </BulletedList>
      </DialogContentText>
      <DialogContentText>
        Note: While Ether aims to offer a range of functionalities within the Ethereum ecosystem, it
        is still a cryptocurrency with associated risks.{' '}
        <LearnMoreButton href={webLinks.blog.compareOnChainOffChain} target="_blank" />
      </DialogContentText>
    </Fragment>
  );
}
