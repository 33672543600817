import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { SidebarNav, SidebarNavItem } from '@noah-labs/fe-shared-ui-components';
import { NavItems } from './NavItems';

export function SidebarNavData(): React.ReactElement {
  const { data: userData } = useUserInitUi();
  return (
    <SidebarNav title={userData?.userProfile.UsernameDisplay}>
      <NavItems Component={SidebarNavItem} />
    </SidebarNav>
  );
}
