import React, { Fragment, useCallback, useRef } from 'react';
import { Stack } from '@mui/material';
import { useCryptoPrice } from '@noah-labs/fe-shared-data-access-wallet';
import { useUserInitUi, useUserWithdrawalAllowance } from '@noah-labs/fe-shared-feature-user';
import { AvailableBalanceWithPaymentMethodData } from '@noah-labs/fe-shared-feature-wallet';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import {
  AppContainer,
  AppHeaderSubtitle,
  AppHeaderTitle,
  generatePath,
} from '@noah-labs/fe-shared-ui-components';
import {
  cryptoNetworkEnvFromId,
  TpPaymentMethod,
  useRouter,
  useWalletParams,
} from '@noah-labs/fe-shared-ui-shared';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { PaymentCurrencyDialog } from '@noah-labs/fe-shared-ui-user';
import {
  CryptoPrice,
  CurrencyDialogToggle,
  EnterAmountScene,
} from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import type { TpAmountForm } from '@noah-labs/fe-shared-util-validation-schemas';
import { getCkoSellAmountSchema } from '@noah-labs/fe-shared-util-validation-schemas';
import type { TpSlippage } from '@noah-labs/shared-currencies';
import { getManualRampCurrencyConfig, truncateAmount } from '@noah-labs/shared-currencies';
import type { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { CurrencyDisplayType, CurrencyUnit, Network } from '@noah-labs/shared-schema-gql';
import BigNumber from 'bignumber.js';
import { AppHeaderData } from '../../../../components/layout/AppHeaderData';
import { isProd, webConfigBrowser } from '../../../../webConfigBrowser';
import { useFiatCurrencyFromCode } from '../../../user/data/useFiatCurrencyFromCode';
import type { StSellRouter } from './SellRouter';

const {
  cko: { feePercentage, minimumFiatAmount, slippagePercentage },
  manualRamp,
} = webConfigBrowser;

const slippage: TpSlippage = {
  slippage: slippagePercentage,
  type: 'negative',
};

type PpEnterAmount = TpStateMachine<StSellRouter>;

export function EnterAmount({ state, updateState }: PpEnterAmount): React.ReactElement {
  const { AccountType, cryptoCurrency, params } = useWalletParams();
  const { push } = useRouter();
  const sellFiatCurrency = useFiatCurrencyFromCode(state.fiatCurrencyCode);
  const { data: userData } = useUserInitUi();
  const paymentCurrencyDialog = useRef<TpDialogToggle>(null);

  const { data: cryptoData } = useCryptoPrice({
    cryptoCurrency,
    fiatCurrency: sellFiatCurrency,
    priceProvider: 'sell',
    slippage,
  });

  const onPaymentMethodClick = useCallback(() => {
    push({
      pathname: generatePath(walletRoutes().sell.payoutMethod.path, params),
      state: {
        successTo: generatePath(walletRoutes().sell.enterAmount.path, params),
      },
    });
  }, [push, params]);

  const onSubmit = useCallback(
    ({ cryptoAmount, fiatAmount }: TpAmountForm) => {
      updateState({
        cryptoAmount: truncateAmount({
          amount: cryptoAmount,
          decimalPlaces: cryptoCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        }),
        fiatAmount: truncateAmount({
          amount: fiatAmount,
          decimalPlaces: sellFiatCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        }),
      });

      push(generatePath(walletRoutes().sell.confirm.path, params));
    },
    [push, params, cryptoCurrency, sellFiatCurrency.decimals, updateState],
  );

  const onCurrencyChange = useCallback(
    (currency: FiatCurrencyCode) => {
      updateState({
        fiatCurrencyCode: currency,
      });
    },
    [updateState],
  );

  const isBankPayoutMethod = state.payoutMethod === TpPaymentMethod.BankTransfer;
  const isCardPayoutMethod = state.payoutMethod === TpPaymentMethod.Card;

  const manualRampCurrencyConfig = getManualRampCurrencyConfig({
    config: manualRamp,
    fiatCurrencyCode: state.fiatCurrencyCode,
  });
  const currencyData = isBankPayoutMethod ? manualRampCurrencyConfig : undefined;

  const allowance = useUserWithdrawalAllowance({
    accountType: AccountType,
    cryptoCurrency,
    cryptoNetwork: cryptoNetworkEnvFromId(Network.Bitcoin, isProd),
  });
  const schema = getCkoSellAmountSchema({
    allowance: allowance?.accountAllowanceCrypto,
    feePercentage,
    fiatCurrency: sellFiatCurrency,
    minFiatCurrencyAmount: currencyData?.MinimumFiatAmount || minimumFiatAmount,
  });

  return (
    <AppContainer headTitle="Sell">
      <AppHeaderData exitButton helpButton backButton={false} backTo={walletRoutes().base.path}>
        <Stack>
          <AppHeaderTitle>Sell bitcoin</AppHeaderTitle>
          <AppHeaderSubtitle>
            <CryptoPrice
              cryptoCurrencyCode={cryptoCurrency.code}
              fiatCurrency={sellFiatCurrency}
              price={cryptoData?.price}
            />
          </AppHeaderSubtitle>
        </Stack>
      </AppHeaderData>
      <EnterAmountScene
        amountRequired
        disableSwitch
        amountPlaceholder={minimumFiatAmount}
        ContentSlot={
          <Fragment>
            <AvailableBalanceWithPaymentMethodData
              AccountType={AccountType}
              cryptoCurrency={cryptoCurrency}
              method={state.payoutMethod}
              paymentBank={state.selectedPayoutBank}
              paymentCard={state.selectedPayoutCard}
              onPaymentMethodClick={onPaymentMethodClick}
            />
            {userData && (
              <PaymentCurrencyDialog
                ref={paymentCurrencyDialog}
                fiatCurrency={userData.userProfile.fiatCurrency.code}
                onCurrencyChange={onCurrencyChange}
              />
            )}
          </Fragment>
        }
        cryptoAmount={state.cryptoAmount}
        cryptoCurrency={cryptoCurrency}
        cryptoUnit={CurrencyUnit.Default}
        ctaButtonDisabled={!schema}
        ctaButtonLabel="Preview sell"
        fiatAmount={state.fiatAmount}
        fiatCurrency={sellFiatCurrency}
        priceProvider="sell"
        primaryCurrency={CurrencyDisplayType.Fiat}
        slippage={slippage}
        SwitchCurrencySlot={
          isCardPayoutMethod ? (
            <CurrencyDialogToggle onClick={(): void => paymentCurrencyDialog.current?.toggle()} />
          ) : undefined
        }
        zodSchema={schema}
        onBlurValues={updateState}
        onSubmit={onSubmit}
      />
    </AppContainer>
  );
}
