import type { SVGProps } from 'react';

export function SvgCircleTick(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 25"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M12 2.107c-5.523 0-10 4.478-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10m4.334 8.31a.91.91 0 1 0-1.396-1.164l-3.909 4.69-2.023-2.024a.91.91 0 0 0-1.285 1.286l2.727 2.727a.91.91 0 0 0 1.341-.061z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
