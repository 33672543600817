import type { TpFeFeature, TpFeFeatureFlag } from '@noah-labs/fe-shared-data-access-user';
import type {
  Quest,
  QuestStatus,
  QuestTaskWithStatus,
  UserBadge,
} from '@noah-labs/shared-schema-gql';
import { compareStrings } from '@noah-labs/shared-util-vanilla';
import type { QuestsAndBadgesQuery } from './rewards.generated';

function isQuestComplete(quest: QuestStatus): boolean {
  return quest.Progress === quest.RequiredProgress;
}

export function isQuestTaskComplete(task: QuestTaskWithStatus): boolean {
  return task.Task.RequiredCount === task.TaskStatus?.Count;
}

export function getCompletedBadges(userBadges: UserBadge[]): UserBadge[] {
  return userBadges.filter((b) => b.Earned);
}

export function isQuestFeatureEnabled(
  quest: Quest,
  features: Map<TpFeFeature, TpFeFeatureFlag> | undefined,
): boolean {
  if (!quest.Features) {
    return true;
  }
  return quest.Features.every((f) => features?.get(f)?.Enabled);
}

/**
 * Perpetual Quests are those that are purposely assigned a huge amount of Tasks
 * in order to prevent user from completing them.
 * A perpetual Quest should have its progress hidden
 * and should not be displayed among the completed quests list
 */
export function isQuestPerpetual(questId: string): boolean {
  const perpetualQuests = ['FReferral20230927'];
  return perpetualQuests.includes(questId);
}

/**
 * Select active quests
 */
export function getActiveQuests(quests: QuestStatus[]): QuestStatus[] {
  return quests.filter((q) => !isQuestComplete(q));
}

/**
 * Select completed quests
 */
export function getCompletedQuests(quests: QuestStatus[]): QuestStatus[] {
  return quests.filter((q) => isQuestComplete(q) && !isQuestPerpetual(q.Quest.QuestID));
}

/**
 * Cumulative reward of all the tasks in a quest
 */
export function getQuestTotalReward(q: Quest): number {
  const totalReward = q.Tasks.reduce((acc, task) => acc + parseFloat(task.RewardNoah), 0);
  return totalReward;
}

export function getQuestByQuestId(quests: QuestStatus[], questId: string): Quest | undefined {
  return quests.find((qs) => compareStrings(qs.Quest.QuestID, questId))?.Quest;
}

/**
 * Find the first incomplete task in a quest.
 * If the quest is complete, return undefined.
 */
export function getActiveTask(q: QuestStatus): QuestTaskWithStatus | undefined {
  return q.Tasks?.find((task) => !isQuestTaskComplete(task));
}

export function getRequiredQuestCount(quest: Quest): number {
  return quest.RequiredQuestIDs.length;
}

export function selectQuestsAndBadges(
  data: QuestsAndBadgesQuery,
  features: Map<TpFeFeature, TpFeFeatureFlag> | undefined,
): { activeQuests: QuestStatus[]; badges: UserBadge[]; completedBadges: UserBadge[] } {
  const activeQuests = getActiveQuests(data.questsAndBadges.QuestStatuses).filter((q) =>
    isQuestFeatureEnabled(q.Quest, features),
  );

  const badges = data.questsAndBadges.UserBadges.filter((b) => {
    const quest = b.QuestID ? getQuestByQuestId(activeQuests, b.QuestID) : undefined;
    return !quest || isQuestFeatureEnabled(quest, features);
  });

  return {
    activeQuests,
    badges,
    completedBadges: getCompletedBadges(data.questsAndBadges.UserBadges),
  };
}
