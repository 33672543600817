import React from 'react';
import {
  AppContainer,
  AppHeaderTitle,
  AppMain,
  AvatarIconFromSvg,
  SceneMain,
  TextWithIcon,
} from '@noah-labs/fe-shared-ui-components';
import type {
  PreferredCryptoCurrencyForm,
  PreferredFiatCurrencyForm,
} from '@noah-labs/fe-shared-ui-user';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';

export type PpUserSettingOptionsScene = {
  ContentSlot?: React.ReactElement;
  CurrencyFormSlot:
    | ReturnType<typeof PreferredCryptoCurrencyForm>
    | ReturnType<typeof PreferredFiatCurrencyForm>;
  dataQa?: string;
  headerSvg?: TpReactSvg;
  pageTitle: string;
};

export function UserSettingOptionsScene({
  ContentSlot,
  CurrencyFormSlot,
  dataQa,
  headerSvg,
  pageTitle,
}: PpUserSettingOptionsScene): React.ReactElement {
  return (
    <AppContainer>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AppMain>
        <AppHeaderData backButton helpButton>
          {headerSvg ? (
            <TextWithIcon>
              <AvatarIconFromSvg size={2.5} svg={headerSvg} />
              <span>{pageTitle}</span>
            </TextWithIcon>
          ) : (
            <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
          )}
        </AppHeaderData>
        <SceneMain dense dataQa={dataQa}>
          {CurrencyFormSlot}
          {ContentSlot}
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
