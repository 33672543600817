import type { AvatarProps } from '@mui/material';
import {
  AvatarIconFromSvg,
  AvatarWithBadge,
  DefaultAvatar,
  TransactionStatusBadge,
} from '@noah-labs/fe-shared-ui-components';
import type { TpTransactionType } from '@noah-labs/fe-shared-ui-shared';
import type { TransactionStatus } from '@noah-labs/shared-schema-gql';

type PpAvatarWithTransactionStatus = {
  avatarSize?: number;
  badgeSize?: number;
  svg?: TpReactSvg | null;
  transactionStatus?: TransactionStatus;
  transactionType?: TpTransactionType;
} & AvatarProps;

export function AvatarWithTransactionStatus({
  avatarSize = 8,
  badgeSize = 3.5,
  svg,
  transactionStatus,
  transactionType,
  ...rest
}: PpAvatarWithTransactionStatus): React.ReactElement {
  return (
    <AvatarWithBadge
      {...rest}
      AvatarSlot={
        svg ? (
          <AvatarIconFromSvg size={avatarSize} svg={svg} />
        ) : (
          <DefaultAvatar size={avatarSize} />
        )
      }
      BadgeSlot={
        transactionStatus &&
        transactionType && (
          <TransactionStatusBadge
            size={badgeSize}
            status={transactionStatus}
            type={transactionType}
          />
        )
      }
      size={avatarSize}
    />
  );
}
