import React, { Fragment, useCallback, useReducer, useRef } from 'react';
import { Stack } from '@mui/material';
import { DownloadIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/DownloadIcon';
import {
  AppFooter,
  AppMain,
  ButtonGroup,
  ListItemCard,
  MnemonicInput,
  OnlyIconButton,
  PrimaryButton,
  SceneHeader,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
  useClickToCopy,
  usePushAlert,
} from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import { mnemonicLength } from '@noah-labs/shared-cryptography';
import type { SafeWrapper } from '@noah-labs/shared-cryptography';
import { logger } from '@noah-labs/shared-logger/browser';
import { RecoveryPhraseDownloadFailed } from '../components/alerts/Alerts';
import { SecretPhraseSavingDialog } from '../components/dialogs/SecretPhraseSavingDialog';

export type PpSecretPhraseScene = {
  onCopyTrack: () => void;
  onSaveTrack: () => void;
  phrase: SafeWrapper<string> | undefined;
  successTo: string;
};

export function SecretPhraseScene({
  onCopyTrack,
  onSaveTrack,
  phrase,
  successTo,
}: PpSecretPhraseScene): React.ReactElement {
  const { replace } = useRouter();
  const pushAlert = usePushAlert();
  const secretPhraseSavingDialog = useRef<TpDialogToggle>(null);
  const [visible, toggle] = useReducer((s) => !s, false);

  const { copied, copyHandler } = useClickToCopy({
    onCopy: onCopyTrack,
    text: phrase?.value.secret,
  });

  const onSaveClick = useCallback(() => {
    try {
      onSaveTrack();

      if (!phrase?.value.secret) {
        return;
      }

      const blob = new Blob([phrase.value.secret], { type: 'text/plain' });

      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');

      link.href = url;
      link.download = 'NOAH Recovery Phrase.txt';

      link.click();
    } catch (error: unknown) {
      logger.error('Failed to download recovery phrase', (error as Error).name);
      pushAlert(RecoveryPhraseDownloadFailed);
    }
  }, [phrase, pushAlert, onSaveTrack]);

  return (
    <Fragment>
      <AppMain>
        <SceneHeader>
          <SceneTitleLarge>PIN Recovery</SceneTitleLarge>
          <SceneParagraph>
            In case you forget your PIN, you can use your recovery phrase to reset it and regain
            access to your account.
          </SceneParagraph>
          <SceneParagraph>
            This phrase is generated on your device and we don’t have access to it, so please save
            these words in a secure location and never share them with anyone.
          </SceneParagraph>
        </SceneHeader>
        <SceneMain>
          <ListItemCard sx={{ padding: 2 }}>
            <Stack width="100%">
              <MnemonicInput
                disabled
                fullWidth
                dataQa="phrase"
                inputProps={{
                  style: visible
                    ? {}
                    : {
                        filter: 'blur(3px)',
                      },
                }}
                name="phrase"
                value={phrase?.value.secret}
                words={mnemonicLength}
              />
              <ButtonGroup direction="row" mt={2}>
                <PrimaryButton color="secondary" size="small" onClick={toggle}>
                  {visible ? 'Hide phrase' : 'Show phrase'}
                </PrimaryButton>
                <PrimaryButton color="primary" size="small" onClick={copyHandler}>
                  {copied ? 'Copied' : 'Copy'}
                </PrimaryButton>
                <OnlyIconButton color="primaryDark" onClick={onSaveClick}>
                  <DownloadIcon />
                </OnlyIconButton>
              </ButtonGroup>
            </Stack>
          </ListItemCard>
        </SceneMain>
      </AppMain>
      <AppFooter>
        <PrimaryButton
          color="primaryBrand"
          data-qa="continue-btn"
          onClick={(): void => secretPhraseSavingDialog.current?.toggle()}
        >
          Continue
        </PrimaryButton>
      </AppFooter>

      <SecretPhraseSavingDialog
        ref={secretPhraseSavingDialog}
        onCancelClick={(): void => secretPhraseSavingDialog.current?.toggle()}
        onContinueClick={(): void => replace(successTo)}
      />
    </Fragment>
  );
}
