import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import { DefaultListItemText, LowDialog, PrimaryButton } from '@noah-labs/fe-shared-ui-components';
import { type TpDialogToggle, useToggleDialog } from '@noah-labs/fe-shared-ui-shared';

export const SlippageDialog = forwardRef<TpDialogToggle>((_, ref): JSX.Element => {
  const { open, toggle } = useToggleDialog({ ref });

  return (
    <LowDialog open={open} onClose={toggle}>
      <DialogTitle>Understanding slippage: How it affects your bitcoin sale</DialogTitle>
      <DialogContent>
        <DialogContentText paragraph>
          The amount you receive in your local currency depends on market conditions and bitcoin’s
          current price. Here’s how we handle it:
        </DialogContentText>
        <Stack marginBottom={2} spacing={2}>
          <DefaultListItemText
            noWrap={false}
            primary="Decrease in bitcoin price:"
            primaryTypographyProps={{
              variant: 'paragraphBodyMBold',
            }}
            secondary="If the value of bitcoin has fallen by 5% or more since you initiated the sale, we’ll temporarily pause the order and get in touch to ask if you want to proceed. If you decide not to proceed, we’ll refund the entire bitcoin amount."
            secondaryTypographyProps={{
              variant: 'paragraphBodyM',
            }}
          />
          <DefaultListItemText
            noWrap={false}
            primary="Increase in bitcoin price:"
            primaryTypographyProps={{
              variant: 'paragraphBodyMBold',
            }}
            secondary="On the other hand, if the price of bitcoin has gone up since you started the sale, don’t worry. We’ll make sure you get the benefit. We’ll refund you the difference so you get your extra payout."
            secondaryTypographyProps={{
              variant: 'paragraphBodyM',
            }}
          />
        </Stack>
        <DialogContentText>
          Our goal is to ensure you get the best value for your bitcoin while keeping you informed
          every step of the way.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton color="primaryBrand" onClick={toggle}>
          Got it
        </PrimaryButton>
      </DialogActions>
    </LowDialog>
  );
});

SlippageDialog.displayName = SlippageDialog.name;
