import { useEffect, useMemo, useState } from 'react';
import { logger } from '@noah-labs/shared-logger/browser';
import type { SettingsFlow } from '@ory/client';
import { useOry } from '../data';
import type { SocialProvider } from '../types';
import {
  getAccountLinkedProviders,
  getAccountUnLinkedProviders,
} from '../utils/getAccountLinkedProviders';

export function useOryLinkedProviders(): {
  availableProviders: SocialProvider[] | undefined;
  linkedProviders: SocialProvider[] | undefined;
  loading: boolean;
} {
  const { ory } = useOry();

  const [flow, setFlow] = useState<SettingsFlow | undefined>();
  useEffect(() => {
    (async (): Promise<void> => {
      const { data } = await ory.createBrowserSettingsFlow();
      setFlow(data);
    })().catch((error) => {
      logger.error(error);
    });
  }, [ory]);

  const linkedProviders = useMemo<SocialProvider[] | undefined>(() => {
    if (!flow) {
      return undefined;
    }
    return getAccountLinkedProviders(flow.ui.nodes);
  }, [flow]);

  const availableProviders = useMemo<SocialProvider[] | undefined>(() => {
    if (!flow) {
      return undefined;
    }
    return [
      ...getAccountLinkedProviders(flow.ui.nodes),
      ...getAccountUnLinkedProviders(flow.ui.nodes),
    ];
  }, [flow]);

  return {
    availableProviders,
    linkedProviders,
    loading: flow === undefined,
  };
}
