import React, { useCallback, useRef } from 'react';
import { css } from '@emotion/react';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ChevronRightIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronRightIcon';
import { LockIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/LockIcon';
import {
  AppContainer,
  AppFooter,
  AppHeaderTitle,
  AppMain,
  Balance,
  FooterContentText,
  LearnMoreButton,
  ListItemButton,
  ListItemCard,
  ListSection,
  PrimaryButton,
  SceneMain,
} from '@noah-labs/fe-shared-ui-components';
import { manualRampEta, TpPaymentMethod } from '@noah-labs/fe-shared-ui-shared';
import type { TpCryptoCurrencyUI, TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import {
  BankFeeDialog,
  CheckoutFeeDialog,
  CryptoVolatilityDialog,
  PaymentMethod,
  ProcessingTimeBankDialog,
  SlippageDialog,
  TransactionSummaryCard,
} from '@noah-labs/fe-shared-ui-wallet';
import { getManualRampCurrencyConfig } from '@noah-labs/shared-currencies';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies';
import { Helmet } from 'react-helmet';
import { VerifyAccountDialog } from '../../../components/dialogs/VerifyAccountDialog';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import { webConfigBrowser } from '../../../webConfigBrowser';
import type { StSellRouter } from '../controllers/sell/SellRouter';

type TpSellRouterValues = Pick<
  StSellRouter,
  'reference' | 'selectedPayoutCard' | 'selectedPayoutBank' | 'payoutMethod'
>;

export type PpConfirmSellScene = {
  CryptoPriceSlot: React.ReactElement;
  FeeAmountSlot: React.ReactNode;
  NetFiatAmountSlot: React.ReactNode;
  TotalCryptoAmountSlot: React.ReactNode;
  cryptoCurrency: TpCryptoCurrencyUI;
  ctaDisabled?: boolean;
  fiatCurrency: TpFiatCurrency;
  isLoading: boolean;
  kycRequired: boolean;
  onConfirm: () => void;
  pageTitle: string;
  payoutMethodHref: string;
} & TpSellRouterValues;

export function ConfirmSellScene({
  cryptoCurrency,
  CryptoPriceSlot,
  ctaDisabled,
  FeeAmountSlot,
  fiatCurrency,
  isLoading,
  kycRequired,
  NetFiatAmountSlot,
  onConfirm,
  pageTitle,
  payoutMethod,
  payoutMethodHref,
  reference,
  selectedPayoutBank,
  selectedPayoutCard,
  TotalCryptoAmountSlot,
}: PpConfirmSellScene): React.ReactElement {
  const theme = useTheme();
  const styles = {
    chevronIcon: css`
      color: ${theme.palette.text.light};
    `,
  };

  const cryptoVolatilityDialog = useRef<TpDialogToggle>(null);
  const feeInfoDialog = useRef<TpDialogToggle>(null);
  const slippageDialog = useRef<TpDialogToggle>(null);
  const processingTimeDialog = useRef<TpDialogToggle>(null);
  const kycDialog = useRef<TpDialogToggle>(null);

  const isValid = !!selectedPayoutCard || !!selectedPayoutBank;

  const manualRampCurrencyConfig = getManualRampCurrencyConfig({
    config: webConfigBrowser.manualRamp,
    fiatCurrencyCode: fiatCurrency.code,
  });

  let processingTime;
  let footerText;
  let FeeDialog: React.ReactElement | null;
  let onLearnMoreClick: () => void;
  let onActionClick: (() => void) | undefined;

  switch (payoutMethod) {
    case TpPaymentMethod.BankTransfer:
      processingTime = `Up to ${manualRampEta}`;
      footerText = 'If prices change significantly, we’ll contact you before proceeding. ';
      onLearnMoreClick = (): void => slippageDialog.current?.toggle();
      onActionClick = (): void => slippageDialog.current?.toggle();
      FeeDialog = manualRampCurrencyConfig ? (
        <BankFeeDialog
          ref={feeInfoDialog}
          fiatCurrencyConfig={manualRampCurrencyConfig}
          fiatCurrencySymbol={fiatCurrency.symbol}
        />
      ) : null;
      break;

    case TpPaymentMethod.Card:
    default:
      footerText =
        'If the bitcoin value changes significantly, the order may be cancelled & refunded. ';
      onLearnMoreClick = (): void => cryptoVolatilityDialog.current?.toggle();
      FeeDialog = <CheckoutFeeDialog ref={feeInfoDialog} action="selling" />;
      break;
  }

  const handleConfirm = useCallback((): void => {
    if (kycRequired) {
      kycDialog.current?.toggle();
      return;
    }
    onConfirm();
  }, [kycRequired, onConfirm]);

  return (
    <AppContainer>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AppMain>
        <AppHeaderData backButton disableFade exitButton helpButton>
          <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
        </AppHeaderData>
        <SceneMain dataQa="confirm-sell" maxWidth="xs">
          <Balance PrimaryAmountSlot={NetFiatAmountSlot} />
          <Stack mt={6} spacing={3}>
            <TransactionSummaryCard
              ActionAmount={TotalCryptoAmountSlot}
              actionAmountLabel="Selling (max)"
              cryptoCurrency={cryptoCurrency}
              CryptoPriceSlot={CryptoPriceSlot}
              FeeAmountSlot={FeeAmountSlot}
              feeLabel="Fee"
              FinalAmount={NetFiatAmountSlot}
              finalAmountLabel="You receive"
              processingTime={processingTime}
              sellReference={reference}
              onActionClick={onActionClick}
              onFeeInfoClick={(): void => feeInfoDialog.current?.toggle()}
              onProcessingTimeClick={(): void => processingTimeDialog.current?.toggle()}
            />

            <ListSection title="Payout method" titleVariant="paragraphBodySBold">
              <ListItemButton href={payoutMethodHref}>
                <ListItemCard>
                  <PaymentMethod
                    method={payoutMethod}
                    paymentBank={selectedPayoutBank}
                    paymentCard={selectedPayoutCard}
                    ToggleSlot={<ChevronRightIcon css={styles.chevronIcon} />}
                  />
                </ListItemCard>
              </ListItemButton>
            </ListSection>
          </Stack>

          <CryptoVolatilityDialog ref={cryptoVolatilityDialog} />
          <SlippageDialog ref={slippageDialog} />
          <ProcessingTimeBankDialog ref={processingTimeDialog} />
          <VerifyAccountDialog ref={kycDialog} action="selling" />
          {FeeDialog}
        </SceneMain>
      </AppMain>
      <AppFooter solidFade>
        <Stack spacing={2}>
          <FooterContentText>
            We will do our best to match the quote for {TotalCryptoAmountSlot}. Market conditions
            may affect the exact amount. {footerText}
            <LearnMoreButton onClick={onLearnMoreClick} />
          </FooterContentText>

          <PrimaryButton
            color="primaryBrand"
            data-qa="confirm-button"
            disabled={!isValid || ctaDisabled}
            endIcon={<LockIcon />}
            loading={isLoading}
            onClick={handleConfirm}
          >
            Sell now
          </PrimaryButton>
        </Stack>
      </AppFooter>
    </AppContainer>
  );
}
