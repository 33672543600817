import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { Popover } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

type PpNotificationPopover = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  open: boolean;
} & PpWC;

export function NotificationPopover({
  anchorEl,
  children,
  onClose,
  open,
}: PpNotificationPopover): ReactElement {
  const theme = useTheme();

  const styles = {
    container: css`
      .MuiPopover-paper {
        overflow: hidden;

        ${theme.breakpoints.down('md')} {
          min-width: 100vw;
          max-width: 100vw;
          min-height: 100vh;
          max-height: 100vh;
          position: static;
          border-radius: 0;
        }
      }
    `,
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      css={styles.container}
      id="notification-center-popover"
      open={open}
      transformOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      transitionDuration={300}
      onClose={onClose}
    >
      {children}
    </Popover>
  );
}
