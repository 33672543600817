import { useUserEstimateBalanceQuery } from '@noah-labs/fe-shared-data-access-wallet';
import { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { duration } from '@noah-labs/shared-util-vanilla';
import { useUserInitUi } from './useUserInitUi';

type TpUseUserAvailableFiatBalance = {
  availableBalance: string | undefined;
  isFetched: boolean;
};

export function useUserAvailableFiatBalance(poll = true): TpUseUserAvailableFiatBalance {
  const { data: userData } = useUserInitUi();

  const { data, isFetched } = useUserEstimateBalanceQuery(
    {
      Input: {
        TargetAsset: userData?.userProfile.FiatCurrencyCode || FiatCurrencyCode.USD,
      },
    },
    {
      enabled: Boolean(userData),
      refetchInterval: poll ? duration.seconds(3) : false,
    },
  );

  return {
    availableBalance: data?.userEstimateBalance.Available,
    isFetched,
  };
}
