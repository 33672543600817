import { useCallback } from 'react';
import { useLnInvoiceCreateMutation } from '@noah-labs/fe-shared-data-access-wallet';
import { useUserInitUi, useUserWithdrawLimits } from '@noah-labs/fe-shared-feature-user';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import { useRouter, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { EnterAmountScene, useWalletError } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import type { TpAmountForm } from '@noah-labs/fe-shared-util-validation-schemas';
import { getLnReceiveSchema } from '@noah-labs/fe-shared-util-validation-schemas';
import { truncateAmount } from '@noah-labs/shared-currencies';
import BigNumber from 'bignumber.js';
import type { SmDeposit } from '../types';

export function LightningReceiveEnterAmountController({
  updateState,
}: Pick<TpStateMachine<SmDeposit>, 'updateState'>): React.ReactElement {
  const { AccountType, cryptoCurrency, params } = useWalletParams();
  const { push } = useRouter();
  const { error, mutateAsync } = useLnInvoiceCreateMutation();
  const { data: userData } = useUserInitUi();

  const limits = useUserWithdrawLimits({ cryptoCurrency });
  const schema = getLnReceiveSchema({
    fiatCurrency: userData?.userProfile.fiatCurrency,
    maxLnSingleSendFiat: limits?.lnSendSingleLimit.limit,
  });

  const onSubmit = useCallback(
    async ({ cryptoAmount, description, fetchedAt, fiatAmount, price }: TpAmountForm) => {
      if (!price || !fetchedAt || !userData) {
        return;
      }
      try {
        const truncateFiatAmount = truncateAmount({
          amount: fiatAmount,
          decimalPlaces: userData.userProfile.fiatCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        });

        const data = await mutateAsync({
          Input: {
            AccountType,
            Amount: truncateAmount({
              // user can leave amount blank for a zero amount invoice.
              // empty strings won't be parsed as '0' in JSON / Go, so need to set it explicitly here
              amount: cryptoAmount || '0',
              decimalPlaces: cryptoCurrency.decimals,
              roundingMode: BigNumber.ROUND_DOWN,
            }),
            CurrencyCode: cryptoCurrency.code,
            Description: description,
            RequestedAmount: {
              // user can leave amount blank for a zero amount invoice.
              // empty strings won't be parsed as '0' in JSON / Go, so need to set it explicitly here
              Amount: truncateFiatAmount || '0',
              FetchedAt: fetchedAt,
              FiatCurrency: userData.userProfile.fiatCurrency.code,
              Price: price,
            },
          },
        });
        const { PaymentRequest, PublicID } = data.lightningInvoiceCreate;
        updateState({
          fiatAmount: truncateFiatAmount,
          paymentRequest: PaymentRequest,
          publicID: PublicID,
        });
        push(generatePath(walletRoutes().receive.lightning.viewInvoice.path, params));
      } catch (e) {
        // error is handled in useWalletError
      }
    },
    [AccountType, cryptoCurrency, mutateAsync, params, push, updateState, userData],
  );

  const { ApiErrorScene } = useWalletError(error);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <EnterAmountScene
      cryptoAmount=""
      cryptoCurrency={cryptoCurrency}
      cryptoUnit={userData?.userProfile.DisplayUnit}
      description=""
      fiatAmount=""
      fiatCurrency={userData?.userProfile.fiatCurrency}
      priceProvider="market"
      primaryCurrency={userData?.userProfile.PrimaryCurrency}
      zodSchema={schema}
      onSubmit={onSubmit}
    />
  );
}
