import type { FiatPaymentMethodsQuery } from '@noah-labs/fe-shared-data-access-wallet';
import type { TpPaymentBank, TpPaymentCard } from '@noah-labs/fe-shared-ui-shared';
import { TpPaymentMethod } from '@noah-labs/fe-shared-ui-shared';

export function mapPaymentCards(data: FiatPaymentMethodsQuery): TpPaymentCard[] | undefined {
  const items = data.fiatPaymentMethods.Items?.map((item): TpPaymentCard | undefined => {
    switch (item.Details.__typename) {
      case 'FiatPaymentCard':
        return {
          billingAddress: item.Details.BillingAddress,
          estimateHours: item.Details.Payout.EstimateHours,
          id: item.DynamoID,
          issuer: item.Details.Issuer,
          last4: item.Details.Last4,
          payoutSupported: item.Details.Payout.EstimateHours !== null,
          scheme: item.Details.Scheme,
        };
      default:
        return undefined;
    }
  });

  return items?.filter(Boolean) as TpPaymentCard[];
}

export function mapPaymentMethods(
  data: FiatPaymentMethodsQuery,
): Array<TpPaymentCard | TpPaymentBank> | undefined {
  const items = data.fiatPaymentMethods.Items?.map(
    (item): TpPaymentCard | TpPaymentBank | undefined => {
      switch (item.Details.__typename) {
        case 'FiatPaymentCard':
          return {
            billingAddress: item.Details.BillingAddress,
            estimateHours: item.Details.Payout.EstimateHours,
            id: item.DynamoID,
            issuer: item.Details.Issuer,
            last4: item.Details.Last4,
            payoutMethod: TpPaymentMethod.Card,
            payoutSupported: item.Details.Payout.EstimateHours !== null,
            scheme: item.Details.Scheme,
          } satisfies TpPaymentCard;
        case 'FiatPaymentBank':
          return {
            accountCurrency: item.Details.AccountCurrency,
            accountHolderAddress: item.AccountHolderDetails?.Address,
            accountNumber: item.Details.AccountNumber,
            bankCode: item.Details.BankCode,
            estimateHours: item.Details.Payout.EstimateHours,
            id: item.DynamoID,
            payoutMethod: TpPaymentMethod.BankTransfer,
            payoutSupported: item.Details.Payout.EstimateHours !== null,
          } satisfies TpPaymentBank;
        default:
          return undefined;
      }
    },
  );

  return items?.filter(Boolean) as Array<TpPaymentCard | TpPaymentBank>;
}
