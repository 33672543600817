import { useEffect, useState } from 'react';
import { getFiatCurrencyFromCode } from '@noah-labs/fe-shared-data-access-currencies';
import { getDefaults } from '@noah-labs/fe-shared-feature-wallet';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies';

const { fiatCurrency: defaultFiatCurrency } = getDefaults();

export function useFiatCurrencyFromCode(code: string): TpFiatCurrency {
  const [fiatCurrency, setFiatCurrency] = useState<TpFiatCurrency>(defaultFiatCurrency);

  useEffect((): void => {
    async function getFiatCurrency(): Promise<void> {
      const fiatCurrencyData = await getFiatCurrencyFromCode(code, defaultFiatCurrency);
      setFiatCurrency(fiatCurrencyData);
    }
    void getFiatCurrency();
  }, [code]);

  return fiatCurrency;
}
