import type { TpPushAlert } from '@noah-labs/fe-shared-ui-components';
import { AlertError } from '@noah-labs/fe-shared-ui-components';

/**
 * Security pin
 */
export const PinCreated: TpPushAlert = {
  dismissable: true,
  key: 'pin-created',
  message: 'Your PIN has been set successfully',
  severity: 'success',
};
export const RecoveryPhraseDownloadFailed: TpPushAlert = AlertError(
  'recoveryPhraseDownloadFailed',
  'An error occurred while attempting to download your recovery phrase. Please copy it manually and make sure to store it in a highly secure and private location.',
);
export const TransactionPinThresholdUpdated: TpPushAlert = {
  dismissable: true,
  key: 'transactionPinThresholdUpdated',
  message: 'Your PIN threshold for transactions has been updated successfully!',
  severity: 'success',
};
export const TransactionPinThresholdUpdateFailed: TpPushAlert = AlertError(
  'transactionPinThresholdUpdateFailed',
  'An error occurred while attempting to update your PIN threshold for transactions. Please try again.',
);
