import { TpFeExtraFeature } from '@noah-labs/fe-shared-data-access-user';
import { useFeature, useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { KycApprovalStatus } from '@noah-labs/shared-schema-gql';

export function useKycNotApprovedAndCheckoutEnabled(): boolean {
  const { data: userData } = useUserInitUi();
  const { feature: fbuy } = useFeature(TpFeExtraFeature.BuyRoutes);
  const { feature: fsell } = useFeature(TpFeExtraFeature.SellRoutes);

  return (
    Boolean(fbuy?.Enabled || fsell?.Enabled) &&
    userData?.userProfile.KycVerification.ApprovalStatus !== KycApprovalStatus.Approved
  );
}
