import React, { useRef } from 'react';
import {
  InfoTooltip,
  ListItem,
  ListItemEndContent,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
} from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoCurrencyUI, TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { CurrencyCode } from '@noah-labs/shared-schema-gql';
import { BitcoinTransactionFeesDialog } from '../dialogs/BitcoinTransactionFeesDialog';
import { UsdStablecoinsFeeDialog } from '../dialogs/UsdStablecoinsFeeDialog';

export type PpNetworkFeeItem = {
  PrimaryAmountSlot: React.ReactNode;
  SecondaryAmountSlot?: React.ReactNode;
  cryptoCurrency: TpCryptoCurrencyUI;
};

export function NetworkFeeItem({
  cryptoCurrency,
  PrimaryAmountSlot,
  SecondaryAmountSlot,
}: PpNetworkFeeItem): React.ReactElement {
  const feeInfoDialog = useRef<TpDialogToggle>(null);

  let Dialog: React.ReactElement;

  switch (cryptoCurrency.code) {
    case CurrencyCode.USDC:
    case CurrencyCode.USDC_TEST:
    case CurrencyCode.USDT:
    case CurrencyCode.USDT_TEST:
      Dialog = <UsdStablecoinsFeeDialog ref={feeInfoDialog} currencyCode={cryptoCurrency.code} />;
      break;

    case CurrencyCode.BTC:
    case CurrencyCode.BTC_TEST:
    default:
      Dialog = <BitcoinTransactionFeesDialog ref={feeInfoDialog} />;
  }

  return (
    <ListItem data-qa="fee">
      <ListItemStartContent>
        <ListItemSecondaryText>
          <InfoTooltip onClick={(): void => feeInfoDialog.current?.toggle()}>
            Network Fee
          </InfoTooltip>
        </ListItemSecondaryText>
      </ListItemStartContent>
      <ListItemEndContent>
        <ListItemPrimaryText>{PrimaryAmountSlot}</ListItemPrimaryText>
        <ListItemSecondaryText>{SecondaryAmountSlot}</ListItemSecondaryText>
      </ListItemEndContent>
      {Dialog}
    </ListItem>
  );
}
