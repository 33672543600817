import { getEnvCurrencyCode } from '@noah-labs/shared-currencies';
import { AccountType, CurrencyCode } from '@noah-labs/shared-schema-gql';
import { getConfig } from './config';
import type { TpAccount } from './types';

function getDefaultAccountsObject(): Record<string, TpAccount> {
  const { isProd } = getConfig();
  return {
    /**
     * defaultAccount is an empty wallet account with default currency code and account type
     * It is used to 'mock' the UI when a user first signs up and does not yet have a real account
     */
    btc: {
      AccountType: AccountType.Current,
      Balance: {
        __typename: 'AccountBalanceCurrent',
        Available: '0',
        Lifetime: '0',
      },
      CurrencyCode: getEnvCurrencyCode(CurrencyCode.BTC, isProd),
      ID: 'DefaultAccountBTC',
      Version: 1,
      VersionBalance: 12,
    },
    eth: {
      AccountType: AccountType.Current,
      Balance: {
        __typename: 'AccountBalanceCurrent',
        Available: '0',
        Lifetime: '0',
      },
      CurrencyCode: getEnvCurrencyCode(CurrencyCode.ETH, isProd),
      ID: 'DefaultAccountETH',
      Version: 1,
      VersionBalance: 12,
    },
    usdc: {
      AccountType: AccountType.Current,
      Balance: {
        __typename: 'AccountBalanceCurrent',
        Available: '0',
        Lifetime: '0',
      },
      CurrencyCode: getEnvCurrencyCode(CurrencyCode.USDC, isProd),
      ID: 'DefaultAccountUSDC',
      Version: 1,
      VersionBalance: 12,
    },
    usdt: {
      AccountType: AccountType.Current,
      Balance: {
        __typename: 'AccountBalanceCurrent',
        Available: '0',
        Lifetime: '0',
      },
      CurrencyCode: getEnvCurrencyCode(CurrencyCode.USDT, isProd),
      ID: 'DefaultAccountUSDT',
      Version: 1,
      VersionBalance: 12,
    },
  };
}

export function getDefaultAccount(): TpAccount {
  return getDefaultAccountsObject().btc;
}

export function getDefaultAccounts(): TpAccount[] {
  const accs = getDefaultAccountsObject();
  return [accs.btc, accs.eth, accs.usdc, accs.usdt];
}
