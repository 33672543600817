import { useCallback } from 'react';
import { logger } from '@noah-labs/shared-logger/browser';
import type { RegistrationFlow } from '@ory/client';
import type { AxiosError } from 'axios';
import { HttpStatusCode, isAxiosError } from 'axios';
import { useOry } from '../data';
import type { AuthSetError, OryContinueWith, TpSignUpPayload } from '../types';
import { assignLocationAndWait, findOryUiNode } from '../utils';
import { getOryCsrfToken } from '../utils/getOryCsrfToken';
import { isContinueWithVerification } from '../utils/oryTypeGuards';
import { getOryUiError } from './utils';

type TpOnSignupCb = (
  values: {
    email: string;
    password: string;
  },
  setError: AuthSetError<{
    email: string;
    password: string;
  }>,
  payload: TpSignUpPayload,
) => Promise<void>;

export function useOrySignUpCallback({
  onContinueWithVerification,
}: {
  onContinueWithVerification: (flowId: string) => Promise<void>;
}): TpOnSignupCb {
  const { ory, returnTo } = useOry();

  return useCallback(
    async (values, setError, payload) => {
      try {
        const { data: flow } = await ory.createBrowserRegistrationFlow({
          afterVerificationReturnTo: returnTo,
          returnTo,
        });

        const csrfToken = getOryCsrfToken(flow.ui.nodes);

        const { data } = await ory.updateRegistrationFlow({
          flow: flow.id,
          updateRegistrationFlowBody: {
            csrf_token: csrfToken,
            method: 'password',
            password: values.password,
            traits: { email: values.email },
            transient_payload: {
              referral_code: payload.referralCode,
              session_key: payload.sessionKey,
            },
          },
        });

        const continueWithVerification =
          'continue_with' in data
            ? (data.continue_with as Array<OryContinueWith>).find(isContinueWithVerification)
            : undefined;
        if (continueWithVerification) {
          await onContinueWithVerification(continueWithVerification.flow.id);
          return;
        }
        await assignLocationAndWait(returnTo);
      } catch (error: unknown) {
        logger.error(error);

        if (!isAxiosError(error)) {
          setError('root.serverError', {
            message: 'Something went wrong.',
            type: 'custom',
          });
          return;
        }

        const oryError = error as AxiosError<RegistrationFlow>;
        if (oryError.response?.status !== HttpStatusCode.BadRequest) {
          setError('root.serverError', {
            message: 'Something went wrong.',
            type: 'custom',
          });
          return;
        }

        const { ui } = oryError.response.data;
        const uiServerMessage = getOryUiError(ui.messages);
        if (uiServerMessage) {
          setError('root.serverError', uiServerMessage);
        }

        const emailNode = findOryUiNode(ui.nodes, 'traits.email');
        if (emailNode?.messages && emailNode.messages.length > 0) {
          setError('email', {
            message: emailNode.messages[0].text,
            type: 'value',
          });
        }

        const passwordNode = findOryUiNode(ui.nodes, 'password');
        if (passwordNode?.messages && passwordNode.messages.length > 0) {
          setError('password', {
            message: passwordNode.messages[0].text,
            type: 'value',
          });
        }
      }
    },
    [ory, returnTo, onContinueWithVerification],
  );
}
