import type { UiNode } from '@ory/client';
import type { InputUiNode, SocialProvider } from '../types';

function isUnlinkUiNode(node: UiNode): node is InputUiNode<SocialProvider> {
  return (
    node.group === 'oidc' &&
    node.attributes.node_type === 'input' &&
    node.attributes.name === 'unlink'
  );
}

function isLinkUiNode(node: UiNode): node is InputUiNode<SocialProvider> {
  return (
    node.group === 'oidc' &&
    node.attributes.node_type === 'input' &&
    node.attributes.name === 'link'
  );
}

export function getAccountLinkedProviders(nodes: UiNode[]): SocialProvider[] {
  const oidcUnlinkNodes = nodes.filter(isUnlinkUiNode);
  return oidcUnlinkNodes.map((n) => n.attributes.value as SocialProvider);
}

export function getAccountUnLinkedProviders(nodes: UiNode[]): SocialProvider[] {
  const oidcUnlinkNodes = nodes.filter(isLinkUiNode);
  return oidcUnlinkNodes.map((n) => n.attributes.value as SocialProvider);
}
