import type { FiatCurrencyCode, ManualRampCurrencySettings } from '@noah-labs/shared-schema-gql';
import { getConfig } from './config';

export function getAllowedBankCurrencies(): Record<string, boolean> {
  const { manualRamp } = getConfig();
  const allowedBankCurrencies = manualRamp.settings.reduce<
    Record<FiatCurrencyCode | string, boolean>
  >(
    (acc, currentObject) => {
      const typedCurrentObject = currentObject as ManualRampCurrencySettings;

      if (!acc[typedCurrentObject.FiatCurrency]) {
        acc[typedCurrentObject.FiatCurrency] = true;
      }
      return acc;
    },
    {} as Record<FiatCurrencyCode, boolean>,
  );
  return allowedBankCurrencies;
}
