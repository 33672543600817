import type { SVGProps } from 'react';

export function SvgFacebookSquare(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
    >
      <path
        clipRule="evenodd"
        d="M3 0C1.3 0 0 1.3 0 3v18c0 1.7 1.3 3 3 3h18c1.7 0 3-1.3 3-3V3c0-1.7-1.3-3-3-3zm12.5 10.4-.4 2.4h-2v5.7c-.9.1-1.8.1-2.6 0v-5.7H8.4v-2.4h2.1V8.6c0-2.1 1.3-3.2 3.2-3.2.9 0 1.9.2 1.9.2v2h-1.1c-1 0-1.4.6-1.4 1.3v1.5z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
