import { useEffect } from 'react';
import { useAddress } from '@noah-labs/fe-shared-feature-address';
import { KycAccessControlData } from '@noah-labs/fe-shared-feature-kyc';
import { useSardineFlow } from '@noah-labs/fe-shared-feature-sardine';
import type { SmWithdraw } from '@noah-labs/fe-shared-feature-wallet';
import {
  WithdrawAccountController,
  WithdrawCompleteController,
  WithdrawConfirmController,
  WithdrawEnterAmountController,
  WithdrawNetworkController,
} from '@noah-labs/fe-shared-feature-wallet';
import { generatePath, Switch404, useStateMachine } from '@noah-labs/fe-shared-ui-components';
import type { PpUseWallet } from '@noah-labs/fe-shared-ui-shared';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature, SardineFlows } from '@noah-labs/shared-schema-gql';
import { matchPath, Route } from 'react-router-dom';
import { AccessControlData } from '../../../auth/AccessControlData';
import { WalletControllerWrapper } from '../../components/layout/WalletControllerWrapper';

const emptyState: SmWithdraw = {
  cryptoAmount: '',
  fetchedAt: '',
  fiatAmount: '',
  payeeData: undefined,
  price: '',
  withdrawOrderId: '',
};

export function WithdrawRouter(): React.ReactElement {
  useSardineFlow({ flow: SardineFlows.CryptoWithdraw });
  const { data, setData } = useAddress();
  /**
   * Remove destination address from the address context on unmount
   */
  useEffect(() => () => setData(undefined), [setData]);

  let initialState;
  if (data) {
    initialState = {
      ...emptyState,
      cryptoAmount: data.amount || '',
      payeeData: data,
    };
  }

  const { base, send } = walletRoutes();

  const sm = useStateMachine<SmWithdraw>({
    emptyState,
    initialState,
    name: 'Withdraw',
  });
  const { pathname } = useRouter();
  const { params } =
    matchPath<PpUseWallet>(pathname, {
      path: send.withParams.path,
    }) || {};

  // if user hasn't scanned or enter an address, we have to go back to the scanner
  const addressInvalidRedirect = !sm.state.payeeData?.address;

  // if any data is missing, we can't show confirm or complete screens
  const confirmInvalid = !sm.state.cryptoAmount || !sm.state.payeeData?.address;
  const completeInvalid = !sm.state.cryptoAmount || !sm.state.payeeData?.address;

  return (
    <Switch404>
      <Route exact path={send.base.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <KycAccessControlData bypassIfUserHasBalance origin="withdraw">
            <WalletControllerWrapper
              backButton={false}
              backTo={generatePath(base.path, params)}
              headTitle={send.base.title}
            >
              <WithdrawAccountController />
            </WalletControllerWrapper>
          </KycAccessControlData>
        </AccessControlData>
      </Route>
      <Route exact path={send.network.path}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          needsFeature={[Feature.Withdraw]}
          redirectInvalid={
            addressInvalidRedirect && params && generatePath(send.address.base.path, params)
          }
        >
          <KycAccessControlData bypassIfUserHasBalance origin="withdraw">
            <WalletControllerWrapper headTitle={send.network.title}>
              <WithdrawNetworkController />
            </WalletControllerWrapper>
          </KycAccessControlData>
        </AccessControlData>
      </Route>
      <Route exact path={send.enterAmount.path}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          needsFeature={[Feature.Withdraw]}
          redirectInvalid={
            addressInvalidRedirect && params && generatePath(send.address.base.path, params)
          }
        >
          <KycAccessControlData bypassIfUserHasBalance origin="withdraw">
            <WalletControllerWrapper
              headerTitle={send.enterAmount.title}
              headTitle={send.enterAmount.title}
            >
              <WithdrawEnterAmountController {...sm} />
            </WalletControllerWrapper>
          </KycAccessControlData>
        </AccessControlData>
      </Route>
      <Route exact path={send.confirm.path}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          needsFeature={[Feature.Withdraw]}
          redirectInvalid={confirmInvalid && params && generatePath(send.enterAmount.path, params)}
        >
          <KycAccessControlData bypassIfUserHasBalance origin="withdraw">
            <WalletControllerWrapper
              headerTitle={send.confirm.title}
              headTitle={send.confirm.title}
            >
              <WithdrawConfirmController {...sm} />
            </WalletControllerWrapper>
          </KycAccessControlData>
        </AccessControlData>
      </Route>
      <Route exact path={send.complete.path}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          needsFeature={[Feature.Withdraw]}
          redirectInvalid={completeInvalid && params && generatePath(send.enterAmount.path, params)}
        >
          <WalletControllerWrapper backButton={false} headTitle={send.complete.title}>
            <WithdrawCompleteController {...sm} />
          </WalletControllerWrapper>
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
