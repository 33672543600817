import { useCallback } from 'react';
import type { SettingsFlow } from '@ory/client';
import { useOry } from '../data';
import { findOryWebAuthnRemoveNodeByTokenName } from '../utils';
import { getOryCsrfToken } from '../utils/getOryCsrfToken';
import { isOryUpdateFlowError } from '../utils/oryTypeGuards';

export function useOryRemoveToken(): (
  flow: SettingsFlow,
  displayName: string,
) => Promise<SettingsFlow> {
  const { ory } = useOry();

  return useCallback(
    async (flow, displayName) => {
      const webAuthnRemoveNode = findOryWebAuthnRemoveNodeByTokenName(flow.ui.nodes, displayName);
      if (webAuthnRemoveNode === undefined) {
        throw Error('No existing tokens');
      }
      if (!('value' in webAuthnRemoveNode.attributes)) {
        throw Error('No token id provided');
      }

      const csrfToken = getOryCsrfToken(flow.ui.nodes);
      const tokenId = webAuthnRemoveNode.attributes.value;

      try {
        const { data: resultFlow } = await ory.updateSettingsFlow({
          flow: flow.id,
          updateSettingsFlowBody: {
            csrf_token: csrfToken,
            method: 'webauthn',

            webauthn_remove: tokenId,
          },
        });

        return resultFlow;
      } catch (error) {
        // retry if failed with update flow
        if (isOryUpdateFlowError(error)) {
          const newFlowId = error.response.data.use_flow_id;
          const { data: newFlow } = await ory.getSettingsFlow({ id: newFlowId });

          const newCsrfToken = getOryCsrfToken(newFlow.ui.nodes);

          const { data: resultFlow } = await ory.updateSettingsFlow({
            flow: newFlow.id,
            updateSettingsFlowBody: {
              csrf_token: newCsrfToken,
              method: 'webauthn',

              webauthn_remove: tokenId,
            },
          });
          return resultFlow;
        }
        throw error;
      }
    },
    [ory],
  );
}
