import React from 'react';
import { FiatAmount } from '@noah-labs/fe-shared-ui-components';
import type { TpSkeletonText } from '@noah-labs/fe-shared-ui-shared';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies';

export type PpCryptoPrice = {
  cryptoCurrencyCode: string;
  fiatCurrency: TpFiatCurrency;
  price: TpSkeletonText;
};

export function CryptoPrice({
  cryptoCurrencyCode,
  fiatCurrency,
  price,
}: PpCryptoPrice): React.ReactElement {
  return (
    <span>
      1 {cryptoCurrencyCode} = <FiatAmount amount={price} currency={fiatCurrency} />
    </span>
  );
}
