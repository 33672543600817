import { z } from 'zod';
import { cardholderNameValidation } from './utils/cardholderNameValidation';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getCkoCardDetailsSchema(fullName: string | undefined, nameMatchThreshold: string) {
  return z.object({
    cardholderName: z
      .string()
      .min(1, 'Cardholder name is required')
      .refine(
        (value) => cardholderNameValidation(value, fullName, nameMatchThreshold),
        'Cardholder name doesn’t match name on profile',
      ),
    cardNumber: z
      .string()
      .refine((isValid) => isValid === 'true', 'Please enter a valid card number'),
    cvv: z.string().refine((isValid) => isValid === 'true', 'Invalid CVV'),
    expiryDate: z.string().refine((isValid) => isValid === 'true', 'Invalid Date'),
  });
}

export type TpCkoCardDetailsSchema = z.infer<ReturnType<typeof getCkoCardDetailsSchema>>;
