import type { SVGProps } from 'react';

export function SvgIosShare(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m16 5-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4zm4 5v11c0 1.1-.9 2-2 2H6a2 2 0 0 1-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3a2 2 0 0 1 2 2"
        fill="currentColor"
      />
    </svg>
  );
}
