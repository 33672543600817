import type { TpAllowance } from '@noah-labs/fe-shared-data-access-user';
import { withSlippage } from '@noah-labs/shared-currencies';
import { isUndefinedOrNull, safeBN } from '@noah-labs/shared-util-vanilla';
import { z } from 'zod';

type TpBuffer = {
  buffer: string;
  bufferInfo: string;
};

export function getCryptoWithdrawalLimitsSchema(
  allowance: TpAllowance | undefined,
  buffer?: TpBuffer,
): z.ZodEffects<z.ZodOptional<z.ZodString>, string | undefined, string | undefined> {
  return z
    .string()
    .optional()
    .superRefine((value, ctx) => {
      let message = ' ';

      /**
       * In cases where there might be slippage (ie. sell flow), we don't allow using 100% of the allowance
       */
      const input = buffer
        ? withSlippage(value, {
            slippage: buffer.buffer,
            type: 'positive',
          })
        : value;

      if (isUndefinedOrNull(allowance) || isUndefinedOrNull(value)) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, message });
        return;
      }

      const allowed = allowance.amount;
      if (safeBN(input).lte(allowed)) {
        return;
      }

      switch (allowance.reason) {
        case 'PolicyLimit':
          message = 'Sorry, you have reached your transaction limits';
          break;
        case 'Balance':
          message = 'Sorry, you don’t have enough funds for this';
          break;
        default:
      }

      if (buffer && buffer.bufferInfo) {
        message = `${message} ${buffer.bufferInfo}`;
      }

      message = `${message}.`;

      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message,
      });
    });
}
