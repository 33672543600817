import { useCallback } from 'react';
import { logger } from '@noah-labs/shared-logger/browser';
import type { LoginFlow } from '@ory/client';
import type { AxiosError } from 'axios';
import { HttpStatusCode, isAxiosError } from 'axios';
import { useOry } from '../data';
import type { AuthSetError, SocialProvider, TpSignUpPayload } from '../types';
import { assignLocationAndWait, getOryCsrfToken } from '../utils';
import { isRedirectBrowserError } from '../utils/oryTypeGuards';
import { getOryUiError } from './utils';

type TpOnSocialSignupCb = (
  provider: SocialProvider,
  setError: AuthSetError<{
    email: string;
    password: string;
  }>,
  payload: TpSignUpPayload,
) => Promise<void>;

export function useOrySocialSignUpCallback(): TpOnSocialSignupCb {
  const { ory, returnTo } = useOry();

  return useCallback(
    async (provider, setError, payload) => {
      try {
        const { data: flow } = await ory.createBrowserRegistrationFlow({
          afterVerificationReturnTo: returnTo,
          returnTo,
        });

        const csrfToken = getOryCsrfToken(flow.ui.nodes);

        await ory.updateRegistrationFlow({
          flow: flow.id,
          updateRegistrationFlowBody: {
            csrf_token: csrfToken,
            method: 'oidc',
            provider,
            transient_payload: {
              referral_code: payload.referralCode,
              session_key: payload.sessionKey,
            },
          },
        });
        await assignLocationAndWait(returnTo);
      } catch (error: unknown) {
        logger.error(error);

        if (!isAxiosError(error)) {
          setError('root.serverError', {
            message: 'Something went wrong.',
            type: 'custom',
          });
          return;
        }

        const oryError: AxiosError<LoginFlow> = error;
        if (isRedirectBrowserError(oryError)) {
          await assignLocationAndWait(oryError.response.data.redirect_browser_to);
          return;
        }

        if (oryError.response?.status !== HttpStatusCode.BadRequest) {
          setError('root.serverError', {
            message: 'Something went wrong.',
            type: 'custom',
          });
          return;
        }

        const { ui } = oryError.response.data;
        const uiServerMessage = getOryUiError(ui.messages);
        if (uiServerMessage) {
          setError('root.serverError', uiServerMessage);
        }
      }
    },
    [ory, returnTo],
  );
}
