import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { LearnMoreButton, LowDialog, PrimaryButton } from '@noah-labs/fe-shared-ui-components';
import { type TpDialogToggle, useToggleDialog } from '@noah-labs/fe-shared-ui-shared';
import { webLinks } from '@noah-labs/shared-constants';

export const LightningAddressDialog = forwardRef<TpDialogToggle>((_, ref): JSX.Element => {
  const { open, toggle } = useToggleDialog({ ref });

  return (
    <LowDialog open={open} onClose={toggle}>
      <DialogTitle>Your NOAH Lightning Address</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your NOAH Lightning Address is your easy-to-remember address that allows you to instantly
          send and receive bitcoin payments without high network fees.{' '}
          <LearnMoreButton href={webLinks.faq.lightningAddress} target="_blank" onClick={toggle}>
            Learn more.
          </LearnMoreButton>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton color="primaryBrand" onClick={toggle}>
          Got it
        </PrimaryButton>
      </DialogActions>
    </LowDialog>
  );
});

LightningAddressDialog.displayName = LightningAddressDialog.name;
