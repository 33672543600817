import { Fragment } from 'react';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import { Route } from 'react-router-dom';
import { AccessControlData } from '../auth/AccessControlData';
import { EditPaymentMethod } from './controllers/EditPaymentMethod';
import { NewPaymentMethod } from './controllers/NewPaymentMethod';
import { PaymentMethods } from './controllers/PaymentMethods';

export function PaymentMethodsRouter(): React.ReactElement {
  return (
    <Fragment>
      {/* preload new payment form because it uses checkout Frames */}
      <Route
        exact
        path={[userRoutes.settings.paymentMethods.path, userRoutes.settings.newPaymentMethod.path]}
      >
        <AccessControlData needsAuthStatus={['authenticated']}>
          <NewPaymentMethod />
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.settings.editPaymentMethod.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <EditPaymentMethod />
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.settings.paymentMethods.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <PaymentMethods />
        </AccessControlData>
      </Route>
    </Fragment>
  );
}
