//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgBitcoin } from '../icons/Bitcoin';

export function BitcoinIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>,
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgBitcoin} data-qa="BitcoinIcon">
      <svg />
    </SvgIcon>
  );
}
