import React from 'react';
import { List, SkeletonCards } from '@noah-labs/fe-shared-ui-components';
import type { PpWOC } from '@noah-labs/fe-shared-ui-shared';

export type PpAccountCardList = PpWOC & {
  direction?: 'row' | 'column';
  isFetched?: boolean;
};

export function AccountCardList({
  children,
  direction = 'column',
  isFetched = false,
}: PpAccountCardList): React.ReactElement {
  return (
    <List disablePadding direction={direction} spacing={1}>
      {isFetched ? children : <SkeletonCards startSecondary={false} />}
    </List>
  );
}
