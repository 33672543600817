import type { SVGProps } from 'react';

export function SvgLock(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 25"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 9.107v-2c0-2.8-2.2-5-5-5s-5 2.2-5 5v2c-1.7 0-3 1.3-3 3v7c0 1.7 1.3 3 3 3h10c1.7 0 3-1.3 3-3v-7c0-1.7-1.3-3-3-3m-8-2c0-1.7 1.3-3 3-3s3 1.3 3 3v2H9zm4.1 8.5-.1.1v1.4c0 .6-.4 1-1 1s-1-.4-1-1v-1.4c-.6-.6-.7-1.5-.1-2.1s1.5-.7 2.1-.1c.6.5.7 1.5.1 2.1"
        fill="currentColor"
      />
    </svg>
  );
}
