import { useMemo } from 'react';
import { useCountryFromCode } from '@noah-labs/fe-shared-data-access-countries';
import type { TpBillingAddressSchema } from '@noah-labs/fe-shared-util-validation-schemas';
import { useUserInitUi } from './useUserInitUi';

export type TpBillingAddressForm = TpBillingAddressSchema & {
  CountryName: string;
};

export function useUserDefaultBillingAddress(): TpBillingAddressForm | undefined {
  const { data: userData } = useUserInitUi();
  const country = useCountryFromCode(userData?.userProfile.HomeAddress?.CountryCode);
  return useMemo(() => {
    if (!userData?.userProfile.HomeAddress || !country) {
      return undefined;
    }

    return {
      ...userData.userProfile.HomeAddress,
      City: userData.userProfile.HomeAddress.City || '',
      CountryCode: userData.userProfile.HomeAddress.CountryCode as string,
      CountryName: country.name,
      PostCode: userData.userProfile.HomeAddress.PostCode || '',
      State: userData.userProfile.HomeAddress.State || '',
      Street: userData.userProfile.HomeAddress.Street || '',
      Street2: userData.userProfile.HomeAddress.Street2 || '',
    };
  }, [country, userData?.userProfile.HomeAddress]);
}
