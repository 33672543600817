import type { ReactElement } from 'react';
import { useNovuSubscriberHashQuery } from '@noah-labs/fe-shared-data-access-notifications';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { NovuProvider as BaseNovuProvider } from '@novu/notification-center';
import type { INotificationCenterStyles } from '@novu/notification-center';

type NovuProviderProps = {
  applicationId: string;
  styles: INotificationCenterStyles;
};

export function NovuProvider({
  applicationId,
  children,
  styles,
}: NovuProviderProps & PpWC): ReactElement {
  const { data: userData } = useUserInitUi();

  const { data: subscriberHashQuery } = useNovuSubscriberHashQuery();

  const subscriberHash = subscriberHashQuery?.novuSubscriberHash.SubscriberHash;

  return (
    <BaseNovuProvider
      applicationIdentifier={subscriberHash ? applicationId : ''}
      styles={styles}
      subscriberHash={subscriberHash}
      subscriberId={userData?.userProfile.UserID}
    >
      {children}
    </BaseNovuProvider>
  );
}
