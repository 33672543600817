import type { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import { PlusCircleThickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/PlusCircleThickIcon';
import { ReferralIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ReferralIcon';
import { Avatar, AvatarBadge, AvatarIcon } from '@noah-labs/fe-shared-ui-components';

export function NewReferralNotificationIcon(): ReactElement {
  const theme = useTheme();

  return (
    <Avatar>
      <AvatarIcon>
        <ReferralIcon sx={{ color: 'warning.dark' }} />
      </AvatarIcon>
      <AvatarBadge>
        <AvatarIcon color={theme.palette.system.green.main} size={2}>
          <PlusCircleThickIcon />
        </AvatarIcon>
      </AvatarBadge>
    </Avatar>
  );
}
