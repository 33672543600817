import { useEffect, useState } from 'react';
import {
  AddressManualController,
  AddressScanController,
  useAddress,
} from '@noah-labs/fe-shared-feature-address';
import { generatePath, Switch404 } from '@noah-labs/fe-shared-ui-components';
import { useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Route } from 'react-router-dom';
import { useBackHijack } from '../../../../hooks/useBackHijack';
import { AccessControlData } from '../../../auth/AccessControlData';
import { WalletControllerWrapper } from '../../components/layout/WalletControllerWrapper';
import { AddressScanWrapper } from './AddressScanWrapper';

export function AddressRouter(): React.ReactElement {
  const { params } = useWalletParams();
  const [appHeaderHeight, setAppHeaderHeight] = useState(0);

  const { scannerUnavailable, setData } = useAddress();

  const { address, base } = walletRoutes().send;

  const { backTo, goBack } = useBackHijack(base.path);

  useEffect(() => () => setData(undefined), [setData]);

  return (
    <Switch404>
      <Route exact path={address.scan.path}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          redirectInvalid={scannerUnavailable && generatePath(address.base.path, params)}
        >
          <AddressScanWrapper setAppHeaderHeight={setAppHeaderHeight}>
            <AddressScanController appHeaderHeight={appHeaderHeight} />
          </AddressScanWrapper>
        </AccessControlData>
      </Route>
      <Route exact path={address.base.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <WalletControllerWrapper
            backButton={goBack}
            backTo={backTo}
            headerTitle={address.base.title}
            headTitle={address.base.title}
          >
            <AddressManualController />
          </WalletControllerWrapper>
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
