import { NoahErrorType } from '@noah-labs/shared-schema-gql';

// See: https://docs.aws.amazon.com/appsync/latest/devguide/resolver-util-reference.html
type TpNoahAppSyncError = {
  data?: Record<string, unknown> | null;
  errorInfo?: Record<string, unknown> | null;
  // ErrorType is normally optional, but for our errors, we will make it required
  errorType: NoahErrorType;
  message: string;
};

/**
 * TpAppSyncError, similar to a regular graphql error but augmented with the AppSync data.
 * Because we might not control all the errors that come back, use Partial<TpNoahAppSyncError>,
 *
 */
type TpAppSyncError = Partial<TpNoahAppSyncError> & {
  locations?: [
    {
      column: number;
      line: number;
      sourceName: null;
    },
  ];
  path?: string[];
};

/**
 * TpGraphqlRequestError describes the error we get back from the graphql-request package, via the error field in the react-query hooks
 */
type TpGraphqlRequestError = {
  request: {
    query: string;
    variables: unknown;
  };
  response: {
    data: unknown;
    errors: TpAppSyncError[];
    headers: unknown;
    status: unknown;
  };
};

function isObject(error: unknown): error is Record<string, unknown> {
  if (!error || typeof error !== 'object') {
    return false;
  }
  return true;
}

/**
 * isGraphqlError: use this function to check if an error has request and response fields,
 * that are objects and with an errors array on the response.errors field
 */
export function isGraphqlError(error: unknown): error is TpGraphqlRequestError {
  if (
    !isObject(error) ||
    !isObject(error.request) ||
    !isObject(error.response) ||
    !Array.isArray(error.response.errors)
  ) {
    return false;
  }
  return true;
}

/**
 * isAppSyncError: use this function to check if an error, from the response.errors array,
 * is a TpAppSyncError with errorType and errorMessage fields
 */
export function isAppSyncError(error: unknown): error is TpAppSyncError {
  if (!isObject(error)) {
    return false;
  }
  if (!error.errorType || !error.message) {
    return false;
  }
  return true;
}

/**
 * getErrorType: use this function to extract the error type from an
 * api error message
 */
export function getErrorType(message: string): NoahErrorType {
  // Error message includes type, instance, action and detail
  // separated by a ' - '.
  // This extracts the first part of the message which is the
  //  error type
  const errorType = message.split('-').map((m) => m.trim())[0];
  if (!(errorType in NoahErrorType)) {
    return NoahErrorType.Unexpected;
  }
  return errorType as NoahErrorType;
}
