import type { SVGProps } from 'react';

export function SvgCode(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 25"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 4.835c0-.402.326-.728.727-.728h2.91c.401 0 .727.326.727.728v2.909a.727.727 0 0 1-.728.727H4.727A.727.727 0 0 1 4 7.744v-2.91ZM4 16.47c0-.4.326-.726.727-.726h2.91c.401 0 .727.325.727.727v2.909a.727.727 0 0 1-.728.727H4.727A.727.727 0 0 1 4 19.38zM15.637 4.835c0-.402.326-.728.727-.728h2.91c.401 0 .727.326.727.728v2.909a.727.727 0 0 1-.728.727h-2.909a.727.727 0 0 1-.727-.727v-2.91Z"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="M15.637 15.744h4.364m0 4.363h-4.364m4.363-8h-8m-8 0h8m0 0v-8m0 16v-4.666"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
}
