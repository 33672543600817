import { TpPaymentMethod } from '../types';
import type { TpPaymentBank, TpPaymentCard } from '../types';
import { getBankCurrencyFeeAndEta } from './getBankCurrencyFeeAndEta';
import { getCardFeeUi } from './getCardFeeUi';
import { getPayoutEstimateTime } from './getPayoutEstimateTime';

export function getPayoutFeeAndEta(method: TpPaymentCard | TpPaymentBank): string {
  if (method.payoutMethod === TpPaymentMethod.Card && method.estimateHours) {
    return [getPayoutEstimateTime(method.estimateHours), getCardFeeUi()].join(' • ');
  }

  const payoutMethod = method as TpPaymentBank;
  return getBankCurrencyFeeAndEta(payoutMethod.accountCurrency);
}
