import type { TpAvatarStatus } from '@noah-labs/fe-shared-ui-components';
import { TpCheckoutPaymentStatus, TpCheckoutPayoutStatus } from '@noah-labs/fe-shared-util-shared';

export function getCheckoutAvatarStatus(
  status: TpCheckoutPaymentStatus | TpCheckoutPayoutStatus,
): TpAvatarStatus {
  switch (status) {
    case TpCheckoutPaymentStatus.pending:
    case TpCheckoutPayoutStatus.pending:
      return 'pending';
    case TpCheckoutPaymentStatus.failure:
    case TpCheckoutPayoutStatus.failure:
      return 'failed';
    case TpCheckoutPaymentStatus.success:
    case TpCheckoutPayoutStatus.success:
    default:
      return 'success';
  }
}
