import { useRef } from 'react';
import {
  InfoTooltip,
  List,
  ListItem,
  ListItemContent,
  ListItemEndContent,
  ListItemSecondaryText,
  ListItemStartContent,
  ProgressBar,
  TextOrSkeleton,
} from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { DailySendLimitsDialog } from '../dialogs/DailySendLimitsDialog';

export type PpTransactionsLimitsIndicator = {
  dailyLimit: number;
  remainingTransactions: number;
};

export function TransactionsLimitsIndicator({
  dailyLimit,
  remainingTransactions,
}: PpTransactionsLimitsIndicator): React.ReactElement {
  const limitDialogRef = useRef<TpDialogToggle>(null);

  const progress = (dailyLimit - remainingTransactions) * 0.1;
  const progressText = `${dailyLimit - remainingTransactions}/${dailyLimit}`;
  const remainingTransactionsText = `${remainingTransactions} transactions left`;

  return (
    <div>
      <List disablePadding>
        <ListItem>
          <ListItemContent padding={0}>
            <ListItemStartContent>
              <ListItemSecondaryText>
                <InfoTooltip onClick={(): void => limitDialogRef.current?.toggle()}>
                  Daily send limit
                </InfoTooltip>
              </ListItemSecondaryText>
            </ListItemStartContent>
          </ListItemContent>
        </ListItem>
        <ProgressBar progress={progress || 0} sx={{ mb: 1, mt: 1.5 }} />
        <ListItem>
          <ListItemContent padding={0}>
            <ListItemStartContent>
              <ListItemSecondaryText>
                <TextOrSkeleton>{progressText}</TextOrSkeleton>
              </ListItemSecondaryText>
            </ListItemStartContent>
            <ListItemEndContent>
              <ListItemSecondaryText>
                <TextOrSkeleton>{remainingTransactionsText}</TextOrSkeleton>
              </ListItemSecondaryText>
            </ListItemEndContent>
          </ListItemContent>
        </ListItem>
      </List>
      <DailySendLimitsDialog ref={limitDialogRef} limit={dailyLimit} />
    </div>
  );
}
