import { isUndefinedOrNull, safeBN } from '@noah-labs/shared-util-vanilla';
import type BigNumber from 'bignumber.js';
import type { TpAmount, TpSimpleAmount } from './types';

/**
 * truncateAmount removes any 'precision' that is not supported by the currency
 * and can also round up/down
 */
type TpTruncateAmount<T = TpAmount> = {
  amount: T;
  decimalPlaces: number | undefined;
  roundingMode?: BigNumber.RoundingMode;
};
export function truncateAmount({
  amount,
  decimalPlaces,
  roundingMode,
}: TpTruncateAmount<null | undefined>): undefined;
export function truncateAmount({
  amount,
  decimalPlaces,
  roundingMode,
}: TpTruncateAmount<string | number>): string;
export function truncateAmount({
  amount,
  decimalPlaces,
  roundingMode,
}: TpTruncateAmount): TpSimpleAmount;
export function truncateAmount({
  amount,
  decimalPlaces,
  roundingMode,
}: TpTruncateAmount): TpSimpleAmount {
  if (isUndefinedOrNull(amount)) {
    return undefined;
  }

  if (isUndefinedOrNull(decimalPlaces)) {
    return undefined;
  }
  return safeBN(amount).decimalPlaces(decimalPlaces, roundingMode).toString();
}
