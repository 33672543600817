import { Divider, Skeleton } from '@mui/material';
import {
  List,
  ListItem,
  ListItemCard,
  ListItemContent,
  ListItemEndContent,
  ListItemSecondaryText,
  ListItemStartContent,
} from '@noah-labs/fe-shared-ui-components';

const itemSx = { height: 36 };

export function SkeletonAddressSummaryCard(): React.ReactElement {
  return (
    <ListItemCard sx={{ padding: 2 }}>
      <List disablePadding spacing={1.5} sx={{ width: '100%' }}>
        <ListItem sx={itemSx}>
          <ListItemContent padding={0}>
            <ListItemStartContent>
              <ListItemSecondaryText>Send</ListItemSecondaryText>
            </ListItemStartContent>
            <ListItemEndContent>
              <Skeleton sx={{ px: '5rem' }} variant="rectangular" />
            </ListItemEndContent>
          </ListItemContent>
        </ListItem>
        <Divider />
        <ListItem sx={itemSx}>
          <ListItemContent padding={0}>
            <ListItemStartContent>
              <ListItemSecondaryText>To</ListItemSecondaryText>
            </ListItemStartContent>
            <ListItemEndContent>
              <Skeleton sx={{ px: '5rem' }} variant="rectangular" />
            </ListItemEndContent>
          </ListItemContent>
        </ListItem>
        <Divider />
        <ListItem sx={itemSx}>
          <ListItemContent padding={0}>
            <ListItemStartContent>
              <ListItemSecondaryText>Network</ListItemSecondaryText>
            </ListItemStartContent>
            <ListItemEndContent>
              <Skeleton sx={{ px: '5rem' }} variant="rectangular" />
            </ListItemEndContent>
          </ListItemContent>
        </ListItem>
      </List>
    </ListItemCard>
  );
}
