import { useCallback } from 'react';
import { logger } from '@noah-labs/shared-logger/browser';
import type { RegistrationFlow, UpdateVerificationFlowBody } from '@ory/client';
import type { AxiosError } from 'axios';
import { HttpStatusCode, isAxiosError } from 'axios';
import { useOry } from '../data';
import type { AuthSetError } from '../types';
import { getOryCsrfToken, getOryFlowId } from '../utils';
import { getOryUiError } from './utils';

export function useOryVerifyAccountCallback(
  onSuccess: () => unknown,
): (
  values: { verificationCode: string },
  setError: AuthSetError<{ verificationCode: string }>,
) => Promise<void> {
  const { ory } = useOry();

  return useCallback(
    async (values, setError): Promise<void> => {
      try {
        const flowId = getOryFlowId() || '';
        const { data: flow } = await ory.getVerificationFlow({
          id: flowId,
        });

        const csrfToken = getOryCsrfToken(flow.ui.nodes);

        const { data } = await ory.updateVerificationFlow({
          flow: flow.id,
          updateVerificationFlowBody: {
            code: values.verificationCode,
            csrf_token: csrfToken,
            method: 'code',
          } as unknown as UpdateVerificationFlowBody,
        });

        const uiErrorMessage = getOryUiError(data.ui.messages);
        if (uiErrorMessage) {
          setError('root.serverError', uiErrorMessage);
          return;
        }

        onSuccess();
      } catch (error: unknown) {
        logger.error(error);

        if (!isAxiosError(error)) {
          setError('root.serverError', {
            message: 'Something went wrong.',
            type: 'custom',
          });
          return;
        }

        const oryError = error as AxiosError<RegistrationFlow>;
        if (oryError.response?.status !== HttpStatusCode.BadRequest) {
          setError('root.serverError', {
            message: 'Something went wrong.',
            type: 'custom',
          });
          return;
        }

        const { ui } = oryError.response.data;
        const uiServerMessage = getOryUiError(ui.messages);
        if (uiServerMessage) {
          setError('root.serverError', uiServerMessage);
        }
      }
    },
    [ory, onSuccess],
  );
}
