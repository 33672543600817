import { List } from '@noah-labs/fe-shared-ui-components';
import type { QuestStatus } from '@noah-labs/shared-schema-gql';
import { getQuestListItem } from '../../mappings/questListItem';
import { QuestListItem } from './QuestListItem';

type PpQuestList = {
  quests: QuestStatus[] | undefined;
};

export function QuestList({ quests }: PpQuestList): React.ReactElement {
  return (
    <List disablePadding spacing={1.5}>
      {quests?.map((questStatus) => (
        <QuestListItem key={questStatus.Quest.QuestID} quest={getQuestListItem(questStatus)} />
      ))}
    </List>
  );
}
