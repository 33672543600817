import type { TpAccount } from '@noah-labs/fe-shared-data-access-wallet';
import { cryptoCurrencyFromCode } from '../cryptoCurrencies';
import type { TpCryptoCurrencyUI } from '../types';

export type TpAccountUI = TpAccount & { cryptoCurrency: TpCryptoCurrencyUI };

export function getAccountUi(item: TpAccount): TpAccountUI;
export function getAccountUi(item?: TpAccount): TpAccountUI | undefined;
export function getAccountUi(item?: TpAccount): TpAccountUI | undefined {
  if (!item) {
    return undefined;
  }

  return {
    ...item,
    cryptoCurrency: cryptoCurrencyFromCode(item.CurrencyCode),
  };
}

export function getAccountsUi(items?: TpAccount[]): TpAccountUI[] | undefined {
  return items?.map((item) => getAccountUi(item));
}
