import type { SVGProps } from 'react';

export function SvgMastercardLight(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg {...props} fill="none" viewBox="0 0 48 24" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#FBFAF8" height={24} rx={2} width={48} />
      <path
        clipRule="evenodd"
        d="M29.806 21C34.884 21 39 16.97 39 12s-4.116-9-9.194-9c-5.077 0-9.193 4.03-9.193 9s4.116 9 9.193 9"
        fill="#F79E1B"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M18.194 21c5.077 0 9.193-4.03 9.193-9s-4.116-9-9.194-9C13.117 3 9 7.03 9 12s4.116 9 9.194 9"
        fill="#EB001B"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M24 5.022c-2.067 1.65-3.387 4.163-3.387 6.978s1.32 5.328 3.387 6.978c2.067-1.65 3.387-4.163 3.387-6.978S26.067 6.672 24 5.022"
        fill="#FF5F00"
        fillRule="evenodd"
      />
    </svg>
  );
}
