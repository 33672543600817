import { webLinks } from '@noah-labs/shared-constants';
import { CurrencyCode as TpCurrencyCode } from '@noah-labs/shared-schema-gql';

export function getAddressCurrencyInfo(currencyCode: TpCurrencyCode): {
  label: string;
  learnMoreLink: string;
  subTitle: string;
} {
  let subTitle: string;
  let label: string;
  let learnMoreLink: string;

  switch (currencyCode) {
    case TpCurrencyCode.USDC:
    case TpCurrencyCode.USDC_TEST:
      subTitle =
        'NOAH supports USDC transfers via the Ethereum, Polygon, and Tron network. Ensure to select the correct network to avoid fund loss.';
      label = 'USDC address';
      learnMoreLink = webLinks.faq.usdStablecoinsSupportedAddresses;
      break;

    case TpCurrencyCode.USDT:
    case TpCurrencyCode.USDT_TEST:
      subTitle =
        'NOAH supports USDT transfers via the Ethereum, Polygon, and Tron network. Ensure to select the correct network to avoid fund loss.';
      label = 'USDT address';
      learnMoreLink = webLinks.faq.usdStablecoinsSupportedAddresses;
      break;

    case TpCurrencyCode.BTC:
    case TpCurrencyCode.BTC_TEST:
      subTitle =
        'NOAH enables BTC transactions on-chain and via the Lightning Network. Please double-check the address for accuracy.';
      label = 'Bitcoin address';
      learnMoreLink = webLinks.faq.supportedAddresses;
      break;

    default:
      return { label: 'Address', learnMoreLink: '', subTitle: '' };
  }

  return { label, learnMoreLink, subTitle };
}
