import type { TpAllowance } from '@noah-labs/fe-shared-data-access-user';
import { CryptoAmount } from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoUnitAmount } from '@noah-labs/fe-shared-ui-shared';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies';
import { isNonZero, isUndefinedOrNull, safeBN } from '@noah-labs/shared-util-vanilla';
import { z } from 'zod';
import { maxSafetyAmount } from './amounts';
import { getCryptoWithdrawalLimitsSchema } from './getCryptoWithdrawalLimitsSchema';

type TpGetLnSendSchema = {
  allowance: TpAllowance | undefined;
  fiatCurrency: TpFiatCurrency | undefined;
  maxAmounts: TpCryptoUnitAmount | undefined;
  minAmounts: TpCryptoUnitAmount | undefined;
};
export function getLnSendSchema({
  allowance,
  fiatCurrency,
  maxAmounts,
  minAmounts,
}: TpGetLnSendSchema): z.ZodSchema {
  return z.object({
    cryptoAmount: z
      .string()
      .optional()
      .superRefine((value, ctx) => {
        const schema = getCryptoWithdrawalLimitsSchema(allowance);

        const result = schema.safeParse(value);
        if (!result.success) {
          ctx.addIssue(result.error.issues[0]);
        }

        if (!isUndefinedOrNull(maxAmounts)) {
          if (!isNonZero(value)) {
            ctx.addIssue({ code: z.ZodIssueCode.custom, message: ' ' });
          }
          if (safeBN(value).gt(maxAmounts.amountBtc)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: (
                <span>
                  Maximum amount is{' '}
                  <CryptoAmount
                    amount={maxAmounts.amountBtc}
                    currency={maxAmounts.cryptoCurrency}
                    currencyUnit={maxAmounts.unit}
                  />
                </span> // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ) as any, // https://github.com/colinhacks/zod/issues/3212
            });
          }
        }

        if (!isUndefinedOrNull(minAmounts)) {
          if (!isNonZero(value)) {
            ctx.addIssue({ code: z.ZodIssueCode.custom, message: ' ' });
          }
          if (safeBN(value).lt(minAmounts.amountBtc)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: (
                <span>
                  Minimum amount is{' '}
                  <CryptoAmount
                    amount={minAmounts.amountBtc}
                    currency={minAmounts.cryptoCurrency}
                    currencyUnit={minAmounts.unit}
                  />
                </span> // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ) as any, // https://github.com/colinhacks/zod/issues/3212
            });
          }
        }
      }),
    description: z.string().optional(),
    fetchedAt: z.string().optional(),
    fiatAmount: maxSafetyAmount(fiatCurrency),
    price: z.string().optional(),
    primaryAmount: z.string().optional(),
    secondaryAmount: z.string().optional(),
  });
}
