import { useCallback } from 'react';
import {
  getQRScannerError,
  parseMarketingUrl,
  Switch404,
  usePushAlert,
} from '@noah-labs/fe-shared-ui-components';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import { Feature } from '@noah-labs/shared-schema-gql';
import { getErrorMessage } from '@noah-labs/shared-util-vanilla';
import { Route, useParams } from 'react-router-dom';
import { AccessControlData } from '../auth/AccessControlData';
import { Marketing } from './controllers/Marketing';
import { routes } from './routes';
import { MarketingScanScene } from './scenes/MarketingScan';

export function Router(): React.ReactElement {
  const { code } = useParams<{ code?: string }>();
  const { push } = useRouter();
  const pushAlert = usePushAlert();

  const handleAddress = useCallback(
    async (qrInput: string) => {
      try {
        const marketingUrl = parseMarketingUrl(qrInput);
        if (!marketingUrl) {
          throw new Error('Sorry, that’s not a valid NOAH promo code');
        }

        push(marketingUrl);
      } catch (error) {
        pushAlert({
          dismissable: true,
          key: 'scanError',
          message: getErrorMessage(error),
          preventDuplicate: true,
          severity: 'error',
        });
      }
    },
    [push, pushAlert],
  );

  return (
    <Switch404>
      <Route exact path={routes.scan.path}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          needsFeature={[Feature.UIPromotions]}
        >
          <MarketingScanScene
            pageTitle={routes.scan.title}
            onSuccess={handleAddress}
            onUnavailable={(error): void =>
              pushAlert({
                dismissable: true,
                key: 'scanError',
                message: getQRScannerError(error),
                preventDuplicate: true,
                severity: 'error',
              })
            }
          />
        </AccessControlData>
      </Route>
      <Route exact path={routes.base.path}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          needsFeature={[Feature.UIPromotions]}
        >
          <Marketing code={code} />
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
