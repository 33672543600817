import { getDefaults } from '@noah-labs/fe-shared-feature-wallet';
import { AlertLink, generatePath } from '@noah-labs/fe-shared-ui-components';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';

const { accountType, cryptoCurrency } = getDefaults();

type TpBuyBitcoinLink = {
  text?: string;
};

export function BuyBitcoinLink({ text = 'Buy bitcoin' }: TpBuyBitcoinLink): React.ReactElement {
  return (
    <AlertLink
      aria-label="buy-bitcoin"
      href={generatePath(walletRoutes().buy.base.path, {
        accountType,
        currencyCode: cryptoCurrency.code,
      })}
    >
      {text}
    </AlertLink>
  );
}
