import { Fragment } from 'react';
import type { TpPushAlert } from '@noah-labs/fe-shared-ui-components';
import { AlertError, GetInTouchLink } from '@noah-labs/fe-shared-ui-components';

/**
 * Errors
 */
export const ApiInvalidUsername: TpPushAlert = AlertError(
  'invalidUsername',
  'Sorry, your Public Username is not valid. It must be between 3 and 25 characters long, containing only alphanumeric, hypens, underscores and dots: ( _, -, . ). Please update it and try again.',
);

export const ApiUsernameExists: TpPushAlert = AlertError(
  'usernameAlreadyExists',
  'The username you have selected is already in use. Please choose a different username.',
);

export const ApiUserProfileExists: TpPushAlert = AlertError(
  'userProfileExists',
  <Fragment>
    Sorry, your profile has already been registered. Please{' '}
    <GetInTouchLink color="inherit" underline="always" />.
  </Fragment>,
);

export const ApiUserProfileUpdate: TpPushAlert = AlertError(
  'userProfileUpdate',
  'There was an error saving your profile details',
);

export const ApiUsernameProfanity: TpPushAlert = AlertError(
  'usernameProfanity',
  'The username you have selected may contain a word that has been blocked by our profanity filter. Please choose a different username.',
);
