import { getCurrencyAmountSlots, List, ListItemCard } from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import { AvailableBalance } from '@noah-labs/fe-shared-ui-wallet';
import type { AccountType as TpAccountType } from '@noah-labs/shared-schema-gql';
import { useUserAccountAvailableBalance } from '../hooks/useUserAccountAvailableBalance';
import { useUserInitUi } from '../hooks/useUserInitUi';

type PpAvailableBalanceData = {
  AccountType: TpAccountType;
  ListItemsSlot?: React.ReactNode;
  cryptoCurrency: TpCryptoCurrencyUI;
};

export function AvailableBalanceData({
  AccountType,
  cryptoCurrency,
  ListItemsSlot,
}: PpAvailableBalanceData): React.ReactElement {
  const { data: userData } = useUserInitUi();
  const fiatCurrency = userData?.userProfile.fiatCurrency;

  const availableBalanceData = useUserAccountAvailableBalance({
    AccountType,
    cryptoCurrency,
    fiatCurrency,
  });

  const Amounts = getCurrencyAmountSlots({
    ...availableBalanceData,
    cryptoCurrency,
    cryptoUnit: userData?.userProfile.DisplayUnit,
    fiatCurrency,
    primaryCurrency: userData?.userProfile.PrimaryCurrency,
    roundDown: true,
  });

  return (
    <ListItemCard sx={{ padding: 2 }}>
      <List disablePadding spacing={1.5}>
        <AvailableBalance cryptoCurrency={cryptoCurrency} {...Amounts} />
        {ListItemsSlot}
      </List>
    </ListItemCard>
  );
}
