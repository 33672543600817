import { CurrencyCode } from '@noah-labs/shared-schema-gql';

export function getStablecoinsDialogLabel(currencyCode: CurrencyCode): string {
  switch (currencyCode) {
    case CurrencyCode.USDC:
    case CurrencyCode.USDC_TEST:
      return 'USD coin';

    case CurrencyCode.USDT:
    case CurrencyCode.USDT_TEST:
      return 'Tether';

    default:
      return '';
  }
}
