import { useCallback } from 'react';
import { Card, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TpAnalyticsEvent, useAnalytics } from '@noah-labs/fe-shared-feature-analytics';
import {
  coreThemeConstants,
  ListItem,
  ListItemContent,
  ListItemPrimaryText,
  ListItemSecondaryText,
  PrimaryButton,
  styledMixins,
  TextOrSkeleton,
} from '@noah-labs/fe-shared-ui-components';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import type { QuestTaskWithStatus } from '@noah-labs/shared-schema-gql';
import { isQuestTaskComplete } from '../../../data/utils';
import { getQuestTaskAction } from '../../../mappings/questTaskActions';
import { getQuestTaskCopy } from '../../../mappings/questTaskCopy';

export type PpQuestTaskCard = {
  task: QuestTaskWithStatus;
};

const StyledCard = styled(Card)<{ completed: boolean }>(({ completed, theme }) => ({
  ':after': completed
    ? {
        content: `''`,
        position: 'absolute',
        ...styledMixins.stretchAll,
        background: theme.palette.grayscale.black40,
      }
    : undefined,
  position: 'relative',
  width: '100%',
}));

const StyledPoints = styled(Typography)(() => ({
  /* TODO Font variant missing */
  fontFamily: coreThemeConstants.fontFamily.extended,
  fontSize: '0.75rem',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: '12px',
  textTransform: 'uppercase',
}));

export function QuestTaskCard({ task }: PpQuestTaskCard): React.ReactElement {
  const { track } = useAnalytics();
  const { pathname, push } = useRouter();

  const taskAction = getQuestTaskAction(task.Task);
  const taskCopy = getQuestTaskCopy(task.Task);
  const isComplete = isQuestTaskComplete(task);

  const handleClick = useCallback(() => {
    if (!taskAction) {
      return;
    }

    track(TpAnalyticsEvent.TaskClick, {
      requirement: taskAction.requirement,
    });

    if (!taskAction.href) {
      return;
    }

    if (taskAction.externalLink) {
      window.open(taskAction.href, '_blank');
      return;
    }

    push({ pathname: taskAction.href, state: { from: pathname } });
  }, [pathname, push, taskAction, track]);

  return (
    <ListItem>
      <StyledCard completed={isComplete}>
        <ListItemContent>
          <Stack spacing={2} width="100%">
            <Stack spacing={1}>
              <ListItemPrimaryText variant="paragraphBodyMBold">
                <TextOrSkeleton>{taskCopy?.title}</TextOrSkeleton>
              </ListItemPrimaryText>
              <ListItemSecondaryText color="text.light">
                <TextOrSkeleton>{taskCopy?.description}</TextOrSkeleton>
              </ListItemSecondaryText>
              <StyledPoints color="brand.light" variant="paragraphBodyS">
                <TextOrSkeleton>{taskCopy?.reward}</TextOrSkeleton>
              </StyledPoints>
            </Stack>
            {!isComplete && !!taskAction?.cta && (
              <PrimaryButton
                color="primaryBrand"
                loading={!taskAction}
                size="medium"
                onClick={handleClick}
              >
                {taskAction.cta}
              </PrimaryButton>
            )}
          </Stack>
        </ListItemContent>
      </StyledCard>
    </ListItem>
  );
}
