import { useCallback } from 'react';
import { logger } from '@noah-labs/shared-logger/browser';
import type { SettingsFlow } from '@ory/client';
import type { AxiosError } from 'axios';
import { HttpStatusCode, isAxiosError } from 'axios';
import { useOry } from '../data';

export function useOryCreateSettingsFlow(
  resetPasswordReturnTo: string,
): () => Promise<string | undefined> {
  const { ory } = useOry();

  return useCallback(async () => {
    try {
      const { data: settingsFlow } = await ory.createBrowserSettingsFlow({
        returnTo: resetPasswordReturnTo,
      });
      return settingsFlow.id;
    } catch (error) {
      logger.error(error);
      if (!isAxiosError(error)) {
        return undefined;
      }

      const oryError = error as AxiosError<SettingsFlow>;
      if (oryError.response?.status !== HttpStatusCode.BadRequest) {
        throw Error('Something went wrong');
      }

      return undefined;
    }
  }, [ory, resetPasswordReturnTo]);
}
