import React, { Fragment } from 'react';
import { Alert } from '@mui/material';
import { AlertLink } from '@noah-labs/fe-shared-ui-components';
import { getTransactionTimeText } from './getTransactionTimeText';

export type PpAlertTransactionTime = {
  /**
   * expected transaction time in ms
   */
  delay: number;
  faqLink?: string;
};

export function AlertTransactionTime({
  delay,
  faqLink,
}: PpAlertTransactionTime): React.ReactElement {
  return (
    <Alert severity="info">
      {getTransactionTimeText(delay)}
      {faqLink && (
        <Fragment>
          {' '}
          <AlertLink href={faqLink}>Find out more here.</AlertLink>
        </Fragment>
      )}
    </Alert>
  );
}
