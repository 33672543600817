import { Fragment, useCallback } from 'react';
import { HelpIconButtonData } from '@noah-labs/fe-shared-feature-help';
import { CloseIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CloseIcon';
import {
  AppContainer,
  AppHeader,
  AppHeaderTitle,
  AppMain,
  HeaderIconButton,
  LowDialog,
  SceneMain,
} from '@noah-labs/fe-shared-ui-components';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import type { CurrencyDisplayType, CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { TransactionDetails } from '../transactions/TransactionDetails';
import type { PpTransactionDetails } from '../transactions/TransactionDetails';

// Use Partial here because we want to be able to show a Skeleton state
export type PpTransactionDialog = Partial<PpTransactionDetails> & {
  backTo?: string;
  open?: boolean;
  userCryptoUnit: CurrencyUnit | undefined;
  userPrimaryCurrency: CurrencyDisplayType | undefined;
};

export function TransactionDialog({
  backTo,
  open = false,
  userCryptoUnit,
  userPrimaryCurrency,
  ...txProps
}: PpTransactionDialog): React.ReactElement {
  const { goBack, push } = useRouter();

  const handleClose = useCallback(() => {
    if (typeof backTo === 'string') {
      push(backTo);
    } else {
      goBack();
    }
  }, [backTo, goBack, push]);

  return (
    <LowDialog fullScreen keepMounted open={open}>
      <AppContainer>
        <AppMain>
          <AppHeader
            disableFade
            backgroundColor="transparent"
            endIconsSlot={
              <Fragment>
                <HelpIconButtonData />
                <HeaderIconButton
                  aria-label="exit"
                  data-qa="exit-button"
                  edge="end"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </HeaderIconButton>
              </Fragment>
            }
          >
            <AppHeaderTitle>{txProps.created}</AppHeaderTitle>
          </AppHeader>
          <SceneMain dataQa="transaction-detail">
            <TransactionDetails
              {...txProps}
              cryptoUnit={userCryptoUnit}
              primaryCurrency={userPrimaryCurrency}
            />
          </SceneMain>
        </AppMain>
      </AppContainer>
    </LowDialog>
  );
}
