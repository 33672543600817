import { CryptoAmount } from '@noah-labs/fe-shared-ui-components';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-shared';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies';
import { CurrencyUnit } from '@noah-labs/shared-schema-gql';
import type { FiatAmount as TpFiatAmount } from '@noah-labs/shared-schema-gql';
import { isUndefinedOrNull, safeBN } from '@noah-labs/shared-util-vanilla';
import { z } from 'zod';
import { maxSafetyAmount } from './amounts';

type TpGetLnReceiveSchema = {
  fiatCurrency: TpFiatCurrency | undefined;
  maxLnSingleSendFiat: TpFiatAmount | undefined | null;
};
export function getLnReceiveSchema({
  fiatCurrency,
  maxLnSingleSendFiat,
}: TpGetLnReceiveSchema): z.ZodSchema {
  return z.object({
    cryptoAmount: z
      .string()
      .optional()
      .superRefine((value, ctx) => {
        if (
          isUndefinedOrNull(maxLnSingleSendFiat) ||
          isUndefinedOrNull(maxLnSingleSendFiat.CryptoAmounts)
        ) {
          ctx.addIssue({ code: z.ZodIssueCode.custom, message: ' ' });
          return;
        }
        const cryptoAmount = maxLnSingleSendFiat.CryptoAmounts[0];
        const AmountSlot = (
          <CryptoAmount
            amount={cryptoAmount.Amount}
            currency={cryptoCurrencyFromCode(cryptoAmount.Currency)}
            currencyUnit={CurrencyUnit.Default}
          />
        );

        if (safeBN(value).gt(cryptoAmount.Amount)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: (
              <span>
                Maximum amount for a single lightning transaction is {AmountSlot}. Consider using an
                onchain transfer
              </span> // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) as any, // https://github.com/colinhacks/zod/issues/3212
          });
        }
      }),
    description: z.string().optional(),
    fetchedAt: z.string().optional(),
    fiatAmount: maxSafetyAmount(fiatCurrency),
    price: z.string().optional(),
    primaryAmount: z.string().optional(),
    secondaryAmount: z.string().optional(),
  });
}
