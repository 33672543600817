import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export function getFirstDayOfNMonthsFromNow(n: number): Date {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + n);
}

export function isInThePast(utcDate: string): boolean {
  return dayjs(utcDate).isBefore(dayjs.utc());
}

function isSameDay(date: Date, offset: number): boolean {
  const now = new Date();
  const d = new Date(now.setDate(now.getDate() + offset));
  return (
    date.getDate() === d.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
}

const dateOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};
export function formatDate(
  timestamp: number | string,
  options: Intl.DateTimeFormatOptions = dateOptions,
): string {
  const date = new Date(timestamp);
  if (isSameDay(date, 0)) {
    return 'Today';
  }
  if (isSameDay(date, -1)) {
    return 'Yesterday';
  }
  const formattedDate = date.toLocaleString(undefined, options);
  return formattedDate;
}

const timeOptions: Intl.DateTimeFormatOptions = {
  timeStyle: 'short',
};
export function formatTime(timestamp: number | string): string {
  const date = new Date(timestamp);
  const formattedTime = date.toLocaleTimeString(undefined, timeOptions);
  return formattedTime;
}
