import type { SVGProps } from 'react';

export function SvgGoogle(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 23 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.105 5.5c1.54 0 3.032.539 4.21 1.524l3.298-3.114a11.1 11.1 0 0 0-4.442-2.48 11.2 11.2 0 0 0-5.092-.246 11.1 11.1 0 0 0-4.664 2.04 11 11 0 0 0-3.25 3.894L5.884 9.96a6.5 6.5 0 0 1 2.384-3.225A6.6 6.6 0 0 1 12.105 5.5"
        fill="#D94F3D"
      />
      <path
        d="M5.548 12a6.4 6.4 0 0 1 .336-2.04L2.165 7.12a10.89 10.89 0 0 0 0 9.764l3.72-2.842a6.4 6.4 0 0 1-.337-2.04Z"
        fill="#F2C042"
      />
      <path
        d="M22.748 10H12.155v4.5h6.003a5.37 5.37 0 0 1-2.298 3.081l3.69 2.82c2.358-2.098 3.744-5.51 3.198-10.4Z"
        fill="#5085ED"
      />
      <path
        d="M15.859 17.581a7.1 7.1 0 0 1-3.754.92 6.6 6.6 0 0 1-3.837-1.235 6.5 6.5 0 0 1-2.384-3.225l-3.719 2.841a11.05 11.05 0 0 0 4.092 4.462A11.2 11.2 0 0 0 12.105 23c2.72.074 5.371-.852 7.444-2.6z"
        fill="#57A75C"
      />
    </svg>
  );
}
