import { getFeatureCurrencies } from '@noah-labs/fe-shared-data-access-user';
import {
  sortFilterWithFallback,
  useAccountsQueryPoll,
} from '@noah-labs/fe-shared-data-access-wallet';
import { getAccountsUi } from '@noah-labs/fe-shared-ui-shared';
import { Feature } from '@noah-labs/shared-schema-gql';
import { useFeature } from './useFeature';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useUserAccountsReceive() {
  const { feature: depositFF } = useFeature(Feature.Deposit);
  const { feature: lnInvoiceFF } = useFeature(Feature.LnInvoice);

  return useAccountsQueryPoll(undefined, {
    select: (data) =>
      getAccountsUi(
        sortFilterWithFallback(data.accounts.items, {
          currencies: getFeatureCurrencies([depositFF, lnInvoiceFF]),
          isFeatureEnabled: Boolean(depositFF?.Enabled || lnInvoiceFF?.Enabled),
        }),
      ),
  });
}
