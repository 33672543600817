import { btcToSats, truncateAmount } from '@noah-labs/shared-currencies';
import { CurrencyUnit } from '@noah-labs/shared-schema-gql';
import type { TpCryptoCurrencyUI } from '../types';

type PpGetCryptoUnitAmount = {
  amount: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  cryptoUnit: CurrencyUnit | undefined;
};

export type TpCryptoUnitAmount = {
  amountBtc: string;
  amountUnit: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  unit?: CurrencyUnit;
};

export function getCryptoUnitAmount({
  amount,
  cryptoCurrency,
  cryptoUnit,
}: PpGetCryptoUnitAmount): TpCryptoUnitAmount {
  let payload: TpCryptoUnitAmount;

  switch (cryptoUnit) {
    case CurrencyUnit.SATS: {
      payload = {
        amountBtc: amount,
        amountUnit: btcToSats(amount),
        cryptoCurrency,
        unit: CurrencyUnit.SATS,
      };
      break;
    }

    case CurrencyUnit.Default:
    default: {
      payload = {
        amountBtc: truncateAmount({
          amount,
          decimalPlaces: cryptoCurrency.decimals,
        }),
        amountUnit: truncateAmount({
          amount,
          decimalPlaces: cryptoCurrency.decimals,
        }),
        cryptoCurrency,
        unit: CurrencyUnit.Default,
      };
    }
  }

  return payload;
}
