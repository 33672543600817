import { useFiatPaymentMethodsQuery } from '@noah-labs/fe-shared-data-access-wallet';
import { mapPaymentCards } from '@noah-labs/fe-shared-feature-wallet';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import { useBackHijack } from '../../../hooks/useBackHijack';
import { PaymentMethodsScene } from '../scenes/PaymentMethods';

export function PaymentMethods(): React.ReactElement {
  const { backTo, goBack } = useBackHijack(userRoutes.settings.base.path);
  const { data: paymentCards } = useFiatPaymentMethodsQuery(undefined, {
    select: mapPaymentCards,
  });

  return (
    <PaymentMethodsScene
      backTo={backTo}
      goBack={goBack}
      pageTitle={userRoutes.settings.paymentMethods.title}
      paymentCards={paymentCards || []}
    />
  );
}
