import { duration } from '@noah-labs/shared-util-vanilla';
import type { UseQueryOptions } from 'react-query';
import { useAccountsQuery } from './wallet.generated';
import type { AccountsQuery, AccountsQueryVariables } from './wallet.generated';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useAccountsQueryPoll<TData = AccountsQuery, TError = unknown>(
  variables?: AccountsQueryVariables,
  options?: UseQueryOptions<AccountsQuery, TError, TData>,
) {
  return useAccountsQuery(variables, {
    refetchInterval: duration.seconds(3),
    ...options,
  });
}
