import type { TpUseQueryResultReplacedData } from '@noah-labs/fe-shared-data-access-shared';
import { truncateAmount } from '@noah-labs/shared-currencies';
import type { TpAmount } from '@noah-labs/shared-currencies';
import { safeBN } from '@noah-labs/shared-util-vanilla';
import { useCryptoPrice } from './useCryptoPrice';
import type { PpUseCryptoPrice, TpMarketOrLiquidityPriceQuery } from './useCryptoPrice';

type TpFiatPrice = {
  fetchedAt: string;
  fiatAmount: string;
  price: string;
};
type TpCryptoPrice = {
  cryptoAmount: string;
  fetchedAt: string;
  price: string;
};

export type PpUseCalculateCryptoFromFiat = PpUseCryptoPrice & {
  fiatAmount: TpAmount;
};
export type PpUseCalculateFiatFromCrypto = PpUseCryptoPrice & {
  cryptoAmount: TpAmount;
};

export function useCalculateFiatFromCrypto({
  cryptoAmount,
  cryptoCurrency,
  fiatCurrency,
  options,
  priceProvider,
  slippage,
}: PpUseCalculateFiatFromCrypto): TpUseQueryResultReplacedData<
  TpMarketOrLiquidityPriceQuery,
  TpFiatPrice
> {
  const { data, ...response } = useCryptoPrice({
    cryptoCurrency,
    fiatCurrency,
    options,
    priceProvider,
    slippage,
  });

  if (!data) {
    return {
      ...response,
      data,
    };
  }

  const fiatAmountBN = safeBN(cryptoAmount).multipliedBy(data.price);
  const fiatAmount = truncateAmount({
    amount: fiatAmountBN.toString(),
    decimalPlaces: fiatCurrency?.decimals,
  });

  return {
    ...response,
    data: {
      ...data,
      fiatAmount,
    },
  };
}

export function useCalculateCryptoFromFiat({
  cryptoCurrency,
  fiatAmount,
  fiatCurrency,
  options,
  priceProvider,
  slippage,
}: PpUseCalculateCryptoFromFiat): TpUseQueryResultReplacedData<
  TpMarketOrLiquidityPriceQuery,
  TpCryptoPrice
> {
  const { data, ...response } = useCryptoPrice({
    cryptoCurrency,
    fiatCurrency,
    options,
    priceProvider,
    slippage,
  });

  if (!data) {
    return {
      ...response,
      data,
    };
  }

  const cryptoAmountBN = safeBN(fiatAmount).dividedBy(data.price);
  const cryptoAmount = truncateAmount({
    amount: cryptoAmountBN.toString(),
    decimalPlaces: cryptoCurrency?.decimals,
  });

  return {
    ...response,
    data: {
      ...data,
      cryptoAmount,
    },
  };
}
