import React, { Fragment } from 'react';
import { DialogContentText } from '@mui/material';
import { LearnMoreButton } from '@noah-labs/fe-shared-ui-components';
import { webLinks } from '@noah-labs/shared-constants';

export function ReceiveBTCLightningContent(): React.ReactElement {
  return (
    <Fragment>
      <DialogContentText paragraph>
        The{' '}
        <LearnMoreButton href={webLinks.blog.lightningNetwork} target="_blank">
          Lightning Network
        </LearnMoreButton>{' '}
        is like the fast lane for Bitcoin transactions. It lets you send and receive Bitcoin super
        quickly without getting bogged down by high fees. Think of it as bypassing the usual traffic
        on the Bitcoin network for a speedier route.
      </DialogContentText>
      <DialogContentText paragraph>
        Your{' '}
        <LearnMoreButton href={webLinks.blog.lightningAddress} target="_blank">
          NOAH Lightning Address
        </LearnMoreButton>
        , on the other hand, works like an email address for Bitcoin. You can instantly send or
        receive Bitcoin over the Lightning Network just by using this address.
      </DialogContentText>
      <DialogContentText>
        With the NOAH app, you can zap Bitcoin to your friends instantly and without any fees. So
        invite your friends to use NOAH and experience the fast lane for Bitcoin transactions!
      </DialogContentText>
    </Fragment>
  );
}
