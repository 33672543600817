import { useCallback } from 'react';
import {
  updateUserInitCache,
  useUserProfileCreateMutation,
} from '@noah-labs/fe-shared-data-access-user';
import { useAuth } from '@noah-labs/fe-shared-feature-auth';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import type { TpUsernameForm } from '@noah-labs/fe-shared-ui-user';
import { UsernameScene, useUserError } from '@noah-labs/fe-shared-ui-user';
import { assetPaths } from '@noah-labs/shared-constants';
import { useQueryClient } from 'react-query';

export type PpUsername = {
  logoutTo: string;
  referralCode?: string | null;
  successTo: string;
  suffix: string;
};

export function Username({
  logoutTo,
  referralCode,
  successTo,
  suffix,
}: PpUsername): React.ReactElement {
  const { data: auth } = useAuth();
  const { push } = useRouter();
  const queryClient = useQueryClient();
  const { error, mutateAsync } = useUserProfileCreateMutation();

  const onSubmit = useCallback(
    async (values: TpUsernameForm): Promise<void> => {
      try {
        const avatarUrl = `${assetPaths.images}/arkonaut-${Math.floor(Math.random() * 6) + 1}.png`;
        const data = await mutateAsync({
          Input: {
            AvatarUrl: avatarUrl,
            Country: values.country,
            ReferrerReferral: auth?.referralCode ?? referralCode,
            UsernameDisplay: values.usernameDisplay,
          },
        });
        // Directly update the useUserInitQuery cache in react-query for - will cause that hook to rerender and cause auth routing to fire
        updateUserInitCache(queryClient, data.userProfileCreate);
        push(successTo);
      } catch (e) {
        // error is handled in useUserError
      }
    },
    [push, mutateAsync, queryClient, referralCode, auth?.referralCode, successTo],
  );

  const { ApiErrorScene } = useUserError(error);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <UsernameScene email={auth?.email} logoutTo={logoutTo} suffix={suffix} onSubmit={onSubmit} />
  );
}
