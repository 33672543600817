import React from 'react';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import {
  SceneHeader,
  SceneParagraph,
  SceneTitleMedium,
  SkeletonQuestList,
} from '@noah-labs/fe-shared-ui-components';
import type { QuestStatus, UserBadge } from '@noah-labs/shared-schema-gql';
import { isFalseyOrEmptyArray } from '@noah-labs/shared-util-vanilla';
import { shareButtons } from '../../../data/social';
import { getBadgeGridItem } from '../../../mappings/badgeGridItem';
import { ReferFriendsSocialShareCard } from '../../cards/ReferFriendsSocialShareCard';
import { QuestList } from '../../questList/QuestList';
import { BadgeGridItem } from '../badges/BadgeGridItem';

type PpQuestsTabPanel = {
  activeQuests: QuestStatus[] | undefined;
  completedBadges: UserBadge[] | undefined;
  referralUrl?: string | undefined;
};

const completedQuestTitle = 'You’ve completed all of our current quests!';
const defaultQuestTitle = 'Quests';

const completedQuestSubtitle =
  'Earning points is easy. The more you use the NOAH app, the more points you’ll earn. We’ll be adding more ways to earn NOAH points so check back soon!';
const defaultQuestSubtitle =
  'Every quest takes you closer to epic rewards. Dive in and let the adventure begin!';

export function QuestsTabPanel({
  activeQuests,
  completedBadges,
  referralUrl,
}: PpQuestsTabPanel): React.ReactElement {
  const completedBadgesList = completedBadges
    ?.map((quest) => getBadgeGridItem(quest))
    .filter(Boolean);

  const completedAllQuests = activeQuests && activeQuests.length === 0;

  return (
    <React.Fragment>
      <ReferFriendsSocialShareCard referralUrl={referralUrl} shareButtons={shareButtons} />
      <SceneHeader>
        <SceneTitleMedium>
          {completedAllQuests ? completedQuestTitle : defaultQuestTitle}
        </SceneTitleMedium>
        <SceneParagraph>
          {completedAllQuests ? completedQuestSubtitle : defaultQuestSubtitle}
        </SceneParagraph>
      </SceneHeader>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Box mb={3}>
          {activeQuests ? <QuestList quests={activeQuests} /> : <SkeletonQuestList rows={3} />}
        </Box>

        {!isFalseyOrEmptyArray(completedBadgesList) && (
          <React.Fragment>
            <Stack mb={1.5} mt={4} spacing={1}>
              <Typography color="text.header" variant="subHeadingM">
                Completed Quests
              </Typography>
              <SceneParagraph>View the quests you have completed below.</SceneParagraph>
            </Stack>
            <Grid container columnSpacing={2} mt={2} rowSpacing={2}>
              {completedBadgesList.map((item) => (
                <Grid key={item?.badgeId} item sm={4} xs={6}>
                  <BadgeGridItem badge={item} />
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        )}
      </Container>
    </React.Fragment>
  );
}
