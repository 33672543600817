import React from 'react';
import { CurrentBalanceData } from '@noah-labs/fe-shared-feature-user';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import { useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { AccountScene } from '@noah-labs/fe-shared-ui-wallet';
import { getReceiveCryptoRoute, walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { ActionsMenuData } from '../../components/ActionsMenuData';
import { TransactionsData } from '../../components/TransactionsData';

export function AccountController(): React.ReactElement {
  const { cryptoCurrency, params } = useWalletParams();

  const receivePath = getReceiveCryptoRoute(cryptoCurrency.requireNetworkSelection.receive);

  return (
    <AccountScene
      ActionsMenuSlot={
        <ActionsMenuData
          customRoutes={{
            receive: generatePath(receivePath, params),
            send: generatePath(walletRoutes().send.address.base.path, params),
          }}
        />
      }
      BalanceSlot={<CurrentBalanceData />}
      TransactionsSlot={<TransactionsData />}
    />
  );
}
