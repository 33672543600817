import React from 'react';
import { css } from '@emotion/react';
import {
  caseSensitiveGeneratePath,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemEndContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
} from '@noah-labs/fe-shared-ui-components';
import type { TpTransactionUi } from '@noah-labs/fe-shared-ui-shared';
import { AvatarWithTransactionStatus } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { getEnvCurrencyCode } from '@noah-labs/shared-currencies';
import { TransactionStatus } from '@noah-labs/shared-schema-gql';
import { getConfig } from '../config';
import { getDefaults } from '../utils/getDefaults';

type PpTransactionListItem = TpTransactionUi & {
  PrimaryAmountSlot: React.ReactElement;
  SecondaryAmountSlot: React.ReactElement;
};

const defaults = getDefaults();

export function TransactionListItem({
  cryptoCurrency,
  id,
  mainPrimaryContent,
  mainSecondaryContent,
  noahID,
  PrimaryAmountSlot,
  publicID,
  SecondaryAmountSlot,
  status,
  type,
  withdrawOrderID,
}: PpTransactionListItem): React.ReactElement {
  const { isProd } = getConfig();

  let transactionDetailUrl;
  if (id) {
    transactionDetailUrl = caseSensitiveGeneratePath(walletRoutes().account.transactions.path, {
      // TODO (cs): we should have a function that parses the source and destination
      // account IDs to get the account type (and could also provide a direction fallback)
      accountType: defaults.accountType.toLowerCase(),
      currencyCode: getEnvCurrencyCode(cryptoCurrency.code, isProd).toLowerCase(),
      id,
    });
  }

  const styles = {
    endContent: css`
      /* no shrinking */
      flex: 1 0 auto;
      text-decoration: ${status === TransactionStatus.Failed ? 'line-through' : undefined};
    `,
    startContent: css`
      /* allow shrinking */
      flex: 1 1 auto;
      overflow: hidden;
    `,
  };

  return (
    <ListItem
      data-qa="txn"
      data-qa-noah-id={noahID}
      data-qa-order-id={withdrawOrderID}
      data-qa-public-id={publicID}
      data-qa-status={status}
    >
      <ListItemButton data-qa="txn-btn" href={transactionDetailUrl}>
        <ListItemContent>
          <ListItemIcon>
            <AvatarWithTransactionStatus
              avatarSize={4}
              badgeSize={2.25}
              svg={cryptoCurrency.svg}
              transactionStatus={status}
              transactionType={type}
            />
          </ListItemIcon>
          <ListItemStartContent css={styles.startContent}>
            <ListItemPrimaryText noWrap>{mainPrimaryContent}</ListItemPrimaryText>
            <ListItemSecondaryText noWrap>{mainSecondaryContent}</ListItemSecondaryText>
          </ListItemStartContent>
          <ListItemEndContent css={styles.endContent}>
            <ListItemPrimaryText noWrap>{PrimaryAmountSlot}</ListItemPrimaryText>
            <ListItemSecondaryText noWrap>{SecondaryAmountSlot}</ListItemSecondaryText>
          </ListItemEndContent>
        </ListItemContent>
      </ListItemButton>
    </ListItem>
  );
}
