import {
  AuthError,
  ForgottenPassword,
  ForgottenPasswordNewPassword,
  ForgottenPasswordWrapper,
  SignedOut,
  SignIn,
  SignOut,
  SignUp,
  VerifyEmail,
} from '@noah-labs/fe-shared-feature-auth';
import { HelpIconButtonData } from '@noah-labs/fe-shared-feature-help';
import { ForgottenPasswordSuccessScene } from '@noah-labs/fe-shared-ui-auth';
import { AppContainer, Switch404 } from '@noah-labs/fe-shared-ui-components';
import { authRoutes } from '@noah-labs/fe-shared-util-routes';
import type { AwsCaptchaApi, SardineFlows } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import type { PpAccessControlData } from './AccessControlData';

export type PpRouter = {
  AccessControlData: (props: PpAccessControlData) => React.ReactElement;
  authedPath: string;
  captcha: AwsCaptchaApi | undefined;
  helpButton?: React.ReactNode;
  referralCode: string | null;
  updateGuestSardineConfig: (flow: SardineFlows) => string;
};

export function Router({
  AccessControlData,
  authedPath,
  captcha,
  helpButton,
  referralCode,
  updateGuestSardineConfig,
}: PpRouter): React.ReactElement {
  return (
    <Switch404>
      <Route exact path={authRoutes.signUp.path}>
        <AccessControlData needsAuthStatus={['guest']} redirectAuthStatus={authedPath}>
          <AppContainer headTitle={authRoutes.signUp.title}>
            <SignUp
              captcha={captcha}
              helpButton={helpButton}
              referralCode={referralCode}
              updateGuestSardineConfig={updateGuestSardineConfig}
            />
          </AppContainer>
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.signIn.path}>
        <AccessControlData needsAuthStatus={['guest']} redirectAuthStatus={authedPath}>
          <AppContainer headTitle={authRoutes.signIn.title}>
            <SignIn captcha={captcha} helpButton={helpButton} />
          </AppContainer>
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.signOut.path}>
        {/* TODO (cs): this is current behaviour, maybe this should only be available to pbGroup though, need to test */}
        <SignOut />
      </Route>
      <Route exact path={authRoutes.signedOut.path}>
        <AccessControlData needsAuthStatus={['guest']}>
          <AppContainer>
            <SignedOut />
          </AppContainer>
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.verify.path}>
        <AccessControlData needsAuthStatus={['authenticatedNotVerified']} redirectVerify={false}>
          <AppContainer headTitle={authRoutes.verify.title}>
            <VerifyEmail helpButton={helpButton} />
          </AppContainer>
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.recovery.path}>
        <AccessControlData needsAuthStatus={['guest']}>
          <ForgottenPasswordWrapper>
            <ForgottenPassword
              helpButton={helpButton}
              returnTo={authRoutes.newPasswordEntry.path}
            />
          </ForgottenPasswordWrapper>
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.newPasswordEntry.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <AppContainer headTitle={authRoutes.newPasswordEntry.title}>
            <ForgottenPasswordNewPassword helpButton={<HelpIconButtonData />} />
          </AppContainer>
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.newPasswordComplete.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <AppContainer headTitle={authRoutes.newPasswordComplete.title}>
            <ForgottenPasswordSuccessScene helpButton={<HelpIconButtonData />} />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route exact path={authRoutes.error.path}>
        <AuthError />
      </Route>
    </Switch404>
  );
}
