import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import confettiEffect from '@noah-labs/fe-shared-ui-assets/lotties/confetti-lottie-data.json';
import { CircleTickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CircleTickIcon';
import {
  AppContainer,
  AppFooter,
  AppMain,
  cssMixins,
  GetInTouchLink,
  PrimaryButton,
  SceneIcon,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import ReactLottie from 'lottie-react';
import { Helmet } from 'react-helmet';
import { getConfig } from '../config';

export type PpCompleteSuccessScene = {
  enableAnimation?: boolean;
};

export function CompleteSuccessScene({
  enableAnimation = getConfig().settings.enableLoaderAnimations,
}: PpCompleteSuccessScene): React.ReactElement {
  const theme = useTheme();
  const styles = {
    animation: css`
      position: absolute;
      ${cssMixins.stretchTop};
      height: 100%;
      width: 100%;
      overflow: hidden;
      z-index: ${theme.zIndex.floatingBar};
      pointer-events: none;
    `,
  };

  return (
    <AppContainer>
      <Helmet>
        <title>Account verification successful</title>
      </Helmet>
      <AppMain>
        <SceneMain dataQa="completeSuccess">
          <SceneIcon>
            <CircleTickIcon color="success" />
          </SceneIcon>
          <SceneTitleLarge>Account verification successful</SceneTitleLarge>
          <SceneParagraph>
            Your account has been successfully verified and you now have access to all features.
          </SceneParagraph>
          <SceneParagraph>
            If you need to change your KYC information, please <GetInTouchLink />.
          </SceneParagraph>
          {enableAnimation && (
            <ReactLottie
              animationData={confettiEffect}
              css={styles.animation}
              loop={false}
              open={false}
              rendererSettings={{
                // makes the lottie to cover the entire viewport
                // https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio
                preserveAspectRatio: 'xMidYMid slice',
              }}
            />
          )}
        </SceneMain>
      </AppMain>
      <AppFooter solidFade>
        <PrimaryButton href={walletRoutes().base.path}>Close</PrimaryButton>
      </AppFooter>
    </AppContainer>
  );
}
