import { useCallback } from 'react';
import type {
  TpApplePaySessionData,
  TpCheckoutTokenResponse,
} from '@noah-labs/fe-shared-data-access-cko';
import { useRequestPaymentToken as useRequestCkoPaymentToken } from '@noah-labs/fe-shared-data-access-cko';
import { logger } from '@noah-labs/shared-logger/browser';
import type { CountryCode } from '@noah-labs/shared-schema-gql';
import { useMutation } from 'react-query';
import type { TpSessionValues } from '../types';

const supportedNetworks = ['visa', 'masterCard', 'discover', 'jcb'];
const merchantCapabilities: ApplePayJS.ApplePayMerchantCapability[] = [
  'supports3DS',
  'supportsEMV',
  'supportsCredit',
  'supportsDebit',
];

const baseRequest = {
  merchantCapabilities,
  supportedNetworks,
};

// https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_on_the_web_version_history/apple_pay_on_the_web_version_4_release_notes
export const version = 4;

type TpApplePaySessionValues = TpSessionValues & {
  countryCode: CountryCode | null | undefined;
};

export type TpApplePay = {
  getApplePayCkoToken: (
    sessionValues: TpApplePaySessionValues,
  ) => Promise<TpCheckoutTokenResponse | undefined>;
  isReady: boolean;
  paymentError: ApplePayError | unknown | null;
};

export function useApplePay(
  fetchMerchantSession?: (url: string) => Promise<string | undefined>,
): TpApplePay {
  const { error: ckoPaymentTokenError, mutateAsync: getCkoPaymentToken } =
    useRequestCkoPaymentToken();

  const handleStartSession = useCallback(
    ({
      amount,
      countryCode,
      fiatCurrencyCode,
    }: TpApplePaySessionValues): Promise<TpApplePaySessionData | undefined> =>
      new Promise<TpApplePaySessionData | undefined>((resolve, reject) => {
        if (!fetchMerchantSession) {
          logger.error('missing merchant session validation');
          return;
        }

        if (!countryCode) {
          logger.error('missing country code');
          return;
        }

        const paymentRequest: ApplePayJS.ApplePayPaymentRequest = {
          ...baseRequest,
          countryCode,
          currencyCode: fiatCurrencyCode,
          total: {
            amount,
            label: 'Total',
          },
        };

        logger.debug('Apple Pay payment request', paymentRequest);
        const session = new ApplePaySession(version, paymentRequest);

        session.onvalidatemerchant = (event: ApplePayJS.ApplePayValidateMerchantEvent): void => {
          async function completeMerchantValidation(): Promise<void> {
            const merchantSession = await fetchMerchantSession?.(event.validationURL);
            if (!merchantSession) {
              logger.error('merchant session not valid');
              return;
            }
            session.completeMerchantValidation(JSON.parse(merchantSession));
          }

          void completeMerchantValidation();
        };

        session.onpaymentauthorized = (event: ApplePayJS.ApplePayPaymentAuthorizedEvent): void => {
          session.completePayment(ApplePaySession.STATUS_SUCCESS);
          resolve(event.payment.token.paymentData as TpApplePaySessionData);
        };

        session.oncancel = (): void => {
          reject(new ApplePayError('unknown', undefined, 'Apple Pay session cancelled'));
        };

        session.begin();
      }),
    [fetchMerchantSession],
  );

  /**
   * Starts the Apple Pay user session.
   * @param sessionValues - values to initiate the session
   * @returns payment data. Contains the token to be exchanged with Checkout
   */
  const { error: sessionError, mutateAsync: startSession } = useMutation<
    TpApplePaySessionData | undefined,
    ApplePayError,
    TpApplePaySessionValues,
    unknown
  >(['applepay/GetPaymentToken'], handleStartSession);

  /**
   * Requests a payment token from Checkout
   */
  const getApplePayCkoToken = useCallback(
    async (
      sessionValues: TpApplePaySessionValues,
    ): Promise<TpCheckoutTokenResponse | undefined> => {
      try {
        const tokenData = await startSession(sessionValues);
        if (!tokenData) {
          return undefined;
        }

        const ckoPaymentToken = await getCkoPaymentToken({
          token_data: tokenData,
          type: 'applepay',
        });
        return ckoPaymentToken.data;
      } catch (err) {
        if (err instanceof ApplePayError) {
          throw new Error(err.message);
        }
        throw new Error('Sorry, there was an issue processing your card.');
      }
    },
    [startSession, getCkoPaymentToken],
  );

  return {
    getApplePayCkoToken,
    isReady: Boolean(
      window.ApplePaySession &&
        ApplePaySession.supportsVersion(version) &&
        ApplePaySession.canMakePayments(),
    ),
    paymentError: sessionError || ckoPaymentTokenError,
  };
}
