import React, { useRef } from 'react';
import {
  InfoTooltip,
  List,
  ListItem,
  ListItemContent,
  ListItemEndContent,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
} from '@noah-labs/fe-shared-ui-components';
import type {
  TpCryptoCurrencyUI,
  TpCryptoNetworkUI,
  TpDialogToggle,
} from '@noah-labs/fe-shared-ui-shared';
import { CurrencyCode } from '@noah-labs/shared-schema-gql';
import { BitcoinNetworkDialog } from '../dialogs/BitcoinNetworkDialog';
import { UsdStablecoinsNetworkDialog } from '../dialogs/UsdStablecoinsNetworkDialog';

export type PpNetworkItem = {
  cryptoCurrency: TpCryptoCurrencyUI;
  cryptoNetwork: TpCryptoNetworkUI;
};

export function NetworkItem({ cryptoCurrency, cryptoNetwork }: PpNetworkItem): React.ReactElement {
  const networkInfoDialog = useRef<TpDialogToggle>(null);

  let Dialog: React.ReactElement;

  switch (cryptoCurrency.code) {
    case CurrencyCode.USDC:
    case CurrencyCode.USDC_TEST:
    case CurrencyCode.USDT:
    case CurrencyCode.USDT_TEST:
      Dialog = (
        <UsdStablecoinsNetworkDialog ref={networkInfoDialog} currencyCode={cryptoCurrency.code} />
      );
      break;

    case CurrencyCode.BTC:
    case CurrencyCode.BTC_TEST:
    default:
      Dialog = <BitcoinNetworkDialog ref={networkInfoDialog} />;
  }

  return (
    <React.Fragment>
      <List disablePadding>
        <ListItem>
          <ListItemContent padding={0}>
            <ListItemStartContent>
              <ListItemSecondaryText>
                <InfoTooltip onClick={(): void => networkInfoDialog.current?.toggle()}>
                  Network
                </InfoTooltip>
              </ListItemSecondaryText>
            </ListItemStartContent>
            <ListItemEndContent>
              <ListItemPrimaryText>{cryptoNetwork.label}</ListItemPrimaryText>
            </ListItemEndContent>
          </ListItemContent>
        </ListItem>
      </List>
      {Dialog}
    </React.Fragment>
  );
}
