import React from 'react';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { getReferralUrl } from '../../../utils/utils';
import { routes } from '../routes';
import { InviteFriendsScene } from '../scenes/InviteFriends';

export function InviteFriends(): React.ReactElement {
  const { data: userData } = useUserInitUi();
  return (
    <InviteFriendsScene
      pageTitle={routes.inviteFriends.title}
      referralUrl={getReferralUrl(userData?.userProfile.UsernameDisplay)}
    />
  );
}
