import type { SVGProps } from 'react';

export function SvgTelegram(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 21"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m22.38.133-21.26 8.2c-1.45.582-1.442 1.391-.264 1.752l5.302 1.655 2.029 6.22c.246.68.125.95.84.95.551 0 .796-.25 1.103-.551l2.651-2.579 5.516 4.075c1.014.56 1.747.27 2-.942l3.62-17.06c.37-1.487-.566-2.16-1.537-1.72M6.99 11.36l11.951-7.54c.597-.362 1.144-.167.695.231L9.402 13.284l-.398 4.25z"
        fill="currentColor"
      />
    </svg>
  );
}
