import React from 'react';
import { generatePath, Switch404 } from '@noah-labs/fe-shared-ui-components';
import { Feature } from '@noah-labs/shared-schema-gql';
import { Redirect, Route } from 'react-router-dom';
import { AccessControlData } from '../auth/AccessControlData';
import { BadgeData } from './controllers/BadgeData';
import { InviteFriends } from './controllers/InviteFriends';
import { QuestData } from './controllers/QuestData';
import { Rewards } from './controllers/Rewards';
import { routes } from './routes';
import { TpRewardsTabs } from './types';

export function Router(): React.ReactElement {
  return (
    <Switch404>
      {/* Path is /rewards, redirect to the the quests tab */}
      <Route
        exact
        // eslint-disable-next-line react/no-unstable-nested-components
        component={(): React.ReactElement => (
          <Redirect
            to={generatePath(routes.tabs.path, {
              tab: TpRewardsTabs.Quests,
            })}
          />
        )}
        path={routes.base.path}
      />
      <Route exact path={routes.tabs.path}>
        <AccessControlData needsAuthStatus={['authenticated']} needsFeature={[Feature.UIRewards]}>
          <Rewards />
        </AccessControlData>
      </Route>
      <Route exact path={routes.badge.path}>
        <AccessControlData needsAuthStatus={['authenticated']} needsFeature={[Feature.UIRewards]}>
          <BadgeData />
        </AccessControlData>
      </Route>
      <Route exact path={routes.quest.path}>
        <AccessControlData needsAuthStatus={['authenticated']} needsFeature={[Feature.UIRewards]}>
          <QuestData />
        </AccessControlData>
      </Route>
      <Route exact path={routes.inviteFriends.path}>
        <AccessControlData needsAuthStatus={['authenticated']} needsFeature={[Feature.UIRewards]}>
          <InviteFriends />
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
