import { useDestinations } from '@noah-labs/fe-shared-data-access-analytics';
import { ConsentScene, useUserError } from '@noah-labs/fe-shared-ui-user';
import { getConfig } from '../config';
import { useConsentOnSubmit } from '../hooks/useConsentOnSubmit';
import { useUserInitUi } from '../hooks/useUserInitUi';

type PpConsent = {
  logoutTo?: string;
  nextAction?: () => void;
  nextActionDirty: () => void;
};

export function Consent({ logoutTo, nextAction, nextActionDirty }: PpConsent): React.ReactElement {
  const { analytics, segment } = getConfig();

  const { data: userData } = useUserInitUi();
  const { data: { groupedDestinations } = {} } = useDestinations([
    segment.writeKey,
    analytics.cdnUrl,
  ]);

  const { error, handleConsentSubmit } = useConsentOnSubmit({
    groupedDestinations,
    nextAction,
    nextActionDirty,
  });

  const { ApiErrorScene } = useUserError(error);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <ConsentScene
      destinations={groupedDestinations}
      email={userData?.userProfile.Email}
      logoutTo={logoutTo}
      userSettingsConsent={userData?.userSettingsConsent}
      variant="settings"
      onSubmit={handleConsentSubmit}
    />
  );
}
