import { Fragment, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, IconButton, Stack, Typography } from '@mui/material';
import { CloseCircleIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CloseCircleIcon';
import { CodeIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CodeIcon';
import {
  AppFooter,
  AppMain,
  AvatarIconFromSvg,
  FormGrid,
  FormItem,
  InputField,
  LearnMoreButton,
  List,
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  ListSection,
  PrimaryButton,
  SceneMain,
  TextWithIcon,
  TruncatedText,
} from '@noah-labs/fe-shared-ui-components';
import {
  cryptoCurrencyFromCode,
  cryptoNetworkFromId,
  useDebounce,
} from '@noah-labs/fe-shared-ui-shared';
import type { CurrencyCode } from '@noah-labs/shared-schema-gql';
import type { UseFormSetError } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { AddressSummaryCard } from '../components/cards/AddressSummaryCard';
import { SkeletonAddressSummaryCard } from '../components/cards/SkeletonAddressSummaryCard';
import type { TpRecentAddresses } from '../types';
import { getAddressErrorMessageElement } from '../utils/errors';
import { getAddressCurrencyInfo } from '../utils/getAddressCurrencyInfo';
import type { TpAddressData } from '../utils/parseAnyAddressData';

const defaultValues = { address: '' };
export type TpAddressForm = typeof defaultValues;

const addressValidationDelayMs = 1200;

const addressFormSchema = z.object({
  address: z.string().min(1, 'Address is a required field'),
});

export type PpAddressManualScene = {
  addressData: TpAddressData | undefined;
  currencyCode: CurrencyCode;
  handleAddress: (
    code: string,
    currencyCode: CurrencyCode,
    setError: UseFormSetError<TpAddressForm>,
  ) => void;
  handleNextUrl: () => void;
  handleRedirectToScan: () => void;
  isLoading: boolean;
  recentAddresses: TpRecentAddresses[];
  scannedAddress: string;
  scannerUnavailable: boolean;
};

export function AddressManualScene({
  addressData,
  currencyCode,
  handleAddress,
  handleNextUrl,
  handleRedirectToScan,
  isLoading,
  recentAddresses,
  scannedAddress,
  scannerUnavailable,
}: PpAddressManualScene): React.ReactElement {
  const debounce = useDebounce();

  const methods = useForm<TpAddressForm>({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(addressFormSchema),
  });
  const {
    formState: { errors },
    setError,
    setValue,
    watch,
  } = methods;

  const addressValue = watch('address');

  const ScanIconButton = scannerUnavailable ? undefined : (
    <IconButton
      aria-label="scan address"
      disabled={isLoading}
      edge="end"
      onClick={handleRedirectToScan}
    >
      <CodeIcon color="primary" />
    </IconButton>
  );

  const ClearIconButton = addressValue ? (
    <IconButton
      aria-label="clear address"
      disabled={isLoading}
      edge="end"
      onClick={(): void => setValue('address', '', { shouldValidate: true })}
    >
      <CloseCircleIcon color={isLoading ? 'disabled' : 'primary'} />
    </IconButton>
  ) : undefined;

  const { label, learnMoreLink, subTitle } = getAddressCurrencyInfo(currencyCode);
  const helperText = getAddressErrorMessageElement(errors.address?.type);

  const showAlertAndRecentAddresses = !isLoading && !addressData && !helperText;
  const showAlert = showAlertAndRecentAddresses && subTitle && learnMoreLink;

  useEffect(() => {
    debounce(() => handleAddress(addressValue, currencyCode, setError), addressValidationDelayMs);
  }, [addressValue, currencyCode, handleAddress, setError, debounce]);

  useEffect(() => {
    if (!scannedAddress) {
      return;
    }
    setValue('address', scannedAddress);
  }, [scannedAddress, setValue]);

  return (
    <Fragment>
      <AppMain>
        <SceneMain dataQa="enter-address">
          <Stack spacing={2}>
            <FormProvider {...methods}>
              <form id="addressForm">
                <FormGrid>
                  <FormItem>
                    <InputField
                      fullWidth
                      required
                      aria-label={label}
                      dataQa="address"
                      disabled={isLoading}
                      endSlot={ClearIconButton || ScanIconButton}
                      helperText={helperText}
                      label="To"
                      name="address"
                      placeholder={label}
                    />
                  </FormItem>
                </FormGrid>
              </form>
            </FormProvider>

            {showAlert && (
              <Alert severity="warning">
                {subTitle} <LearnMoreButton href={learnMoreLink} target="_blank" />
              </Alert>
            )}

            {scannerUnavailable && (
              <Alert data-qa="no-scanner-alert" severity="info">
                Camera unavailable, please enter the text address manually.
              </Alert>
            )}

            {isLoading && <SkeletonAddressSummaryCard />}

            {!isLoading && addressData && (
              <Fragment>
                <AddressSummaryCard
                  address={addressData.address}
                  cryptoCurrency={cryptoCurrencyFromCode(addressData.currencyCode)}
                  cryptoNetwork={addressData.network && cryptoNetworkFromId(addressData.network)}
                />
                <Typography color="text.light" variant="paragraphBodyS">
                  Compatible address found. The list of addresses you can send to is determined by
                  the currency and network.
                </Typography>
              </Fragment>
            )}
          </Stack>

          {showAlertAndRecentAddresses && recentAddresses.length > 0 && (
            <ListSection sx={{ mt: 4 }} title="Recent addresses" titleColor="text.dark">
              <List disablePadding data-qa="recent-addresses" spacing={1}>
                {recentAddresses.map(({ address, cryptoCurrency, cryptoNetwork }) => (
                  <ListItem key={address}>
                    <ListItemButton
                      data-qa={`address-${address}`}
                      onClick={(): void => {
                        setValue('address', address, { shouldValidate: true });
                      }}
                    >
                      <ListItemCard>
                        <ListItemContent>
                          <ListItemIcon>
                            <AvatarIconFromSvg size={3} svg={cryptoCurrency.svg} />
                          </ListItemIcon>
                          <ListItemStartContent>
                            <ListItemPrimaryText>
                              <TruncatedText prefix={12} text={address} />
                            </ListItemPrimaryText>
                            <ListItemSecondaryText>
                              <TextWithIcon>
                                <AvatarIconFromSvg size={1.5} svg={cryptoNetwork.svg} />
                                <span>Via {cryptoNetwork.label}</span>
                              </TextWithIcon>
                            </ListItemSecondaryText>
                          </ListItemStartContent>
                        </ListItemContent>
                      </ListItemCard>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </ListSection>
          )}
        </SceneMain>
      </AppMain>
      <AppFooter>
        <PrimaryButton
          color="primaryBrand"
          data-qa="continue-button"
          disabled={!addressData}
          form="addressForm"
          loading={isLoading}
          onClick={handleNextUrl}
        >
          Continue
        </PrimaryButton>
      </AppFooter>
    </Fragment>
  );
}
