//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgCloseCircle } from '../icons/CloseCircle';

export function CloseCircleIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>,
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgCloseCircle} data-qa="CloseCircleIcon">
      <svg />
    </SvgIcon>
  );
}
