import { forwardRef, useCallback } from 'react';
import { DialogContent, DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { TpPaymentMethodChange } from '@noah-labs/fe-shared-feature-wallet';
import {
  generatePath,
  List,
  ListItem,
  ListItemButton,
  ListItemCard,
  ListSection,
  LowDialog,
} from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle, TpPaymentCard } from '@noah-labs/fe-shared-ui-shared';
import {
  TpPaymentMethod,
  useRouter,
  useToggleDialog,
  useWalletParams,
} from '@noah-labs/fe-shared-ui-shared';
import { PaymentMethod } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { ManagePaymentMethodsLink } from '../../buttons/ManagePaymentMethodsLink';

export type PpPaymentMethodsDialog = {
  applePayEnabled: boolean;
  googlePayEnabled: boolean;
  handleChange: ({ card, type }: TpPaymentMethodChange) => void;
  initialOpen?: boolean;
  onSubmitCardDetailsRedirect: string;
  paymentCards?: TpPaymentCard[];
};

export const PaymentMethodsDialog = forwardRef<TpDialogToggle, PpPaymentMethodsDialog>(
  (
    {
      applePayEnabled,
      googlePayEnabled,
      handleChange,
      initialOpen,
      onSubmitCardDetailsRedirect,
      paymentCards,
    },
    ref,
  ) => {
    const theme = useTheme();
    const { open, toggle } = useToggleDialog({ initialOpen, ref });

    const { params } = useWalletParams();
    const { push } = useRouter();

    const onNewPaymentCard = useCallback((): void => {
      push({
        pathname: generatePath(walletRoutes().buy.payment.path, params),
        state: {
          successTo: onSubmitCardDetailsRedirect,
        },
      });
      toggle();
    }, [push, onSubmitCardDetailsRedirect, params, toggle]);

    return (
      <LowDialog dataQa="payment-methods" open={open} onClose={toggle}>
        <DialogTitle>Choose a payment method</DialogTitle>
        <DialogContent>
          <List
            disablePadding
            data-qa="payment-methods"
            spacing={1}
            sx={{ margin: theme.spacing(2, 0, 3) }}
          >
            {applePayEnabled && (
              <ListItem data-qa="apple-pay">
                <ListItemButton
                  onClick={(): void => handleChange({ type: TpPaymentMethod.ApplePay })}
                >
                  <ListItemCard>
                    <PaymentMethod method={TpPaymentMethod.ApplePay} />
                  </ListItemCard>
                </ListItemButton>
              </ListItem>
            )}

            {googlePayEnabled && (
              <ListItem data-qa="google-pay">
                <ListItemButton
                  onClick={(): void => handleChange({ type: TpPaymentMethod.GooglePay })}
                >
                  <ListItemCard>
                    <PaymentMethod method={TpPaymentMethod.GooglePay} />
                  </ListItemCard>
                </ListItemButton>
              </ListItem>
            )}

            <ListItem data-qa="new-payment-card">
              <ListItemButton onClick={onNewPaymentCard}>
                <ListItemCard>
                  <PaymentMethod method={TpPaymentMethod.Card} paymentCard={undefined} />
                </ListItemCard>
              </ListItemButton>
            </ListItem>
          </List>

          {paymentCards && (
            <ListSection
              title="Saved methods"
              titleColor="text.dark"
              TitleEndSlot={<ManagePaymentMethodsLink />}
            >
              <List disablePadding data-qa="saved-payment-methods" spacing={1}>
                {paymentCards.map((card, i) => (
                  <ListItem key={card.id} data-qa={`saved-card-${i}`}>
                    <ListItemButton
                      onClick={(): void =>
                        handleChange({
                          card,
                          type: TpPaymentMethod.Card,
                        })
                      }
                    >
                      <ListItemCard>
                        <PaymentMethod method={TpPaymentMethod.Card} paymentCard={card} />
                      </ListItemCard>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </ListSection>
          )}
        </DialogContent>
      </LowDialog>
    );
  },
);
PaymentMethodsDialog.displayName = 'PaymentMethodsDialog';
