import { useCallback } from 'react';
import { logger } from '@noah-labs/shared-logger/browser';
import { isAxiosError } from 'axios';
import { useOry } from '../data';
import type { SocialProvider } from '../types';
import { assignLocationAndWait } from '../utils';
import { isRedirectBrowserError, isSessionRefreshRequiredError } from '../utils/oryTypeGuards';

export function useOrySocialLinkCallback(): (provider: SocialProvider) => Promise<void> {
  const { ory } = useOry();

  return useCallback(
    async (provider) => {
      try {
        const { data: flow } = await ory.createBrowserSettingsFlow({
          returnTo: window.location.href,
        });

        await ory.updateSettingsFlow({
          flow: flow.id,
          updateSettingsFlowBody: {
            link: provider,
            method: 'oidc',
          },
        });
      } catch (error) {
        logger.error(error);
        if (!isAxiosError(error)) {
          return;
        }
        if (isSessionRefreshRequiredError(error)) {
          throw Error('Please login again to link an account');
        }
        if (isRedirectBrowserError(error)) {
          await assignLocationAndWait(error.response.data.redirect_browser_to);
        }
      }
    },
    [ory],
  );
}
