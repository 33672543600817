import { useCallback, useEffect, useState } from 'react';
import type { SettingsFlow } from '@ory/client';
import { useOry } from '../data';
import { findOryWebAuthnRemoveNodeByTokenName, isSessionRefreshRequiredError } from '../utils';
import { useOryAddToken } from './useOryAddToken';
import { useOryRemoveToken } from './useOryRemoveToken';

const DEFAULT_TOKEN_DISPLAY_NAME = 'token';

type TpOryWebAuthnToggle = {
  isWebAuthnEnabled: boolean;
  loading: boolean;
  toggleWebAuthn: () => Promise<void>;
};
export function useOryWebAuthnToggle(): TpOryWebAuthnToggle {
  const { ory } = useOry();

  const [loading, setLoading] = useState(false);
  const [flow, setFlow] = useState<undefined | SettingsFlow>(undefined);
  useEffect(() => {
    void (async (): Promise<void> => {
      try {
        setLoading(true);
        const { data } = await ory.createBrowserSettingsFlow({
          returnTo: window.location.href,
        });
        setFlow(data);
      } finally {
        setLoading(false);
      }
    })();
  }, [ory, setLoading]);

  const isTokenExists = !!(
    flow && findOryWebAuthnRemoveNodeByTokenName(flow.ui.nodes, DEFAULT_TOKEN_DISPLAY_NAME)
  );

  const removeCallback = useOryRemoveToken();
  const removeTokenCallback = useCallback(async () => {
    if (flow === undefined) {
      throw Error('Flow is undefined');
    }

    try {
      setLoading(true);
      const newFlow = await removeCallback(flow, DEFAULT_TOKEN_DISPLAY_NAME);
      setFlow(newFlow);
    } catch (error) {
      if (isSessionRefreshRequiredError(error)) {
        throw Error('Please login again to disable biometric');
      }
      throw error;
    } finally {
      setLoading(false);
    }
  }, [removeCallback, flow]);

  const addToken = useOryAddToken();
  const addTokenCallback = useCallback(async () => {
    if (flow === undefined) {
      throw Error('Flow is undefined');
    }

    try {
      setLoading(true);
      const newFlow = await addToken(flow, DEFAULT_TOKEN_DISPLAY_NAME);
      setFlow(newFlow);
    } catch (error) {
      if (isSessionRefreshRequiredError(error)) {
        throw Error('Please login again to enable biometric');
      }
      throw error;
    } finally {
      setLoading(false);
    }
  }, [addToken, flow]);

  return {
    isWebAuthnEnabled: isTokenExists,
    loading,
    toggleWebAuthn: isTokenExists ? removeTokenCallback : addTokenCallback,
  };
}
