export const badgeNames: Record<string, string> = {
  AdventureStone: 'The Adventure Stone',
  Arkonaut: 'The Arkonaut Badge',
  ArkStone001: 'Ark Stone | 001',
  ArkStone002: 'Ark Stone | 002',
  ArkStone003: 'Ark Stone | 003',
  BitrefillStone: 'The Bitrefill Stone',
  BitrefillStonePlus: 'The Bitrefill Stone+',
  DiscordStone: 'The Discord Stone',
  Founder: 'The Founders Badge',
  LightningStone: 'The Lightning Stone',
  StarlightStone: 'The Starlight Stone',
  Traveler: 'The Traveler Badge',
  TreasureStone: 'The Treasure Stone',
  Wanderer: 'The Wanderer Badge',
  WarpStone: 'The Warp Stone',
};

export const earnedBadgeNotices: Record<string, string> = {
  AdventureStone:
    'You completed the Onboarding Quest! The Adventure Stone is the first step on your journey with NOAH. You’ve earned this stone — along with 150 NP — as a reward.',
  Arkonaut:
    'As an Arkonaut, you’re one of the elite, a true pioneer in the world of NOAH. They say it’s lonely at the top, but we know you’ll enjoy the view up here.',
  ArkStone001:
    'The Limited Edition Ark Stone | 001 represents the first of its kind, a must-have for our most ardent collectors. You know what they say — the early bird gets the worm. Congrats on claiming the Ark Stone | 001 and your 300 NP!',
  ArkStone002:
    'Love has arrived! Your heart-shaped Ark\u00A0Stone\u00A0|\u00A0002 and 500 NOAH Points await in your NOAH wallet, a token of your love.',
  ArkStone003:
    'Bravo! The Ark Stone | 003 and 200 NP are yours. It’s evident your hunting skills are top-notch. Keep the fun going, and enjoy your well-deserved reward!',
  BitrefillStone:
    'Fantastic job! The Bitrefill Stone and 500 NP are now yours. With your passion for unlocking the power of Bitcoin for quick purchasing of goods and services, you’re unstoppable! You’ve got your hands on gift cards and phone top-ups faster than ever before. Congratulations, keep it up!',
  BitrefillStonePlus:
    'A challenge tastes sweet, but victory tastes sweeter. You’ve emerged triumphant in the limited edition 48-hour Bitrefill Quest and earned the Bitrefill Stone and 500 NOAH Points as a reward for your efforts.',
  DiscordStone:
    '(Discontinued) The Discord Stone proves you understand the power of community. You’ve just earned the Stone — along with 200 NP — for your commitment to being an Arkonaut. We’re happy to have you!',
  Founder:
    'You’ve hit the stratosphere. You’re one of our exalted Founders. You’re part of an exclusive group, and we want to make sure you know it. Soak it all up, you’re the best of the best.',
  LightningStone:
    'The Lightning Stone is a testament to your speed and efficiency in completing the Lightning Quest, and you’ve earned it — along with 500 NP — as a reward for your hard work.',
  StarlightStone:
    'Congratulations on a job well done! The Starlight Stone and 2000 NP are yours. Keep reaching for the stars and keep buying bitcoin through the NOAH app!',
  Traveler:
    'The momentum is strong. Your drive is unrelenting. You’ve reached a new level on your NOAH journey. As a Traveler, you’re well on your way to becoming an Arkonaut legend. Keep up the excellent work!',
  TreasureStone:
    'You completed the Deposit Quest! Fortune favors those who save. By depositing $100 in your NOAH account, you’ve taken the first step toward a bright future.',
  Wanderer:
    'A Wanderer deserves credit for their impressive feats in exploration and adventure. You’ve proven your dedication to your NOAH journey and earned the Wanderer Badge along with 500 NP.',
  WarpStone:
    'Congratulations on securing the Warp Stone - a token of distinction for our most committed adventurers. Plus, you’ve added a solid 1000 NOAH Points to your NOAH Wallet. Well done!',
};

export const lockedBadgeNotices: Record<string, string> = {
  AdventureStone:
    'By completing the onboarding quest, NOAH automatically adds the Adventure Stone — along with 150 NP — to your inventory, free for you to marvel at its majestic glory.',
  Arkonaut:
    'Complete Referral Quest III. Refer 15 friends to earn the mighty and metallic Arkonaut Badge plus 100 NP per referral — added right into your NOAH wallet.',
  ArkStone001:
    'Deposit $20 into your NOAH account and get the Limited Edition Ark Stone | 001 — plus 300 NP along with it.',
  ArkStone002:
    'Get ready for a show of affection. Express your love by depositing 100k sats and earn the Ark\u00A0Stone\u00A0|\u00A0002 and 500 NP.',
  ArkStone003:
    'An egg-citing journey lies ahead. Uncover the secret Ark Stone | 003 within our app and receive 200 NOAH Points as a festive reward.',
  BitrefillStone:
    'Your journey starts here. To unlock the Bitrefill Stone, make a gift card or phone top up purchase using Bitrefill and enjoy a bounty of 500 NOAH Points as your reward.',
  BitrefillStonePlus:
    'Get ready to level up with the limited edition Bitrefill Stone and 500 NOAH Points. Starting February 9th, make a purchase using Bitrefill before the timer runs out to embark on this innovative quest and earn the ultimate reward.',
  Founder:
    'Complete Referral Quest IV. Refer 21 friends to earn the Founders Badge plus 100 NP per referral — added right into your NOAH wallet.',
  LightningStone:
    'Register your Lightning Address, create a Lightning invoice, and have it paid — you get the Lightning Stone and 500 NP — as a reward for your hard work.',
  StarlightStone:
    'Get ready to shine! Buy bitcoin with NOAH and receive the dazzling Starlight Stone and 2000 NP.',
  Traveler:
    'Complete Referral Quest II. Refer 10 friends to complete the quest and claim the bold and beautiful Traveler Badge plus 100 NP per referral — added right into your NOAH wallet. ',
  TreasureStone:
    'Deposit $100 USD worth of bitcoin and claim your Treasure Stone and 500 NP. A worthy bounty. Good things come to those who save.',
  Wanderer:
    'Complete Referral Quest I. Refer 5 friends to complete the quest and claim the sleek and sturdy Wanderer Badge plus 100 NP per referral — added right into your NOAH wallet.',
  WarpStone:
    'You have 72 hours to simply buy $20 worth of bitcoin with your NOAH account and the Warp Stone will be yours, along with an extra 1000 NOAH Points!',
};
