import type { MouseEvent } from 'react';
import { Fragment, useCallback, useRef } from 'react';
import {
  useOptimisticUserInitOptions,
  useUpdateUserCurrencyUnitSettingMutation,
  useUpdateUserFiatCurrencyCodeSettingMutation,
} from '@noah-labs/fe-shared-data-access-user';
import { Balance, FiatAmount } from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { PreferredCurrenciesDialog } from '@noah-labs/fe-shared-ui-user';
import { CurrencyDialogToggle } from '@noah-labs/fe-shared-ui-wallet';
import type { CurrencyUnit, FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { useUserAvailableFiatBalance } from '../hooks/useUserAvailableFiatBalance';
import { useUserInitUi } from '../hooks/useUserInitUi';

/**
 * Displays a known crypto currency amount, calculates an approximate fiat amount
 * and displays the preferred currencies dialog component
 */
export function CurrentBalanceDataWithCurrencyDialog(): React.ReactElement {
  const preferredCurrencyDialog = useRef<TpDialogToggle>(null);
  const { data: userData } = useUserInitUi();
  const userAvailableBalance = useUserAvailableFiatBalance();

  const amount = userAvailableBalance.isFetched ? userAvailableBalance.availableBalance : undefined;

  const handleToggle = useCallback((event?: MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    preferredCurrencyDialog.current?.toggle();
  }, []);

  const updateOptions = useOptimisticUserInitOptions();

  const { mutate: mutateFiatCurrencyCode } =
    useUpdateUserFiatCurrencyCodeSettingMutation(updateOptions);
  const { mutate: mutateDisplayUnit } = useUpdateUserCurrencyUnitSettingMutation(updateOptions);

  const handleDisplayUnitChange = useCallback(
    (value: CurrencyUnit) => {
      mutateDisplayUnit({ Input: { DisplayUnit: value } });
      preferredCurrencyDialog.current?.toggle();
    },
    [mutateDisplayUnit],
  );

  const handleFiatCurrencyCodeChange = useCallback(
    (value: FiatCurrencyCode) => {
      mutateFiatCurrencyCode({
        Input: { FiatCurrencyCode: value },
      });
      preferredCurrencyDialog.current?.toggle();
    },
    [mutateFiatCurrencyCode],
  );

  return (
    <Fragment>
      <Balance
        IconSlot={<CurrencyDialogToggle onClick={handleToggle} />}
        label="Your balance"
        PrimaryAmountSlot={
          <FiatAmount roundDown amount={amount} currency={userData?.userProfile.fiatCurrency} />
        }
      />
      <PreferredCurrenciesDialog
        ref={preferredCurrencyDialog}
        handleDisplayUnitChange={handleDisplayUnitChange}
        handleFiatCurrencyCodeChange={handleFiatCurrencyCodeChange}
        unitCurrency={userData?.userProfile.DisplayUnit}
      />
    </Fragment>
  );
}
