import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { PpQRScanner } from '@noah-labs/fe-shared-ui-components';
import {
  AppContainer,
  AppHeaderTitle,
  AppMain,
  QRScanner,
  SceneMain,
} from '@noah-labs/fe-shared-ui-components';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';

export type PpScanScene = PpQRScanner & {
  pageTitle: string;
};

export function MarketingScanScene({ pageTitle, ...rest }: PpScanScene): React.ReactElement {
  const theme = useTheme();
  const backgroundColor = theme.palette.common.black;
  const fontColor = theme.palette.common.white;

  return (
    <AppContainer backgroundColor={backgroundColor} fontColor={fontColor}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AppMain>
        <AppHeaderData
          backButton
          exitButton
          helpButton
          backgroundColor={backgroundColor}
          fontColor={fontColor}
        >
          <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
        </AppHeaderData>
        <SceneMain dense dataQa="scan">
          <Stack direction="column" spacing={4} sx={{ alignItems: 'center' }}>
            <Typography variant="paragraphBodyS">Scan a promo QR code</Typography>
            <QRScanner {...rest} />
          </Stack>
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
