import type {
  BooleanMap,
  CountryCode,
  FeatureRestrictedCountryRegionMap,
} from '@noah-labs/shared-schema-gql';
import type { UseQueryResult } from 'react-query';
import type { TpCountryNeeds } from '../filter-functions';
import type { TpCountriesForSelect } from '../types';
import { getFilterCountryForFeatureData } from '../utils';
import { useCountriesForSelect } from './useCountriesForSelect';

/**
 * This hook returns a list of countries based on specific features e.g CkoBuy, CkoSell -
 * and the user's default country validated against the returned list. It can be used for scenarios
 * where the user's country needs to be prefilled based on certain features.
 *
 * @param {CountryCode} userCountry - The user's country.
 * @param {TpCountryNeeds} needs - An array of feature requirements.
 */
export function useCountriesForFeature(
  userCountry: CountryCode | null | undefined,
  needs: TpCountryNeeds,
  prohibitedCountries: BooleanMap,
  restrictedCountries: FeatureRestrictedCountryRegionMap,
): UseQueryResult<TpCountriesForSelect> {
  return useCountriesForSelect(
    userCountry,
    getFilterCountryForFeatureData(needs, prohibitedCountries, restrictedCountries),
  );
}
