import { useTheme } from '@mui/material/styles';
import { useKycExpiryStatus } from '@noah-labs/fe-shared-feature-kyc';
import { CircleTickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CircleTickIcon';
import { CloseCircleIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CloseCircleIcon';
import { WarningIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/WarningIcon';
import { KycApprovalStatus } from '@noah-labs/shared-schema-gql';

export type TpKycApprovalStatusData = {
  Icon?: React.ReactElement;
  color?: string;
  description: string;
};

export function useKycApprovalStatusData(
  status: KycApprovalStatus | null | undefined,
): TpKycApprovalStatusData {
  const theme = useTheme();
  const { kycExpired, kycIsExpiring } = useKycExpiryStatus();

  switch (status) {
    case KycApprovalStatus.Pending:
      return { description: 'Pending' };
    case KycApprovalStatus.Declined:
      return {
        color: theme.palette.error.main,
        description: 'Verification failed',
        Icon: <CloseCircleIcon color="error" />,
      };
    case KycApprovalStatus.Approved:
      if (kycIsExpiring) {
        return {
          color: theme.palette.warning.light,
          description: 'Verify your information before it expires',
          Icon: <WarningIcon color="error" />,
        };
      }
      if (kycExpired) {
        return {
          color: theme.palette.warning.light,
          description: 'Verification expired. Please complete your verification',
          Icon: <WarningIcon color="error" />,
        };
      }
      return {
        color: theme.palette.success.main,
        description: 'Account verified',
        Icon: <CircleTickIcon color="success" />,
      };
    default:
      return {
        description: 'Not yet verified',
      };
  }
}
