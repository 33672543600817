import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import {
  BulletedList,
  LearnMoreButton,
  LowDialog,
  PrimaryButton,
} from '@noah-labs/fe-shared-ui-components';
import { useToggleDialog } from '@noah-labs/fe-shared-ui-shared';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { webLinks } from '@noah-labs/shared-constants';

export type PpSupportedCryptoCurrenciesDialog = {
  initialOpen?: boolean;
};

export const SupportedCryptoCurrenciesDialog = forwardRef<
  TpDialogToggle,
  PpSupportedCryptoCurrenciesDialog
>(({ initialOpen }, ref): JSX.Element => {
  const { open, toggle } = useToggleDialog({ initialOpen, ref });

  return (
    <LowDialog open={open} onClose={toggle}>
      <DialogTitle>Supported cryptocurrencies and networks</DialogTitle>
      <DialogContent>
        <BulletedList>
          <li>
            <DialogContentText variant="paragraphBodyMBold">
              Bitcoin:{' '}
              <DialogContentText component="span" variant="paragraphBodyM">
                We support transactions on both the On-chain and Lightning networks.
              </DialogContentText>
            </DialogContentText>
          </li>
          <li>
            <DialogContentText variant="paragraphBodyMBold">
              USDC:{' '}
              <DialogContentText component="span" variant="paragraphBodyM">
                Transactions are supported via the Ethereum, Polygon, and Tron network.
              </DialogContentText>
            </DialogContentText>
          </li>
        </BulletedList>
        <DialogContentText variant="paragraphBodyM">
          Still have questions?{' '}
          <LearnMoreButton href={webLinks.blog.supportedNetworks} target="_blank">
            Learn more about our supported addresses & networks.
          </LearnMoreButton>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton color="primaryBrand" onClick={toggle}>
          Got it
        </PrimaryButton>
      </DialogActions>
    </LowDialog>
  );
});

SupportedCryptoCurrenciesDialog.displayName = SupportedCryptoCurrenciesDialog.name;
