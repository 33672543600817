import type { ReactElement } from 'react';
import {
  AvatarIconFromSvg,
  ListItem,
  ListItemContent,
  ListItemEndContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
} from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-shared';

export type PpAvailableBalance = {
  PrimaryAmountSlot: React.ReactNode;
  SecondaryAmountSlot: React.ReactNode;
  cryptoCurrency: TpCryptoCurrencyUI;
};

export function AvailableBalance({
  cryptoCurrency,
  PrimaryAmountSlot,
  SecondaryAmountSlot,
}: PpAvailableBalance): ReactElement {
  return (
    <ListItem>
      <ListItemContent padding={0}>
        <ListItemIcon>
          <AvatarIconFromSvg svg={cryptoCurrency.svg} />
        </ListItemIcon>
        <ListItemStartContent>
          <ListItemPrimaryText>{`${cryptoCurrency.label} Balance`}</ListItemPrimaryText>
        </ListItemStartContent>
        <ListItemEndContent>
          <ListItemPrimaryText>{PrimaryAmountSlot}</ListItemPrimaryText>
          <ListItemSecondaryText>{SecondaryAmountSlot}</ListItemSecondaryText>
        </ListItemEndContent>
      </ListItemContent>
    </ListItem>
  );
}
