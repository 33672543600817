import React, { useCallback } from 'react';
import { Typography } from '@mui/material';
import {
  useOptimisticUserInitOptions,
  useUpdateUserFiatPaymentCurrencyCodeSettingMutation,
} from '@noah-labs/fe-shared-data-access-user';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { fiatCurrencyItems, useRouter } from '@noah-labs/fe-shared-ui-shared';
import { PreferredFiatCurrencyForm, useUserError } from '@noah-labs/fe-shared-ui-user';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import type { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { UserSettingOptionsScene } from '../scenes/UserSettingOptions';

export function PaymentCurrencySetting(): React.ReactElement {
  const updateOptions = useOptimisticUserInitOptions();
  const { error: mutateError, mutate } =
    useUpdateUserFiatPaymentCurrencyCodeSettingMutation(updateOptions);
  const { ApiErrorScene } = useUserError(mutateError);
  const { data: userData } = useUserInitUi();
  const { goBack } = useRouter();

  const handleChange = useCallback(
    (value: FiatCurrencyCode) => {
      if (!userData) {
        return;
      }

      mutate({
        Input: {
          FiatCurrencyCode: userData.userProfile.fiatCurrency.code,
          FiatPaymentCurrencyCode: value,
        },
      });
      goBack();
    },
    [goBack, mutate, userData],
  );

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <UserSettingOptionsScene
      ContentSlot={
        <Typography color="text.light" marginTop={3} textAlign="center" variant="paragraphBodyS">
          More currencies coming soon
        </Typography>
      }
      CurrencyFormSlot={
        <PreferredFiatCurrencyForm
          hideValues
          id="fiatPayment"
          items={fiatCurrencyItems}
          title="Select your preferred fiat currency for buying and selling."
          onChange={handleChange}
        />
      }
      dataQa="user-settings-payment-currency"
      pageTitle={userRoutes.settings.paymentCurrency.title}
    />
  );
}
