import { useValidateReferralCode } from '@noah-labs/fe-shared-feature-auth';
import { HelpIconButtonData } from '@noah-labs/fe-shared-feature-help';
import { useSearchParams } from '@noah-labs/fe-shared-ui-components';
import { authRoutes } from '@noah-labs/fe-shared-util-routes';
import { useRouteMatch } from 'react-router-dom';
import { webConfigBrowser } from '../../webConfigBrowser';
import { AccessControlData } from './AccessControlData';
import type { PpRouter } from './Router';
import { Router } from './Router';

type PpAuthRouterData = Omit<PpRouter, 'AccessControlData' | 'helpButton' | 'referralCode'>;

export function AuthRouterData({
  authedPath,
  captcha,
  updateGuestSardineConfig,
}: PpAuthRouterData): React.ReactElement {
  const isSignupRoute = useRouteMatch(authRoutes.signUp.path);
  const searchParams = useSearchParams();
  const referralCode = searchParams?.get(webConfigBrowser.settings.referralCodeParam) ?? null;

  useValidateReferralCode({
    enabled: !!isSignupRoute?.isExact && !!referralCode,
    referralCode,
  });

  return (
    <Router
      AccessControlData={AccessControlData}
      authedPath={authedPath}
      captcha={captcha}
      helpButton={<HelpIconButtonData />}
      referralCode={referralCode}
      updateGuestSardineConfig={updateGuestSardineConfig}
    />
  );
}
