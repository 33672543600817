import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import {
  ButtonGroup,
  ListItemCard,
  LowDialog,
  PrimaryButton,
} from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle, TpPaymentCard } from '@noah-labs/fe-shared-ui-shared';
import { TpPaymentMethod, useToggleDialog } from '@noah-labs/fe-shared-ui-shared';
import { PaymentMethod } from '@noah-labs/fe-shared-ui-wallet';

type PpConfirmRemoveDialog = {
  cardDetails: TpPaymentCard | undefined;
  onRemoveCard: () => void;
};

export const ConfirmRemoveDialog = forwardRef<TpDialogToggle, PpConfirmRemoveDialog>(
  ({ cardDetails, onRemoveCard }, ref): JSX.Element => {
    const { open, toggle } = useToggleDialog({ ref });

    return (
      <LowDialog dataQa="confirm-delete" open={open} onClose={toggle}>
        <DialogTitle>Are you sure you want to remove this card?</DialogTitle>
        <DialogContent>
          <DialogContentText paragraph>This will not affect any pending orders.</DialogContentText>
          <ListItemCard>
            <PaymentMethod method={TpPaymentMethod.Card} paymentCard={cardDetails} />
          </ListItemCard>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <PrimaryButton
              color="primaryBrand"
              onClick={(): void => {
                onRemoveCard();
                toggle();
              }}
            >
              Yes, Remove
            </PrimaryButton>
            <PrimaryButton variant="text" onClick={toggle}>
              Cancel
            </PrimaryButton>
          </ButtonGroup>
        </DialogActions>
      </LowDialog>
    );
  },
);

ConfirmRemoveDialog.displayName = ConfirmRemoveDialog.name;
