import type { TpFeFeature, TpFeFeatureFlag } from '@noah-labs/fe-shared-data-access-user';

type TpFeatureMap = Map<TpFeFeature, TpFeFeatureFlag> | undefined | null;
export type TpFeatureNeeds = Array<TpFeFeature | boolean | Array<TpFeFeature | boolean>>;

type TpTestFeatureOrBoolean = {
  has: TpFeatureMap;
  need: TpFeFeature | boolean;
};
function testFeatureOrBoolean({ has, need }: TpTestFeatureOrBoolean): boolean {
  if (typeof need === 'boolean') {
    return need;
  }
  const userFeature = has?.get(need);
  return Boolean(userFeature?.Enabled);
}

export type PpCheckFeature = {
  has: TpFeatureMap;
  needs: TpFeatureNeeds | undefined;
};
/**
 * Determines whether a user meets certain feature requirements.
 * The requirements (`needs`) are checked against the user's available features (`has`).
 * A requirement can be a feature flag (as a string), a boolean, or an array of these indicating multiple conditions.
 *
 * Usage:
 * - To require one or more specific features, include them in the `needs` array.
 *   Example: `needs: ['Deposit', 'Withdraw']` means both "Deposit" AND "Withdraw" features need to be enabled for the user.
 *
 * - To specify that one out of several features is sufficient, place them in a nested array within the `needs` array.
 *   Example: `needs: [['Deposit', 'Withdraw']]` means either "Deposit" OR "Withdraw" being enabled is sufficient.
 *
 * - You can combine these conditions:
 *   Example: `needs: ['Deposit', ['Withdraw', 'LightningSend']]` indicates the user needs "Deposit" AND either "Withdraw" OR "LightningSend" enabled.
 *
 * - Boolean values in `needs` are evaluated as part of the conditions.
 *   Example: `needs: [true, 'Deposit']` means the function evaluates both the `true` value and whether the 'Deposit' feature is enabled. The presence of a `true` in the array does not guarantee the function will return `true`. All conditions, including boolean values and feature checks, must be met for the function to return `true`.
 *
 * @param {Object} param - The parameters object.
 * @param {Array<TpFeFeature | boolean | Array<TpFeFeature | boolean>>} param.needs - An array of feature requirements. Each element can be a feature flag, a boolean, or an array of these types, indicating a combination of feature requirements.
 * @param {Map<TpFeFeature, TpFeFeatureFlag> | undefined | null} param.has - A map (or null/undefined) representing the user's available features with their enabled status.
 *
 * @returns {boolean} - Returns `true` if all the user's feature requirements are met, otherwise `false`.
 */
export function checkFeature({ has, needs }: PpCheckFeature): boolean {
  if (!needs) {
    return true;
  }
  return needs.every((need) => {
    if (Array.isArray(need)) {
      // nested array is an OR condition
      return need.some((featureOrBoolean) => testFeatureOrBoolean({ has, need: featureOrBoolean }));
    }
    // this is an AND condition
    return testFeatureOrBoolean({ has, need });
  });
}
