import React, { Fragment, useCallback, useRef } from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import { useFiatPaymentMethodsQuery } from '@noah-labs/fe-shared-data-access-wallet';
import type { TpPaymentMethodChange } from '@noah-labs/fe-shared-feature-wallet';
import { mapPaymentCards } from '@noah-labs/fe-shared-feature-wallet';
import { ChevronDownIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronDownIcon';
import { ListItemButton, ListItemCard } from '@noah-labs/fe-shared-ui-components';
import type {
  TpDialogToggle,
  TpPaymentCard,
  TpPaymentMethod,
} from '@noah-labs/fe-shared-ui-shared';
import { PaymentMethod } from '@noah-labs/fe-shared-ui-wallet';
import { PaymentMethodsDialog } from './PaymentMethodsDialog';

export type PpPaymentMethodsPicker = {
  applePayEnabled: boolean;
  googlePayEnabled: boolean;
  onChange: ({ card, type }: TpPaymentMethodChange) => void;
  onSubmitCardDetailsRedirect: string;
  paymentMethod: TpPaymentMethod | undefined;
  selectedPaymentCard: TpPaymentCard | undefined;
};

export function PaymentMethodsPicker({
  applePayEnabled,
  googlePayEnabled,
  onChange,
  onSubmitCardDetailsRedirect,
  paymentMethod,
  selectedPaymentCard,
}: PpPaymentMethodsPicker): React.ReactElement {
  const theme = useTheme();
  const styles = {
    chevron: css`
      color: ${theme.palette.text.light};
    `,
  };

  const dialogRef = useRef<TpDialogToggle>(null);

  const { data: paymentCards } = useFiatPaymentMethodsQuery(undefined, {
    select: mapPaymentCards,
  });

  const handleChange = useCallback(
    ({ card, type }: TpPaymentMethodChange) => {
      onChange({ card, type });
      dialogRef.current?.toggle();
    },
    [onChange],
  );

  return (
    <Fragment>
      <ListItemButton
        data-qa="payment-method-picker"
        onClick={(): void => dialogRef.current?.toggle()}
      >
        <ListItemCard>
          <PaymentMethod
            method={paymentMethod}
            paymentCard={selectedPaymentCard}
            ToggleSlot={<ChevronDownIcon css={styles.chevron} />}
          />
        </ListItemCard>
      </ListItemButton>
      <PaymentMethodsDialog
        ref={dialogRef}
        applePayEnabled={applePayEnabled}
        googlePayEnabled={googlePayEnabled}
        handleChange={handleChange}
        paymentCards={paymentCards}
        onSubmitCardDetailsRedirect={onSubmitCardDetailsRedirect}
      />
    </Fragment>
  );
}
