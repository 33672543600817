import { useCalculateFiatFromCrypto } from '@noah-labs/fe-shared-data-access-wallet';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import { useUserInitUi } from './useUserInitUi';

type PpUseUserAccountFiatBalance = {
  balance: string | undefined;
  cryptoCurrency: TpCryptoCurrencyUI;
};

export function useUserAccountFiatBalance({
  balance,
  cryptoCurrency,
}: PpUseUserAccountFiatBalance): string | undefined {
  const { data: userData } = useUserInitUi();

  const { data: priceData } = useCalculateFiatFromCrypto({
    cryptoAmount: balance,
    cryptoCurrency,
    fiatCurrency: userData?.userProfile.fiatCurrency,
    priceProvider: 'market',
  });

  return priceData?.fiatAmount;
}
