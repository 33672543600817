import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { LowDialog, PrimaryButton } from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { useToggleDialog } from '@noah-labs/fe-shared-ui-shared';

export const CryptoVolatilityDialog = forwardRef<TpDialogToggle>((_, ref): JSX.Element => {
  const { open, toggle } = useToggleDialog({ ref });

  return (
    <LowDialog open={open} onClose={toggle}>
      <DialogTitle>Cryptocurrency price may vary during payment</DialogTitle>
      <DialogContent>
        <DialogContentText paragraph>
          The price of a cryptocurrency can be affected by many factors, including supply and
          demand, market sentiment, and global economic conditions. Because of this volatility, the
          price of a cryptocurrency may change slightly once a payment is made from what is shown at
          the time of transaction.
        </DialogContentText>
        <DialogContentText>
          We strive to provide the most accurate and up-to-date information to our users, but please
          understand that we cannot guarantee the price of any cryptocurrency the moment the payment
          is made.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton color="primaryBrand" onClick={toggle}>
          Got it!
        </PrimaryButton>
      </DialogActions>
    </LowDialog>
  );
});

CryptoVolatilityDialog.displayName = CryptoVolatilityDialog.name;
