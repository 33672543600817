import type { AccountCreateMutation, AccountsQuery } from './data/wallet.generated';
import type { TpAccount, TpFindAccount } from './types';

export function selectAccount({
  AccountType,
  CurrencyCode,
  data,
}: TpFindAccount): TpAccount | undefined {
  if (!Array.isArray(data.accounts.items)) {
    return undefined;
  }

  return data.accounts.items.find(
    (acc) => acc.AccountType === AccountType && acc.CurrencyCode === CurrencyCode,
  );
}

type PpGetAccount = Omit<TpFindAccount, 'data'> & { data?: AccountsQuery };
export function getAccount({
  AccountType,
  CurrencyCode,
  data,
}: PpGetAccount): TpAccount | undefined {
  if (!data) {
    return undefined;
  }

  return selectAccount({ AccountType, CurrencyCode, data });
}

export function findDepositAddress(
  account: AccountCreateMutation['accountCreate'] | undefined,
): string | undefined {
  // TODO: Also need to think about cases with multiple deposit addresses
  if (!account || !Array.isArray(account.DepositAddresses)) {
    return undefined;
  }

  return account.DepositAddresses[0].DestinationAddress.Address;
}
