import { CurrencyCode, Network } from '@noah-labs/shared-schema-gql';
import type { TpCryptoCurrency } from './types';

export const cryptoCurrencies: Record<CurrencyCode, TpCryptoCurrency> = {
  BTC: {
    code: CurrencyCode.BTC,
    decimals: 8,
    label: 'Bitcoin',
    requireNetworkSelection: { receive: true, send: false },
    supportedNetworks: [Network.Bitcoin, Network.Lightning],
    symbol: '₿',
  },
  BTC_TEST: {
    code: CurrencyCode.BTC_TEST,
    decimals: 8,
    label: 'Bitcoin',
    requireNetworkSelection: { receive: true, send: false },
    supportedNetworks: [Network.BitcoinTest, Network.LightningTest],
    symbol: '₿',
  },
  ETH: {
    code: CurrencyCode.ETH,
    decimals: 18,
    label: 'Ether',
    requireNetworkSelection: { receive: false, send: false },
    supportedNetworks: [Network.Ethereum],
    symbol: 'Ξ',
  },
  ETH_TEST_SEPOLIA: {
    code: CurrencyCode.ETH_TEST_SEPOLIA,
    decimals: 18,
    label: 'Ether',
    requireNetworkSelection: { receive: false, send: false },
    supportedNetworks: [Network.EthereumTestSepolia],
    symbol: 'Ξ',
  },
  MATIC: {
    code: CurrencyCode.MATIC,
    decimals: 18,
    label: 'Matic',
    requireNetworkSelection: { receive: false, send: false },
    supportedNetworks: [Network.PolygonPos],
    symbol: '',
  },
  MATIC_TEST: {
    code: CurrencyCode.MATIC_TEST,
    decimals: 18,
    label: 'Matic',
    requireNetworkSelection: { receive: false, send: false },
    supportedNetworks: [Network.PolygonTestAmoy],
    symbol: '',
  },
  NOAH: {
    code: CurrencyCode.NOAH,
    decimals: 0,
    label: 'NOAH',
    requireNetworkSelection: { receive: false, send: false },
    supportedNetworks: [],
    symbol: 'N',
  },
  TRX: {
    code: CurrencyCode.TRX,
    decimals: 6,
    label: 'Tron',
    requireNetworkSelection: { receive: false, send: false },
    supportedNetworks: [Network.Tron],
    symbol: '',
  },
  TRX_TEST: {
    code: CurrencyCode.TRX_TEST,
    decimals: 6,
    label: 'Tron',
    requireNetworkSelection: { receive: false, send: false },
    supportedNetworks: [Network.TronTestShasta],
    symbol: '',
  },
  USDC: {
    code: CurrencyCode.USDC,
    decimals: 6,
    label: 'USDC',
    requireNetworkSelection: { receive: true, send: true },
    supportedNetworks: [Network.Ethereum, Network.PolygonPos, Network.Tron],
    symbol: '$',
  },
  USDC_TEST: {
    code: CurrencyCode.USDC_TEST,
    decimals: 6,
    label: 'USDC',
    requireNetworkSelection: { receive: true, send: true },
    supportedNetworks: [
      Network.EthereumTestSepolia,
      Network.PolygonTestAmoy,
      Network.TronTestShasta,
    ],
    symbol: '$',
  },
  USDT: {
    code: CurrencyCode.USDT,
    decimals: 6,
    label: 'USDT',
    requireNetworkSelection: { receive: true, send: true },
    supportedNetworks: [Network.Ethereum, Network.PolygonPos, Network.Tron],
    symbol: '$',
  },
  USDT_TEST: {
    code: CurrencyCode.USDT_TEST,
    decimals: 6,
    label: 'USDT',
    requireNetworkSelection: { receive: true, send: true },
    supportedNetworks: [
      Network.EthereumTestSepolia,
      Network.PolygonTestAmoy,
      Network.TronTestShasta,
    ],
    symbol: '$',
  },
};
