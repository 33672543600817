import React, { useRef, useState } from 'react';
import { NotificationsCenterController } from '@noah-labs/fe-shared-feature-notifications';
import type { PpHeaderIconButton } from '@noah-labs/fe-shared-ui-components';
import { NotificationCenterButton } from '@noah-labs/fe-shared-ui-components';
import { useNotifications } from '@novu/notification-center';

type PpNotificationCenterButton = Pick<PpHeaderIconButton, 'edge'>;

export function NotificationCenterButtonData({
  edge,
}: PpNotificationCenterButton): React.ReactElement | null {
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const [isNotificationCenterOpen, setIsNotificationCenterOpen] = useState(false);

  const { unseenCount } = useNotifications();

  function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    anchorEl.current = e.currentTarget;

    setIsNotificationCenterOpen(true);
  }

  return (
    <React.Fragment>
      <NotificationCenterButton
        edge={edge}
        hasUnseenNotifications={unseenCount > 0}
        onClick={(e): void => handleClick(e)}
      />

      <NotificationsCenterController
        ref={anchorEl}
        isNotificationCenterOpen={isNotificationCenterOpen}
        setIsNotificationCenterOpen={setIsNotificationCenterOpen}
      />
    </React.Fragment>
  );
}
