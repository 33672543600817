import type {
  TpAmount,
  TpCryptoCurrency,
  TpFiatCurrency,
  TpSimpleAmount,
  TpSlippage,
} from '@noah-labs/shared-currencies';
import { calculateFee } from '@noah-labs/shared-currencies';
import { safeBN } from '@noah-labs/shared-util-vanilla';
import type { UseQueryOptions } from 'react-query';
import { useCalculateCryptoFromFiat } from './useCalculate';
import type { TpMarketOrLiquidityPriceQuery, TpPriceProvider } from './useCryptoPrice';

type TpAmountsWithFee = {
  feeCryptoAmount: TpSimpleAmount;
  feeFiatAmount: string;
  fetchedAt?: string;
  netCryptoAmount: string;
  netFiatAmount: string;
  price: TpSimpleAmount;
  totalCryptoAmount: string;
  totalFiatAmount: string;
};

type TpFees = {
  feeFiatAmount?: string;
  feeMinimumFiatAmount?: string;
  feePercentage: string;
};

type PpCurrencyAmountsWithFee = {
  cryptoCurrency: TpCryptoCurrency | undefined;
  feePromotion?: TpAmount;
  fees: TpFees;
  fiatAmount: TpAmount;
  fiatCurrency: TpFiatCurrency | undefined;
  isAmountNet?: boolean;
  options?: UseQueryOptions<TpMarketOrLiquidityPriceQuery>;
  priceProvider: TpPriceProvider;
  slippage?: TpSlippage;
};

export function useAmountsWithFee({
  cryptoCurrency,
  feePromotion,
  fees,
  fiatAmount,
  fiatCurrency,
  isAmountNet,
  options,
  priceProvider,
  slippage,
}: PpCurrencyAmountsWithFee): TpAmountsWithFee {
  const feeFiatAmount = calculateFee({
    amount: fiatAmount,
    fee: fees.feePercentage,
    feeMin: fees.feeMinimumFiatAmount,
    promotion: feePromotion,
  });

  const { data: cryptoAmountData } = useCalculateCryptoFromFiat({
    cryptoCurrency,
    fiatAmount,
    fiatCurrency,
    options,
    priceProvider,
    slippage,
  });

  const { data: feeCryptoData } = useCalculateCryptoFromFiat({
    cryptoCurrency,
    fiatAmount: feeFiatAmount.toString(),
    fiatCurrency,
    options: {
      enabled: feeFiatAmount.gt(0),
    },
    priceProvider,
    slippage,
  });

  const feeCryptoAmount = feeCryptoData?.cryptoAmount;

  const fiatAmountBN = safeBN(fiatAmount);
  let totalFiatAmount = fiatAmountBN;
  let totalCryptoAmount = safeBN(cryptoAmountData?.cryptoAmount);

  if (isAmountNet) {
    totalFiatAmount = fiatAmountBN.plus(feeFiatAmount);
    totalCryptoAmount = totalCryptoAmount.plus(feeCryptoAmount || 0);
  }

  const netFiatAmount = totalFiatAmount.minus(feeFiatAmount);
  const netCryptoAmount = totalCryptoAmount.minus(feeCryptoAmount || 0);

  return {
    feeCryptoAmount,
    feeFiatAmount: feeFiatAmount.toString(),
    fetchedAt: feeCryptoData?.fetchedAt,
    netCryptoAmount: netCryptoAmount.toString(),
    netFiatAmount: netFiatAmount.toString(),
    price: feeCryptoData?.price,
    totalCryptoAmount: totalCryptoAmount.toString(),
    totalFiatAmount: totalFiatAmount.toString(),
  };
}
