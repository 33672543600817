import type { SxProps, Theme } from '@mui/material';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { AvatarIconButton } from '@noah-labs/fe-shared-ui-components';
import { checkFeature } from '@noah-labs/fe-shared-ui-shared';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature } from '@noah-labs/shared-schema-gql';

type PpAvatarIconButtonData = {
  showLnAddress?: boolean;
  sx: SxProps<Theme>;
};

export function AvatarIconButtonData({
  showLnAddress,
  sx,
}: PpAvatarIconButtonData): React.ReactElement {
  const { data: userData } = useUserInitUi();

  const displayLnAddress = checkFeature({
    has: userData?.userProfile.features,
    needs: [!!showLnAddress, [Feature.LnInvoice, Feature.LnPayment]],
  });

  return (
    <AvatarIconButton
      avatarContent={userData?.userProfile.userInitial}
      href={userRoutes.settings.base.path}
      showLnAddress={displayLnAddress}
      sx={sx}
      userLnAddress={userData?.userProfile.lnAddress}
      usernameDisplay={userData?.userProfile.UsernameDisplay}
    />
  );
}
