import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import { Balance, generatePath, getCurrencyAmountSlots } from '@noah-labs/fe-shared-ui-components';
import { useRouter, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { CompleteScene } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { getConfig } from '../../config';
import { useInAppReviewTrigger } from '../../hooks/useInAppReviewTrigger';
import type { SmWithdraw } from '../types';

export function WithdrawCompleteController({
  resetState,
  state,
}: TpStateMachine<SmWithdraw>): React.ReactElement {
  const { enableLoaderAnimations } = getConfig();
  const { cryptoCurrency, params } = useWalletParams();
  const { data: userData } = useUserInitUi();
  const { useOnRouteChange } = useRouter();

  /**
   * End of the flow so reset the flow state machine
   */
  useOnRouteChange(resetState);

  const enableInAppReviewTrigger = !!state.withdrawOrderId;
  useInAppReviewTrigger(enableInAppReviewTrigger);

  const Amounts = getCurrencyAmountSlots({
    cryptoAmount: state.cryptoAmount,
    cryptoCurrency,
    cryptoUnit: userData?.userProfile.DisplayUnit,
    fiatAmount: state.fiatAmount,
    fiatCurrency: userData?.userProfile.fiatCurrency,
    primaryCurrency: userData?.userProfile.PrimaryCurrency,
    roundDown: true,
  });

  return (
    <CompleteScene
      BalanceSlot={<Balance {...Amounts} />}
      enableAnimation={enableLoaderAnimations}
      processingTimeMs={state.payeeData?.processingTime}
      transactionUrl={generatePath(walletRoutes().account.base.path, params)}
    />
  );
}
