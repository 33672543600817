import { Typography } from '@mui/material';
import {
  AppContainer,
  AppLogoWithoutFullStop,
  AppMain,
  SceneMain,
  TextWithIcon,
  useDesktop,
} from '@noah-labs/fe-shared-ui-components';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import { NavBarData } from '../../../components/navigation/NavBarData';
import { SidebarNavData } from '../../../components/navigation/SidebarNavData';
import { RewardsHeader } from '../components/cards/RewardsHeader';
import type { TpRewardsTabProps, TpRewardsTabs } from '../types';

export type PpRewardsScene = {
  activeTab: TpRewardsTabs;
  pageTitle: string;
  tabs: TpRewardsTabProps[];
  userPoints: string | undefined;
};

export function RewardsScene({
  activeTab,
  pageTitle,
  tabs,
  userPoints,
}: PpRewardsScene): React.ReactElement {
  const isDesktop = useDesktop();

  return (
    <AppContainer BottomNavbarSlot={<NavBarData />} SideNavbarSlot={<SidebarNavData />}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AppMain>
        <AppHeaderData
          avatarButton
          disableBalancing
          disableFade
          helpButton
          notificationCenterButton
          fullWidth={isDesktop}
          // design request
          sx={{
            position: 'static',
          }}
        >
          <TextWithIcon>
            <AppLogoWithoutFullStop />
            <Typography noWrap variant="subHeadingM">
              Rewards
            </Typography>
          </TextWithIcon>
        </AppHeaderData>
        <RewardsHeader activeTab={activeTab} tabs={tabs} userPoints={userPoints} />

        <SceneMain dense dataQa="rewards">
          {tabs.map((t) => (
            <div key={t.value} hidden={activeTab !== t.value} role="tabpanel">
              {t.panel}
            </div>
          ))}
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
