//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgMinus } from '../icons/Minus';

export function MinusIcon(props: Omit<SvgIconProps, 'component' | 'children'>): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgMinus} data-qa="MinusIcon">
      <svg />
    </SvgIcon>
  );
}
