import { useImperativeHandle, useReducer } from 'react';
import type { Ref } from 'react';

type PpUseToggleDialog = {
  initialOpen?: boolean;
  ref: Ref<unknown>;
};

export type TpDialogToggle = {
  open: boolean;
  toggle: () => void;
};

export function useToggleDialog({ initialOpen, ref }: PpUseToggleDialog): TpDialogToggle {
  const [open, toggle] = useReducer((prev) => !prev, initialOpen ?? false);

  useImperativeHandle(ref, () => ({
    open,
    toggle,
  }));

  return { open, toggle };
}
