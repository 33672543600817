import type { MutableRefObject } from 'react';
import { useCallback, useRef } from 'react';
import type { TpUseWithdrawFeeWithTransaction } from '@noah-labs/fe-shared-data-access-wallet';
import { TpAnalyticsEvent, useAnalytics } from '@noah-labs/fe-shared-feature-analytics';
import type { TpOnSign } from '@noah-labs/fe-shared-feature-signing';
import { useSigning } from '@noah-labs/fe-shared-feature-signing';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import { SafeWrapper } from '@noah-labs/shared-cryptography';
import type { TpSimpleAmount } from '@noah-labs/shared-currencies';
import { logger } from '@noah-labs/shared-logger/browser';
import type { AccountType } from '@noah-labs/shared-schema-gql';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';
import { getWithdrawFeeAmounts } from '../utils/getWithdrawFeeAmounts';
import { hasFeeIncreased } from '../utils/hasFeeIncreased';

export type PpOnSubmitWithdrawOrder = {
  cryptoAmount: string;
  feeQuote: string | null | undefined;
  signature: TpOnSign['signature'];
};

type PpOnSubmit = TpOnSign & {
  cryptoAmount: string;
  cryptoAmountWithFee: string;
  feeFiatAmount: TpSimpleAmount;
  feeQuote: string | null | undefined;
  feeQuoteExpiresIn: dayjs.Dayjs;
  fiatAmount: string;
};

type PpOnSubmitWithNewSignature = {
  cryptoAmount: string;
  feeQuote: string | null | undefined;
  networkFee: TpSimpleAmount;
};

type PpCreateNewSignature = Pick<PpOnSubmitWithNewSignature, 'cryptoAmount' | 'networkFee'> & {
  pin: string;
};

type PpUseWithdrawNewSignature = {
  AccountType: AccountType;
  address: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  currentFeeDataRef: MutableRefObject<TpUseWithdrawFeeWithTransaction | undefined>;
  onSubmit: (payload: PpOnSubmitWithdrawOrder) => Promise<void>;
  toggleFeeIncreasedDialog: () => void;
};

export type TpUseWithdrawNewSignature = {
  isSubmitWithNewSignatureLoading: boolean;
  onSubmitWithNewSignature: (payload: PpOnSubmitWithNewSignature) => Promise<void>;
  onSubmitWithdrawOrder: (payload: PpOnSubmit) => Promise<void>;
};

export function useWithdrawNewSignature({
  AccountType,
  address,
  cryptoCurrency,
  currentFeeDataRef,
  onSubmit,
  toggleFeeIncreasedDialog,
}: PpUseWithdrawNewSignature): TpUseWithdrawNewSignature {
  const { track } = useAnalytics();
  const { createSignature } = useSigning();

  const storedPin = useRef('');

  const handleCreateNewSignature = useCallback(
    async (values: PpCreateNewSignature): Promise<TpOnSign['signature'] | undefined> => {
      try {
        const signature = await createSignature(new SafeWrapper(values.pin), {
          AccountType,
          Amount: values.cryptoAmount,
          CurrencyCode: cryptoCurrency.code,
          Destination: address,
          inputType: 'withdraw',
          NetworkFee: values.networkFee,
        });

        return signature;
      } catch {
        logger.error('Failed to create signature');
        return undefined;
      }
    },
    [createSignature, AccountType, address, cryptoCurrency.code],
  );

  const onCreateNewSignature = useMutation(
    async (values: PpOnSubmitWithNewSignature): Promise<void> => {
      try {
        const newSignature = await handleCreateNewSignature({
          cryptoAmount: values.cryptoAmount,
          networkFee: values.networkFee,
          pin: storedPin.current,
        });

        await onSubmit({
          cryptoAmount: values.cryptoAmount,
          feeQuote: values.feeQuote,
          signature: newSignature,
        });

        toggleFeeIncreasedDialog();
      } catch {
        logger.error('Failed to submit with new signature');
      }
    },
  );

  const onSubmitWithdrawOrder = useCallback(
    async (values: PpOnSubmit): Promise<void> => {
      try {
        const now = dayjs();
        const isQuoteExpired = now.isAfter(values.feeQuoteExpiresIn);

        if (!values.pin || !isQuoteExpired) {
          track(TpAnalyticsEvent.WithdrawSubmittedWithUnexpiredFeeQuote);
          await onSubmit({
            cryptoAmount: values.cryptoAmountWithFee,
            feeQuote: values.feeQuote,
            signature: values.signature,
          });
          return;
        }

        track(TpAnalyticsEvent.WithdrawFeeQuoteExpired);

        if (currentFeeDataRef.current?.feeError) {
          throw new Error('Withdraw fee refetch failed');
        }

        const latestFeeData = currentFeeDataRef.current;
        const submittedFeeFiatAmount = values.feeFiatAmount;

        if (hasFeeIncreased(latestFeeData?.feeFiatAmount, submittedFeeFiatAmount)) {
          track(TpAnalyticsEvent.WithdrawFeeIncreased);
          storedPin.current = values.pin;
          toggleFeeIncreasedDialog();
          return;
        }

        /**
         * at this point we know the amounts didn't increase,
         * so we can recreate the signature with the latest amounts
         * and submit with the latest fee quote
         */
        const latestTotalAmounts = getWithdrawFeeAmounts({
          cryptoAmount: values.cryptoAmount,
          feeCryptoAmount: latestFeeData?.feeCryptoAmount,
          feeFiatAmount: latestFeeData?.feeFiatAmount,
          fiatAmount: values.fiatAmount,
        });

        const newSignature = await handleCreateNewSignature({
          cryptoAmount: latestTotalAmounts.cryptoAmountWithFee,
          networkFee: latestFeeData?.feeCryptoAmount,
          pin: values.pin,
        });

        await onSubmit({
          cryptoAmount: latestTotalAmounts.cryptoAmountWithFee,
          feeQuote: latestFeeData?.feeQuote,
          signature: newSignature,
        });
      } catch {
        // useWalletError handles error
      }
    },
    [currentFeeDataRef, handleCreateNewSignature, onSubmit, track, toggleFeeIncreasedDialog],
  );

  return {
    isSubmitWithNewSignatureLoading: onCreateNewSignature.isLoading,
    onSubmitWithdrawOrder,
    onSubmitWithNewSignature: onCreateNewSignature.mutateAsync,
  };
}
