import type { SVGProps } from 'react';

export function SvgApple(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="currentColor"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20.916 8.182c-.139.108-2.597 1.493-2.597 4.573 0 3.562 3.128 4.822 3.221 4.853-.014.077-.496 1.726-1.649 3.406-1.027 1.479-2.1 2.955-3.732 2.955s-2.052-.948-3.936-.948c-1.837 0-2.49.979-3.982.979-1.493 0-2.535-1.368-3.733-3.048C3.121 18.979 2 15.914 2 13.004c0-4.666 3.034-7.14 6.02-7.14 1.586 0 2.909 1.041 3.905 1.041.948 0 2.427-1.104 4.232-1.104.684 0 3.142.063 4.76 2.381ZM15.3 3.826c.746-.886 1.274-2.115 1.274-3.344 0-.17-.014-.343-.045-.482-1.215.046-2.66.809-3.531 1.82-.684.777-1.323 2.006-1.323 3.252 0 .187.032.374.046.434.077.015.202.031.326.031 1.09 0 2.46-.73 3.253-1.711" />
    </svg>
  );
}
