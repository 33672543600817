import type { ReactElement } from 'react';
import { checkFeature } from '@noah-labs/fe-shared-ui-shared';
import type { PpWC, TpFeatureNeeds } from '@noah-labs/fe-shared-ui-shared';
import { useUserInitUi } from '../hooks/useUserInitUi';

type PpRestrictedFeature = PpWC & {
  // An array of feature requirements. Can be a mix of single features and arrays of features.
  needs: TpFeatureNeeds;
};

/**
 * The `RestrictedFeature` component is a conditional wrapper that determines whether its children should be rendered
 * based on a set of feature requirements provided in the `needs` prop and the user's available features.
 */
export function RestrictedFeature({ children, needs }: PpRestrictedFeature): ReactElement | null {
  const { data: userData } = useUserInitUi();
  const hasAllNeededFeatures = checkFeature({ has: userData?.userProfile.features, needs });
  return hasAllNeededFeatures ? (children as ReactElement) : null;
}
