import { useCallback } from 'react';
import { useDestinations } from '@noah-labs/fe-shared-data-access-analytics';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import type { TpConsentFormValues } from '@noah-labs/fe-shared-ui-user';
import { MarketingUpdatesScene, useUserError } from '@noah-labs/fe-shared-ui-user';
import { getConfig } from '../config';
import { useConsentOnSubmit } from '../hooks/useConsentOnSubmit';
import type { StRegistration } from '../types';

type PpMarketingUpdates = {
  nextAction: () => void;
  nextActionDirty: () => void;
} & Pick<TpStateMachine<StRegistration>, 'state'>;

export function MarketingUpdates({
  nextAction,
  nextActionDirty,
  state,
}: PpMarketingUpdates): React.ReactElement {
  const { analytics, segment } = getConfig();

  const { data: { groupedDestinations } = {} } = useDestinations([
    segment.writeKey,
    analytics.cdnUrl,
  ]);

  const { error, handleConsentSubmit } = useConsentOnSubmit({
    groupedDestinations,
    nextAction,
    nextActionDirty,
  });

  const handleSubmit = useCallback(
    async (emailContact) => {
      const values: TpConsentFormValues = { ...state, EmailContact: emailContact };
      await handleConsentSubmit(values, state.isDirty);
    },
    [handleConsentSubmit, state],
  );

  const { ApiErrorScene } = useUserError(error);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return <MarketingUpdatesScene onSubmit={handleSubmit} />;
}
