import { NoahErrorType } from '@noah-labs/shared-schema-gql';

type TpFeError = {
  helpText?: string;
  message: string;
};
export function getFeError(errorType?: NoahErrorType | string): TpFeError {
  switch (errorType) {
    case NoahErrorType.Unexpected:
      return {
        helpText: 'Please go back and try again',
        message: 'An unexpected error occurred',
      };
    case NoahErrorType.UnsupportedCurrency:
      return {
        helpText: 'Please go back and select a different currency',
        message: 'The requested currency is not supported',
      };
    case NoahErrorType.InvalidMessage:
      return {
        helpText: 'Please go back and try again',
        message: 'The request you made had some bad data',
      };
    case NoahErrorType.InvalidEmail:
      return {
        helpText: 'Please login and try again',
        message: 'You do not have a registered email',
      };
    default:
      return {
        message: 'An unknown error occurred',
      };
  }
}
