import type { PpCountdown } from '@noah-labs/fe-shared-ui-components';
import { Countdown } from '@noah-labs/fe-shared-ui-components';

type PpInvoiceExpires = Pick<PpCountdown, 'expiryTime'>;

export function InvoiceExpires({ expiryTime }: PpInvoiceExpires): React.ReactElement {
  return (
    <Countdown
      expiredLabel="Your invoice is expired."
      expiryTime={expiryTime}
      prefixLabel="Invoice expires in"
      variant="paragraphBodyS"
    />
  );
}
