import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { Link, Stack } from '@mui/material';
import type { TpNormalisedDestinations } from '@noah-labs/fe-shared-data-access-analytics';
import type { UserInitQuery } from '@noah-labs/fe-shared-data-access-user';
import {
  AppFooter,
  AppMain,
  ContactOptions,
  CookieOptions,
  FooterContentText,
  ListSection,
  PrimaryButton,
  SceneHeader,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
  TermsOptions,
  TextOrSkeleton,
} from '@noah-labs/fe-shared-ui-components';
import type { TpSkeletonText } from '@noah-labs/fe-shared-ui-shared';
import { webLinks } from '@noah-labs/shared-constants';
import { FormProvider, useForm } from 'react-hook-form';

export type TpUserSettingsConsentQuery = UserInitQuery['userSettingsConsent'][0];

export type TpConsentFormValues = {
  Cookies: {
    advertising: boolean;
    functional: boolean;
    marketingAndAnalytics: boolean;
  };
  EmailContact: boolean;
  Terms: boolean;
};
export type PpConsentScene = {
  destinations: TpNormalisedDestinations | undefined;
  email: TpSkeletonText;
  logoutTo?: string;
  onSubmit: (values: TpConsentFormValues, isDirty: boolean) => Promise<void> | void;
  userSettingsConsent: TpUserSettingsConsentQuery | undefined | null;
  variant: 'settings' | 'registration';
};
export function ConsentScene({
  destinations,
  email,
  logoutTo,
  onSubmit,
  userSettingsConsent,
  variant,
}: PpConsentScene): React.ReactElement {
  /**
   * needs useMemo else cause the form to reset on each render
   */
  const defaultValues = useMemo<TpConsentFormValues>(
    () => ({
      /**
       * functional cookies are required and are exempt from GDPR consent requirements.
       * advertising, analytics and marketing cookies need explicit consent.
       */
      Cookies: {
        advertising: userSettingsConsent?.Cookies?.advertising ?? false,
        functional: true,
        marketingAndAnalytics: userSettingsConsent?.Cookies?.marketingAndAnalytics ?? false,
      },
      EmailContact: userSettingsConsent?.EmailContact ?? false,
      Terms: true,
    }),
    [
      userSettingsConsent?.Cookies?.advertising,
      userSettingsConsent?.Cookies?.marketingAndAnalytics,
      userSettingsConsent?.EmailContact,
    ],
  );
  const methods = useForm<TpConsentFormValues>({ defaultValues });

  let buttonLabel = 'Continue';
  let buttonDisabled = false;
  let showContactField = false;
  let cookiesTitle;

  switch (variant) {
    case 'settings':
      buttonDisabled = !methods.formState.isDirty;
      buttonLabel = 'Save';
      showContactField = true;
      cookiesTitle = 'Cookies';
      break;
    default:
      break;
  }

  useEffect(() => {
    /**
     * since the user's prefs are loaded from an api, this can come after the form
     * already has default values, hence we need to reset it with the new ones
     * however, we don't want to reset whilst the form is submitting else the button will go to disabled state
     */
    if (methods.formState.isSubmitting) {
      return;
    }
    methods.reset(defaultValues);
  }, [defaultValues, methods]);

  const handleSubmit = useCallback(
    async (values: TpConsentFormValues) => {
      await onSubmit(values, methods.formState.isDirty);
    },
    [methods.formState.isDirty, onSubmit],
  );

  return (
    <Fragment>
      <AppMain>
        <SceneHeader>
          <SceneTitleLarge>We value your privacy</SceneTitleLarge>
          <SceneParagraph>
            Please select your privacy preferences below. Your preferences can be changed at any
            time.
          </SceneParagraph>
        </SceneHeader>
        <SceneMain dataQa="user-consent">
          <Stack spacing={4}>
            <FormProvider {...methods}>
              <form id="user-consent" onSubmit={methods.handleSubmit(handleSubmit)}>
                <Stack spacing={2}>
                  {showContactField && (
                    <ListSection title="Contact">
                      <ContactOptions />
                    </ListSection>
                  )}
                  <ListSection title={cookiesTitle}>
                    {destinations && <CookieOptions {...destinations} />}
                  </ListSection>
                  <ListSection>
                    <TermsOptions
                      privacyPolicyPath={webLinks.privacyPolicy.path}
                      termsAndConditionsPath={webLinks.termsAndConditions.path}
                    />
                  </ListSection>
                </Stack>
              </form>
            </FormProvider>
          </Stack>
        </SceneMain>
      </AppMain>
      <AppFooter hasContent>
        <Stack spacing={3}>
          {logoutTo && (
            <FooterContentText>
              Not <TextOrSkeleton>{email}</TextOrSkeleton>?{' '}
              <Link href={logoutTo}>Log&nbsp;out and start over.</Link>
            </FooterContentText>
          )}
          <PrimaryButton
            disabled={buttonDisabled}
            form="user-consent"
            loading={methods.formState.isSubmitting}
            type="submit"
          >
            {buttonLabel}
          </PrimaryButton>
        </Stack>
      </AppFooter>
    </Fragment>
  );
}
