import type { SVGProps } from 'react';

export function SvgProfile(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 25"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 12.107a5 5 0 1 0 0-9.999 5 5 0 0 0 0 10Zm3.5 1.25h-.652a6.8 6.8 0 0 1-5.696 0H8.5a5.25 5.25 0 0 0-5.25 5.25v1.625c0 1.035.84 1.875 1.875 1.875h13.75c1.035 0 1.875-.84 1.875-1.875v-1.625a5.25 5.25 0 0 0-5.25-5.25"
        fill="currentColor"
      />
    </svg>
  );
}
