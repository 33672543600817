import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { LearnMoreButton, LowDialog, PrimaryButton } from '@noah-labs/fe-shared-ui-components';
import { useToggleDialog } from '@noah-labs/fe-shared-ui-shared';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { webLinks } from '@noah-labs/shared-constants';
import type { ManualRampCurrencySettings } from '@noah-labs/shared-schema-gql';

type PpBankFeeDialog = {
  fiatCurrencyConfig: ManualRampCurrencySettings;
  fiatCurrencySymbol: string;
};

export const BankFeeDialog = forwardRef<TpDialogToggle, PpBankFeeDialog>(
  (
    { fiatCurrencyConfig: { FeeMinimumFiatAmount, FeePercentage }, fiatCurrencySymbol },
    ref,
  ): JSX.Element => {
    const { open, toggle } = useToggleDialog({ ref });

    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle>Fees</DialogTitle>
        <DialogContent>
          <DialogContentText paragraph>
            At NOAH, we believe in full transparency. We charge a competitive {FeePercentage}% fee
            on all bank transfer sell orders, with a minimum fee of {fiatCurrencySymbol}
            {FeeMinimumFiatAmount}. All fees are displayed on your sell confirmation screen.
          </DialogContentText>

          <DialogContentText>
            Note: Your bank may charge additional fees, which are beyond NOAH’s control.
          </DialogContentText>

          <LearnMoreButton
            href={webLinks.faq.offRampFiatCurrencySupport}
            target="_blank"
            variant="paragraphBodyM"
          >
            Learn more about fees.
          </LearnMoreButton>
        </DialogContent>
        <DialogActions>
          <PrimaryButton color="primaryBrand" onClick={toggle}>
            Got it
          </PrimaryButton>
        </DialogActions>
      </LowDialog>
    );
  },
);

BankFeeDialog.displayName = BankFeeDialog.name;
