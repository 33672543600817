export class LightningError extends Error {
  static ErrorName = 'LightningLimitError';

  static Title = 'Lightning Error';

  constructor(message: string, title?: string) {
    super(message);
    LightningError.Title = title || LightningError.Title;
    this.name = LightningError.ErrorName;
  }
}
