import type { TpFiatCurrency } from '@noah-labs/shared-currencies';
import { z } from 'zod';
import { combinedFiatAmountSchema } from './amounts';

export function getCkoBuyAmountSchema(
  fiatCurrency: TpFiatCurrency | undefined,
  minimumFiatAmount: string,
): z.ZodSchema {
  return z.object({
    fiatAmount: combinedFiatAmountSchema(fiatCurrency, minimumFiatAmount, 'buy'),
    // this value may be null on the first load while the crypto price is being fetched
    secondaryAmount: z.string().min(1),
  });
}
