import { getDefaultAccount } from '@noah-labs/fe-shared-data-access-wallet';
import { CodeIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CodeIcon';
import type { PpHeaderIconButton } from '@noah-labs/fe-shared-ui-components';
import { generatePath, HeaderIconButton } from '@noah-labs/fe-shared-ui-components';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';

type PpScanIconButtonData = Pick<PpHeaderIconButton, 'edge'>;

export function ScanIconButtonData({ edge }: PpScanIconButtonData): React.ReactElement {
  const da = getDefaultAccount();
  const params = {
    accountType: da.AccountType,
    currencyCode: da.CurrencyCode,
  };

  return (
    <HeaderIconButton
      aria-label="scan"
      edge={edge}
      href={generatePath(walletRoutes().receive.base.path, params)}
    >
      <CodeIcon />
    </HeaderIconButton>
  );
}
