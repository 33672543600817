import React from 'react';
import { TpFeExtraFeature } from '@noah-labs/fe-shared-data-access-user';
import { KycAccessControlData } from '@noah-labs/fe-shared-feature-kyc';
import {
  DepositAccountController,
  DepositNetworkController,
  LightningReceiveEnterAmountController,
  LightningReceiveViewInvoiceController,
  ReceiveController,
} from '@noah-labs/fe-shared-feature-wallet';
import type { SmDeposit } from '@noah-labs/fe-shared-feature-wallet';
import {
  AppContainer,
  AppHeaderTitle,
  generatePath,
  Switch404,
  useStateMachine,
} from '@noah-labs/fe-shared-ui-components';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature } from '@noah-labs/shared-schema-gql';
import { matchPath, Route } from 'react-router-dom';
import { AppHeaderData } from '../../../../components/layout/AppHeaderData';
import { AccessControlData } from '../../../auth/AccessControlData';

const emptyState: SmDeposit = {
  fiatAmount: '',
  paymentRequest: '',
  publicID: '',
};

export function ReceiveRouter(): React.ReactElement {
  const { base, lightning, network, viewAddress } = walletRoutes().receive;
  const { pathname } = useRouter();
  const { params } =
    matchPath(pathname, {
      path: lightning.viewInvoice.path,
    }) || {};

  const sm = useStateMachine<SmDeposit>({
    emptyState,
    name: 'Deposit',
  });

  const viewInvoiceInvalid = !sm.state.paymentRequest;

  return (
    <Switch404>
      <Route exact path={base.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <KycAccessControlData origin="deposit">
            <AppContainer headTitle={base.title}>
              <AppHeaderData backButton exitButton helpButton />
              <DepositAccountController />
            </AppContainer>
          </KycAccessControlData>
        </AccessControlData>
      </Route>

      <Route exact path={network.path}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          needsFeature={[TpFeExtraFeature.DepositRoutes]}
        >
          <AppContainer headTitle={network.title}>
            <AppHeaderData backButton exitButton helpButton />
            <DepositNetworkController />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route exact path={viewAddress.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <AppContainer headTitle={viewAddress.title}>
            <AppHeaderData backButton disableFade helpButton>
              <AppHeaderTitle>{viewAddress.title}</AppHeaderTitle>
            </AppHeaderData>
            <ReceiveController />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route exact path={lightning.enterAmount.path}>
        <AccessControlData needsAuthStatus={['authenticated']} needsFeature={[Feature.LnInvoice]}>
          <AppContainer headTitle={lightning.enterAmount.title}>
            <AppHeaderData backButton exitButton helpButton>
              <AppHeaderTitle>{lightning.enterAmount.title}</AppHeaderTitle>
            </AppHeaderData>
            <LightningReceiveEnterAmountController {...sm} />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route exact path={lightning.viewInvoice.path}>
        <AccessControlData
          // redirect to the beginning of the flow if the payment request has not yet been generated
          needsAuthStatus={['authenticated']}
          needsFeature={[Feature.LnInvoice]}
          redirectInvalid={
            viewInvoiceInvalid && params && generatePath(lightning.enterAmount.path, params)
          }
        >
          <AppContainer headTitle={lightning.viewInvoice.title}>
            <AppHeaderData exitButton helpButton>
              <AppHeaderTitle>{lightning.viewInvoice.title}</AppHeaderTitle>
            </AppHeaderData>
            <LightningReceiveViewInvoiceController {...sm} />
          </AppContainer>
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
