import type { SVGProps } from 'react';

export function SvgAccess(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.2 12C1.2 6.035 6.036 1.2 12 1.2S22.8 6.035 22.8 12 17.965 22.8 12 22.8C6.036 22.8 1.2 17.965 1.2 12Z"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="M9 12.686 11.305 15 15.6 9.6"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
}
