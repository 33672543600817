import type { NotificationType } from './types';

export const headerHeight = '59px';
export const notificationListDesktopHeight = '600px';
export const notificationListMobileHeight = '100vh';

export const transactionNotificationTypes: NotificationType[] = [
  'failed-buy',
  'failed-external-deposit',
  'failed-external-withdraw',
  'failed-internal-deposit',
  'failed-internal-withdraw',
  'failed-sell',
  'successful-buy',
  'successful-external-deposit',
  'successful-external-withdraw',
  'successful-internal-deposit',
  'successful-internal-withdraw',
  'successful-sell',
];
