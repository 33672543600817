import { TransferDestinationType } from '@noah-labs/shared-schema-gql';
import { TpPaymentMethod } from '../types';

export function getPayoutTansferDestinationType(pm?: TpPaymentMethod): TransferDestinationType {
  switch (pm) {
    case TpPaymentMethod.BankTransfer:
      return TransferDestinationType.ManualRamp;
    default:
      return TransferDestinationType.Checkout;
  }
}
