import type { SVGProps } from 'react';

export function SvgCloseDialog(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M7.771 6.301A1.037 1.037 0 1 0 6.304 7.77l4.23 4.23-4.23 4.23a1.038 1.038 0 0 0 1.468 1.467l4.229-4.23 4.227 4.229a1.037 1.037 0 1 0 1.467-1.468L13.468 12l4.228-4.229a1.038 1.038 0 0 0-1.467-1.467L12 10.53 7.77 6.3Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
