import { forwardRef, useCallback, useEffect, useRef } from 'react';
import { DialogContent } from '@mui/material';
import { useDialogs } from '@noah-labs/fe-shared-ui-components';
import { logger } from '@noah-labs/shared-logger/browser';
import type { AwsCaptchaApi } from '@noah-labs/shared-schema-gql';
import { HttpStatusCode } from 'axios';
import useScript from 'react-script-hook';

async function awaitInitializationWithTimeout(): Promise<void> {
  const { AwsWafCaptcha, AwsWafIntegration } = window;
  let isTimeout = false;
  const timeout = setTimeout(() => {
    isTimeout = true;
    logger.error('aws waf captcha initialization timeout');
  }, 3000);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  while ((AwsWafCaptcha === undefined || AwsWafIntegration === undefined) && !isTimeout) {
    // eslint-disable-next-line no-await-in-loop
    await new Promise((resolve) => {
      setTimeout(resolve, 300);
    });
  }
  clearTimeout(timeout);
}
type TpUseAwsCaptcha = {
  captchaCallback: () => Promise<boolean>;
};

const DIALOG_KEY = 'captcha';

const CaptchaContent = forwardRef((props: { onMount: () => void; onUnmount: () => void }, ref) => {
  const onUnmountRef = useRef(props.onUnmount);
  const onMountRef = useRef(props.onMount);
  useEffect(() => {
    onMountRef.current();
    return onUnmountRef.current;
  }, []);
  return <DialogContent ref={ref} />;
});
CaptchaContent.displayName = 'CaptchaContent';

export function useAwsCaptcha(api: AwsCaptchaApi | undefined): TpUseAwsCaptcha {
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const [, scriptError] = useScript({
    checkForExisting: true,
    src: api?.src ?? null,
  });
  const { closeDialog, pushDialog } = useDialogs();

  useEffect(() => {
    logger.info('aws waf captcha enabled:', !!api);
  }, [api]);

  useEffect(() => {
    if (!scriptError) {
      return;
    }
    logger.error('aws waf captcha script loading failed ', scriptError);
  }, [scriptError]);

  const captchaCallback = useCallback(async (): Promise<boolean> => {
    if (!api) {
      return true;
    }

    if (scriptError) {
      return false;
    }

    await awaitInitializationWithTimeout();
    const { AwsWafCaptcha, AwsWafIntegration } = window;

    if (!(AwsWafCaptcha && AwsWafIntegration)) {
      logger.error('aws waf captcha script was not initialized');
      return false;
    }

    try {
      const awsResponse = await AwsWafIntegration.fetch('');

      logger.info(`waf integration response status:`, awsResponse.status);
      if (awsResponse.status === HttpStatusCode.MethodNotAllowed) {
        let isSucceed = false;
        let resolve: undefined | ((v: boolean) => void);
        const promise = new Promise<boolean>((res) => {
          resolve = res;
        });

        pushDialog({
          Content: (
            <CaptchaContent
              ref={dialogRef}
              onMount={(): void => {
                AwsWafCaptcha.renderCaptcha(dialogRef.current, {
                  apiKey: api.key,
                  onError: (captchaError) => {
                    logger.error('aws waf captcha verification failed', captchaError);
                    resolve?.(false);
                  },
                  onSuccess: () => {
                    isSucceed = true;
                    logger.info('aws waf captcha verification succeed');
                    resolve?.(true);
                  },
                });
              }}
              onUnmount={(): void => {
                if (isSucceed) {
                  return;
                }
                logger.error('aws waf captcha verification failed', 'dialog was closed');
                resolve?.(false);
              }}
            />
          ),
          key: DIALOG_KEY,
        });
        return await promise;
      }

      return true;
    } catch (error) {
      logger.error('aws waf captcha verification failed', error);
      return false;
    } finally {
      closeDialog(DIALOG_KEY);
    }
  }, [api, scriptError, pushDialog, closeDialog]);

  return { captchaCallback };
}
