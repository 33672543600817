import type { TpCheckoutTokenRequest } from '@noah-labs/fe-shared-data-access-cko';
import type { TpPushAlert } from '@noah-labs/fe-shared-ui-components';
import { TpPaymentMethod } from '@noah-labs/fe-shared-ui-shared';
import { TpCheckoutPaymentStatus } from '@noah-labs/fe-shared-util-shared';
import type { CardIssuerNotification } from '@noah-labs/shared-schema-gql';
import {
  CardIssuerNotificationReason,
  FiatPaymentStatus,
  FiatPaymentTokenizationSource,
} from '@noah-labs/shared-schema-gql';

export function getCheckoutTokenType(
  paymentMethod: TpPaymentMethod,
): TpCheckoutTokenRequest['type'] {
  switch (paymentMethod) {
    case TpPaymentMethod.GooglePay:
      return 'googlepay';
    case TpPaymentMethod.ApplePay:
    default:
      return 'applepay';
  }
}

export function getCheckoutPaymentStatus(status: FiatPaymentStatus): TpCheckoutPaymentStatus {
  switch (status) {
    case FiatPaymentStatus.Successful:
      return TpCheckoutPaymentStatus.success;
    case FiatPaymentStatus.Failed:
    default:
      return TpCheckoutPaymentStatus.failure;
  }
}

export function getTokenSource(method: TpPaymentMethod): FiatPaymentTokenizationSource {
  switch (method) {
    case TpPaymentMethod.ApplePay:
      return FiatPaymentTokenizationSource.ApplePay;
    case TpPaymentMethod.GooglePay:
      return FiatPaymentTokenizationSource.GooglePay;
    case TpPaymentMethod.Card:
    default:
      return FiatPaymentTokenizationSource.Checkout;
  }
}

export function getCardIssuerNotificationAlert(
  notification: CardIssuerNotification,
): TpPushAlert | undefined {
  if (!notification.Active) {
    return undefined;
  }

  switch (notification.Reason) {
    case CardIssuerNotificationReason.HsbcPaymentsFailing:
      return {
        autoHideDuration: 0,
        dismissable: true,
        key: CardIssuerNotificationReason.HsbcPaymentsFailing,
        message:
          'Due to ongoing payment processing issues with cards issued by HSBC, it is likely that this transaction will be declined. We are working on addressing this issue over the coming weeks.',
        preventDuplicate: true,
        severity: 'warning',
      };
    case CardIssuerNotificationReason.CreditCardPaymentsFailing:
      return {
        autoHideDuration: 0,
        dismissable: true,
        key: CardIssuerNotificationReason.CreditCardPaymentsFailing,
        message:
          'Please be aware that transactions with a credit card are very likely to be declined by your card issuer. We are working on addressing this issue over the coming weeks.',
        preventDuplicate: true,
        severity: 'warning',
      };
    default:
      return undefined;
  }
}
