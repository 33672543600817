import { useUserAccountsSend } from '@noah-labs/fe-shared-feature-user';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import { AccountCardList, SelectAccountScene } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { isFalseyOrEmptyArray } from '@noah-labs/shared-util-vanilla';
import { AccountCardData } from '../../components/AccountCardData';

export function WithdrawAccountController(): React.ReactElement {
  const { data: accountsData, isFetched } = useUserAccountsSend();

  const AccountsSlot = (
    <AccountCardList isFetched={isFetched}>
      {!isFalseyOrEmptyArray(accountsData) &&
        accountsData.map((account) => (
          <AccountCardData
            key={account.ID}
            balance={account.Balance?.Available}
            cryptoCurrency={account.cryptoCurrency}
            href={generatePath(walletRoutes().send.address.base.path, {
              accountType: account.AccountType,
              currencyCode: account.CurrencyCode,
            })}
            id={account.ID}
          />
        ))}
    </AccountCardList>
  );

  return (
    <SelectAccountScene
      AccountsSlot={AccountsSlot}
      subTitle="Select which of your NOAH accounts you are sending from."
      title="Select account"
    />
  );
}
