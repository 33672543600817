import { css } from '@emotion/react';
import type { SxProps, Theme } from '@mui/material';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StopwatchIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/StopwatchIcon';
import { coreThemeConstants, Countdown, cssMixins } from '@noah-labs/fe-shared-ui-components';

export type PpFlashTimer = {
  ends: string | undefined | null;
  sx?: SxProps<Theme>;
};

export function FlashTimer({ ends, sx }: PpFlashTimer): React.ReactElement {
  const theme = useTheme();
  const styles = {
    container: css`
      width: 100%;
      ${cssMixins.rowCentered}
      padding: ${theme.spacing(1.25, 1)};
      color: ${theme.palette.grayscale.white};
      background: ${theme.palette.gradients.primary};
    `,
    icon: css`
      font-size: 0.875rem;
    `,
    title: css`
      /* TODO Font variant missing */
      font-family: ${coreThemeConstants.fontFamily.expanded};
      font-style: normal;
      font-weight: 900;
      font-size: 0.875rem;
      text-transform: uppercase;
      line-height: 0.75rem;
    `,
  };

  return (
    <Stack css={styles.container} direction="column" spacing={0.5} sx={sx}>
      <Typography color="grayscale.white" css={styles.title}>
        LIMITED TIME QUEST
      </Typography>
      <Stack alignItems="center" direction="row" spacing={0.5}>
        <StopwatchIcon css={styles.icon} />
        <Countdown expiryTime={ends} suffixLabel="left" variant="paragraphBodySBold" />
      </Stack>
    </Stack>
  );
}
