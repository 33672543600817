import { useCallback } from 'react';
import KycId from '@noah-labs/fe-shared-ui-assets/svg/images/kyc-id.svg?url';
import KycWallet from '@noah-labs/fe-shared-ui-assets/svg/images/kyc-wallet.svg?url';
import { KycReminderScene } from '@noah-labs/fe-shared-ui-kyc';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import { kycRoutes as routes } from '@noah-labs/fe-shared-util-routes';

export type KycReminderOrigin = 'buy' | 'deposit' | 'login' | 'onboarding' | 'sell' | 'withdraw';

function getParagraph(origin: KycReminderOrigin | undefined): string {
  switch (origin) {
    case 'onboarding':
      return 'Please complete KYC verification to unlock all the features of the NOAH app.';
    case 'login':
      return "We've made important security upgrades. Please complete your KYC verification now to continue enjoying all app features.";
    case 'deposit':
    case 'buy':
    case 'sell':
      return 'To ensure the safety of your assets, please complete your identity verification. Additionally, avoid sending funds to previously used NOAH wallet addresses to prevent potential loss.';
    case 'withdraw':
      return 'Your NOAH account currently has a zero balance. Please complete your identity verification to continue using our transfer services.';
    default:
      return "We've made important security upgrades. Please complete your KYC verification now to continue enjoying all app features.";
  }
}

function getTitle(origin: KycReminderOrigin | undefined): string {
  switch (origin) {
    case 'onboarding':
    case 'login':
      return 'Please complete identity verification to continue';
    case 'deposit':
      return 'Verify your identity to access your deposit addresses';
    case 'buy':
    case 'sell':
      return 'Complete KYC to ensure the safety of your assets';
    case 'withdraw':
      return 'Verify your identity to enable transfers';
    default:
      return 'Complete KYC to continue using the NOAH app';
  }
}

export function KycReminder(): React.ReactElement {
  const { goBack, push, search } = useRouter();

  const origin = new URLSearchParams(search).get('origin') as KycReminderOrigin | undefined;

  const handleNotNowClick = useCallback(() => {
    goBack();
  }, [goBack]);

  const handleYesClick = useCallback(() => {
    push(routes.base.path);
  }, [push]);

  const showFaqLink = Boolean(origin === 'login' || origin === 'withdraw');

  const image = origin === 'deposit' ? KycWallet : KycId;
  const paragraph = getParagraph(origin);
  const title = getTitle(origin);

  return (
    <KycReminderScene
      handleNotNowClick={handleNotNowClick}
      handleYesClick={handleYesClick}
      image={image}
      paragraph={paragraph}
      showFaqLink={showFaqLink}
      title={title}
    />
  );
}
