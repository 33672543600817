import { useCallback, useEffect, useRef } from 'react';

export function useThrottling<F extends (...args: unknown[]) => unknown>(func: F, time: number): F {
  const blockerRef = useRef<boolean>(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const throttledFunction = useCallback(
    (...args: Parameters<F>) => {
      if (blockerRef.current) {
        return;
      }
      func(...args);
      blockerRef.current = true;
      timeoutRef.current = setTimeout(() => {
        blockerRef.current = false;
      }, time);
    },
    [func, time],
  ) as F;

  // Cleanup timeouts on unmount to avoid memory leaks
  useEffect(
    () => () => {
      if (!timeoutRef.current) {
        return;
      }
      clearTimeout(timeoutRef.current);
    },
    [],
  );

  return throttledFunction;
}
