import type { FormEvent } from 'react';
import { Fragment, useCallback } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AppFooter,
  AppMain,
  PinInput,
  PrimaryButton,
  SceneHeader,
  SceneMain,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';
import { pinLength } from '@noah-labs/shared-cryptography';
import type { Resolver, UseFormSetError } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import type { TpPinForm } from '../types';
import { verifyPinSchema } from '../utils/securityValidation';

export type PpEnterCurrentPinScene = {
  onSubmit: ({ pin }: TpPinForm, setError: UseFormSetError<TpPinForm>) => Promise<void>;
};

const formId = 'user-update-pin-enter-current-pin';

export function EnterCurrentPinScene({ onSubmit }: PpEnterCurrentPinScene): React.ReactElement {
  const methods = useForm<TpPinForm>({
    defaultValues: {
      pin: '',
    },
    mode: 'onChange',
    resolver: zodResolver(verifyPinSchema) as Resolver<TpPinForm>,
  });

  const { handleSubmit, setError } = methods;

  const onSubmitClick = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      await handleSubmit(async (value) => {
        await onSubmit(value, setError);
      })(e);
    },
    [handleSubmit, onSubmit, setError],
  );

  return (
    <Fragment>
      <AppMain>
        <SceneHeader>
          <SceneTitleLarge>Enter your security PIN</SceneTitleLarge>
        </SceneHeader>
        <SceneMain dataQa={formId}>
          <FormProvider {...methods}>
            <form id={formId} onSubmit={onSubmitClick}>
              <PinInput name="pin" pinLength={pinLength} />
            </form>
          </FormProvider>
        </SceneMain>
      </AppMain>
      <AppFooter>
        <PrimaryButton
          disabled={!methods.formState.isValid}
          form={formId}
          loading={methods.formState.isSubmitting}
          type="submit"
        >
          Continue
        </PrimaryButton>
      </AppFooter>
    </Fragment>
  );
}
