//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgEye } from '../icons/Eye';

export function EyeIcon(props: Omit<SvgIconProps, 'component' | 'children'>): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgEye} data-qa="EyeIcon">
      <svg />
    </SvgIcon>
  );
}
