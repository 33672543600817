import { TpRewardsTabs } from './types';

const tabs = Object.keys(TpRewardsTabs).join('|').toLowerCase();

export const routes = {
  badge: { path: `/rewards/${TpRewardsTabs.Badges}/:id` },
  base: { path: '/rewards' },
  inviteFriends: { path: `/rewards/invite-friends`, title: 'Invite friends' },
  quest: { path: `/rewards/${TpRewardsTabs.Quests}/:id` },
  tabs: { path: `/rewards/:tab(${tabs})`, title: 'NOAH Rewards' },
};
