import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { LowDialog, PrimaryButton } from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoNetworkUI, TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { useToggleDialog } from '@noah-labs/fe-shared-ui-shared';
import { isLightningNetwork } from '@noah-labs/shared-currencies';
import { CurrencyCode } from '@noah-labs/shared-schema-gql';
import { ReceiveBTCDialogContent } from './content/ReceiveBTCDialogContent';
import { ReceiveBTCLightningContent } from './content/ReceiveBTCLightningContent';
import { ReceiveETHDialogContent } from './content/ReceiveETHDialogContent';
import { ReceiveUSDCDialogContent } from './content/ReceiveUSDCDialogContent';
import { ReceiveUSDTDialogContent } from './content/ReceiveUSDTDialogContent';

type PpReceiveLearnMoreDialog = {
  cryptoCurrency: CurrencyCode;
  network: TpCryptoNetworkUI;
};

export const ReceiveLearnMoreDialog = forwardRef<TpDialogToggle, PpReceiveLearnMoreDialog>(
  ({ cryptoCurrency, network }, ref): JSX.Element => {
    const { open, toggle } = useToggleDialog({ ref });

    let title;
    let content;

    switch (cryptoCurrency) {
      case CurrencyCode.USDC:
      case CurrencyCode.USDC_TEST:
        title = 'What is USDC?';
        content = <ReceiveUSDCDialogContent />;
        break;
      case CurrencyCode.ETH:
      case CurrencyCode.ETH_TEST_SEPOLIA:
        title = 'Understanding Ether (ETH)';
        content = <ReceiveETHDialogContent />;
        break;
      case CurrencyCode.USDT:
      case CurrencyCode.USDT_TEST:
        title = 'What is USDT?';
        content = <ReceiveUSDTDialogContent />;
        break;
      case CurrencyCode.BTC:
      case CurrencyCode.BTC_TEST:
      default: {
        if (isLightningNetwork(network)) {
          title = 'The Lightning Network and Your NOAH Lightning Address';
          content = <ReceiveBTCLightningContent />;
          break;
        }
        title = 'On-Chain Bitcoin Transactions and Your On-Chain Address';
        content = <ReceiveBTCDialogContent />;
        break;
      }
    }
    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <PrimaryButton color="primaryBrand" onClick={toggle}>
            Got it
          </PrimaryButton>
        </DialogActions>
      </LowDialog>
    );
  },
);

ReceiveLearnMoreDialog.displayName = ReceiveLearnMoreDialog.name;
