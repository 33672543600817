import { pinLength } from '@noah-labs/shared-cryptography';
import { compareStrings } from '@noah-labs/shared-util-vanilla';
import { z } from 'zod';

type PinTest = {
  message: string;
  test: (value: string | undefined) => boolean;
};

function threeConsecutive(value: string | undefined): boolean {
  if (typeof value !== 'string') {
    return false;
  }
  for (let i = 0; i < value.length - 2; i += 1) {
    const first = parseInt(value[i], 10);
    const second = parseInt(value[i + 1], 10);
    const third = parseInt(value[i + 2], 10);
    if (first + 1 === second && second + 1 === third) {
      return false;
    }
  }
  return true;
}

function threeIdentical(value: string | undefined): boolean {
  if (typeof value !== 'string') {
    return false;
  }
  for (let i = 0; i < value.length - 2; i += 1) {
    const first = value[i];
    const second = value[i + 1];
    const third = value[i + 2];
    if (first === second && second === third) {
      return false;
    }
  }
  return true;
}

function threeConsecutivePairs(value: string | undefined): boolean {
  if (typeof value !== 'string') {
    return false;
  }
  let count = 0;
  for (let i = 0; i < value.length - 1; i += 1) {
    const first = parseInt(value[i], 10);
    const second = parseInt(value[i + 1], 10);
    if (first + 1 === second) {
      count += 1;
    }
  }

  if (count > 2) {
    return false;
  }
  return true;
}

export const pinValidation: Record<string, PinTest> = {
  threeConsecutive: {
    message: 'PIN cannot contain three consecutive numbers in a row.',
    test: threeConsecutive,
  },
  threeConsecutivePairs: {
    message: 'PIN cannot contain three occurences of consecutive numbers.',
    test: threeConsecutivePairs,
  },
  threeIdentical: {
    message: 'PIN cannot contain three identical numbers in a row.',
    test: threeIdentical,
  },
};

export const verifyPinSchema = z.object({
  pin: z.string().length(pinLength, 'Enter your 6-digit PIN'),
});

export function createPinSchema(unconfirmedPin: string | null): z.ZodSchema {
  let pinSchema;
  pinSchema = z
    .string()
    .refine(pinValidation.threeConsecutive.test, {
      message: pinValidation.threeConsecutive.message,
    })
    .refine(pinValidation.threeIdentical.test, {
      message: pinValidation.threeIdentical.message,
    })
    .refine(pinValidation.threeConsecutivePairs.test, {
      message: pinValidation.threeConsecutivePairs.message,
    })
    .refine((value) => value.length === pinLength, {
      message: unconfirmedPin ? 'Confirm your 6-digit PIN' : 'Enter your 6-digit PIN',
    });

  if (unconfirmedPin) {
    pinSchema = pinSchema.refine((value) => compareStrings(value, unconfirmedPin), {
      message: 'PINs do not match',
    });
  }

  return z.object({
    pin: pinSchema,
  });
}

export const phraseSchema = z.object({
  phrase: z
    .string()
    .min(1, 'Phrase is required')
    .refine(
      (value) => {
        const words = value.trim().split(/\s+/);
        return words.length === 24;
      },
      {
        message: 'Phrase should be 24 words long',
      },
    ),
});
