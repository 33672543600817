import { Fragment } from 'react';
import DontMissOutOnUpdates from '@noah-labs/fe-shared-ui-assets/svg/images/dont-miss-out-on-updates.svg?url';
import {
  AppFooter,
  AppMain,
  ButtonGroup,
  PrimaryButton,
  SceneImage,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';

export type PpMarketingUpdatesScene = {
  onSubmit: (accept: boolean) => void;
};

export function MarketingUpdatesScene({ onSubmit }: PpMarketingUpdatesScene): React.ReactElement {
  return (
    <Fragment>
      <AppMain sx={{ textAlign: 'center' }}>
        <SceneMain dataQa="marketing-updates">
          <SceneImage alt="" src={DontMissOutOnUpdates} />
          <SceneTitleLarge>Don’t miss out on updates</SceneTitleLarge>
          <SceneParagraph>
            We would like to inform you about new NOAH features and updates. We take your privacy
            very seriously; your personal data will never be sold.
          </SceneParagraph>
        </SceneMain>
      </AppMain>
      <AppFooter hasContent>
        <ButtonGroup>
          <PrimaryButton
            color="primaryBrand"
            data-qa="consent-updates-yes"
            onClick={(): void => onSubmit(true)}
          >
            Yes
          </PrimaryButton>
          <PrimaryButton
            data-qa="consent-updates-not-now"
            variant="text"
            onClick={(): void => onSubmit(false)}
          >
            Not now
          </PrimaryButton>
        </ButtonGroup>
      </AppFooter>
    </Fragment>
  );
}
