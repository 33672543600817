import type { SVGProps } from 'react';

export function SvgWallet(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 25 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.167 10.97v2.06c0 .55-.44 1-1 1.02h-1.96c-1.08 0-2.07-.79-2.16-1.87-.06-.63.18-1.22.6-1.63.37-.38.88-.6 1.44-.6h2.08c.56.02 1 .47 1 1.02"
        fill="currentColor"
      />
      <path
        d="M20.637 15.55h-1.43c-1.9 0-3.5-1.43-3.66-3.25-.09-1.04.29-2.08 1.05-2.82.64-.66 1.53-1.03 2.49-1.03h1.55c.29 0 .53-.24.5-.53-.22-2.43-1.83-4.09-4.22-4.37-.24-.04-.49-.05-.75-.05h-9q-.42 0-.81.06c-2.55.32-4.19 2.22-4.19 4.94v7c0 2.76 2.24 5 5 5h9c2.8 0 4.73-1.75 4.97-4.42a.49.49 0 0 0-.5-.53m-7.47-5.8h-6c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h6c.41 0 .75.34.75.75s-.34.75-.75.75"
        fill="currentColor"
      />
    </svg>
  );
}
