import { ErrorPage, PrimaryButton } from '@noah-labs/fe-shared-ui-components';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';

type PpFailedScene = {
  helpText?: string;
  message: string;
};

export function FailedScene(props: PpFailedScene): React.ReactElement {
  return (
    <ErrorPage
      AppHeaderSlot={<AppHeaderData exitButton helpButton />}
      FooterSlot={
        <PrimaryButton data-qa="done-button" href="/">
          Back to NOAH
        </PrimaryButton>
      }
      {...props}
    />
  );
}
