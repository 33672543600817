import type {
  AppConfigManualRamp,
  FiatCurrencyCode,
  ManualRampCurrencySettings,
} from '@noah-labs/shared-schema-gql';

type PpGetManualRampCurrencyConfig = {
  config: AppConfigManualRamp;
  fiatCurrencyCode: FiatCurrencyCode;
};
export function getManualRampCurrencyConfig({
  config,
  fiatCurrencyCode,
}: PpGetManualRampCurrencyConfig): ManualRampCurrencySettings | undefined | null {
  const manualRampCurrencyConfig = config.settings.find(
    (c) => c?.FiatCurrency === fiatCurrencyCode,
  );
  return manualRampCurrencyConfig;
}
