import { getManualRampCurrencyConfig } from '@noah-labs/shared-currencies';
import type { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { getConfig } from '../config';
import { fiatCurrencyFromCode } from '../fiatCurrencies';

export const manualRampEta = '1-2 business days';

function getBankCurrencyFee(currency: FiatCurrencyCode): string {
  const { manualRamp } = getConfig();
  const manualRampCurrencyConfig = getManualRampCurrencyConfig({
    config: manualRamp,
    fiatCurrencyCode: currency,
  });

  if (!manualRampCurrencyConfig) {
    return '';
  }

  const { FeeMinimumFiatAmount, FeePercentage, FiatCurrency } = manualRampCurrencyConfig;
  const currencySymbol = fiatCurrencyFromCode(FiatCurrency).symbol;

  const bankFee = `${FeePercentage}% fee (min ${currencySymbol}${FeeMinimumFiatAmount})`;
  return bankFee;
}

export function getBankCurrencyFeeAndEta(currency: FiatCurrencyCode): string {
  const bankCurrencyFee = getBankCurrencyFee(currency);
  return [manualRampEta, bankCurrencyFee].join(' • ');
}
