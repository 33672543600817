import { useCallback, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, AlertTitle, Stack } from '@mui/material';
import { BankIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/BankIcon';
import { CreditCardIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CreditCardIcon';
import type { TpSelectOption } from '@noah-labs/fe-shared-ui-components';
import {
  AlertBody,
  AppContainer,
  AppHeaderTitle,
  AppMain,
  DialogSelectField,
  List,
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  SceneHeader,
  SceneMain,
  SceneParagraph,
} from '@noah-labs/fe-shared-ui-components';
import type { TpPaymentBank, TpPaymentCard } from '@noah-labs/fe-shared-ui-shared';
import { getBankCurrencyFeeAndEta, getCardFeeUi } from '@noah-labs/fe-shared-ui-shared';
import { paymentMethodIconStyles } from '@noah-labs/fe-shared-ui-wallet';
import type { CountryCode, FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { isFalseyOrEmptyArray } from '@noah-labs/shared-util-vanilla';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { SavedPayoutMethodsList } from '../components/payment/SavedPayoutMethodsList';
import { IsCountryAllowed } from '../utils/utils';

type TpCountryFormValues = {
  Country: string;
  CountryCurrency: string;
  // This is only used for display purposes
  CountryName: string | undefined;
};

const countryFormSchema = z.object({
  Country: z.string().min(1),
  CountryCurrency: z.string().min(1),
  CountryName: z.string(),
});

const emptyDefaultValues: TpCountryFormValues = {
  Country: '',
  CountryCurrency: '',
  CountryName: '',
};

export type PpSelectPayoutMethodScene = {
  countries: TpSelectOption[] | undefined;
  defaultCountryValues: TpCountryFormValues | undefined;
  onNewBankAccountClick: (countryCurrency: CountryCode) => void;
  onNewCardClick: () => void;
  onPayoutMethodClick: (payoutMethod: TpPaymentCard | TpPaymentBank) => void;
  pageTitle: string;
  savedPayoutMethods?: Array<TpPaymentCard | TpPaymentBank>;
};

export function SelectPayoutMethodScene({
  countries,
  defaultCountryValues,
  onNewBankAccountClick,
  onNewCardClick,
  onPayoutMethodClick,
  pageTitle,
  savedPayoutMethods,
}: PpSelectPayoutMethodScene): React.ReactElement {
  const [showCountryAlert, setShowCountryAlert] = useState(false);
  const defaultValues = useMemo(
    () => defaultCountryValues || emptyDefaultValues,
    [defaultCountryValues],
  );

  const methods = useForm<TpCountryFormValues>({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(countryFormSchema),
    values: defaultValues,
  });

  const handleCountryFormValues = useCallback(
    (value: TpSelectOption | null) => {
      setShowCountryAlert(false);

      const isCountryNotAvailable =
        webConfigBrowser.regions.ProhibitedCountries[value?.value || ''];

      if (isCountryNotAvailable) {
        setShowCountryAlert(true);
      }

      methods.setValue('Country', value?.value || '', { shouldValidate: true });
      methods.setValue('CountryName', value?.label || '');
      methods.setValue('CountryCurrency', value?.currency || '');
    },
    [methods],
  );

  const countryCurrencies = methods.watch('CountryCurrency').split(',');
  const country = methods.watch('Country');
  const [mainCountryCurrency] = countryCurrencies;

  const showCardPayoutMethods = Boolean(country) && !showCountryAlert;
  const showBankPayoutMethod = IsCountryAllowed(country, countryCurrencies);
  const showPayoutMethodsList = showCardPayoutMethods || showBankPayoutMethod;

  const cardFee = getCardFeeUi();

  return (
    <AppContainer>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AppMain>
        <AppHeaderData backButton exitButton helpButton>
          <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
        </AppHeaderData>
        <SceneHeader dense>
          <SceneParagraph>
            Sell bitcoin and receive funds in your local currency. Select a recipient country and
            payout method.
          </SceneParagraph>
        </SceneHeader>
        <SceneMain dense dataQa="enter-amount">
          <Stack spacing={3}>
            <FormProvider {...methods}>
              <form id="countryForm">
                <Stack spacing={2}>
                  <DialogSelectField
                    isFetched={!!countries}
                    label="Choose a country"
                    name="CountryName"
                    options={countries}
                    onChange={handleCountryFormValues}
                  />
                  {showCountryAlert && (
                    <Alert severity="error">
                      <AlertTitle>Payouts are not yet supported here</AlertTitle>
                      <AlertBody>
                        Our goal is to make payouts available everywhere. Get in touch to see if we
                        can help.
                      </AlertBody>
                    </Alert>
                  )}
                </Stack>
              </form>
            </FormProvider>
            <List disablePadding spacing={1}>
              {showCardPayoutMethods && (
                <ListItem>
                  <ListItemButton onClick={onNewCardClick}>
                    <ListItemCard>
                      <ListItemContent data-qa="new-card">
                        <ListItemIcon>
                          <CreditCardIcon sx={paymentMethodIconStyles} />
                        </ListItemIcon>
                        <ListItemStartContent>
                          <ListItemPrimaryText>New credit/debit card</ListItemPrimaryText>
                          <ListItemSecondaryText>{cardFee}</ListItemSecondaryText>
                        </ListItemStartContent>
                      </ListItemContent>
                    </ListItemCard>
                  </ListItemButton>
                </ListItem>
              )}

              {showBankPayoutMethod && (
                <ListItem>
                  <ListItemButton
                    onClick={(): void => {
                      onNewBankAccountClick(methods.getValues().Country as CountryCode);
                    }}
                  >
                    <ListItemCard>
                      <ListItemContent data-qa="new-bankAccount">
                        <ListItemIcon>
                          <BankIcon sx={paymentMethodIconStyles} />
                        </ListItemIcon>
                        <ListItemStartContent>
                          <ListItemPrimaryText>Bank transfer</ListItemPrimaryText>
                          <ListItemSecondaryText>
                            {getBankCurrencyFeeAndEta(mainCountryCurrency as FiatCurrencyCode)}
                          </ListItemSecondaryText>
                        </ListItemStartContent>
                      </ListItemContent>
                    </ListItemCard>
                  </ListItemButton>
                </ListItem>
              )}
            </List>

            {showPayoutMethodsList && !isFalseyOrEmptyArray(savedPayoutMethods) && (
              <SavedPayoutMethodsList
                savedPayoutMethods={savedPayoutMethods}
                onPayoutMethodClick={onPayoutMethodClick}
              />
            )}
          </Stack>
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
