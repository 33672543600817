import { TpFeExtraFeature } from '@noah-labs/fe-shared-data-access-user';
import { KycAccessControlData } from '@noah-labs/fe-shared-feature-kyc';
import { getDefaults } from '@noah-labs/fe-shared-feature-wallet';
import { generatePath, Switch404, useStateMachine } from '@noah-labs/fe-shared-ui-components';
import type { TpPaymentBank, TpPaymentCard, TpPaymentMethod } from '@noah-labs/fe-shared-ui-shared';
import { useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import type { CountryCode, FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { CurrencyUnit, Feature } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AccessControlData } from '../../../auth/AccessControlData';
import { Complete } from './Complete';
import { Confirm } from './Confirm';
import { EnterAccountDetails } from './EnterAccountDetails';
import { EnterAmount } from './EnterAmount';
import { EnterPaymentInfo } from './EnterPaymentInfo';
import { SelectPayoutMethod } from './SelectPayoutMethod';

export type StSellRouter = {
  cryptoAmount: string;
  cryptoUnit: CurrencyUnit;
  fetchedAt: string;
  fiatAmount: string;
  fiatCurrencyCode: FiatCurrencyCode;
  payoutMethod?: TpPaymentMethod;
  reference?: string;
  selectedCountry: CountryCode | undefined;
  selectedPayoutBank?: TpPaymentBank;
  selectedPayoutCard?: TpPaymentCard;
  transactionId?: string;
};

const { fiatCurrency } = getDefaults();

const emptyState: StSellRouter = {
  cryptoAmount: '',
  cryptoUnit: CurrencyUnit.Default,
  fetchedAt: '',
  fiatAmount: '',
  fiatCurrencyCode: fiatCurrency.code,
  selectedCountry: undefined,
  selectedPayoutCard: undefined,
  transactionId: undefined,
};

export function SellRouter(): React.ReactElement {
  const { params } = useWalletParams();
  const initialState = { ...emptyState };

  const { complete, confirm, enterAccountDetails, enterAmount, enterPaymentInfo, payoutMethod } =
    walletRoutes().sell;

  const sm = useStateMachine<StSellRouter>({
    emptyState,
    initialState,
    name: 'SellRouter',
  });

  const hasPaymentMethod = sm.state.selectedPayoutCard || sm.state.selectedPayoutBank;

  return (
    <Switch404>
      <Route exact path={[payoutMethod.path, enterPaymentInfo.path]}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          needsFeature={[TpFeExtraFeature.SellRoutes]}
        >
          <Route exact path={payoutMethod.path}>
            <SelectPayoutMethod {...sm} />
          </Route>

          {/* Keep EnterPaymentInfo in the DOM, but hidden, on all the routes in the buy flow to ensure Frames card fields are preloaded */}
          <EnterPaymentInfo {...sm} />
        </AccessControlData>
      </Route>

      <Route exact path={enterAccountDetails.path}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          needsFeature={[TpFeExtraFeature.SellRoutes]}
        >
          <EnterAccountDetails {...sm} />
        </AccessControlData>
      </Route>

      <Route exact path={enterAmount.path}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          needsFeature={[TpFeExtraFeature.SellRoutes]}
        >
          <EnterAmount {...sm} />
        </AccessControlData>
      </Route>

      <Route exact path={confirm.path}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          needsFeature={[TpFeExtraFeature.SellRoutes]}
          redirectInvalid={!hasPaymentMethod && generatePath(payoutMethod.path, params)}
        >
          <KycAccessControlData origin="sell">
            <Confirm {...sm} />
          </KycAccessControlData>
        </AccessControlData>
      </Route>

      <Route exact path={complete.path}>
        <AccessControlData needsAuthStatus={['authenticated']} needsFeature={[Feature.Sell]}>
          <KycAccessControlData origin="sell">
            <Complete {...sm} />
          </KycAccessControlData>
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
