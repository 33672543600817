import { ChevronRightIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronRightIcon';
import {
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemEndContent,
  ListItemPrimaryText,
  ListItemStartContent,
} from '@noah-labs/fe-shared-ui-components';

type PpLightningInvoiceCard = {
  lightningInvoiceHref: string;
};

export function LightningInvoiceCard({
  lightningInvoiceHref,
}: PpLightningInvoiceCard): React.ReactElement {
  return (
    <ListItem data-qa="create-ln-invoice">
      <ListItemButton href={lightningInvoiceHref}>
        <ListItemCard>
          <ListItemContent>
            <ListItemStartContent>
              <ListItemPrimaryText>Create a lightning invoice</ListItemPrimaryText>
            </ListItemStartContent>
            <ListItemEndContent>
              <ChevronRightIcon />
            </ListItemEndContent>
          </ListItemContent>
        </ListItemCard>
      </ListItemButton>
    </ListItem>
  );
}
