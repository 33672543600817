import { Fragment } from 'react';
import { DialogContentText } from '@mui/material';
import { LearnMoreButton } from '@noah-labs/fe-shared-ui-components';
import { webLinks } from '@noah-labs/shared-constants';

export function ReceiveBTCDialogContent(): React.ReactElement {
  return (
    <Fragment>
      <DialogContentText paragraph>
        On-Chain Bitcoin transactions are like entries in a giant, secure public ledger known as the{' '}
        <LearnMoreButton href={webLinks.blog.bitcoinBlockhain} target="_blank">
          Bitcoin blockchain.
        </LearnMoreButton>{' '}
        When you make an on-chain transaction, it is recorded directly onto this blockchain and then
        verified—usually taking between 10 minutes to an hour to complete.
      </DialogContentText>
      <DialogContentText paragraph>
        Your On-Chain Address is a unique alpha-numerical string, similar to a bank account number,
        used for receiving Bitcoin directly into your NOAH wallet. This process is transparent and
        tamper-proof, making it nearly impossible for anyone to cheat or steal your Bitcoin.
      </DialogContentText>
      <DialogContentText>
        With on-chain transactions, you enjoy the full security and transparency of the Bitcoin
        network.{' '}
        <LearnMoreButton href={webLinks.blog.compareOnChainOffChain} target="_blank">
          Compare on-chain and off-chain transactions here.
        </LearnMoreButton>
      </DialogContentText>
    </Fragment>
  );
}
