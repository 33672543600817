import type { TpAllowance } from '@noah-labs/fe-shared-data-access-user';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies';
import { z } from 'zod';
import { combinedFiatAmountSchema } from './amounts';
import { getCryptoWithdrawalLimitsSchema } from './getCryptoWithdrawalLimitsSchema';

type TpGetCkoSellAmountSchema = {
  allowance: TpAllowance | undefined;
  feePercentage: string;
  fiatCurrency: TpFiatCurrency | undefined;
  minFiatCurrencyAmount: string;
};
export function getCkoSellAmountSchema({
  allowance,
  feePercentage,
  fiatCurrency,
  minFiatCurrencyAmount,
}: TpGetCkoSellAmountSchema): z.ZodSchema {
  return z.object({
    cryptoAmount: getCryptoWithdrawalLimitsSchema(allowance, {
      buffer: feePercentage,
      bufferInfo: '(fees included)',
    }).optional(),
    fiatAmount: combinedFiatAmountSchema(fiatCurrency, minFiatCurrencyAmount, 'sell'),
    // this value may be null on the first load while the crypto price is being fetched
    secondaryAmount: z.string().optional(),
  });
}
