import { useState } from 'react';
import { NavBar, NavBarItem } from '@noah-labs/fe-shared-ui-components';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import { NavItems } from './NavItems';

export function NavBarData(): React.ReactElement {
  const { pathname } = useRouter();
  const [selectedMenu, setSelectedMenu] = useState(pathname);

  return (
    <NavBar
      value={selectedMenu}
      onChange={(_, newValue: string): void => {
        setSelectedMenu(newValue);
      }}
    >
      <NavItems Component={NavBarItem} />
    </NavBar>
  );
}
