import type { ReactElement } from 'react';
import { getOryFlowId } from '@noah-labs/fe-shared-data-access-auth';
import { AppContainer } from '@noah-labs/fe-shared-ui-components';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

export function ForgottenPasswordWrapper({ children }: PpWC): ReactElement {
  const flowId = getOryFlowId();
  const email = new URL(window.location.href).searchParams.get('email');

  const hasFlowIdAndEmail = flowId && email;

  return (
    <AppContainer headTitle={hasFlowIdAndEmail ? 'Check your email' : 'Forgotten your password?'}>
      {children}
    </AppContainer>
  );
}
