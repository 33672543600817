/* eslint-disable react/no-multi-comp */
import React from 'react';
import { Link } from '@mui/material';
import { DisabledPage, GetInTouchLink } from '@noah-labs/fe-shared-ui-components';
import { webLinks } from '@noah-labs/shared-constants';
import { FeatureFlagReason } from '@noah-labs/shared-schema-gql';
import { AppHeaderData } from '../../../../components/layout/AppHeaderData';

const appHeader = <AppHeaderData backButton exitButton helpButton />;

type PpDescription = {
  content?: React.ReactNode;
  faqLink?: string;
};
function Description({ content, faqLink = webLinks.faq.base }: PpDescription): React.ReactElement {
  return (
    <React.Fragment>
      {content}
      {content && ' '}
      Please visit our{' '}
      <Link href={faqLink} target="_blank">
        FAQs
      </Link>{' '}
      for more information.
    </React.Fragment>
  );
}

export type PpDisabledFeature = {
  reason: FeatureFlagReason[] | undefined;
};
export function DisabledFeature({ reason }: PpDisabledFeature): React.ReactElement {
  // order is important here - don't want to tell someone that's suspended that they're block because of system maintenance

  if (reason?.includes(FeatureFlagReason.Suspended)) {
    return (
      <DisabledPage
        AppHeaderSlot={appHeader}
        description={
          <Description content="Your account has been suspended for being in breach of our terms & conditions." />
        }
        title="This feature is disabled"
      />
    );
  }

  if (reason?.includes(FeatureFlagReason.RestrictedRegion)) {
    return (
      <DisabledPage
        AppHeaderSlot={appHeader}
        description={
          <Description
            content="Rest assured we are working hard to enable all NOAH features globally and we will keep you updated on our progress. We will re-activate your account as soon as we are able to do so."
            faqLink={webLinks.faq.availableRegions}
          />
        }
        title="This feature is not currently available in your region."
      />
    );
  }

  if (reason?.includes(FeatureFlagReason.Maintenance)) {
    return (
      <DisabledPage
        AppHeaderSlot={appHeader}
        description={
          <React.Fragment>
            Every now and then, we need to give NOAH a quick spruce-up. Please come back later or{' '}
            <GetInTouchLink /> to see how we’re doing.
          </React.Fragment>
        }
        title="This feature is currently getting a little TLC"
      />
    );
  }

  return (
    <DisabledPage
      AppHeaderSlot={appHeader}
      description={<Description />}
      title="This feature is disabled"
    />
  );
}
