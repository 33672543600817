import type { SVGProps } from 'react';

export function SvgCloseCircle(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      clipRule="evenodd"
      fillRule="evenodd"
      height="1em"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      viewBox="0 0 24 25"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
    >
      <path
        d="M12.207 2.321c5.519 0 10 4.481 10 10s-4.481 10-10 10-10-4.48-10-10 4.481-10 10-10m1.36 10.2 3.2-3.2a.82.82 0 0 0 0-1.159.82.82 0 0 0-1.159-.001l-3.201 3.201-3.2-3.201a.817.817 0 0 0-1.159.001.82.82 0 0 0-.241.579c0 .218.087.426.24.58l3.201 3.2-3.201 3.2a.8.8 0 0 0-.177.266.8.8 0 0 0-.001.628.823.823 0 0 0 1.072.444q.151-.064.266-.178l3.2-3.2 3.2 3.2a.82.82 0 0 0 1.16-1.16z"
        fill="currentColor"
      />
    </svg>
  );
}
