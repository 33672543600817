import { useCallback, useEffect, useRef } from 'react';
import type { TpTimeout } from '@noah-labs/shared-util-vanilla';

export function useDebounce(): (fn: () => void, delay: number) => void {
  const timer = useRef<TpTimeout | null>(null);

  useEffect(
    () => () => {
      if (!timer.current) {
        return;
      }
      clearTimeout(timer.current);
    },
    [],
  );

  const debouncedCallback = useCallback((callback: () => void, delay: number): void => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      callback();
    }, delay);
  }, []);

  return debouncedCallback;
}
