import type { ReactElement } from 'react';
import { Fragment } from 'react';
import { Divider } from '@mui/material';
import { AvailableBalanceData } from '@noah-labs/fe-shared-feature-user';
import { ChevronRightIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronRightIcon';
import { ListItem, ListItemButton } from '@noah-labs/fe-shared-ui-components';
import type {
  TpCryptoCurrencyUI,
  TpPaymentBank,
  TpPaymentCard,
  TpPaymentMethod,
} from '@noah-labs/fe-shared-ui-shared';
import { PaymentMethod } from '@noah-labs/fe-shared-ui-wallet';
import type { AccountType as TpAccountType } from '@noah-labs/shared-schema-gql';

type PpAvailableBalanceWithPaymentMethodData = {
  AccountType: TpAccountType;
  cryptoCurrency: TpCryptoCurrencyUI;
  method: TpPaymentMethod | undefined;
  onPaymentMethodClick: () => void;
  paymentBank?: TpPaymentBank;
  paymentCard?: TpPaymentCard;
};
export function AvailableBalanceWithPaymentMethodData({
  AccountType,
  cryptoCurrency,
  method,
  onPaymentMethodClick,
  paymentBank,
  paymentCard,
}: PpAvailableBalanceWithPaymentMethodData): ReactElement {
  return (
    <AvailableBalanceData
      AccountType={AccountType}
      cryptoCurrency={cryptoCurrency}
      ListItemsSlot={
        <Fragment>
          <Divider />
          <ListItem>
            <ListItemButton onClick={onPaymentMethodClick}>
              <PaymentMethod
                disablePadding
                hideAddress
                method={method}
                paymentBank={paymentBank}
                paymentCard={paymentCard}
                ToggleSlot={Boolean(onPaymentMethodClick) && <ChevronRightIcon />}
              />
            </ListItemButton>
          </ListItem>
        </Fragment>
      }
    />
  );
}
