import type { CountryCode } from '@noah-labs/shared-schema-gql';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { countriesForSelect } from '../query-functions';
import type { TpCountriesForSelect, TpCountryFilterFunction } from '../types';

/**
 * Hook to fetch a list of countries.
 * If the user's country is provided, it'll be validated and returned based on the fetched list.
 *
 * @param {CountryCode} userCountry - The user's country.
 * @param {Function} filterFn - A function to filter countries based on specific criteria.
 */
export function useCountriesForSelect<TData = TpCountriesForSelect, TError = unknown>(
  userCountry?: CountryCode | null | undefined,
  filterFn?: TpCountryFilterFunction,
  options?: UseQueryOptions<TpCountriesForSelect, TError, TData>,
): UseQueryResult<TData> {
  return useQuery<TpCountriesForSelect, TError, TData>({
    queryFn: () => countriesForSelect(userCountry, filterFn),
    queryKey: ['CountriesForSelect', filterFn],
    ...options,
  });
}
