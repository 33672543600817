import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { LowDialog, PrimaryButton } from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { useToggleDialog } from '@noah-labs/fe-shared-ui-shared';

type PpDailySendLimitsDialog = {
  limit?: number;
};

export const DailySendLimitsDialog = forwardRef<TpDialogToggle, PpDailySendLimitsDialog>(
  ({ limit }, ref): JSX.Element => {
    const { open, toggle } = useToggleDialog({ ref });

    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle>Daily On-Chain Limits</DialogTitle>
        <DialogContent>
          <DialogContentText>
            At NOAH, we cover your on-chain transaction fees. For optimal performance and to benefit
            all, we support up to {limit} free transactions daily. Enjoy efficient, cost-free
            transactions with us!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton color="primaryBrand" onClick={toggle}>
            Got it
          </PrimaryButton>
        </DialogActions>
      </LowDialog>
    );
  },
);

DailySendLimitsDialog.displayName = DailySendLimitsDialog.name;
