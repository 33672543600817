import type { FrTransactionItemFragment } from '@noah-labs/fe-shared-data-access-wallet';
import {
  Network as TpNetwork,
  TransactionDirection,
  TransactionStatus,
} from '@noah-labs/shared-schema-gql';
import { formatTime, safeBN, truncateString } from '@noah-labs/shared-util-vanilla';
import type { TpCryptoCurrencyUI } from '../types';

export function getNativeTitle(tx: FrTransactionItemFragment): string {
  const fallback = 'Unknown';

  const inTitle = tx.SourceUsernameDisplay || truncateString(tx.Source?.Address ?? fallback);
  const outTitle =
    tx.DestinationUsernameDisplay || truncateString(tx.Destination?.Address ?? fallback);

  const nativeTitleMap: Record<TransactionDirection, string> = {
    [TransactionDirection.In]: `From ${inTitle}`,
    [TransactionDirection.Out]: `To ${outTitle}`,
  };
  return tx.Direction ? nativeTitleMap[tx.Direction] : fallback;
}

export function getRampTitle(tx: FrTransactionItemFragment): string {
  const checkoutTitleMap: Record<TransactionDirection, string> = {
    [TransactionDirection.In]: `Bought ${tx.Currency}`,
    [TransactionDirection.Out]: `Sold ${tx.Currency}`,
  };
  return tx.Direction ? checkoutTitleMap[tx.Direction] : 'Unknown';
}

export function getL2Title(
  cryptoCurrency: TpCryptoCurrencyUI,
  tx: FrTransactionItemFragment,
): string {
  const fallback = 'Unknown';
  const l2TitleMap: Record<TransactionDirection, string> = {
    [TransactionDirection.In]: [`Receive ${cryptoCurrency.code}`, tx.SourceUsernameDisplay]
      .filter(Boolean)
      .join(' from '),
    [TransactionDirection.Out]: [`Send ${cryptoCurrency.code}`, tx.DestinationUsernameDisplay]
      .filter(Boolean)
      .join(' to '),
  };
  return tx.Direction ? l2TitleMap[tx.Direction] : fallback;
}

type TpGetMainPrimaryContentWithIcon = {
  cryptoCurrency: TpCryptoCurrencyUI;
  direction?: TransactionDirection | undefined | null;
  isInternal?: boolean;
  isRamp?: boolean;
  tx: FrTransactionItemFragment;
};
export function getMainPrimaryContent({
  cryptoCurrency,
  isInternal,
  isRamp,
  tx,
}: TpGetMainPrimaryContentWithIcon): string {
  switch (tx.Network) {
    case TpNetwork.Bitcoin:
    case TpNetwork.BitcoinTest:
    case TpNetwork.Ethereum:
    case TpNetwork.EthereumTestSepolia:
    case TpNetwork.PolygonPos:
    case TpNetwork.PolygonTestAmoy:
    case TpNetwork.PolygonTestMumbai:
    case TpNetwork.Tron:
    case TpNetwork.TronTestShasta:
      return isRamp ? getRampTitle(tx) : getNativeTitle(tx);

    case TpNetwork.OffNetwork: {
      if (isRamp) {
        return getRampTitle(tx);
      }
      if (isInternal && tx.Direction === TransactionDirection.In) {
        return getL2Title(cryptoCurrency, tx);
      }
      return getNativeTitle(tx);
    }
    case TpNetwork.Lightning:
    case TpNetwork.LightningTest:
      return getL2Title(cryptoCurrency, tx);

    default:
      return 'Unknown transaction type';
  }
}

export function getStatusText(tx: FrTransactionItemFragment, hasExpired: boolean): string {
  switch (tx.Status) {
    case TransactionStatus.Pending:
    case TransactionStatus.Failed:
      return hasExpired && tx.Direction === TransactionDirection.In ? 'Expired' : tx.Status;
    default:
      return tx.Status;
  }
}

export function getMainSecondaryContent(
  tx: FrTransactionItemFragment,
  hasExpired: boolean,
): string {
  return [formatTime(tx.Created), getStatusText(tx, hasExpired)].filter(Boolean).join(' · ');
}

export function positiveOrNegative(
  amount: string,
  direction?: TransactionDirection | undefined | null,
): string {
  return direction === TransactionDirection.In ? amount : safeBN(amount).negated().toString();
}

export function getStatus(tx: FrTransactionItemFragment, hasExpired: boolean): TransactionStatus {
  let status = tx.Status;
  if (hasExpired && status === TransactionStatus.Pending) {
    status = TransactionStatus.Failed;
  }
  return status;
}
