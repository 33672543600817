let isLocalStorageAvailableValue: undefined | boolean;

export function isLocalStorageAvailable(): boolean {
  if (isLocalStorageAvailableValue !== undefined) {
    return isLocalStorageAvailableValue;
  }

  const test = 'NOAH-test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    isLocalStorageAvailableValue = true;
  } catch (e) {
    isLocalStorageAvailableValue = false;
  }
  return isLocalStorageAvailableValue;
}
