import { KycAccessControlData } from '@noah-labs/fe-shared-feature-kyc';
import { Switch404 } from '@noah-labs/fe-shared-ui-components';
import { Feature } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AccessControlData } from '../auth/AccessControlData';
import { BitrefillScene } from './bitrefill/scenes/Bitrefill';
import { routes } from './routes';

export function Router(): React.ReactElement {
  return (
    <Switch404>
      <Route path={routes.bitrefill.path}>
        <AccessControlData needsAuthStatus={['authenticated']} needsFeature={[Feature.Bitrefill]}>
          <KycAccessControlData bypassIfUserHasBalance>
            <BitrefillScene pageTitle={routes.bitrefill.title} />
          </KycAccessControlData>
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
