export const questNames: Record<string, string> = {
  Arkonaut: 'Referral Quest III',
  Bitrefill: 'Bitrefill Quest',
  BuyBitcoin: 'Buy bitcoin with NOAH',
  Deposit: 'Deposit Quest',
  FBitrefill: 'Bitrefill Flash Quest',
  FBlackFriday2022: 'Black Friday Quest',
  FBlackFridayReferral2022: 'Triple NP Weekend',
  FEasterEgg2023: 'Ark Stone | 003',
  FNewYear2023: 'New Year Quest',
  FNewYearReferral2023: 'New Year Referrals Quest',
  Founder: 'Referral Quest IV',
  FReferral20230203: 'NP Double-Up Weekend',
  FReferral20230927: 'NOAH Boost',
  FValentine2023: 'Ark Stone | 002',
  FValentineReferral2023: `Valentine’s Referral Quest`,
  Lightning: 'Lightning Quest',
  Onboarding: 'Onboarding Quest',
  Traveler: 'Referral Quest II',
  Wanderer: 'Referral Quest I',
  WarpStone: 'Warp Stone Quest',
};

export const questDescriptions: Record<string, string> = {
  Arkonaut:
    'The Arkonaut Badge will be achieved after completing this quest. Arkonauts are the seasoned veterans. The experts in spreading the word.',
  Bitrefill:
    'Your journey starts here. To unlock the Bitrefill Stone, make a gift card or phone top up purchase using Bitrefill and enjoy a bounty of 500 NOAH Points as your reward.',
  BuyBitcoin:
    'Get ready to shine! Buy bitcoin with NOAH and receive the dazzling Starlight Stone and 2000 NP.',
  Deposit:
    'Fortune favors those who save. By depositing $100 in your NOAH account, you’ve taken the first step toward a bright future.',
  FBitrefill:
    'Get ready to level up with the limited edition Bitrefill Stone and 500 NOAH Points. Starting February 9th, make a purchase using Bitrefill before the timer runs out to embark on this innovative quest and earn the ultimate reward.',
  FBlackFriday2022:
    'Complete the Black Friday Quest to claim the Limited Edition Ark Stone | 001. Only available until Monday.',
  FEasterEgg2023:
    'An egg-citing journey lies ahead. Uncover the secret Ark Stone | 003 within our app and receive 200 NOAH Points as a festive reward.',
  FNewYear2023:
    'Get a 500 NOAH Points boost on your account when you deposit 100,000 sats at once — hurry up and stack those sats. Offer ends January 3rd, 2023!',
  FNewYearReferral2023:
    'Double up your NOAH Points reward with 5 referrals! Five friends, 1000 points! Hurry, this offer ends January 3rd, 2023.',
  Founder:
    'The Founders Badge will be achieved after completing this quest. Founders are an exclusive group of people who have taken the time to share NOAH with the world.',
  FReferral20230203:
    'Double up your NOAH Points reward with 5 referrals! Five friends, 1000 points! Hurry, this offer ends February 5th, 2023.',
  FReferral20230927:
    'Double the fun! Invite your friends to NOAH by this Sunday, the 8th, and watch your account swell with an additional 100 NP for each referral!',
  FValentine2023:
    'Get ready for a show of affection. Express your love by depositing 100k sats and earn the Ark\u00A0Stone\u00A0|\u00A0002 and 500 NP.',
  Lightning:
    'The Lightning Stone will be achieved after completing this quest. Send and receive free, lightning fast payments to begin.',
  Onboarding:
    'The Adventure Stone will be achieved after completing this quest. Get off on the right foot and prepare for success.',
  Traveler:
    'The Traveler Badge will be achieved after completing this quest. The Traveler’s Referral Quest is for those who want to explore even further.',
  Wanderer:
    'The Wanderer Badge will be achieved after completing this quest. Wanderers are on the ground floor, the first movers, the ones who believe in spreading the word.',
  WarpStone:
    'You have 72 hours to simply buy $20 worth of bitcoin with your NOAH account and the Warp Stone will be yours, along with an extra 1000 NOAH Points!',
};
