import { FiatAmount } from '@noah-labs/fe-shared-ui-components';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies';
import { isBlank, safeBN } from '@noah-labs/shared-util-vanilla';
import { z } from 'zod';

const MAX_FIAT_AMOUNT = 10000000;

export function maxSafetyAmount(
  fiatCurrency: TpFiatCurrency | undefined,
): z.ZodString | z.ZodEffects<z.ZodString> {
  if (fiatCurrency === undefined) {
    return z.string();
  }
  return z.string().refine((value) => safeBN(value).lt(safeBN(MAX_FIAT_AMOUNT)), {
    message: (
      <span>
        Maximum amount is <FiatAmount amount={MAX_FIAT_AMOUNT} currency={fiatCurrency} />
      </span> // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as any, // https://github.com/colinhacks/zod/issues/3212
  });
}

type TpMinRampFiatAmount = {
  fiatCurrency: TpFiatCurrency | undefined;
  minFiatAmount: string;
  prefix: string;
};

export function minRampFiatAmount({
  fiatCurrency,
  minFiatAmount,
  prefix,
}: TpMinRampFiatAmount): z.ZodSchema {
  return z.string().refine(
    (value) => {
      if (isBlank(value)) {
        return true;
      }
      return safeBN(value).gte(minFiatAmount);
    },
    {
      message: (
        <span>
          {prefix} <FiatAmount amount={minFiatAmount} currency={fiatCurrency} />
        </span> // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as any, // https://github.com/colinhacks/zod/issues/3212
    },
  );
}

export function combinedFiatAmountSchema(
  fiatCurrency: TpFiatCurrency | undefined,
  minimumFiatAmount: string,
  action: 'buy' | 'sell',
): z.ZodSchema {
  const minRampSchema = minRampFiatAmount({
    fiatCurrency,
    minFiatAmount: minimumFiatAmount,
    prefix: `Minimum ${action} amount is`,
  });

  const maxSafetySchema = maxSafetyAmount(fiatCurrency);

  return z.lazy(() =>
    z
      .string()
      .optional()
      .superRefine((value, ctx) => {
        const minResult = minRampSchema.safeParse(value);
        if (!minResult.success) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: minResult.error.errors[0].message,
          });
        }

        const maxResult = maxSafetySchema.safeParse(value);
        if (!maxResult.success) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: maxResult.error.errors[0].message,
          });
        }
      }),
  );
}
