import type { AxiosResponse } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';
import { ckoApi } from './cko';
import type { TpCheckoutTokenRequest, TpCheckoutTokenResponse } from './types';

/**
 * https://api-reference.checkout.com/#operation/requestAToken
 */
export function useRequestPaymentToken<TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AxiosResponse<TpCheckoutTokenResponse>,
    TError,
    TpCheckoutTokenRequest,
    TContext
  >,
): UseMutationResult<
  AxiosResponse<TpCheckoutTokenResponse>,
  TError,
  TpCheckoutTokenRequest,
  TContext
> {
  return useMutation<
    AxiosResponse<TpCheckoutTokenResponse>,
    TError,
    TpCheckoutTokenRequest,
    TContext
  >(
    ['checkout/GetPaymentToken'],
    async (req: TpCheckoutTokenRequest) =>
      ckoApi.client.post<TpCheckoutTokenResponse>('/tokens', req),
    options,
  );
}
