import { useCallback } from 'react';
import {
  useOptimisticUserInitOptions,
  useUpdateUserPrimaryCurrencySettingMutation,
} from '@noah-labs/fe-shared-data-access-user';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { PreferredCryptoCurrencyForm, useUserError } from '@noah-labs/fe-shared-ui-user';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import { CurrencyDisplayType } from '@noah-labs/shared-schema-gql';
import { UserSettingOptionsScene } from '../scenes/UserSettingOptions';

export function PrimaryCurrencySetting(): React.ReactElement {
  const { data: userData, error } = useUserInitUi();
  const updateOptions = useOptimisticUserInitOptions();
  const { error: mutateError, mutate } = useUpdateUserPrimaryCurrencySettingMutation(updateOptions);
  const { ApiErrorScene } = useUserError(error || mutateError);

  const handleChange = useCallback(
    (value: CurrencyDisplayType) => {
      mutate({ Input: { PrimaryCurrency: value } });
    },
    [mutate],
  );

  const items = [
    {
      label: 'Crypto Currency',
      value: CurrencyDisplayType.Crypto,
    },
    {
      label: 'Fiat Currency',
      value: CurrencyDisplayType.Fiat,
    },
  ];

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <UserSettingOptionsScene
      CurrencyFormSlot={
        <PreferredCryptoCurrencyForm
          hideValues
          items={items}
          name="primaryCurrencyType"
          title="Select your preferred primary currency for displaying balances and transactions."
          value={userData?.userProfile.PrimaryCurrency}
          onChange={handleChange}
        />
      }
      dataQa="user-settings-primary-currency"
      pageTitle={userRoutes.settings.primaryCurrency.title}
    />
  );
}
