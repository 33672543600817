import React from 'react';
import { css } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  AppContainer,
  AppFooter,
  AppMain,
  ButtonGroup,
  FooterContentText,
  GetInTouchLink,
  PrimaryButton,
  SceneMain,
  StatusElevatedCard,
  useNavigation,
} from '@noah-labs/fe-shared-ui-components';
import { manualRampEta, TpPaymentMethod } from '@noah-labs/fe-shared-ui-shared';
import { TpCheckoutPayoutStatus } from '@noah-labs/fe-shared-util-shared';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { getCheckoutAvatarStatus } from '../controllers/buy/getAvatarStatus';

export type PpCompleteSellScene = {
  BalanceSlot: React.ReactElement;
  enableAnimation?: boolean;
  payoutMethod?: TpPaymentMethod;
  payoutStatus: TpCheckoutPayoutStatus;
  transactionsUrl: string;
};

export function CompleteSellScene({
  BalanceSlot,
  enableAnimation = webConfigBrowser.settings.enableLoaderAnimations,
  payoutMethod,
  payoutStatus,
  transactionsUrl,
}: PpCompleteSellScene): React.ReactElement {
  const theme = useTheme();
  const styles = {
    balance: css`
      margin-top: ${theme.spacing(3)};
    `,
    content: css`
      margin-top: ${theme.spacing(1)};
    `,
  };

  const { exitTo } = useNavigation();

  const isPending = payoutStatus === TpCheckoutPayoutStatus.pending;
  const isFailed = payoutStatus === TpCheckoutPayoutStatus.failure;

  let estimatedTime;

  switch (payoutMethod) {
    case TpPaymentMethod.BankTransfer:
      estimatedTime = `Arriving within ${manualRampEta} to your account, check status in the transaction details.`;
      break;

    case TpPaymentMethod.Card:
    default:
      estimatedTime =
        'Arriving within 1-2 business days to your credit / debit card, check status in the transaction details.';
      break;
  }

  return (
    <AppContainer>
      <Helmet>
        <title>Transaction Complete</title>
      </Helmet>
      <AppMain>
        <AppHeaderData exitButton helpButton />
        <SceneMain dataQa="complete">
          <StatusElevatedCard
            enableAnimation={enableAnimation}
            status={getCheckoutAvatarStatus(payoutStatus)}
          >
            <Stack css={styles.content} spacing={1} textAlign="center">
              {isPending && (
                <Typography color="primary.dark" variant="paragraphBodyM">
                  Payout is being processed!
                </Typography>
              )}
              {isFailed && (
                <React.Fragment>
                  <Typography color="primary.dark" variant="paragraphBodyM">
                    Transaction failed
                  </Typography>
                  <Typography color="grayscale.700" variant="paragraphBodyS">
                    Sorry, something went wrong when processing your transaction, please try again
                    later. If the problem persists, please <GetInTouchLink />.
                  </Typography>
                </React.Fragment>
              )}
            </Stack>
            <div css={styles.balance}>{BalanceSlot}</div>
          </StatusElevatedCard>
        </SceneMain>
      </AppMain>
      <AppFooter>
        <Stack spacing={2} textAlign="center">
          {isPending && <FooterContentText>{estimatedTime}</FooterContentText>}
          <ButtonGroup>
            <PrimaryButton data-qa="done-button" href={exitTo}>
              Done
            </PrimaryButton>
            {isPending && (
              <PrimaryButton
                data-qa="view-transaction-button"
                disabled={!transactionsUrl}
                href={transactionsUrl}
                variant="text"
              >
                View transactions
              </PrimaryButton>
            )}
          </ButtonGroup>
        </Stack>
      </AppFooter>
    </AppContainer>
  );
}
