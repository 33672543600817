import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CtaCardFull } from '@noah-labs/fe-shared-ui-components';
import type { TpShareProps } from '@noah-labs/fe-shared-ui-components';
import { assetPaths, webLinks } from '@noah-labs/shared-constants';
import type { TpSocialSharePlatform } from '../../types';
import { SocialShareButtons } from '../buttons/SocialShareButtons';

const generalIntro =
  'NOAH enables you to instantly send, receive, buy, and sell bitcoin. Fee-free with Lightning. All in one place. Use my referral link to register in the NOAH App, so we can both receive exciting rewards:';

type PpReferFriendsSocialShareCard = {
  referralUrl: string | undefined;
  shareButtons: TpSocialSharePlatform[];
};

const subtitle =
  'Earn 100 points for every friend you refer.\nYour referral will also get 100 points.';

export function ReferFriendsSocialShareCard({
  referralUrl,
  shareButtons,
}: PpReferFriendsSocialShareCard): React.ReactElement {
  const theme = useTheme();

  const shareData: TpShareProps = {
    text: [generalIntro, referralUrl].filter(Boolean).join(' '),
  };

  return (
    <CtaCardFull
      backgroundImage={`${assetPaths.images}/carousel/cta-bg-3.png`}
      ContentSlot={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ padding: theme.spacing(2, 3, 0) }}
        >
          <SocialShareButtons
            buttons={shareButtons}
            referralUrl={referralUrl || webLinks.app.path}
            shareData={shareData}
          />
        </Stack>
      }
      subtitle={subtitle}
      title="Invite friends, earn points!"
    />
  );
}
