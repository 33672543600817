import type { SVGProps } from 'react';

export function SvgShare(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#share_svg__a)">
        <path
          d="M18 15.893a2.8 2.8 0 0 0-1.878.738l-6.833-3.977a3 3 0 0 0 .086-.671c0-.23-.038-.45-.086-.671l6.756-3.939c.517.48 1.198.777 1.955.777a2.87 2.87 0 0 0 2.875-2.875A2.87 2.87 0 0 0 18 2.4a2.87 2.87 0 0 0-2.875 2.875c0 .23.038.45.086.67l-6.756 3.94A2.86 2.86 0 0 0 6.5 9.107a2.87 2.87 0 0 0-2.875 2.875A2.87 2.87 0 0 0 6.5 14.858c.757 0 1.438-.297 1.955-.776l6.823 3.986a2.7 2.7 0 0 0-.076.623A2.8 2.8 0 0 0 18 21.49a2.8 2.8 0 0 0 2.798-2.799A2.8 2.8 0 0 0 18 15.893m0-11.577a.96.96 0 0 1 .958.959.96.96 0 0 1-.958.958.96.96 0 0 1-.958-.958.96.96 0 0 1 .958-.959M6.5 12.941a.96.96 0 0 1-.958-.958.96.96 0 0 1 .958-.959c.527 0 .958.432.958.959a.96.96 0 0 1-.958.958M18 19.67a.96.96 0 0 1-.958-.959.96.96 0 0 1 .958-.958.96.96 0 0 1 .958.958.96.96 0 0 1-.958.959"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="share_svg__a">
          <path d="M.75.483h23v23h-23z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
