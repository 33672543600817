import { useEffect } from 'react';
import { logger } from '@noah-labs/shared-logger/browser';
import { useOry } from '../data';

export function useSignOut(onSignOut: () => Promise<void>, returnTo: string): void {
  const { ory } = useOry();

  useEffect(() => {
    logger.attempt('signing out');

    async function signOut(): Promise<void> {
      try {
        // Create a "logout flow" in Ory Identities
        const { data: flow } = await ory.createBrowserLogoutFlow();
        // Use the received token to "update" the flow and thus perform the logout
        await ory.updateLogoutFlow({ token: flow.logout_token });
        await onSignOut();
      } catch (error) {
        // The user could not be logged out. This typically happens if the token does not match the session, or is otherwise malformed or missing
        logger.error(error);
      }
      // Logout was succesful
      window.location.assign(returnTo);
    }

    void signOut();
  }, [onSignOut, ory, returnTo]);
}
