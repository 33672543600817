import { Fragment, useCallback, useRef } from 'react';
import { Alert, Stack } from '@mui/material';
import {
  AddressCopiedAlert,
  AppMain,
  AvatarIconFromSvg,
  DynamicCopyIcon,
  LearnMoreButton,
  SceneHeader,
  SceneMain,
  SceneParagraph,
  SceneTitleMedium,
  TextWithIcon,
  useClickToCopy,
  usePushAlert,
} from '@noah-labs/fe-shared-ui-components';
import type {
  TpCryptoCurrencyUI,
  TpCryptoNetworkUI,
  TpDialogToggle,
} from '@noah-labs/fe-shared-ui-shared';
import { isLightningNetwork } from '@noah-labs/shared-currencies';
import { LightningAddressCard } from '../cards/LightningAddressCard';
import { LightningInvoiceCard } from '../cards/LightningInvoiceCard';
import { OnChainReceiveCard } from '../cards/OnChainReceiveCard';
import { ReceiveAddressInfoDialog } from '../dialogs/ReceiveAddressInfoDialog';
import { ReceiveInitialDialog } from '../dialogs/ReceiveInitialDialog';

export type PpReceiveScene = {
  address?: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  cryptoNetwork: TpCryptoNetworkUI;
  isAddressLoading: boolean;
  lightningInvoiceHref: string;
  lnAddress?: string;
};

export function ReceiveScene({
  address,
  cryptoCurrency,
  cryptoNetwork,
  isAddressLoading,
  lightningInvoiceHref,
  lnAddress,
}: PpReceiveScene): React.ReactElement {
  const showInfoAddressDialog = useRef<TpDialogToggle>(null);
  const pushAlert = usePushAlert();

  const { copied: onChainCopied, copyHandler: onChainCopyHandler } = useClickToCopy({
    onCopy: () => pushAlert(AddressCopiedAlert),
    text: address,
  });

  const { copied: lnAddressCopied, copyHandler: lnCopyHandler } = useClickToCopy({
    onCopy: () => pushAlert(AddressCopiedAlert),
    text: lnAddress,
  });

  const handleInfoAddressDialogToggle = useCallback(() => {
    showInfoAddressDialog.current?.toggle();
  }, []);

  const ContentSlot = isLightningNetwork(cryptoNetwork) ? (
    <Fragment>
      <LightningAddressCard
        lnAddress={lnAddress}
        lnCopyHandler={lnCopyHandler}
        LnCopyIcon={<DynamicCopyIcon copied={lnAddressCopied} />}
      />
      <LightningInvoiceCard lightningInvoiceHref={lightningInvoiceHref} />
    </Fragment>
  ) : null;

  return (
    <AppMain>
      <SceneHeader dense>
        <SceneTitleMedium>{cryptoCurrency.label}</SceneTitleMedium>
        <SceneParagraph paragraph>
          <TextWithIcon>
            <AvatarIconFromSvg size={1.5} svg={cryptoNetwork.svg} />
            <span>
              {cryptoNetwork.label} {cryptoNetwork.description}
            </span>
          </TextWithIcon>
        </SceneParagraph>
        <SceneParagraph paragraph>
          Choose how you would like to receive {cryptoCurrency.code} on the {cryptoNetwork.label}{' '}
          network.
        </SceneParagraph>
        <Alert severity="warning">
          Only send {cryptoCurrency.code} to these addresses.{' '}
          <LearnMoreButton onClick={handleInfoAddressDialogToggle} />
        </Alert>
      </SceneHeader>
      <SceneMain dataQa="receive">
        <Stack spacing={1}>
          {(isAddressLoading || !!address) && (
            <OnChainReceiveCard
              address={address}
              cryptoCurrency={cryptoCurrency}
              cryptoNetwork={cryptoNetwork}
              OnChainCopyIcon={<DynamicCopyIcon copied={onChainCopied} />}
              onChainCopyHandler={onChainCopyHandler}
            />
          )}
          {ContentSlot}
        </Stack>
      </SceneMain>

      <ReceiveInitialDialog />
      <ReceiveAddressInfoDialog ref={showInfoAddressDialog} />
    </AppMain>
  );
}
