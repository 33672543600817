import { IconButton } from '@mui/material';
import { ShareIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ShareIcon';
import {
  ListItem,
  ListItemCard,
  ListItemContent,
  ListItemEndContent,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  TextOrSkeleton,
  TruncatedText,
  useShare,
} from '@noah-labs/fe-shared-ui-components';

type PpLightningAddressCard = {
  LnCopyIcon: React.ReactElement;
  lnAddress: string | undefined;
  lnCopyHandler: () => void;
};

export function LightningAddressCard({
  lnAddress,
  lnCopyHandler,
  LnCopyIcon,
}: PpLightningAddressCard): React.ReactElement {
  const { handleSharing, isSupported } = useShare({ text: lnAddress });

  return (
    <ListItem>
      <ListItemCard>
        <ListItemContent>
          <ListItemStartContent>
            <ListItemSecondaryText gutterBottom>Your lightning address</ListItemSecondaryText>
            <ListItemPrimaryText>
              <TextOrSkeleton>
                {lnAddress && <TruncatedText prefix={12} text={lnAddress} />}
              </TextOrSkeleton>
            </ListItemPrimaryText>
          </ListItemStartContent>
          <ListItemEndContent>
            <IconButton
              color="primary"
              data-qa="copy-ln-address"
              disabled={!lnAddress}
              onClick={lnCopyHandler}
            >
              {LnCopyIcon}
            </IconButton>
            {isSupported && (
              <IconButton color="primary" onClick={handleSharing}>
                <ShareIcon />
              </IconButton>
            )}
          </ListItemEndContent>
        </ListItemContent>
      </ListItemCard>
    </ListItem>
  );
}
