import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { LowDialog, PrimaryButton } from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { useToggleDialog } from '@noah-labs/fe-shared-ui-shared';

export type PpBitcoinTransactionFeesDialog = {
  initialOpen?: boolean;
};

export const BitcoinTransactionFeesDialog = forwardRef<
  TpDialogToggle,
  PpBitcoinTransactionFeesDialog
>(({ initialOpen }, ref): JSX.Element => {
  const { open, toggle } = useToggleDialog({ initialOpen, ref });

  return (
    <LowDialog open={open} onClose={toggle}>
      <DialogTitle>Bitcoin on-chain transaction fees</DialogTitle>
      <DialogContent>
        <DialogContentText paragraph>
          Cryptocurrency transactions usually have fees to ensure they’re secure and valid on the
          public blockchain, which is like a public book of all transactions. To ensure the book is
          accurate and nobody cheats, a fee is charged to validate each transaction.
        </DialogContentText>
        <DialogContentText>
          However, NOAH is making it easier by using a new payment network called the bitcoin
          lightning network to reduce transaction times and eliminate fees so that you can send
          money to other NOAH users or lightning-supported wallets instantly and for free!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton color="primaryBrand" onClick={toggle}>
          Got it
        </PrimaryButton>
      </DialogActions>
    </LowDialog>
  );
});

BitcoinTransactionFeesDialog.displayName = BitcoinTransactionFeesDialog.name;
