import { forwardRef, useCallback } from 'react';
import { DialogContent, DialogTitle, Typography } from '@mui/material';
import {
  useOptimisticUserInitOptions,
  useUpdateUserFiatPaymentCurrencyCodeSettingMutation,
} from '@noah-labs/fe-shared-data-access-user';
import { LowDialog } from '@noah-labs/fe-shared-ui-components';
import { fiatCurrencyItems } from '@noah-labs/fe-shared-ui-shared';
import { type TpDialogToggle, useToggleDialog } from '@noah-labs/fe-shared-ui-shared';
import type { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { PreferredFiatCurrencyForm } from '../forms/PreferredFiatCurrencyForm';

export type PpPaymentCurrencyDialog = {
  fiatCurrency: FiatCurrencyCode;
  initialOpen?: boolean;
  onCurrencyChange?: (currency: FiatCurrencyCode) => void;
};

const title = 'Choose your preferred\n payment currency';

export const PaymentCurrencyDialog = forwardRef<TpDialogToggle, PpPaymentCurrencyDialog>(
  ({ fiatCurrency, initialOpen, onCurrencyChange }, ref): JSX.Element => {
    const updateOptions = useOptimisticUserInitOptions();
    const { open, toggle } = useToggleDialog({ initialOpen, ref });

    const { mutate: mutateFiatCurrencyCode } =
      useUpdateUserFiatPaymentCurrencyCodeSettingMutation(updateOptions);

    const handleChange = useCallback(
      (value: FiatCurrencyCode) => {
        if (onCurrencyChange) {
          onCurrencyChange(value);
        }

        mutateFiatCurrencyCode({
          Input: {
            FiatCurrencyCode: fiatCurrency,
            FiatPaymentCurrencyCode: value,
          },
        });
        toggle();
      },
      [mutateFiatCurrencyCode, toggle, onCurrencyChange, fiatCurrency],
    );

    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle sx={{ textAlign: 'center' }}>{title}</DialogTitle>
        <DialogContent>
          <PreferredFiatCurrencyForm
            hideValues
            id="fiatPayment"
            items={fiatCurrencyItems}
            title="Select your preferred fiat currency for buying and selling."
            onChange={handleChange}
          />
          <Typography color="text.light" marginTop={3} textAlign="center" variant="paragraphBodyS">
            More currencies coming soon
          </Typography>
        </DialogContent>
      </LowDialog>
    );
  },
);

PaymentCurrencyDialog.displayName = PaymentCurrencyDialog.name;
