import { useCallback } from 'react';
import {
  useOptimisticUserInitOptions,
  useUpdateUserFiatCurrencyCodeSettingMutation,
} from '@noah-labs/fe-shared-data-access-user';
import { fiatCurrencyItems, useRouter } from '@noah-labs/fe-shared-ui-shared';
import { PreferredFiatCurrencyForm, useUserError } from '@noah-labs/fe-shared-ui-user';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import type { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { UserSettingOptionsScene } from '../scenes/UserSettingOptions';

export function DisplayCurrencySetting(): React.ReactElement {
  const updateOptions = useOptimisticUserInitOptions();
  const { error: mutateError, mutate: mutateFiatCurrencyCode } =
    useUpdateUserFiatCurrencyCodeSettingMutation(updateOptions);
  const { ApiErrorScene } = useUserError(mutateError);
  const { goBack } = useRouter();

  const handleChange = useCallback(
    (value: FiatCurrencyCode) => {
      mutateFiatCurrencyCode({
        Input: {
          FiatCurrencyCode: value,
        },
      });
      goBack();
    },
    [goBack, mutateFiatCurrencyCode],
  );

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <UserSettingOptionsScene
      CurrencyFormSlot={
        <PreferredFiatCurrencyForm
          hideValues
          id="fiatDisplay"
          items={fiatCurrencyItems}
          title="Select your preferred fiat currency for viewing your balances."
          onChange={handleChange}
        />
      }
      dataQa="user-settings-display-currency"
      pageTitle={userRoutes.settings.displayCurrency.title}
    />
  );
}
