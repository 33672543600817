import { getDefaultAccount } from '@noah-labs/fe-shared-data-access-wallet';
import { ErrorPage, generatePath, PrimaryButton } from '@noah-labs/fe-shared-ui-components';
import { useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';

type PpExpiredInvoiceScene = {
  AppHeaderSlot: React.ReactElement;
};
export function ExpiredInvoiceScene({ AppHeaderSlot }: PpExpiredInvoiceScene): React.ReactElement {
  const da = getDefaultAccount();
  const { params } = useWalletParams({
    accountType: da.AccountType,
    currencyCode: da.CurrencyCode,
  });

  return (
    <ErrorPage
      AppHeaderSlot={AppHeaderSlot}
      FooterSlot={
        <PrimaryButton
          data-qa="done-button"
          href={generatePath(walletRoutes().send.address.base.path, params)}
        >
          Start over
        </PrimaryButton>
      }
      helpText="Ask the sender to create a new one and try again."
      message="This invoice has expired"
      pageTitle="Invoice Expired"
    />
  );
}
