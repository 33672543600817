import { forwardRef, useState } from 'react';
import { Box, DialogContent, DialogTitle, Stack, Tab, Tabs } from '@mui/material';
import { LowDialog } from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import {
  fiatCurrencyItems,
  unitCurrencyItems,
  useToggleDialog,
} from '@noah-labs/fe-shared-ui-shared';
import type { CurrencyUnit, FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { PreferredCryptoCurrencyForm } from '../forms/PreferredCryptoCurrencyForm';
import { PreferredFiatCurrencyForm } from '../forms/PreferredFiatCurrencyForm';
import type { TpCurrencyDisplay } from '../forms/types';

export type PpPreferredCurrenciesDialog = {
  handleDisplayUnitChange: (value: CurrencyUnit) => void;
  handleFiatCurrencyCodeChange: (value: FiatCurrencyCode) => void;
  initialOpen?: boolean;
  unitCurrency: CurrencyUnit | undefined;
};

const currencyTabs = [
  {
    label: 'Display currency',
    value: 'fiatDisplay',
  },
  {
    label: 'Bitcoin unit',
    value: 'cryptoUnit',
  },
] as const;

const title = 'Choose your preferred\n display currencies';

export const PreferredCurrenciesDialog = forwardRef<TpDialogToggle, PpPreferredCurrenciesDialog>(
  (
    { handleDisplayUnitChange, handleFiatCurrencyCodeChange, initialOpen, unitCurrency },
    ref,
  ): JSX.Element => {
    const [currentTabPanel, setCurrentTabPanel] = useState<TpCurrencyDisplay>('fiatDisplay');

    const { open, toggle } = useToggleDialog({ initialOpen, ref });

    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle sx={{ textAlign: 'center' }}>{title}</DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', pt: 2 }}>
            <Tabs
              aria-label="preferred currencies"
              value={currentTabPanel}
              variant="fullWidth"
              onChange={(_, newValue: TpCurrencyDisplay): void => setCurrentTabPanel(newValue)}
            >
              {currencyTabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          <Stack sx={{ p: [1.5, 0, 3] }}>
            <div hidden={currentTabPanel !== 'fiatDisplay'}>
              <PreferredFiatCurrencyForm
                id="fiatDisplay"
                items={fiatCurrencyItems}
                title="Select your preferred fiat currency for viewing your balances."
                onChange={handleFiatCurrencyCodeChange}
              />
            </div>
            <div hidden={currentTabPanel !== 'cryptoUnit'}>
              <PreferredCryptoCurrencyForm
                hideValues
                items={unitCurrencyItems}
                name="cryptoUnit"
                title="Select your preferred Bitcoin display units."
                value={unitCurrency}
                onChange={handleDisplayUnitChange}
              />
            </div>
          </Stack>
        </DialogContent>
      </LowDialog>
    );
  },
);

PreferredCurrenciesDialog.displayName = PreferredCurrenciesDialog.name;
