import bank from '@noah-labs/fe-shared-ui-assets/svg/icons/bank.svg';
import applepaylight from '@noah-labs/fe-shared-ui-assets/svg/images/applepay-light.svg';
import googlepaylight from '@noah-labs/fe-shared-ui-assets/svg/images/googlepay-light.svg';
import { toTitleCase } from '@noah-labs/shared-util-vanilla';
import type { TpPaymentBank, TpPaymentCard } from '../types';
import { TpPaymentMethod } from '../types';
import { formatStreetAddress } from './formatStreetAddress';
import { getCardSchemeIcon } from './getCardSchemeIcon';

type TpPaymentMethodInfo = {
  address?: string;
  dataQa?: string;
  primary: string;
  primaryTypographyProps?: {
    color?: string;
  };
  secondary?: string;
  svg?: TpReactSvg;
};

export function getPaymentMethod(
  method: TpPaymentMethod | undefined,
  paymentCard: TpPaymentCard | undefined,
  paymentBank: TpPaymentBank | undefined,
  hideAddress: boolean | undefined,
): TpPaymentMethodInfo {
  switch (method) {
    case TpPaymentMethod.ApplePay:
      return {
        dataQa: 'apple-pay',
        primary: 'Apple Pay',
        svg: applepaylight,
      };

    case TpPaymentMethod.GooglePay:
      return {
        dataQa: 'google-pay',
        primary: 'Google Pay',
        svg: googlepaylight,
      };

    case TpPaymentMethod.BankTransfer:
      if (paymentBank) {
        const bankCode = paymentBank.bankCode ? `- ${paymentBank.bankCode}` : '';
        const showAddress = paymentBank.accountHolderAddress && !hideAddress;
        return {
          address: showAddress ? formatStreetAddress(paymentBank.accountHolderAddress) : undefined,
          dataQa: 'bank-transfer',
          primary: `${paymentBank.accountCurrency} account`,
          secondary: `${paymentBank.accountNumber} ${bankCode}`,
          svg: bank,
        };
      }
      return {
        primary: 'Bank transfer / Add new account',
        svg: bank,
      };

    case TpPaymentMethod.Card:
      if (paymentCard) {
        const showAddress = paymentCard.billingAddress && !hideAddress;
        return {
          address: showAddress ? formatStreetAddress(paymentCard.billingAddress) : undefined,
          dataQa: `credit-card-${paymentCard.last4}`,
          primary: toTitleCase(paymentCard.issuer || 'Credit Card'),
          secondary: `**** ${paymentCard.last4}`,
          svg: getCardSchemeIcon(paymentCard.scheme),
        };
      }
      return {
        primary: 'Add new card',
        secondary: 'We accept Visa and Mastercard',
        svg: getCardSchemeIcon(undefined),
      };

    default:
      return {
        dataQa: 'choose-payment-method',
        primary: 'Choose a payment method',
        primaryTypographyProps: {
          color: 'text.link',
        },
      };
  }
}
