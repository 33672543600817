import { findOryUiNode, getOryUiError } from '@noah-labs/fe-shared-data-access-auth';
import type { TpSignInForm } from '@noah-labs/fe-shared-ui-auth';
import type { LoginFlow } from '@ory/client';
import type { AxiosError } from 'axios';
import { HttpStatusCode, isAxiosError } from 'axios';
import type { UseFormSetError } from 'react-hook-form';

export function handleLoginFlowError(
  error: unknown,
  setError: UseFormSetError<TpSignInForm>,
): void {
  if (!isAxiosError(error)) {
    setError('root.serverError', {
      message: 'Something went wrong.',
      type: 'custom',
    });
    return;
  }

  const oryError = error as AxiosError<LoginFlow>;
  if (oryError.response?.status !== HttpStatusCode.BadRequest) {
    setError('root.serverError', {
      message: 'Something went wrong.',
      type: 'custom',
    });
    return;
  }

  const { ui } = oryError.response.data;
  const uiServerMessage = getOryUiError(ui.messages);
  if (uiServerMessage) {
    setError('root.serverError', uiServerMessage);
  }

  const emailNode = findOryUiNode(ui.nodes, 'traits.email');
  if (emailNode?.messages && emailNode.messages.length > 0) {
    setError('email', {
      message: emailNode.messages[0].text,
      type: 'value',
    });
  }

  const passwordNode = findOryUiNode(ui.nodes, 'password');
  if (passwordNode?.messages && passwordNode.messages.length > 0) {
    setError('password', {
      message: passwordNode.messages[0].text,
      type: 'value',
    });
  }
}
