import { useEffect } from 'react';
import { useValidateReferralCodePublicQuery } from '@noah-labs/fe-shared-data-access-auth';
import { usePushAlert } from '@noah-labs/fe-shared-ui-components';

type TpUseValidateReferralCode = {
  enabled: boolean;
  referralCode: string | null | undefined;
};

export function useValidateReferralCode({
  enabled,
  referralCode,
}: TpUseValidateReferralCode): void {
  const pushAlert = usePushAlert();

  const { data } = useValidateReferralCodePublicQuery(
    {
      Input: { ReferralCode: referralCode || '' },
    },
    {
      enabled,
    },
  );

  useEffect(() => {
    // checks explicitly for 'false' result because we don't want to trigger alert when undefined
    if (data?.validateReferralCode !== false) {
      return;
    }
    pushAlert({
      dismissable: true,
      key: 'invalidReferralCode',
      message:
        'Sorry, the referral link you have used is invalid, please ask the referrer to create a new link.',
      preventDuplicate: true,
      severity: 'error',
    });
  }, [data?.validateReferralCode, pushAlert]);
}
