import type { StRegistration } from '@noah-labs/fe-shared-feature-user';
import { TermsAndCookies, Username } from '@noah-labs/fe-shared-feature-user';
import { MarketingUpdates } from '@noah-labs/fe-shared-feature-user/src/controllers/MarketingUpdates';
import {
  AppContainer,
  Switch404,
  useSearchParams,
  useStateMachine,
} from '@noah-labs/fe-shared-ui-components';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import type { Maybe } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AppHeaderData } from '../../components/layout/AppHeaderData';
import { webConfigBrowser } from '../../webConfigBrowser';
import { AccessControlData } from '../auth/AccessControlData';

const emptyState = {
  Cookies: {
    advertising: false,
    functional: true,
    marketingAndAnalytics: false,
  },
  isDirty: false,
  Terms: true,
};

type PpPostSignupRouter = {
  logoutTo: string;
  successTo: string;
  usernameDisplay?: Maybe<string>;
};

/**
 * PostSignupRouter controls user's who still need to accept terms and / or set their username
 */
export function PostSignupRouter({
  logoutTo,
  successTo,
  usernameDisplay,
}: PpPostSignupRouter): React.ReactElement {
  const { push } = useRouter();
  const sm = useStateMachine<StRegistration>({ emptyState, name: 'registrationState' });
  const searchParams = useSearchParams();
  const referralCode = searchParams?.get(webConfigBrowser.settings.referralCodeParam);

  return (
    <Switch404>
      <Route exact path={userRoutes.username.path}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          redirectInvalid={Boolean(usernameDisplay) && successTo}
        >
          <AppContainer headTitle={userRoutes.username.title}>
            <AppHeaderData helpButton />
            <Username
              logoutTo={logoutTo}
              referralCode={referralCode}
              successTo={userRoutes.consent.path}
              suffix={`@${webConfigBrowser.lnDomain}`}
            />
          </AppContainer>
        </AccessControlData>
      </Route>
      <Route exact path={userRoutes.consent.path}>
        <AccessControlData
          needsAuthStatus={['authenticated']}
          redirectInvalid={!usernameDisplay && userRoutes.username.path}
        >
          <AppContainer headTitle={userRoutes.consent.title}>
            <AppHeaderData helpButton />
            <TermsAndCookies
              logoutTo={logoutTo}
              successTo={userRoutes.acceptUpdates.path}
              {...sm}
            />
          </AppContainer>
        </AccessControlData>
      </Route>
      <Route exact path={userRoutes.acceptUpdates.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <AppContainer>
            <AppHeaderData helpButton />
            <MarketingUpdates
              {...sm}
              nextAction={(): void => push(successTo)}
              nextActionDirty={(): void => window.location.assign(successTo)}
            />
          </AppContainer>
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
