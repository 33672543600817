import { useSardineFlow } from '@noah-labs/fe-shared-feature-sardine';
import {
  CurrentBalanceDataWithCurrencyDialog,
  useUserAccounts,
} from '@noah-labs/fe-shared-feature-user';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import { AccountCardList, AccountsScene } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { SardineFlows } from '@noah-labs/shared-schema-gql';
import { isFalseyOrEmptyArray } from '@noah-labs/shared-util-vanilla';
import { AccountCardData } from '../../components/AccountCardData';
import { ActionsMenuData } from '../../components/ActionsMenuData';

export function AccountsController(): React.ReactElement {
  useSardineFlow({ flow: SardineFlows.Dashboard });
  const { data, isFetched } = useUserAccounts();

  const AccountsSlot = (
    <AccountCardList isFetched={isFetched}>
      {!isFalseyOrEmptyArray(data) &&
        data.map((account) => (
          <AccountCardData
            key={account.ID}
            balance={account.Balance?.Available}
            cryptoCurrency={account.cryptoCurrency}
            href={generatePath(walletRoutes().account.base.path, {
              accountType: account.AccountType,
              currencyCode: account.CurrencyCode,
            })}
            id={account.ID}
          />
        ))}
    </AccountCardList>
  );

  return (
    <AccountsScene
      AccountsSlot={AccountsSlot}
      ActionsMenuSlot={<ActionsMenuData />}
      TotalBalanceSlot={<CurrentBalanceDataWithCurrencyDialog />}
    />
  );
}
