import type { SVGProps } from 'react';

export function SvgOpenInWindow(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 25"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M2 3.707a1.6 1.6 0 0 1 1.6-1.6h16.8a1.6 1.6 0 0 1 1.6 1.6v16.8a1.6 1.6 0 0 1-1.6 1.6H8a1 1 0 1 1 0-2h12v-16H4v12a1 1 0 1 1-2 0z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7 12.107a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-1.585l-6.793 6.792A1 1 0 1 1 2.793 19.9l6.793-6.793H8a1 1 0 0 1-1-1"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
