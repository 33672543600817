import { Fragment } from 'react';
import { Stack, Typography } from '@mui/material';
import {
  AppFooter,
  AppMain,
  ButtonGroup,
  FooterContentText,
  PrimaryButton,
  SceneMain,
  StatusElevatedCard,
  useNavigation,
} from '@noah-labs/fe-shared-ui-components';
import { getTransactionTimeText } from '../alerts/getTransactionTimeText';

export type PpCompleteScene = {
  BalanceSlot: React.ReactElement;
  enableAnimation?: boolean;
  processingTimeMs?: number;
  transactionUrl?: string;
};

export function CompleteScene({
  BalanceSlot,
  enableAnimation,
  processingTimeMs,
  transactionUrl,
}: PpCompleteScene): React.ReactElement {
  const { exitTo } = useNavigation();

  const processingTime = processingTimeMs ? getTransactionTimeText(processingTimeMs) : undefined;

  return (
    <Fragment>
      <AppMain>
        <SceneMain dataQa="complete">
          <StatusElevatedCard enableAnimation={enableAnimation} status="pending">
            <Stack marginTop={1} spacing={3} textAlign="center">
              <Typography color="primary.dark" variant="paragraphBodyM">
                Payment is on its way!
              </Typography>
              {BalanceSlot}
            </Stack>
          </StatusElevatedCard>
        </SceneMain>
      </AppMain>
      <AppFooter>
        <Stack spacing={2} textAlign="center">
          {processingTime && <FooterContentText>{processingTime}</FooterContentText>}
          <ButtonGroup>
            <PrimaryButton data-qa="done-button" href={exitTo}>
              Done
            </PrimaryButton>
            <PrimaryButton
              data-qa="view-transaction-button"
              disabled={!transactionUrl}
              href={transactionUrl}
              variant="text"
            >
              View transactions
            </PrimaryButton>
          </ButtonGroup>
        </Stack>
      </AppFooter>
    </Fragment>
  );
}
