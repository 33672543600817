import { Fragment, useRef } from 'react';
import { Link, Typography } from '@mui/material';
import { InlineLinkOrButton } from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { SupportedCryptoCurrenciesDialog } from '../components/dialogs/SupportedCryptoCurrenciesDialog';
import { AddressErrors } from './parseCurrencyAddressData';

function AddressNotFound(): React.ReactElement {
  const dialogRef = useRef<TpDialogToggle>(null);

  return (
    <Fragment>
      <Typography variant="paragraphBodyS">
        Address not recognised. Please enter a different, supported address.{' '}
        <InlineLinkOrButton onClick={(): void => dialogRef.current?.toggle()}>
          View supported addresses and networks.
        </InlineLinkOrButton>
      </Typography>
      <SupportedCryptoCurrenciesDialog ref={dialogRef} />
    </Fragment>
  );
}

export function getAddressErrorMessageElement(
  error: string | undefined,
): React.ReactElement | undefined {
  let helperText: React.ReactElement | undefined;

  switch (error) {
    case AddressErrors.notFound:
      helperText = <AddressNotFound />;
      break;

    case AddressErrors.notSupported:
      helperText = (
        <Typography variant="paragraphBodyS">
          Address is valid but not supported for the selected currency.{' '}
          <Link href={walletRoutes().send.base.path}>Change currency.</Link>
        </Typography>
      );
      break;

    default:
      break;
  }

  return helperText;
}
