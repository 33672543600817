import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { generatePath, LoadingPage } from '@noah-labs/fe-shared-ui-components';
import { Redirect, useParams } from 'react-router-dom';
import { isQuestFeatureEnabled } from '../data/utils';
import { useQuestStatusById } from '../hooks/useQuestStatusById';
import { routes } from '../routes';
import { QuestScene } from '../scenes/Quest';
import { TpRewardsTabs } from '../types';

export function QuestData(): React.ReactElement | null {
  const params = useParams<{ id: string }>();
  const { data: userData } = useUserInitUi();

  const { data: questStatus, isLoading } = useQuestStatusById(params.id);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!questStatus || !isQuestFeatureEnabled(questStatus.Quest, userData?.userProfile.features)) {
    return (
      <Redirect
        to={generatePath(routes.tabs.path, {
          tab: TpRewardsTabs.Quests,
        })}
      />
    );
  }

  return <QuestScene questStatus={questStatus} />;
}
