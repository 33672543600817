import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { LoadingPage } from '@noah-labs/fe-shared-ui-components';
import {
  CompleteFailedScene,
  CompletePendingScene,
  CompleteSuccessScene,
} from '@noah-labs/fe-shared-ui-kyc';
import { KycApprovalStatus } from '@noah-labs/shared-schema-gql';

export function Complete(): React.ReactElement {
  const { data: userData } = useUserInitUi();

  if (!userData) {
    return <LoadingPage message="Retrieving your verification result..." />;
  }

  switch (userData.userProfile.KycVerification.ApprovalStatus) {
    case KycApprovalStatus.Approved:
      return <CompleteSuccessScene />;
    case KycApprovalStatus.Declined:
      return <CompleteFailedScene />;
    case KycApprovalStatus.Pending:
    default:
      return <CompletePendingScene />;
  }
}
