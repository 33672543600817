import type { ReactElement } from 'react';
import type { CSSInterpolation } from '@emotion/css';
import { css } from '@emotion/react';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { PpWC, TpCryptoNetworkUI } from '@noah-labs/fe-shared-ui-shared';

type PpQRCodeArea = {
  cryptoNetwork: TpCryptoNetworkUI;
  dataQa?: string;
} & PpWC;

export function QRCodeArea({ children, cryptoNetwork, dataQa }: PpQRCodeArea): ReactElement {
  const theme = useTheme();

  // Your existing emotion styles
  const styles = {
    border: css`
      ${cryptoNetwork.styles.border as CSSInterpolation};
      padding: ${theme.spacing(0.25, 0.25)};
      border-radius: ${theme.borderRadius.md};
    `,
    card: css`
      ${cryptoNetwork.styles.card as CSSInterpolation};
      border-radius: ${theme.borderRadius.md};
      color: ${theme.palette.grayscale[100]};
      position: relative;
    `,
  };

  return (
    <div css={styles.border} data-qa={dataQa}>
      <Stack css={styles.card}>{children}</Stack>
    </div>
  );
}
